import React, { useState, useRef, useEffect } from 'react'
import { stripUnit } from 'polished'
import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
  VictoryTooltip,
  LineSegment,
} from 'victory'
import styled, { withTheme } from 'styled-components'

const gridInterval = 32
const borderWidth = 1

const renderLabel = ({ datum }) =>
  datum.value !== null &&
  (datum.value >= 0
    ? `+${datum.value.toFixed(2)} %`
    : `${datum.value.toFixed(2)} %`)

const GridWithOffset = props => {
  return (
    <LineSegment
      {...props}
      type="grid"
      x1={props.x1 + gridInterval}
      x2={props.x2 + gridInterval}
    />
  )
}

const ChartStyle = styled.div`
  svg:first-of-type {
    > g {
      &:first-of-type,
      &:nth-of-type(2) > g:last-of-type {
        > line {
          stroke: none !important;
        }
      }
    }
  }
`

const FundGraph = ({ data, theme: { colors, radius } }) => {
  const [relativeFontSize, setRelativeFontSize] = useState(0)

  const wrapperRef = useRef()

  const graphPadding = gridInterval - borderWidth * 2

  useEffect(() => {
    if (!data.length) return

    const fontSize = (500 / wrapperRef.current.clientWidth) * 12
    setRelativeFontSize(fontSize)
  }, [data])

  if (!data.length) {
    return null
  }

  const tickMax = Math.abs(
    Math.ceil(
      data
        .map(datum => datum.value)
        .reduce((acc, curr) => Math.max(Math.abs(acc), Math.abs(curr)), 1) / 5
    ) * 5
  )

  const filledData = Array.from({ length: 7 - data.length })
    .fill(null)
    .map((_, i) => {
      const earliestTimePeriod = data[0].timePeriod
      return { timePeriod: earliestTimePeriod.previous(i + 1), value: null }
    })
    .reverse()
    .concat(data)

  const graphData = filledData.map(item => ({
    timePeriod: item.timePeriod.render(),
    value: item.value,
  }))

  return (
    <ChartStyle ref={wrapperRef} style={{ pointerEvents: 'none' }}>
      <VictoryChart
        domain={{ y: [-1 * tickMax, tickMax] }}
        domainPadding={{ x: [gridInterval, gridInterval + borderWidth] }}
        padding={{ top: 20, bottom: graphPadding, left: 54, right: 0 }}
        height={200}
        width={500}
        style={{ parent: { border: colors.greyChartBackground } }}
      >
        <VictoryAxis
          tickValues={[-1 * tickMax, 0, tickMax]}
          tickFormat={x => `${x} %`}
          crossAxis
          dependentAxis
          style={{
            axis: { stroke: colors.greyLight },
            grid: { stroke: colors.greyLight },
            tickLabels: {
              fontFamily: 'inherit',
              fontSize: relativeFontSize,
              padding: 5,
              renderInPortal: true,
            },
          }}
        />
        <VictoryAxis
          crossAxis
          style={{
            axis: { stroke: colors.greyLight },
            tickLabels: {
              fontFamily: 'inherit',
              fontSize: relativeFontSize,
              padding: 85,
              fill: ({ index }) =>
                graphData[index].value === null && colors.grey,
            },
            grid: { stroke: colors.greyLight },
          }}
          gridComponent={<GridWithOffset />}
        />
        <VictoryBar
          alignment="middle"
          data={graphData}
          x="timePeriod"
          y="value"
          cornerRadius={2}
          barRatio={0.7}
          style={{
            data: {
              fill: ({ datum }) =>
                datum.value >= 0
                  ? colors.primaryColor
                  : colors.chart.deficitColor,
            },
            labels: {
              fill: colors.primaryColor,
              fontSize: relativeFontSize,
              padding: 5,
            },
          }}
          labels={renderLabel}
          labelComponent={
            <VictoryTooltip
              cornerRadius={stripUnit(radius.md)}
              pointerWidth={0}
              pointerLength={0}
              renderInPortal={true}
              dy={datum => (datum !== null && datum.value >= 0 ? 0 : -20)}
              flyoutStyle={{ stroke: colors.greyLight, fill: colors.white }}
              style={{
                fontFamily: 'inherit',
                fill: colors.primaryColor,
                fontSize: relativeFontSize,
                padding: 5,
              }}
            />
          }
        />
      </VictoryChart>
    </ChartStyle>
  )
}

export default withTheme(FundGraph)
