import styled from 'styled-components'
import Button from '../buttons/button'
import sortArrowStyle from '../sortArrow'

const Tab = styled(Button).attrs({ role: 'tab' })`
  border-radius: ${({ isLarge }) => (isLarge ? '1em' : '.5em')};
  margin-right: ${({ theme }) => theme.spacing.small};
  display: inline-block;
  vertical-align: middle;
  padding-left: 0.5em;
  text-align: left;

  :before {
    content: '';
    margin-right: 4px;
    display: inline-block;
    vertical-align: middle;
    ${sortArrowStyle};
    margin-left: 0;
  }
`

export default Tab
