import React from 'react'
import styled from 'styled-components'
import { bool } from 'prop-types'
import { personPropTypes } from '../../utils/prop-types'
import BackgroundImage from '../backgroundImage'
import Subheading from '../typography/headings'
import SmallText from '../typography/smallText'
import LinkItemStyle from '../links/linkItemStyle'

const propTypes = {
  data: personPropTypes.isRequired,
  isAsideCard: bool,
}

const generateGMapsLink = ({ lat, lon }) =>
  `https://www.google.fi/maps/place/${lat}+${lon}/@${lat},${lon},15z`

const ImageWrap = styled.div`
  position: relative;
  height: 120px;
  width: 90px;
  margin-bottom: 1em;
  align-self: center;

  @media (max-width: 1200px) {
    align-self: flex-start;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    align-self: center;
  }
`

const PersonData = styled.div(
  ({ theme: t }) => `
  width: 100%;
  color: ${t.colors.blue};
  margin-top: auto;

  a {
    color: inherit;
  }

  > div {
    word-break: break-all;
  }
`
)

const Name = styled(Subheading).attrs({ headingSize: '3' })`
  font-family: Saol Display;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 10px;
`

const PersonTitle = styled.p`
  margin: 0 0 10px;
`

const Content = styled(SmallText).attrs({ as: 'div' })`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 400px) {
    padding-bottom: 0;
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }

  a {
    font-size: 12px !important;
    line-height: normal;
    margin: 0 !important;
  }
`

const Wrapper = styled.div``

const FlexWrapper = styled.div`
  @media (min-width: 400px) {
    display: flex;
    min-height: 170px;

    ${ImageWrap} {
      height: auto;
      min-height: 150px;
      flex: 0 1 100px;
      width: 100px;
      margin-bottom: 0;
    }
    ${PersonData} {
      padding-left: 15px;
      padding-bottom: 5px;
      display: flex;
      flex: 1 1 calc(100% - 100px);
      flex-direction: column;
    }
  }
`

const OfficesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`

const PersonCardContent = ({ data, isAsideCard }) => {
  const offices = data.allOffices || []

  const renderOffices = () => {
    if (offices.length === 0) {
      return null
    }

    return (
      <OfficesWrapper>
        {offices.map((office, idx) => (
          <LinkItemStyle
            key={office.id}
            to={generateGMapsLink(office.location)}
            focusAfter
            hidebefore={(idx > 0).toString()}
          >
            {office.city}
          </LinkItemStyle>
        ))}
      </OfficesWrapper>
    )
  }

  const renderCardContent = () => {
    return (
      <>
        <ImageWrap>
          <BackgroundImage
            image={data.image.gatsbyImageData}
            alt={`${data.firstName} ${data.lastName}`}
          />
        </ImageWrap>
        <PersonData>
          <Name>
            {data.firstName}
            <br />
            {data.lastName}
          </Name>
          <Content>
            {data.title && <PersonTitle>{data.title}</PersonTitle>}
            {data.phoneNumber && (
              <LinkItemStyle to={`tel:${data.phoneNumber}`} focusAfter>
                {data.phoneNumber}
              </LinkItemStyle>
            )}
            {data.email && (
              <LinkItemStyle to={`mailto:${data.email}`} focusAfter>
                {data.email}
              </LinkItemStyle>
            )}
            {renderOffices()}
          </Content>
        </PersonData>
      </>
    )
  }
  return (
    <Wrapper name={data.slug} id={data.slug}>
      {isAsideCard ? (
        renderCardContent()
      ) : (
        <FlexWrapper>{renderCardContent()}</FlexWrapper>
      )}
    </Wrapper>
  )
}

PersonCardContent.propTypes = propTypes

export default PersonCardContent
