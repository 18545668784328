import styled from 'styled-components'

export default styled.div`
  overflow-x: auto;

  > div {
    border-top: 0;
  }

  table {
    border-top: 0;
  }

  thead {
    border-top: 0;
  }
`
