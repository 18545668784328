import { css } from 'styled-components'

import button from './button'

export default css`
  a {
    color: ${p => p.theme.colors.primaryColor};

    &:not(.button) {
      text-decoration: underline;
    }

    :hover {
      color: ${p => p.theme.colors.highlightColor};
    }
  }

  a.button {
    ${button}
  }
`
