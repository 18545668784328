import React from 'react'

const EUR = ({ value, minimumFractionDigits = 2 }) => {
  const float = parseFloat(value)
  const floatToLocaleString = float.toLocaleString('fi-FI', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits,
  })
  const eur = isNaN(float) ? '-' : floatToLocaleString

  return <span>{eur}</span>
}

export default EUR
