/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Location } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

// NOTE: as lax ruling as possible. this should not even be here, but due to no access to envs this was done
const isProduction = loc =>
  !loc || !loc.host || typeof loc.host.indexOf !== 'function'
    ? true
    : loc.host.indexOf('alexandria.fi') > -1

function SEO({ description, image, lang, meta, keywords, title }) {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulConfig {
          edges {
            node {
              title
              description {
                description
              }
              author
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  )
  const siteConfig = data.allContentfulConfig.edges[0].node

  const metaImage = image || `https:${siteConfig.image.file.url}`
  const metaDescription = description || siteConfig.description.description

  return (
    <Location>
      {({ location }) => {
        const isProd = isProduction(location)

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${siteConfig.title}`}
            meta={[
              {
                name: 'google-site-verification',
                content: isProd
                  ? 'df_KAg_o70SFejBsfkUxutFdNX-TRHnKMa5b-xHwyGY'
                  : '',
              },
              {
                name: 'facebook-domain-verification',
                content: isProd ? 'iefbdg7xrqacyjd6qbidnd13q469u1' : '',
              },
              {
                name: 'robots',
                content: !isProd ? 'noindex' : '',
              },
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: title,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:image',
                content: metaImage,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:creator',
                content: siteConfig.author,
              },
              {
                name: 'twitter:title',
                content: title,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
              {
                name: 'twitter:image',
                content: metaImage,
              },
              {
                name: 'referrer',
                content: 'origin',
              },
              {
                name: 'twitter:dnt',
                content: 'on',
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: 'keywords',
                      content: keywords.join(', '),
                    }
                  : []
              )
              .concat(meta)
              .filter(({ content }) => !!content)}
          />
        )
      }}
    </Location>
  )
}

SEO.defaultProps = {
  lang: 'fi-FI',
  meta: [],
  keywords: [],
  description: '',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.any),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
