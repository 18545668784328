import React from 'react'

import HelpText from './helpText'
import FieldWrapper from './fieldWrapper'
import ContainerFieldWrapper from './containerFieldWrapper'
import Label from './label'

const Textarea = ({
  field,
  form,
  helpText,
  suffix,
  label,
  required,
  isContainer,
  ...props
}) => {
  const Component = isContainer ? ContainerFieldWrapper : FieldWrapper
  return (
    <Component
      invalid={!!(form.touched[field.name] && form.errors[field.name])}
    >
      {label && <Label target={field.name} value={label} required={required} />}
      <div>
        <textarea {...field} id={field.name} {...props}>
          {props.value}
        </textarea>
        {suffix && <span>{suffix}</span>}
      </div>
      {helpText && <HelpText>{helpText}</HelpText>}
    </Component>
  )
}

export default Textarea
