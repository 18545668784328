import React from 'react'
import styled from 'styled-components'
import { navItemsPropTypes } from '../../utils/prop-types'
import FooterLinks from './footerLinks'
import FooterTagCloud from './footerTagCloud'
import FooterContact from './footerContact'
import SocialMediaLinks from './socialMediaLinks'
import Link from '../links/link'
import SmallCaps from '../typography/smallCaps'
import Logo from '../../images/alexandria-logo'
import AccessibilityText from '../typography/accessibilityText'
import Button from '../buttons/button'

import { ReactComponent as LockIcon } from '../../images/lock.svg'

const propTypes = {
  footerNavItems: navItemsPropTypes.isRequired,
}

const LogoLink = styled(Link)`
  padding-right: 65px;
  svg {
    width: 140px;
    height: 100%; // IE has issues with this, but that's a compromise.
  }

  @media (max-width: ${p => p.theme.breakpoints.md.max}) {
    padding-right: 0;
  }
`

const Wrapper = styled.footer(
  ({ theme: t }) => `
  padding: 58px 90px 55px 57px;
  background: ${t.colors.backgroundColor};
  color: ${t.colors.primaryColor};

  a {
    color: inherit;
  }

  ${LogoLink} {
    path {
      fill: ${t.colors.highlightColor};
    }
  }

  @media (max-width: ${t.breakpoints.md.max}) {
    padding: 30px 20px 16px 20px;
  }
`
)

const Content = styled.div`
  margin: 0 auto;
  max-width: ${p => p.theme.width.max};
`

const CTAButton = () => `
  font-weight: 500;
  width: 200px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    color: white;
  }
`

const LoginButton = styled(Button)`
  ${CTAButton}
  background-color: ${p => p.theme.colors.blue};

  @media (max-width: 340px) {
    gap: 5px;
  }

  & span {
    flex: 1;
  }

  & svg {
    margin-left: auto;
  }

  &:hover {
    & svg {
      & path {
        fill: ${p => p.theme.colors.blue};
      }
    }

    span {
      color: ${p => p.theme.colors.blue};
    }
  }
`
const ReservationButton = styled(Button)`
  ${CTAButton}
  background-color: ${p => p.theme.colors.blue};
  &:hover {
    span {
      color: ${p => p.theme.colors.blue};
    }
  }
`
const ContactButton = styled(Button)`
  ${CTAButton}
  background-color: ${p => p.theme.colors.blue};
  &:hover {
    span {
      color: ${p => p.theme.colors.blue};
    }
  }
`
const NewsLetterButton = styled(Button)`
  ${CTAButton}
  background-color: ${p => p.theme.colors.blue};
  &:hover {
    span {
      color: ${p => p.theme.colors.blue};
    }
  }
`

const Nav = styled.nav`
  ul {
    li {
      &:first-of-type {
        margin-top: 15px;
      }
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.md.max}) {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;

    ul {
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const TopContainer = styled.div`
  @media (min-width: ${p => p.theme.breakpoints.md.max}) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row nowrap;
    margin-bottom: 5%;
  }
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 90px;

  @media (max-width: ${p => p.theme.breakpoints.lg.max}) {
    gap: 20px;
  }

  @media (max-width: ${p => p.theme.breakpoints.md.max}) {
    flex-direction: column;
    gap: 50px;
  }
`

const BottomContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: ${props => props.theme.spacing.xlarge};
  text-align: center;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 50px;
  }
`

const IconContainer = styled.div`
  display: flex;
  margin-left: auto;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;

  a {
    display: flex;
    width: 35px;
    align-items: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    margin-left: 0;
    margin-bottom: 50px;
  }
`

const LegalWrap = styled.div`
  color: inherit;

  p {
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: ${p => p.theme.breakpoints.md.max}) {
    margin-top: 20px;

    p {
      line-height: 18px;
      font-size: 14px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.md.max}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 3em;

  @media (max-width: ${p => p.theme.breakpoints.lg.max}) {
    margin-right: 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-right: 0;
    margin-bottom: 50px;

    & a {
      width: 100%;
    }
  }
`

const LogoAndLegalContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    flex-direction: column;
  }
`

const LinksAndContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${props =>
      props.theme.breakpoints.md.max}) and (min-width: ${props =>
      props.theme.breakpoints.sm.min}) {
    justify-content: space-around;
  }
`

const DesktopContactContainer = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    display: none;
  }
`

const MobileContactContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md.max}) {
    display: none;
  }
`

const Footer = ({ footerNavItems, footerItems, filterGroups }) => (
  <Wrapper>
    <Content>
      <TopContainer>
        <Nav aria-label="Footer">
          <ButtonContainer>
            <LoginButton as="a" href="https://oma.alexandria.fi/login">
              <span>{footerItems.buttons[0]}</span> <LockIcon />
            </LoginButton>
            <ReservationButton as="a" href="/varaa-aika">
              <span>{footerItems.buttons[1]}</span>
            </ReservationButton>
            <ContactButton as="a" href="/toimipaikat-ja-yhteystiedot">
              <span>{footerItems.buttons[2]}</span>
            </ContactButton>
            <NewsLetterButton as="a" href="/tilaa-uutiskirje">
              <span>{footerItems.buttons[3]}</span>
            </NewsLetterButton>
          </ButtonContainer>
          <LinksContainer>
            <LinksAndContactContainer>
              <FooterLinks items={footerNavItems} title="Alexandria" />
              <MobileContactContainer>
                <FooterContact
                  contactInformation={footerItems.contactInformation}
                  isMobile={true}
                />
              </MobileContactContainer>
            </LinksAndContactContainer>
            <FooterTagCloud
              values={filterGroups.values.map(option => option.value)}
              postsAmount={filterGroups.values.map(
                option => option.postsAmount
              )}
              label={filterGroups.label}
            />
          </LinksContainer>
        </Nav>
        <DesktopContactContainer>
          <FooterContact
            contactInformation={footerItems.contactInformation}
            isMobile={false}
          />
        </DesktopContactContainer>
      </TopContainer>
      <BottomContainer>
        <LogoAndLegalContainer>
          <LogoLink to="/">
            <Logo aria-label="Etusivu" />
            <AccessibilityText>Etusivulle</AccessibilityText>
          </LogoLink>
          <LegalWrap>
            <p>
              <SmallCaps>&copy; 2024 Alexandria Group Oyj</SmallCaps>
            </p>
            <p>
              <SmallCaps>Y-tunnus 1063450-9</SmallCaps>
            </p>
          </LegalWrap>
        </LogoAndLegalContainer>
        <IconContainer>
          <SocialMediaLinks />
        </IconContainer>
      </BottomContainer>
    </Content>
  </Wrapper>
)

Footer.propTypes = propTypes

export default Footer
