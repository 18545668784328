import React from 'react'
import styled from 'styled-components'
import { Heading } from '../typography/headings'
import Button from '../buttons/button'
import { Link } from 'gatsby'
import { ReactComponent as Tip } from '../../images/tip.svg'

const HeroWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props => props.theme.colors.pink};

  @media (min-width: ${props => props.theme.breakpoints.lg.min}) {
    margin-top: ${props => props.theme.navHeight.lg};
  }

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
  }

  @media (min-width: ${props => props.theme.breakpoints.md.min}) {
    height: 70vh;
  }
`
const BackgroundWrapper = styled.div`
  position: absolute;
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 70vh;

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    grid-template-rows: 70vh 1fr 1fr;
    grid-template-columns: 1fr;
  }
`

const BackgroundBlock = styled.div`
  width: 100%;
  height: 100%;

  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ContentBlock = styled.div``

const ContentGrid = styled.div`
  position: relative;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  column-gap: 20px;
  height: 100%;
  max-width: ${props => props.theme.width.max};

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    margin: 0;
    height: auto;
    grid-template-columns: 1fr;
    grid-template-rows: 70vh 1fr 1fr;
  }

  ${ContentBlock} {
    align-self: stretch;
    @media (max-width: ${props => props.theme.breakpoints.md.max}) {
      align-self: shrink;
    }
  }

  ${ContentBlock}:nth-child(1) {
    grid-column: 1 / span 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: ${props => props.theme.breakpoints.md.max}) {
      grid-column: 1 / span 1;
    }
  }
  ${ContentBlock}:nth-child(2) {
    grid-column: 5 / span 4;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: ${props => props.theme.breakpoints.md.max}) {
      grid-column: 1 / span 1;
      order: -1;
    }
  }
  ${ContentBlock}:nth-child(3) {
    grid-column: 9 / span 4;
    @media (max-width: ${props => props.theme.breakpoints.md.max}) {
      grid-column: 1 / span 1;
      order: -1;
    }
  }
`

const FeatureBox = styled.div`
  height: 70%;
  width: 80%;

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    height: 100%;
    width: 100%;

    padding: ${props => props.theme.spacing.xxlarge}
      ${props => props.theme.spacing.xxlarge};
  }

  border-radius: ${props => props.theme.radius.xxlarge};
  box-shadow: ${props => props.theme.boxShadow.heroCard};
  padding: ${props => props.theme.spacing.xxlarge}
    ${props => props.theme.spacing.medium};
  background-color: #ffe9e8;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const FeaturePadder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    margin-bottom: -136px;
    padding: ${props => props.theme.spacing.medium};
  }
`

const HeroTextBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.xlarge};

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    background-color: ${props => props.theme.colors.pink};
    h1 {
      font-size: 2.5rem;
    }
  }

  ${Heading} p {
    line-height: 1.2;
    margin-bottom: 0;
  }
`

const FloatBadgeContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const FloatBadge = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 11rem;
  width: 11rem;
  padding: 1rem;
  bottom: -4rem;
  right: 2rem;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.red};

  h2 {
    padding: 0;
    margin: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    bottom: -${props => props.theme.spacing.medium};
    right: ${props => props.theme.spacing.medium};
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${props => props.theme.spacing.xxlarge};

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    margin-top: ${props => props.theme.spacing.large};
  }

  ${Button} {
    @media (max-width: ${props => props.theme.breakpoints.md.max}) {
      background-color: transparent;
    }
`

export const CampaignHero = ({
  media,
  heading,
  body,
  featureImage,
  ctaText,
  ctaTarget,
  badge,
}) => {
  const badgeData = JSON.parse(badge)
  return (
    <HeroWrapper>
      <BackgroundWrapper>
        <BackgroundBlock />
        <BackgroundBlock>
          {media.type.includes('video') ? (
            <video
              src={media.url}
              controls={false}
              loop
              autoPlay
              muted
              playsInline
            />
          ) : (
            <img src={media.url} />
          )}
        </BackgroundBlock>
      </BackgroundWrapper>
      <ContentGrid>
        <ContentBlock>
          <HeroTextBox>
            <Heading
              headingSize={2}
              dangerouslySetInnerHTML={{
                __html: heading.childMarkdownRemark.html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            ></div>
            <ButtonWrapper>
              <Button as={Link} to={ctaTarget} large={'true'}>
                {ctaText}
              </Button>
            </ButtonWrapper>
          </HeroTextBox>
        </ContentBlock>
        <ContentBlock>
          <FeaturePadder>
            {featureImage && (
              <FeatureBox>
                <img src={featureImage} />
              </FeatureBox>
            )}
            <Tip />
          </FeaturePadder>
        </ContentBlock>
        <ContentBlock>
          {badgeData && !badgeData.isEmpty && (
            <FloatBadgeContainer>
              <Link to="#newsletter">
                <FloatBadge>
                  <Heading
                    as="h2"
                    headingSize={4}
                    dangerouslySetInnerHTML={{ __html: badgeData.text }}
                  />
                </FloatBadge>
              </Link>
            </FloatBadgeContainer>
          )}
        </ContentBlock>
      </ContentGrid>
    </HeroWrapper>
  )
}
