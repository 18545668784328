import React from 'react'
import styled from 'styled-components'
import { Heading } from '../typography/headings'
import Button from '../buttons/button'
import { Link } from 'gatsby'
import { FeedbackItemVertical } from './feedbackItem'

const data = [
  {
    comment: `Aivan huippu paikka. Ennakko käsitykset oli vähän huonot mutta matkan varrella käsitys firmasta muuttui täysin. Ei voi moittia oikein mitenkään että ensiluokkaista palvelua aina vastaanottotiskiltä neuvonantajaan asti.`,
    nps_score: 10,
  },
  {
    comment: `Alexandrian toimintamalli sopii erinomaisesti monelle sijoittajalle, niin kokemattomille kuin kokeneillekin. Turvallista ja tuottoisaa!`,
    nps_score: 9,
  },
  {
    comment:
      'Arvostan sitä, että minua palvelee aina sama henkilö, jonka osaamiseen luotan. Olisin antanut täyden 10 suositeltuun ratkaisuun, jos sen riskitaso olisi ollut alempi, mutta muuta kiinnostavaa ja minulle sopivaa ei nyt ollut tarjolla. En pidä ”suosittelu” kysymyksistä, sillä tapanani ei ole suositella tällaisia palveluja muille.',
    nps_score: 5,
  },
  {
    comment: `Minun neuvonantajani on asiantunteva ja osaa kertoa asiat että ymmärrän mitä markkinoilla tapahtuu. Ajanvaraaminen on helppoa ja mukavaa.`,
    nps_score: 7,
  },
  {
    comment:
      'Aina yhtä positiivinen ja kiireetön ilmapiiri. Ei tuputusta, vaan asiallista henkilökohtaista neuvontaa ja ehdotuksia perusteluineen. Kiitos!',
    nps_score: 10,
  },
]

const CommentStripWrapper = styled.div`
  width: 100%;
  padding: 4rem 0 8rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CommentStripInnerWrapper = styled.div`
  width: 100%;
  overflow: scroll;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 1rem;
  margin: 1rem 0 5rem 0;
  padding: 0 1rem;
  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    scroll-snap-type: x mandatory;
  }
`

const CommentItemContainer = styled.div`
  display: block;
  margin-top: ${props => (props.odd ? '0' : '1.5rem')};
  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    margin-top: 0;
  }
`

const CommentCTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    max-width: ${props => props.theme.width.content};
  }

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    h2 {
      text-align: center;
      padding: ${props => props.theme.spacing.medium}
        ${props => props.theme.spacing.medium};
    }

    p {
      padding: 0 ${props => props.theme.spacing.medium};
    }
  }
`

export const CommentStrip = () => {
  return (
    <CommentStripWrapper>
      <CommentStripInnerWrapper>
        {data.map((feedback, index) => (
          <CommentItemContainer odd={index % 2 === 0}>
            <FeedbackItemVertical key={index} feedback={feedback} />
          </CommentItemContainer>
        ))}
      </CommentStripInnerWrapper>
      <CommentCTAContainer>
        <Heading headingSize={3} as="h2">
          Tervetuloa tyytyväisten sijoittajien joukkoon
        </Heading>
        <p>
          Yli 90 % asiakkaistamme antaa meille positiivisen palautteen.
          Läpinäkyvyyden nimissä päätimme kuitenkin julkaista kaikki palautteet
          avoimesti. Viimeisen 1,5 vuoden aikana olemme saaneet yli 15 000
          asiakaspalautetta. Tuhannet heistä kertovat myös omin sanoin,
          millaisia kokemuksia heillä on sijoitusneuvonnasta.
        </p>
        <Button as={Link} to={'/kokemuksia/asiakaspalautteet'} large={'true'}>
          Katso, mitä meistä puhutaan
        </Button>
      </CommentCTAContainer>
    </CommentStripWrapper>
  )
}
