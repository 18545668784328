import styled from 'styled-components'

export default styled.div`
  background: ${({ theme }) => theme.colors.offWhite};
  border-radius: 1.25rem;
  padding: 1.25rem;

  &[href] {
    transition: border-color 0.125s ease-in, box-shadow 0.125s ease-in,
      background 0.125s ease-in;
    :hover,
    :active {
      outline: none;
      transition-timing-function: ease-out;
    }

    // Take advantage of Link component's default :focus style
    :before {
      margin: 0;
      border-radius: inherit;
    }

    :active:before {
      border-color: ${p => p.theme.colors.primaryColor};
    }

    :hover {
      box-shadow: 0 2px 8px rgba(0, 4, 80, 0.3);
      background: ${({ theme }) => theme.colors.backgroundColor};
    }
  }

  p {
    word-break: break-word;
  }
`
