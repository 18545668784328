import React from 'react'
import { shape, string } from 'prop-types'
import styled from 'styled-components'
import { imagePropTypes } from '../../utils/prop-types'
import EditSvgUrl from '../../images/edit.svg'
import Card from './card'
import MediumText from '../typography/mediumText'
import BackgroundImage from '../backgroundImage'

const propTypes = {
  data: shape({
    image: imagePropTypes,
    firstName: string.isRequired,
    lastName: string.isRequired,
    articlePresentation: shape({
      articlePresentation: string.isRequired,
    }),
  }).isRequired,
}

const ImageWrap = styled.div`
  position: relative;
  height: 150px;
  width: 100px;
  margin-bottom: 1em;
`

const Name = styled(MediumText).attrs({ as: 'p' })`
  padding-left: 1.75em;
`

const Presentation = styled.p`
  white-space: pre-wrap;
`

const Email = styled.p`
  white-space: pre-wrap;
  padding-left: 33px;
`

const PersonData = styled.div(
  ({ theme: { colors } }) => `
  width: 100%;
  position: relative;
  color: ${colors.primaryColor};

  ${Name} {
    &:before {
      content: url('${EditSvgUrl}');
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`
)

const FlexWrapper = styled.div`
  @media (min-width: 48em) {
    display: flex;
    min-height: 150px;

    ${ImageWrap} {
      flex: 0 1 100px;
      height: 150px;
      width: 100px;
      margin-bottom: 0;
    }
    ${PersonData} {
      padding-left: 1.5em;
      flex: 1 1 calc(100% - 100px);
      display: flex;
      flex-direction: column;
    }
    ${Name} {
      &:before {
        top: 0;
        left: 1.5em;
      }
    }
  }
`

const AuthorCard = ({
  data: { firstName, lastName, email, articlePresentation, image },
  ...rest
}) => {
  const { articlePresentation: authorPresentation } = articlePresentation || {}
  const { gatsbyImageData: imageData } = image
  return (
    <Card {...rest}>
      <FlexWrapper>
        <ImageWrap>
          <BackgroundImage image={imageData} alt={`${firstName} ${lastName}`} />
        </ImageWrap>
        <PersonData>
          <Name>
            <strong>
              {firstName} {lastName}
            </strong>
          </Name>
          {email && !authorPresentation && <Email>{email}</Email>}
          {authorPresentation && (
            <Presentation>{authorPresentation}</Presentation>
          )}
        </PersonData>
      </FlexWrapper>
    </Card>
  )
}

AuthorCard.propTypes = propTypes

export default AuthorCard
