import React, { useState, useEffect } from 'react'
import { Location } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'

import DesktopNavbar from './desktopNavbar'
import MobileNavbar from './mobileNavbar'

const DEFAULT = 'default'

// import { useIntersect } from '../../utils/useIntersect'
//
// const isViewportAtTop = observerEntry => {
//   const isObserverEntryInitialized = () => Object.entries(observerEntry).length === 0 && observerEntry.constructor === Object
//   const isObserverEntryFullyInViewport = () => observerEntry.intersectionRatio === 1
//
//   return isObserverEntryInitialized() || isObserverEntryFullyInViewport()
// }
// const [ref, observerEntry] = useIntersect({
//   threshold: [1],
// })
// const viewportAtTop = isViewportAtTop(observerEntry)

const NavContainer = styled.div`
  &.isHidden {
    visibility: hidden;
  }
`

const Navbar = ({ theme, isSearchOpen, setIsSearchOpen, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPage {
        nodes {
          __typename
          slug
          title
        }
      }
      allContentfulArticle {
        nodes {
          __typename
          slug
          title
        }
      }
      allContentfulInvestorMaterialPage {
        nodes {
          __typename
          slug
          title
        }
      }
      allContentfulIpoMarketingPage {
        nodes {
          __typename
          slug
          title
        }
      }
      allContentfulStructuredInvestment {
        nodes {
          __typename
          slug
          title
        }
      }
      allContentfulOffice {
        nodes {
          __typename
          slug
          title
        }
      }
      allContentfulJobPosting {
        nodes {
          __typename
          slug
          title
        }
      }
      allContentfulLegal {
        nodes {
          __typename
          slug
          title
        }
      }
      allContentfulInsurance {
        nodes {
          __typename
          slug
          title
        }
      }
      allContentfulFund {
        nodes {
          __typename
          slug
          title
        }
      }
      allContentfulCareerPost {
        nodes {
          __typename
          slug
          title
        }
      }
      allContentfulCustomerExperiencePage {
        nodes {
          __typename
          slug
          title
        }
      }
      allContentfulNavbar {
        edges {
          node {
            name
            navigationGroups {
              title
              items {
                ... on Node {
                  ... on ContentfulArticle {
                    __typename
                    slug
                    title
                  }
                  ... on ContentfulCareerPost {
                    __typename
                    slug
                    title
                  }
                  ... on ContentfulFund {
                    __typename
                    slug
                    title
                  }
                  ... on ContentfulInsurance {
                    __typename
                    slug
                    title
                  }
                  ... on ContentfulJobPosting {
                    __typename
                    slug
                    title
                  }
                  ... on ContentfulLegal {
                    __typename
                    slug
                    title
                  }
                  ... on ContentfulOffice {
                    __typename
                    slug
                    title
                  }
                  ... on ContentfulPage {
                    __typename
                    slug
                    title
                  }
                  ... on ContentfulInvestorMaterialPage {
                    __typename
                    slug
                    title
                  }
                  ... on ContentfulIpoMarketingPage {
                    __typename
                    slug
                    title
                  }
                  ... on ContentfulStructuredInvestment {
                    __typename
                    slug
                    title
                  }
                  ... on ContentfulLink {
                    __typename
                    url
                    title
                  }
                  ... on ContentfulCustomerExperiencePage {
                    slug
                    title
                  }
                  ... on ContentfulNpsPage {
                    slug
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [hidden, setHidden] = useState(false)

  const handleScroll = () => {
    const currentScrollPos = window.scrollY
    setHidden(
      !(
        currentScrollPos <= 122 ||
        currentScrollPos === 0 ||
        prevScrollPos > currentScrollPos
      )
    )
    setPrevScrollPos(currentScrollPos)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevScrollPos, hidden])

  useEffect(() => {
    setHidden(false)
  }, [isSearchOpen])

  return (
    <Location>
      {({ location }) => {
        // Searchable pages:
        const pages = data.allContentfulPage.nodes
        const articles = data.allContentfulArticle.nodes
        const investorMaterialPages =
          data.allContentfulInvestorMaterialPage.nodes
        const ipoMarketingPages = data.allContentfulIpoMarketingPage.nodes
        const structuredInvestmentPages =
          data.allContentfulStructuredInvestment.nodes
        const offices = data.allContentfulOffice.nodes
        const jobPostings = data.allContentfulJobPosting.nodes
        const legals = data.allContentfulLegal.nodes
        const insurances = data.allContentfulInsurance.nodes
        const funds = data.allContentfulFund.nodes
        const careerPosts = data.allContentfulCareerPost.nodes
        const customerExperiencePages =
          data.allContentfulCustomerExperiencePage.nodes

        const edges = data.allContentfulNavbar.edges

        const allPages = pages.concat(
          articles,
          investorMaterialPages,
          ipoMarketingPages,
          structuredInvestmentPages,
          offices,
          jobPostings,
          legals,
          insurances,
          funds,
          careerPosts,
          customerExperiencePages
        )

        allPages.sort((a, b) => a.title.localeCompare(b.title))

        const {
          node: { navigationGroups },
        } = edges.find(({ node: { name } }) => name.toLowerCase() === DEFAULT)

        const navbarNode = (
          <header>
            <NavContainer className={hidden ? 'isHidden' : ''}>
              <DesktopNavbar
                navGroups={navigationGroups}
                pages={allPages}
                isSearchOpen={isSearchOpen}
                setIsSearchOpen={setIsSearchOpen}
                {...rest}
              />
              <MobileNavbar
                navGroups={navigationGroups}
                pages={allPages}
                isSearchOpen={isSearchOpen}
                setIsSearchOpen={setIsSearchOpen}
                {...rest}
              />
            </NavContainer>
          </header>
        )

        if (theme) {
          return <ThemeProvider theme={theme}>{navbarNode}</ThemeProvider>
        }
        return navbarNode
      }}
    </Location>
  )
}

export default Navbar
