import React from 'react'
import styled from 'styled-components'

import RatingLabel from './ratingLabel'

const Wrapper = styled.div`
  text-align: center;
`

const Bar = styled.span`
  background-color: ${props =>
    props.active ? props.theme.colors.blue : props.theme.colors.greyLight};
  display: inline-block;
  height: ${props => (props.active ? '9px' : '5px')};
  margin-right: 3px;
  width: 5px;

  &:last-child {
    margin-right: 0;
  }
`

const SRRIRating = ({ value }) => {
  return (
    <Wrapper>
      {new Array(7).fill().map((_, index) => {
        const active = index < value
        return <Bar key={index} active={active} />
      })}
      <RatingLabel>Riskitaso {value}</RatingLabel>
    </Wrapper>
  )
}

export default SRRIRating
