import React from 'react'
import styled from 'styled-components'
import { FeedbackScore } from './feedbackScore'

const SummaryPositioner = styled.div`
  display: flex;
  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    padding-left: 0.5rem;
  }
`

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  span {
    font-size: 0.875rem;
    color: rgba(0, 4, 80, 1);
  }
`

export const FeedbackSummaryItem = ({ feedback }) => {
  return (
    <SummaryPositioner>
      <SummaryWrapper>
        <FeedbackScore score={feedback.nps_score} />
        {/*         <span>18-55 vuotias</span>
        <span>sijoittaja</span> */}
      </SummaryWrapper>
    </SummaryPositioner>
  )
}
