import React from 'react'
import { graphql } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import { pinkTheme } from '../styles/theme'
import Layout from '../components/layout'
import LayoutContent from '../components/layoutContent'
import SplitHero from '../components/heroes/splitHero'
import Performance from '../components/fund/performance'
import CommonInfoAccordion from '../components/fund/commonInfoAccordion'
import SustainabilityInfoAccordion from '../components/fund/sustainabilityInfoAccordion'
import ReservationContainer from '../components/reservationContainer/reservationContainer'

const renderHero = ({ image, heading, title, leadParagraph }) => {
  const { leadParagraph: lead } = leadParagraph || {}
  return (
    <ThemeProvider theme={pinkTheme}>
      <SplitHero {...{ image, lead, heading: heading || title }} />
    </ThemeProvider>
  )
}

const StyledPerformance = styled(Performance)`
  margin-bottom: 2em;
`

const FundPage = ({
  data: { contentfulFund: fund },
  pageContext: { fundData },
}) => {
  const renderPerformace = () => {
    if (!fundData) {
      return null
    }
    // If rating is set in Contentful, override the value coming from Morningstar
    if (fund.riskRating && fundData['CollectedSRRI']) {
      fundData['CollectedSRRI']['Rank'] = fund.riskRating
    }
    return <StyledPerformance data={fundData} />
  }

  const image = `https:${fund.image.file.url}`
  return (
    <Layout
      title={fund.title}
      description={fund.description.description}
      image={image}
      hero={renderHero(fund)}
      showContactMethods={fund.showContactMethods}
      contactMethodsShort={true}
    >
      <LayoutContent
        renderContent={MainComponent => (
          <>
            <MainComponent
              alignTo="left"
              dangerouslySetInnerHTML={{
                __html: fund.body.childMarkdownRemark.html,
              }}
            />
            {renderPerformace()}
            <CommonInfoAccordion fund={fund} morningstarData={fundData} />
            <SustainabilityInfoAccordion fund={fund} />
            <ReservationContainer paddingTop={true} />
          </>
        )}
      />
    </Layout>
  )
}

export default FundPage

export const query = graphql`
  query ($slug: String!) {
    contentfulFund(slug: { eq: $slug }) {
      slug
      title
      description {
        description
      }
      leadParagraph {
        leadParagraph
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
        gatsbyImageData(width: 1200, placeholder: BLURRED)
      }
      managers
      investmentStrategy {
        childMarkdownRemark {
          html
        }
      }
      isin
      startDate
      entryFee
      exitFee
      managementFee
      ter
      feeDetails {
        childMarkdownRemark {
          html
        }
      }
      minimumInvestment
      minimumMonthlyInvestment
      keyInformation {
        title
        file {
          url
        }
      }
      keyInformationBiSeries {
        title
        file {
          url
        }
      }
      universalBrochure {
        title
        file {
          url
        }
      }
      rules {
        title
        file {
          url
        }
      }
      universalRules {
        title
        file {
          url
        }
      }
      pricing {
        title
        file {
          url
        }
      }
      benchmarkIndex
      riskRating
      sustainabilityNegativeEffects {
        childMarkdownRemark {
          html
        }
      }
      sustainableFundsInfo {
        childMarkdownRemark {
          html
        }
      }
      environmentalFundsInfo {
        childMarkdownRemark {
          html
        }
      }
      showContactMethods
    }
  }
`
