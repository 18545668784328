import React from 'react'
import styled from 'styled-components'
import linkStyles from '../styles/mixins/links'
import { linkItemStyles } from './links/linkItemStyle'

const Div = styled.div(
  ({ theme, marginTop }) => `
  margin-top: ${marginTop ? '50px !important' : '0'};
  max-width: 40em;
  margin: 0 auto;
  img {
    max-width: 100%;
  }

  iframe {
    max-width: 100%;
  }

  table {
    width: 100%;
    padding: 0 !important;
    table-layout: fixed;

    th {
      border-bottom: 1px solid ${theme.colors.chart.borderColor};
    }

    td,
    th {
      text-align: right;
      padding-right: 1rem;
      padding-left: 0.5rem;
    }
    
    td:first-child,
    th:first-child {
      text-align: left;
      padding-left: 1rem;
    }

    td:last-child,
    th:last-child {
      padding-right: 6rem; // hard-coding some padding between last column and right side of table. This might break things if there are multiple columns
    }

    tr:last-child td {
      padding-bottom: 0.5rem;
    }
  }

  /* Below are some CSS classes to apply for Contentful Body Text. */
  /* Used in pages such as teemme-sijoittamisesta-sinulle-helppoa, tervetuloa-alexandriaan */

  .video-container {
    &-outer {
      margin-bottom: ${theme.spacing.xlarge}
    }

    &-inner {
      position: relative;
      padding-bottom: 75%;

      iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .cta-button {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 20px;
    padding: 15px 20px;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none !important;
    
    &:hover {
      transform: scale(1.05);
      transition: transform 0.5s ease 0s;
    }
  }
  
  .cta-button.bg-grey {
    background: rgba(153, 153, 153, 0.20);
    color: ${theme.colors.primaryColor};
  }

  .flex-container {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 1.45em;
    gap: .5em;
    justify-content: center;

    .card, .article-card {
      flex: 1;
    }

    .card.w-half,
    .article-card.w-half {
      flex-basis: calc(50% - .5em);
    }

    .card.w-third,
    .article-card.w-third {
      flex-basis: calc(33% - .5em);
    }
  }

  .card {
    padding: 1.25rem;
    border-radius: 24px;

    &-text-content {
      height: 100%;
      display: flex;
      flex-direction: column;

      div {
        flex: 1;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    &-text-content.center {
      justify-content: center;

      div {
        flex: 0;
      }
    }

    a.button {
      background-color: ${theme.colors.blue} !important;
      border: 1px solid ${theme.colors.blue} !important;
      color: white !important;
      ${linkStyles};
    }

    a {
      ${linkItemStyles};
      text-decoration: none !important;
    }

    &-pink {
      background-color: ${theme.colors.pink};
    }

    &-red {
      background-color: ${theme.colors.red};
    }

    &-blue {
      background-color: ${theme.colors.blue};

      a.button {
        border: 1px solid white !important;
      }

      .card-text-content * {
        color: white !important;
      }
    }

    &-blue-light {
      background-color: ${theme.colors.blueLight};
    }

    &-offwhite {
      background-color: ${theme.colors.offWhite};
    }
  }

  .card.small-text {
    .card-text-content p {
      line-height: 1.25;
      margin-bottom: 1em;
      font-size: 16px;
    }
  }

  .card.smaller-text {
    .card-text-content p {
      line-height: 1.25;
      margin-bottom: 1em;
      font-size: 14px;
    }
  }

  .article-card {
    border-radius: 24px;
    background-color: ${theme.colors.white};
    box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px;
    
    & > a {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      text-decoration: none !important;
      
      .image-wrapper {
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
        overflow: hidden;
        height: 180px;
        width: 100%;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  
      .card-body {
        flex-grow: 1;
        padding: 1.25rem 1rem;
      }
    }
  }

  .split-card {
    border-radius: 24px;
    background-color: ${theme.colors.white};
    box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px;
    padding: 1.25rem;
    display: flex;
    flex-direction: row;

    .body-left {
      min-width: 40%;
      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .body-right {
      flex-grow: 1;
      padding-left: 1.25rem;

      p {
        font-size: 14px;
      }

      strong {
        font-size: 16px;
      }
    }
  }

  .split-container {
    display: flex;
    flex-flow: row nowrap;
    gap: .5em;
    padding-bottom: 1.45em;

    .body-left.image-wrapper {
      overflow: hidden;
      position: relative;
      min-width: 33%;
      min-height: 280px;

      img {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .body-right {
      flex-grow: 0;

      p {
        margin: 0;
      }
      
      blockquote {
        font-size: 1.25rem;
        margin: 0;
        padding: 0;
      }
    }
  }

  .hero.w-full-page {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    padding: 3em 1em;
    margin: 3em 0;

    &.bg-blue {
      background-color: ${theme.colors.blue};
      color: ${theme.colors.white};
      
      h2, h3 {
        color: ${theme.colors.red};
      }
    }

    &.no-margin-top {
      margin-top: 0;
    }

    &.no-padding-bottom {
      padding-bottom: 0;
    }

    .hero-content {
      max-width: 37.5em;
      margin: 0 auto;
    }

    ol {
      list-style-type: none;
      counter-reset: my-counter;
      padding-left: 5.5rem;

      li {
        counter-increment: my-counter;
        position: relative;

        h3 {
          display: flex;
          align-items: center;
          margin-bottom: 0.125em;
          
          ::before {
            content: counter(my-counter);
            display: inline-block;
            width: 1.5em;
            height: 1.5em;
            color: inherit;
            border: 1px solid currentColor;
            border-radius: 50%;
            font-size: 1.75em;
            text-align: center;
            line-height: 1.5em;
            margin-left: -2em;
            margin-right: 0.5em;
            flex-shrink: 0;
          }
        }
      }

    }
  }

  @media (max-width: ${theme.breakpoints.sm.max}) {
    table {
      td:last-child,
      th:last-child {
        padding-right: 1rem;
      }
    }

    .flex-container {
      flex-direction: column;

      .card,
      .article-card,
      .split-card {
        width: 100%;
      }
    }

    .card,
    .article-card {
      h3 {
        flex-basis: unset;
      }
    }

    .split-card {
      flex-direction: column;

      .body-left {
        min-height: 200px;
        position: relative;
        iframe {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
        }
      }

      .body-right {
        padding-left: 0;
      }
    }

    .split-container {
      .body-right {
        blockquote {
          font-size: 14px !important;

          ::before {
            margin-left: 5px;
            margin-right: 10px;
          }
        }
      }
    }
  }
`
)

const ContentfulText = ({ text, className = '', marginTop }) => {
  if (!text) {
    return null
  }
  const __html = text.childMarkdownRemark ? text.childMarkdownRemark.html : text

  return (
    <Div
      className={className}
      dangerouslySetInnerHTML={{ __html }}
      marginTop={marginTop}
    />
  )
}

export default ContentfulText
