import React from 'react'

import FieldWrapper from './fieldWrapper'
import ContainerFieldWrapper from './containerFieldWrapper'
import Label from './label'
import HelpText from './helpText'

const Input = ({
  children,
  field,
  form,
  helpText,
  label,
  required,
  whiteTheme,
  isContainer,
  ...rest
}) => {
  const Component = isContainer ? ContainerFieldWrapper : FieldWrapper
  return (
    <Component
      invalid={
        !!(form.touched[field.name] && form.errors[field.name] && required)
      }
      isWhite={whiteTheme}
      {...rest}
    >
      {label && <Label target={field.name} value={label} required={required} />}
      {children}
      {helpText && <HelpText>{helpText}</HelpText>}
    </Component>
  )
}

export default Input
