import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import CisionReleases from './releaseFeed'

/* What, How, Why?
 * Gatsby can generate these releases easily for us in build time. However the releases are, afaik, regulatory
 * thing and need to be on the site pretty hastily. At the same time we want to take advantage of Gatsby.
 * Solution: Fetch certain amount of releases in build time and refresh on client side in case there's newer releases available.
 * Load more is not yet necessary, but it probably needs to be done at some point so why not already, when it can be taken
 * into consideration now.
 * TLDR: seems to work.
 *
 * Edit:
 * A new page was created for releases about purchasing own stock (Ilmoitukset omien osakkeiden hankkimisesta).
 * Use this component to show Releases which have these new releases filtered out.
 */

// The name of Alexandria Pankkiiriliike changed to Alexandria Group. Is the title regex in the query still valid?

const CisionNewsReleases = ({ LIMIT = 5, slug }) => {
  const {
    feed: { nodes: releases, total },
  } = useStaticQuery(
    graphql`
      query {
        feed: allCisionFeedItem(
          filter: {
            id: { nin: ["3943587", "3954459"] }
            title: { regex: "/^((?!(.*OYJ:n OMIEN OSAKKEIDEN HANKINTA)).*)$/" }
          }
        ) {
          nodes {
            id
            title
            feedUrl
            serviceUrl
            lead
            publishDate
            url
            image {
              src
            }
          }
          total: totalCount
        }
      }
    `
  )

  return (
    <CisionReleases
      limit={LIMIT}
      slug={slug}
      releases={releases}
      total={total}
    />
  )
}

export default CisionNewsReleases
