import React, { useState, useEffect } from 'react'
import Flow from './flow'
import { ReactComponent as CoinIcon } from '../../../images/coin.svg'
import { ReactComponent as CoinsIcon } from '../../../images/coins.svg'
import { ReactComponent as BriefcaseIcon } from '../../../images/briefcase.svg'

const FlowStep2View1 = ({
  step,
  selectedOptions,
  onSelectedFlowOptionsChange,
}) => {
  const [buttonStates, setButtonStates] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const updateButtonStates = options => {
    const updatedStates = [...buttonStates]

    options.forEach(option => {
      if (option >= 0 && option < updatedStates.length) {
        updatedStates[option] = true
      }
    })

    setButtonStates(updatedStates)
  }

  useEffect(() => {
    selectedOptions && updateButtonStates(selectedOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  const flowButtons = [
    { icon: CoinIcon, text: step.buttonTexts[0] },
    {
      icon: CoinsIcon,
      text: step.buttonTexts[1],
    },
    { icon: BriefcaseIcon, text: step.buttonTexts[2] },
  ]

  return (
    <Flow
      heading={step.heading}
      flowButtons={flowButtons}
      buttonStates={buttonStates}
      setButtonStates={setButtonStates}
      onSelectedFlowOptionsChange={onSelectedFlowOptionsChange}
      currentStep={2}
      step2view1={true}
    />
  )
}

export default FlowStep2View1
