import React from 'react'
import styled from 'styled-components'
import Container from '../container'
import { getColorByContentType } from '../../utils/segments'

const Wrapper = styled.div(
  ({ color, theme: { navHeight, breakpoints } }) => `
  position: relative;
  background: ${color};
  padding: ${navHeight.min} 0 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: calc(${navHeight.min} + 315px);

  @media (min-width: ${breakpoints.md.min}) {
    padding: ${navHeight.min} 0 240px;
  }

  @media (min-width: ${breakpoints.lg.min}) {
    padding: ${navHeight.lg} 0 240px;
  }

  @media (max-width: ${breakpoints.md.max}) {
    min-height: unset;
  }
`
)

const HeroContainer = styled(Container)(
  ({ theme: { breakpoints } }) => `
  @media (min-width: ${breakpoints.lg.min}) {
    display: flex;
    justify-content: center;
  }
`
)

const ContentPageHero = ({ contentType }) => {
  return (
    <Wrapper color={getColorByContentType[contentType]}>
      <HeroContainer />
    </Wrapper>
  )
}

export default ContentPageHero
