import React from 'react'
import { graphql } from 'gatsby'

import InvestmentsPage from '../../components/investmentsPage'

const MaturedInvestmensPage = ({ data, pageContext: { feedData } }) => {
  const page = data.page
  const structuredInvestments = data.structuredInvestments.edges.map(
    ({ node }) => node
  )

  return (
    <InvestmentsPage
      page={page}
      feedData={feedData}
      contentfulData={structuredInvestments}
      showHint={false}
      showValuationEur={true}
      showEffectiveInterest={true}
      back={{
        to: '/strukturoidut-sijoitukset/strukturoitujen-sijoitusten-arvostukset',
        text: 'Nykyisten tuotteiden tiedot',
      }}
    />
  )
}

export default MaturedInvestmensPage

export const query = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
    structuredInvestments: allContentfulStructuredInvestment {
      edges {
        node {
          ...contentfulStructuredInvestmentExtendedInformation
        }
      }
    }
  }
`
