import React from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'
import CtaButton from '../buttons/ctaButton'
import DateTime from '../format/datetime'
import { Heading } from '../typography/headings'

const propTypes = {
  title: string,
  children: node,
}

const NewsReleasesContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.large};
`

const NewsRelease = styled.li`
  ${Heading},
  p {
    margin-bottom: 0.5rem;
  }
`

const NewsReleaseList = styled.ul`
  ${NewsRelease} {
    margin-bottom: ${({ theme }) => theme.spacing.large};

    &:not(:last-of-type) {
      border-bottom: 1px solid currentColor;
    }
  }
`

const NewsReleases = ({ title, children, className }) => (
  <NewsReleasesContainer className={className}>
    {title && (
      <Heading as="h2" headingSize="3">
        {title}
      </Heading>
    )}
    <NewsReleaseList>{children}</NewsReleaseList>
  </NewsReleasesContainer>
)

NewsReleases.propTypes = propTypes

const ReleaseWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
  align-items: flex-start;

  > div,
  > a {
    margin-bottom: ${({ theme }) => theme.spacing.large};
  }

  > div {
    padding-right: 1em;
  }
`

export const NewsReleaseItem = ({ title, date, lead, linkTo }) => (
  <NewsRelease>
    <p>
      <DateTime>{date}</DateTime>
    </p>
    <ReleaseWrap>
      <div>
        <Heading headingSize="4" as="h3">
          {title}
        </Heading>
        <p>{lead}</p>
      </div>
      <CtaButton to={linkTo}>Lue lisää</CtaButton>
    </ReleaseWrap>
  </NewsRelease>
)

export default NewsReleases
