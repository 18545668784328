import React from 'react'
import styled from 'styled-components'
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import Accordion from '../accordion/accordion'
import TriggerButton from '../accordion/filterMenuAccordionTrigger'
import Container from '../container'
import { ReactComponent as FilterIcon } from '../../images/filter.svg'
import { ReactComponent as CloseIcon } from '../../images/close2.svg'
import SelectInput from '../formInputs/selectInput'
import Separator from '../separator'

const propTypes = {
  optionGroups: arrayOf(node),
  postsAmount: number,
  clearAllFilters: func,
  hasSelectedFilters: bool,
  sort: shape({
    value: oneOfType([string, number]),
    options: arrayOf(string),
    onChange: func,
  }),
}

const MenuToolsWrapper = styled(Container)(
  ({ theme }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 30px;
  padding: 0 20px;

  @media (max-width: ${theme.breakpoints.md.max}) {
    gap: 20px;
    padding: 0 10px;

    & > *:last-child {
      margin-top: .5em;
    }
  }
  
  @media (min-width: ${theme.breakpoints.md.max}) {
    flex-direction: row;
    align-items: center;
    text-align: right;

    & > *:last-child {
      margin-left: auto;
    }
  }
`
)

const PostsAmountLabel = styled.span`
  font-family: Saol Display;
  color: ${p => p.theme.colors.primaryColor};
  font-size: 28px;
  font-weight: 600;
  line-height: 33px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: ${p => p.theme.breakpoints.md.max}) {
    position: relative;
    font-size: 20px;
    line-height: 24px;
    transform: none;
    left: 0;
    top: 0;
    justify-content: center;
  }

  @media (max-width: ${p => p.theme.breakpoints.md.min}) {
    width: 100%;
  }
`

const OptionGroupsWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  position: relative;
  
  @media (max-width: ${theme.breakpoints.md.max}) {
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  
  @media (min-width: ${theme.breakpoints.md.min}) {
    & > div:not(:last-child) {
      margin-right: 20px;
    }
  }
`
)

const CloseTriggerWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${p => p.theme.spacing.large} 20px 0 20px;

  @media (max-width: ${p => p.theme.breakpoints.lg.max}) {
    position: relative;
    bottom: 0;
    right: 0;
  }
`

const CloseMenuButton = styled.button(
  ({ theme }) => `
  width: 200px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-left: auto;
  border-radius: 80px;
  color: ${theme.colors.primaryColor};
  border: 2px solid ${theme.colors.primaryColor};

  svg {
    margin-left: .5em;
    path {
      fill: ${theme.colors.primaryColor};
    }
  }

  &:hover {
    background-color: ${theme.colors.primaryColor};
    color: ${theme.colors.white};

    svg {
      path {
        fill: ${theme.colors.white};
      }
    }
  }

  @media (max-width: ${theme.breakpoints.md.max}) {
    margin-left: 0;
  }
`
)

const FilterOptionsMenu = ({
  optionGroups,
  hasSelectedFilters,
  postsAmount,
  clearAllFilters,
  sort,
}) => {
  if (!optionGroups) {
    return null
  }

  return (
    <Accordion
      css={`
        margin-bottom: 20px;
        position: relative;
      `}
      renderTrigger={props => {
        return (
          <>
            <Separator sisallot={true} />
            <MenuToolsWrapper>
              <PostsAmountLabel>{postsAmount} artikkelia</PostsAmountLabel>
              {!props.open ? (
                <TriggerButton {...props}>
                  Suodata ja järjestä <FilterIcon />
                </TriggerButton>
              ) : (
                <SelectInput
                  key="sort"
                  value={sort.value}
                  options={sort.options}
                  label="Järjestä"
                  name="sort"
                  onChange={e => sort.onChange(e)}
                />
              )}
            </MenuToolsWrapper>
          </>
        )
      }}
      renderCloseTrigger={props => (
        <CloseTriggerWrapper>
          <CloseMenuButton {...props}>
            Sulje suodattimet <CloseIcon />
          </CloseMenuButton>
        </CloseTriggerWrapper>
      )}
      sections={[
        {
          title: '',
          data: <OptionGroupsWrapper>{optionGroups}</OptionGroupsWrapper>,
        },
      ]}
    />
  )
}

FilterOptionsMenu.propTypes = propTypes

export default FilterOptionsMenu
