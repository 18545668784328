import React from 'react'
import { graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { tableTheme } from '../styles/theme'
import Layout from '../components/layout'
import LayoutContent from '../components/layoutContent'
import Accordion from '../components/accordion/accordion'
import AccordionTableContent from '../components/accordion/accordionTableContent'
import { CardGroups } from '../components/contentfulPage'
import { getHeroThemeBySlug, getHeroTheme } from '../utils/segments'
import SplitHero from '../components/heroes/splitHero'
import { CardTable } from '../components/tables/table'
import FeeDetails from '../components/fund/feeDetails'
import personnelCardGroups from '../components/cards/personnelCardGroups'
import ContentfulText from '../components/contentfulText'

const RANGE = 'Liikkumaväli'
const MARKET = 'Markkina'

const renderHero = ({
  image,
  heading,
  title,
  slug,
  leadParagraph,
  heroTheme: heroThemeName,
}) => {
  const heroProps = {
    image,
    heading,
    lead: leadParagraph ? leadParagraph.leadParagraph : null,
  }
  const heroTheme =
    (heroThemeName && getHeroTheme(heroThemeName)) || getHeroThemeBySlug(slug)

  if (heroTheme) {
    return (
      <ThemeProvider theme={heroTheme}>
        <SplitHero {...heroProps} />
      </ThemeProvider>
    )
  }

  return <SplitHero {...heroProps} />
}

const AssetManagementPage = ({ data: { page }, location: { hash = '' } }) => {
  const personnel = page.cards?.some(
    card => card.__typename === 'ContentfulDepartment'
  )
    ? personnelCardGroups({ page, hash })
    : null

  const portfolios = page.cards?.filter(
    card => card.__typename === 'ContentfulAssetManagementPortfolio'
  )

  const renderPercentageValue = value => (value ? `${value} %` : '-')

  const accordionSections = portfolios?.map(portfolio => {
    const allocations = portfolio.allocations.map(a => {
      return [a.market, renderPercentageValue(a.range)]
    })
    return {
      title: portfolio.title,
      data: (
        <AccordionTableContent>
          {portfolio.body && (
            <FeeDetails
              dangerouslySetInnerHTML={{
                __html: portfolio.body.childMarkdownRemark.html,
              }}
            />
          )}
          <ThemeProvider theme={tableTheme}>
            <CardTable headers={[MARKET, RANGE]} rows={allocations} />
          </ThemeProvider>
        </AccordionTableContent>
      ),
    }
  })

  return (
    <Layout
      title={page.title}
      description={page.description.description}
      image={`https:${page.image.file.url}`}
      hero={renderHero(page)}
    >
      {page.body || accordionSections?.length > 0 ? (
        <LayoutContent>
          {page.body && <ContentfulText text={page.body} />}
          {accordionSections && (
            <Accordion openIdx={0} sections={accordionSections} />
          )}
        </LayoutContent>
      ) : null}
      {personnel?.cardGroups && (
        <CardGroups cardGroups={personnel.cardGroups} />
      )}
    </Layout>
  )
}

export default AssetManagementPage

export const query = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
  }
`
