import React from 'react'
import { getLabelByText } from '../utils/segments'
import { getLabelName } from './filterOptions/filterOptionsGroup'
import Multiselect from 'multiselect-react-dropdown'
import FieldWrapper from './formInputs/fieldWrapper'
import { sanitizeString } from '../utils'

const MobileDropdownFilters = ({
  filterGroup: { type, values, label },
  onSelectedFilterChange,
}) => {
  const options = values
    .filter(filter => filter !== 'Kaikki')
    .map(filter => ({
      label: getLabelByText(filter),
      value: filter,
    }))
  return (
    <FieldWrapper>
      <Multiselect
        key={type}
        displayValue="label"
        placeholder={getLabelName(label)}
        options={options}
        onSelect={data => {
          const values = {
            type: type,
            values: data.map(value => sanitizeString(value.value)),
          }
          onSelectedFilterChange(values)
        }}
        onRemove={data => {
          let filtered
          if (data.length === 0) {
            filtered = [{ label: 'Kaikki', value: 'kaikki' }]
          } else {
            filtered = data
          }
          const values = {
            type: type,
            values: filtered.map(value => sanitizeString(value.value)),
          }
          onSelectedFilterChange(values)
        }}
        avoidHighlightFirstOption
      />
    </FieldWrapper>
  )
}

export default MobileDropdownFilters
