import styled from 'styled-components'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

export default styled(Slider)(
  ({ theme: { colors } }) => `
  width: 100%;
  &.rc-slider {
    height: 40px;
    margin: 0 0.75rem;
  }
  .rc-slider-rail {
    background-color: ${colors.greyLight};
    height: 2px;
  }
  .rc-slider-track {
    height: 2px;
    background-color: ${colors.primaryColor};
  }
  .rc-slider-step {
    height: 5px;
    top: 15px;
    .rc-slider-dot {
      height: 5px;
      border: none;
      border-left: 1px solid ${colors.black};
      bottom: -1px;
      margin-left: -1px;
      border-radius: 0;
      background-color: transparent;
    }
  }
  .rc-slider-mark {
    top: 25px;
    .rc-slider-mark-text,
    .rc-slider-dot.mark-text-active {
      color: ${colors.black};
    }
    .rc-slider-mark-text {
      transform: translateX(-2px) !important;
    }
  }
  .rc-slider-handle {
    border: none;
    color: ${colors.primaryColor};
    background-color: currentColor;
    transition: box-shadow .125s ease-in;

    &:focus,
    &:active {
      box-shadow: 0 0 1px 2px currentColor;
      transition-timing-function: ease-out;
    }
  }
`
)
