import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { linkPropTypes, cardPropTypes } from '../utils/prop-types'
import ContentfulPage from '../components/contentfulPage'
import ContentfulCard from '../components/contentfulCard'
import Card from '../components/cards/card'
import AsideLinks from '../components/asideLinks'
import Container from '../components/container'
import PersonnelCard from '../components/cards/personnelCard'
import { Heading } from '../components/typography/headings'
import { MicrosoftClarityScript } from '../lib/ms-clarity'

const propTypes = {
  data: shape({
    contentfulPage: shape({
      aside: arrayOf(linkPropTypes),
      asideHeading: string,
      cards: arrayOf(cardPropTypes),
    }).isRequired,
  }).isRequired,
}

const PageAsideCard = styled(Card).attrs({ as: 'aside' })``
const PeopleAside = styled.aside`
  margin-left: 150px;

  @media (max-width: 1100px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > div {
    margin-bottom: 1.5em;
  }
`

const heroTypes = [
  'ContentfulCard',
  'ContentfulHeroSection',
  'ContentfulArticle',
]

const getCards = cards => {
  if (!cards) return {}

  return cards.reduce(
    (acc, card) => {
      if (heroTypes.includes(card.__typename)) {
        return {
          ...acc,
          heros: [...acc.heros, card],
        }
      }
      return {
        ...acc,
        cards: [...acc.cards, card],
      }
    },
    { cards: [], heros: [] }
  )
}

const getCardGroups = ({ cards, heros }, cardsTitle, slug) => {
  const groups = []

  if (cards?.length > 0) {
    groups.push({
      items: cards.map(card => <ContentfulCard card={card} />),
      itemsPerRow: 2,
      hero: cardsTitle ? (
        <Heading headingSize="3" as="h2">
          {cardsTitle}
        </Heading>
      ) : null,
    })
  }

  if (heros?.length > 0) {
    groups.push({
      items: heros.map(hero => (
        <ContentfulCard card={hero} isHero smallHero={cards?.length > 0} />
      )),
      itemsPerRow: 1,
    })
  }

  return groups.length ? groups : null
}

const resolveAsideFooter = ({ aside, asideHeading }) => {
  switch (aside[0].__typename) {
    case 'ContentfulPerson':
      return (
        <PeopleAside>
          {aside.map(person => (
            <PersonnelCard key={person.slug} person={person} />
          ))}
        </PeopleAside>
      )
    default:
      return (
        <PageAsideCard>
          <AsideLinks links={aside} heading={asideHeading} />
        </PageAsideCard>
      )
  }
}

const Page = ({ data: { contentfulPage: page } }) => {
  return (
    <ContentfulPage
      page={page}
      cardGroups={getCardGroups(
        getCards(page.cards),
        page.cardsTitle,
        page.slug
      )}
      contentFooter={
        page.aside &&
        !page.asideBlock && (
          <Container containerWidth="content">
            {resolveAsideFooter(page)}
          </Container>
        )
      }
    />
  )
}

Page.propTypes = propTypes

export default Page

// Todo: this is currently missing from custom page templates
export function Head({ data: { contentfulPage } }) {
  if (contentfulPage.addMsClarityTracking) {
    return <MicrosoftClarityScript />
  }

  return <></>
}

export const query = graphql`
  query ($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
  }
`
