import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import AccordionButton from './accordionButton'
import { useBreakpoints } from '../../utils/dom'

const makeId = s => (s || '').toLowerCase().replace(/\s/g, '-')

const getTitle = stringOrNode =>
  typeof stringOrNode === 'string' ? stringOrNode : stringOrNode.props.children

const AccordionBodyWrapper = styled.div(
  ({ theme, open, bodyHeight }) => `
  max-height: ${open ? '100%' : '0'};
  overflow: hidden;
  transition: max-height ${theme.transition.duration};
  transition-timing-function: ${theme.transition.timingFunction};

    @media (max-width: ${theme.breakpoints.md.max}) {
      overflow: visible;
    }
  `
)

const AccordionBody = styled.div`
  padding: 0;
`

const defaultRenderTrigger = props => <AccordionButton {...props} />

const AccordionSection = ({
  children,
  title,
  open,
  onClick,
  renderTrigger = defaultRenderTrigger,
  renderCloseTrigger,
}) => {
  const [bodyHeight, setBodyHeight] = useState(0)
  const breakpoints = useBreakpoints()
  const id = makeId(getTitle(title))
  const bodyRef = useRef()

  useEffect(() => {
    const height = bodyRef.current.clientHeight
    setBodyHeight(height)
  }, [open, breakpoints])

  return (
    <>
      {renderTrigger({
        onClick,
        title,
        open,
        'aria-controls': id,
        'aria-expanded': `${!!open}`,
      })}
      <AccordionBodyWrapper
        bodyHeight={bodyHeight}
        open={open}
        tabIndex={open ? 0 : -1}
        id="suodatus"
        aria-labelledby={id}
        aria-hidden={!open}
        role="region"
        hidden={!open}
      >
        <AccordionBody ref={bodyRef}>
          {children}
          {renderCloseTrigger ? renderCloseTrigger({ onClick }) : null}
        </AccordionBody>
      </AccordionBodyWrapper>
    </>
  )
}

export default AccordionSection
