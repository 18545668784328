import React from 'react'
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import styled from 'styled-components'
import Grid from './grid'
import GridItem from './gridItem'
import Container from '../container'
import SmallCaps from '../typography/smallCaps'
import FilterOptionsMenu from '../filterOptions/filterOptionsMenu'

const propTypes = {
  cateogry: string,
  backgroundStyle: string,
  items: arrayOf(shape({})),
  itemsPerRow: number,
  hero: node,
  footer: node,
  filterMenu: shape({
    optionGroups: arrayOf(node),
    postsAmount: number,
    clearAllFilters: func,
    hasSelectedFilters: bool,
    sort: shape({
      value: oneOfType([string, number]),
      options: arrayOf(string),
      onChange: func,
    }),
  }),
}

const Category = styled(SmallCaps)`
  font-size: 1rem;
  margin-bottom: 0.5em;
`

const Header = styled.div`
  margin-bottom: 0;
  text-align: center;
  font-size: 1rem;
`

const InnerWrapper = styled.div`
  font-size: 1rem;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

const OuterWrapper = styled.div(
  ({ backgroundStyle = '', theme: { colors, spacing }, smallPadding }) => `
  padding: ${smallPadding ? '10px 0' : '30px 0 40px'};
  background: ${colors.backgroundColor};

  main > header ~ & + & ${InnerWrapper},
  main > header ~ & + & ${Header} {
    border-top: 1px solid ${colors.primaryColor};
    padding-top: 3.5em;
  }

  main > header ~ & + & ${Header} + ${InnerWrapper} {
    border-top: 0;
    padding-top: 0;
  }

  ${
    backgroundStyle &&
    `
    background: ${backgroundStyle};
  `
  };
  &:first-child:not(:only-child) {
    margin-bottom: calc(2 * ${spacing.xxlarge});
  }
  &:last-child:not(:only-child) {
    padding-bottom: calc(2em + ${spacing.xxlarge});
  }
`
)

const CardGroup = ({
  footer,
  items,
  filterMenu,
  category,
  hero,
  itemsPerRow = 3,
  backgroundStyle,
  containerWidth,
  smallPadding,
  centered,
  ...rest
}) => {
  return (
    <OuterWrapper
      backgroundStyle={backgroundStyle}
      smallPadding={smallPadding}
      {...rest}
    >
      <Container containerWidth={containerWidth}>
        {category && <Category>{category}</Category>}
        {hero && <Header>{hero}</Header>}
        {filterMenu ? <FilterOptionsMenu {...filterMenu} /> : null}
        <InnerWrapper>
          <Grid centered={centered}>
            {items.map((item, i) => (
              <GridItem key={i} itemsPerRow={itemsPerRow}>
                {item}
              </GridItem>
            ))}
          </Grid>
        </InnerWrapper>
        {footer}
      </Container>
    </OuterWrapper>
  )
}

CardGroup.propTypes = propTypes

export default CardGroup
