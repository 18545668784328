import React from 'react'
import styled from 'styled-components'
import Accordion from './accordion/accordion'
import AccordionButton from './accordion/accordionButton'
import Container from './container'

const AccordionText = styled.div(
  ({ theme }) => `
  padding-top: ${theme.spacing.small};
  padding-bottom: ${theme.spacing.medium};
`
)

const BodyTextSections = ({ sections }) => {
  if (!sections?.length) {
    return null
  }

  return (
    <Accordion
      openIdx={0}
      renderTrigger={props => (
        <Container containerWidth="content">
          <AccordionButton {...props} />
        </Container>
      )}
      sections={sections.map(section => ({
        title: section.heading,
        data: (
          <AccordionText
            dangerouslySetInnerHTML={{
              __html: section.body.childMarkdownRemark.html,
            }}
          />
        ),
      }))}
    />
  )
}

export default BodyTextSections
