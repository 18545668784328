import React, { useReducer, createContext } from 'react'
import { FILTER_OPTION_ALL, SORT_OPTION_NEWEST } from '../utils/constants'

const rootState = {
  articleFilters: {
    contentType: [FILTER_OPTION_ALL],
    category: [FILTER_OPTION_ALL],
    tag: [FILTER_OPTION_ALL],
  },
  articleSorter: SORT_OPTION_NEWEST,
}

const Context = createContext()

function uiReducer(state, action) {
  switch (action.type) {
    case 'set_article_filters':
      return { ...state, articleFilters: action.payload }

    case 'set_article_sorter':
      return { ...state, articleSorter: action.payload }

    default:
      return state
  }
}

function Provider({ children }) {
  const [state, dispatch] = useReducer(uiReducer, rootState)

  const setArticleFilters = payload => {
    dispatch({
      type: 'set_article_filters',
      payload,
    })
  }

  const setArticleSorter = payload => {
    dispatch({
      type: 'set_article_sorter',
      payload,
    })
  }

  return (
    <Context.Provider value={{ state, setArticleFilters, setArticleSorter }}>
      {children}
    </Context.Provider>
  )
}

export { Context, Provider }
