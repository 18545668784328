import React from 'react'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import createPersistedState from 'use-persisted-state'
import RichText from '../components/contentfulRichText'
import Button from '../components/buttons/button'
import Container from '../components/container'
import MediumText from '../components/typography/mediumText'
import text from '../styles/mixins/width/text'

const ref = React.createRef()

const usePersistedCountryState = createPersistedState('confirmedCountry')
const useAgreedState = createPersistedState('agreedToDisclaimer')

export const Body = styled(Container)`
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ theme }) => theme.width.content};
  padding-top: ${({ theme }) => theme.spacing.xxlarge};
  padding-bottom: ${({ theme }) => theme.spacing.xxlarge};

  & table {
    border-spacing: 1px;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${({ theme }) => theme.spacing.xxlarge};
    margin-bottom: ${({ theme }) => theme.spacing.xxlarge};

    & td,
    th {
      border: 1px solid ${({ theme }) => theme.colors.primaryColor};
      padding: ${({ theme }) => theme.spacing.medium};

      & em {
        font-size: 0.75rem;
        vertical-align: top;
      }
    }

    & tr > th:first-of-type {
      border-left: 0px;
      border-top: 0px;
    }

    & tbody > tr:first-of-type {
      font-weight: bold;
      font-style: italic;
    }
  }

  ul {
    margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  }

  ul > li {
    list-style: inside;
  }

  a {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & select {
    margin-top: ${({ theme }) => theme.spacing.medium};
    margin-bottom: ${({ theme }) => theme.spacing.medium};
    padding: ${({ theme }) => theme.spacing.small};
    border-radius: ${({ theme }) => theme.spacing.xxlarge};
    max-width: 100%;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  margin-left: auto;
  margin-right: auto;

  ${text}

  & button + button {
    margin-top: ${({ theme }) => theme.spacing.medium};
  }
`

const Step1 = ({
  page,
  selectedCountry,
  setSelectedCountry,
  setConfirmedCountry,
}) => (
  <>
    <RichText richText={page.disclaimerBody} />
    <ButtonContainer key="step1button">
      <SelectContainer>
        <MediumText as="label" htmlFor="countrySelect">
          Valitse luettelosta asuinmaasi
        </MediumText>
        <select
          id="countrySelect"
          value={selectedCountry}
          onChange={event => setSelectedCountry(event.target.value)}
        >
          <option disabled={!!selectedCountry}>Valitse....</option>
          {page.disclaimerCountries.map(i => (
            <option key={i}>{i}</option>
          ))}
        </select>
      </SelectContainer>
      <Button
        disabled={!selectedCountry}
        onClick={() => {
          setConfirmedCountry(selectedCountry)
          if (ref.current) {
            ref.current.scrollIntoView()
          }
        }}
      >
        Hyväksy
      </Button>
    </ButtonContainer>
  </>
)

const Step2Yes = ({
  confirmedCountry,
  setAgreed,
  page,
  setConfirmedCountry,
}) => {
  return (
    <>
      <RichText
        richText={
          confirmedCountry === 'Suomi'
            ? page.disclaimerContent
            : page.disclaimerOtherCountriesContent
        }
      />
      <ButtonContainer key="step2buttons">
        <Button
          disabled={false}
          onClick={() => {
            setAgreed(true)
          }}
        >
          Hyväksy
        </Button>
        <Button onClick={() => navigate('/')}>Hylkää</Button>
        <Button
          onClick={() => {
            if (ref.current) {
              ref.current.scrollIntoView()
            }
            setConfirmedCountry(null)
          }}
        >
          Takaisin
        </Button>
      </ButtonContainer>
    </>
  )
}

const Step2No = ({ page, setConfirmedCountry }) => (
  <>
    <RichText richText={page.disclaimerAltContent} />
    <ButtonContainer>
      <Button
        onClick={() => {
          if (ref.current) {
            ref.current.scrollIntoView()
          }
          setConfirmedCountry(null)
        }}
      >
        Takaisin
      </Button>
      <Button
        onClick={() => {
          navigate('/')
        }}
      >
        Etusivulle
      </Button>
    </ButtonContainer>
  </>
)

const Configurator = ({ setAgreed, page }) => {
  const [selectedCountry, setSelectedCountry] = React.useState(undefined)
  const [confirmedCountry, setConfirmedCountry] = usePersistedCountryState(null)

  let step = null

  if (confirmedCountry === null) {
    step = (
      <Step1
        selectedCountry={selectedCountry}
        confirmedCountry={confirmedCountry}
        setConfirmedCountry={setConfirmedCountry}
        setSelectedCountry={setSelectedCountry}
        page={page}
      />
    )
  } else {
    if (confirmedCountry === 'Suomi' || confirmedCountry === 'Muut maat') {
      step = (
        <Step2Yes
          setAgreed={setAgreed}
          page={page}
          setConfirmedCountry={setConfirmedCountry}
        />
      )
    } else {
      step = (
        <Step2No
          setAgreed={setAgreed}
          page={page}
          setConfirmedCountry={setConfirmedCountry}
        />
      )
    }
  }

  return <Body>{step}</Body>
}

const ClickThrough = ({ disclaimer, renderPage }) => {
  const bodyRef = React.useRef(null)

  const [agreed, setAgreed] = useAgreedState(false)

  const [shouldScroll] = React.useState(!agreed)

  // FIXME: This doesn't scroll to correct position, the offset is wrong
  React.useEffect(() => {
    if (bodyRef.current && shouldScroll) {
      const headerOffset = 80
      const elementPosition = bodyRef.current.getBoundingClientRect().top

      const offsetPosition = elementPosition - headerOffset
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }, [agreed, shouldScroll])

  if (typeof window === 'undefined') {
    return null
  }

  if (agreed === true) {
    return renderPage(bodyRef)
  } else {
    return <Configurator page={disclaimer.data} setAgreed={setAgreed} />
  }
}

export default ClickThrough
