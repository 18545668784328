/**
 * Common functions to build Cision feed component
 */

import styled from 'styled-components'
import button from '../buttons/button'

const CISION_URL = 'https://publish.ne.cision.com'
const STOCK_PURCHASE_FILTER = 'OYJ:n OMIEN OSAKKEIDEN HANKINTA'
export const STOCK_PURCHASE_SLUG = 'ilmoitukset-omien-osakkeiden-hankkimisesta'

export const LoadMoreButton = styled(button)``

// The first two press releases should be filtered out
const idDisallowList = ['3943587', '3954459']

const filterReleases = (slug, releases) =>
  releases
    .filter(r => !idDisallowList.includes(r.id))
    .filter(r => {
      if (slug === STOCK_PURCHASE_SLUG) {
        return r.title.includes(STOCK_PURCHASE_FILTER)
      } else {
        return !r.title.includes(STOCK_PURCHASE_FILTER)
      }
    })

export const sanitizeCisionRelease = ({
  Id,
  Title,
  Intro,
  PublishDate,
  Images,
  RawHtmlUrl,
}) => {
  const img = Images && Images.length ? Images[0] : null
  return {
    id: `${Id}`,
    title: Title,
    lead: Intro,
    publishDate: PublishDate,
    url: `${CISION_URL}${RawHtmlUrl}`,
    image: img
      ? {
          src: img.DownloadUrl,
          alt: img.Title,
          description: img.Description,
        }
      : null,
  }
}

/**
 * Cision API returns data (releases) in 'pages'. One page holds max 100 releases.
 * The total amount of releases exceeds this amount which means we need to make
 * separate API calls to fetch the remaining pages
 */
export const fetchReleases = async (url, slug) => {
  const res = await window.fetch(url)
  const data = await res.json()
  let { Releases: refreshedData } = data
  const { TotalFoundReleases: refreshedTotal, PageSize: pageSize } = data

  if (refreshedTotal > pageSize) {
    try {
      const releasesLeft = refreshedTotal - pageSize
      const pagesLeft = Math.ceil(releasesLeft / pageSize)
      // Create an array of numbers for pageIndices: [2, 3, ...]
      const pageIndicesToFetch = Array.from(Array(pagesLeft).keys()).map(
        val => val + 2
      )
      const remainingData = await Promise.all(
        pageIndicesToFetch.map(idx =>
          window.fetch(`${url}&PageIndex=${idx}`).then(r => r.json())
        )
      )
      remainingData.forEach(d => {
        if (d.Releases) {
          refreshedData = [...refreshedData, ...d.Releases]
        }
      })
    } catch (e) {
      console.log('Error while fetching Cision releases: ', e)
    }
  }
  const sanitizedReleases = refreshedData.map(sanitizeCisionRelease)
  const filteredReleases = filterReleases(slug, sanitizedReleases)

  return { refreshedData: filteredReleases, refreshedTotal }
}

export const getNewReleases = (newReleases, initialReleases) => {
  /* as I write this, I realize that data is mapped in graphql already, but this is straight from the
   * source so we map it here again ":D"
   *
   * We could assume that the offset amount of new releases first in the array are the new ones,
   * but assumption is the mother of all
   */
  return newReleases.reduce((acc, cisionRelease) => {
    if (
      !idDisallowList.includes(cisionRelease.id) &&
      !initialReleases.find(
        ({ id }) => parseInt(id) === parseInt(cisionRelease.id)
      )
    ) {
      return [...acc, cisionRelease]
    }
    return acc
  }, [])
}
