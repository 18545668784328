import React from 'react'
import { ThemeProvider } from 'styled-components'
import { tableTheme } from '../../styles/theme'
import Accordion from '../accordion/accordion'
import AccordionBody from '../accordion/accordionBody'
import AccordionTableContent from '../accordion/accordionTableContent'
import ContentfulAsset from '../contentfulAsset'
import Day from '../format/day'
import EUR from '../format/eur'
import Percentage from '../format/percentage'
import DetailsTable from '../tables/detailsTable'
import DetailsTableListItem from '../tables/detailsTableListItem'
import { CardTable } from '../tables/table'
import FeeDetails from './feeDetails'
import NetAssetRow from './netAssetRow'

const KID = 'Avaintietoasiakirja (KID)'
const KID_I = 'Avaintietoasiakirja (KID) I-osuussarja'

const FUND_BROCHURE = 'Rahastoesite'
const RULES = 'Säännöt'
const UNIVERSAL_RULES = 'Säännöt (yhteinen osa)'
const PRICING = ' Alexandria-rahastojen hinnasto'

const CommonInfoAccordion = ({ fund, morningstarData }) => {
  const getManagersLabel = slug => {
    if (
      [
        'alexandria-maltillinen',
        'alexandria-tasapainoinen',
        'alexandria-tuottohakuinen',
        'alexandria-maailma-osake',
        'alexandria-kehittyvat-markkinat-osinko',
        'alexandria-pienyhtiot',
      ].includes(slug)
    ) {
      return 'Hallinnoi / Salkunhoitaja'
    }

    return 'Salkunhoitaja'
  }

  const getManagers = managers => {
    if (!managers || managers.length === 0) {
      return null
    }
    return managers.map((manager, i) => (
      <DetailsTableListItem key={i}>{manager}</DetailsTableListItem>
    ))
  }

  const renderPercentageValue = value =>
    value ? <Percentage value={value} /> : '-'

  const getISIN = () => {
    const isin = morningstarData?.['Isin'] ?? fund.isin
    return isin || '-'
  }

  const getInceptionDate = () => {
    const date = morningstarData?.['InceptionDate'] ?? fund.startDate
    return date ? <Day value={date} /> : '-'
  }

  const getMinMonthlyInvestment = () => (
    <EUR value={fund.minimumMonthlyInvestment} minimumFractionDigits={0} />
  )

  const getMinInitialInvestment = () => (
    <EUR value={fund.minimumInvestment} minimumFractionDigits={0} />
  )

  const validMonthlyAmount = () => fund.minimumMonthlyInvestment > 0

  const renderCurrentPrice = () => {
    const date = morningstarData?.['LastPrice']?.['Date'] ?? null
    const price = morningstarData?.['LastPrice']?.['Value'] ?? null

    return (
      <tr>
        <td>Osuuden arvo {date && <Day value={date} />}</td>
        <td>{price ? <EUR value={price} /> : '-'}</td>
      </tr>
    )
  }

  const netAssetValues = morningstarData?.['NetAssetValues']
  const accordionSections = [
    {
      title: 'Perustiedot',
      data: (
        <AccordionTableContent>
          <DetailsTable
            colgroup={
              <colgroup>
                <col span="1" style={{ width: '50%' }} />
                <col span="1" style={{ width: '50%' }} />
              </colgroup>
            }
            tbody={
              <>
                <tr>
                  <td>{getManagersLabel(fund.slug)}</td>
                  <td>{getManagers(fund.managers)}</td>
                </tr>
                <tr>
                  <td>ISIN</td>
                  <td>{getISIN()}</td>
                </tr>
                <tr>
                  <td>Perustamispäivä</td>
                  <td>{getInceptionDate()}</td>
                </tr>
                {fund.minimumMonthlyInvestment && validMonthlyAmount() && (
                  <tr>
                    <td>Minimimerkintä (kk-säästö)</td>
                    <td>{getMinMonthlyInvestment()}</td>
                  </tr>
                )}
                <tr>
                  <td>Minimimerkintä (kertasijoitus)</td>
                  <td>{getMinInitialInvestment()}</td>
                </tr>
                <tr>
                  <td>Rahaston vertailuindeksi</td>
                  <td>{fund.benchmarkIndex}</td>
                </tr>
                {renderCurrentPrice()}
                {<NetAssetRow data={netAssetValues && netAssetValues[0]} />}
                <tr>
                  <td>{KID}</td>
                  <td>
                    {fund.keyInformation && (
                      <ContentfulAsset
                        data={fund.keyInformation}
                        title="Lataa"
                        useAssetRedirectUrl={true}
                        slug={fund.slug}
                        assetName="keyInformation"
                      />
                    )}
                  </td>
                </tr>
                {fund.keyInformationBiSeries && (
                  <tr>
                    <td>{KID_I}</td>
                    <td>
                      <ContentfulAsset
                        data={fund.keyInformationBiSeries}
                        title="Lataa"
                        useAssetRedirectUrl={true}
                        slug={fund.slug}
                        assetName="keyInformationBiSeries"
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <td>{FUND_BROCHURE}</td>
                  <td>
                    {fund.universalBrochure && (
                      <ContentfulAsset
                        data={fund.universalBrochure}
                        title="Lataa"
                        useAssetRedirectUrl={true}
                        slug={fund.slug}
                        assetName="universalBrochure"
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{RULES}</td>
                  <td>
                    {fund.rules && (
                      <ContentfulAsset
                        data={fund.rules}
                        title="Lataa"
                        useAssetRedirectUrl={true}
                        slug={fund.slug}
                        assetName="rules"
                      />
                    )}
                  </td>
                </tr>
                {fund.universalRules && (
                  <tr>
                    <td>{UNIVERSAL_RULES}</td>
                    <td>
                      <ContentfulAsset
                        data={fund.universalRules}
                        title="Lataa"
                        useAssetRedirectUrl={true}
                        slug={fund.slug}
                        assetName="universalRules"
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <td>{PRICING}</td>
                  <td>
                    {fund.pricing && (
                      <ContentfulAsset
                        data={fund.pricing}
                        title="Lataa"
                        useAssetRedirectUrl={true}
                        slug={fund.slug}
                        assetName="pricing"
                      />
                    )}
                  </td>
                </tr>
              </>
            }
          />
        </AccordionTableContent>
      ),
    },
    {
      title: 'Sijoituspolitiikka',
      data: (
        <AccordionBody
          dangerouslySetInnerHTML={{
            __html:
              fund.investmentStrategy &&
              fund.investmentStrategy.childMarkdownRemark.html,
          }}
        />
      ),
    },
    {
      title: 'Palkkiot',
      data: (
        <AccordionTableContent>
          <ThemeProvider theme={tableTheme}>
            <CardTable
              cellWithLabels={true}
              headers={[
                'Merkintäpalkkio',
                'Lunastuspalkkio',
                'Juoksevat kulut, p.a',
              ]}
              rows={[
                [
                  renderPercentageValue(fund.entryFee),
                  renderPercentageValue(fund.exitFee),
                  renderPercentageValue(fund.ter),
                ],
              ]}
            />
          </ThemeProvider>
          {fund.feeDetails && (
            <FeeDetails
              dangerouslySetInnerHTML={{
                __html: fund.feeDetails.childMarkdownRemark.html,
              }}
            />
          )}
        </AccordionTableContent>
      ),
    },
  ]

  return <Accordion sections={accordionSections} />
}

export default CommonInfoAccordion
