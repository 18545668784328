import React from 'react'
import styled from 'styled-components'
import LinkItemStyle from './links/linkItemStyle'
import Link from './links/link'

const StyledLink = styled(LinkItemStyle.withComponent(Link))``

const ContentfulAsset = ({
  data,
  title,
  useAssetRedirectUrl,
  slug,
  assetName,
}) => {
  let url = ''

  // 'AssetRedirectUrl' points to a Netlify function
  if (useAssetRedirectUrl) {
    // To make this safer in production, use production url as fallback if window is `undefined`
    const baseUrl =
      typeof window !== 'undefined'
        ? window.location.origin
        : 'https://alexandria.fi'

    url = `${baseUrl}/public/asset?fund=${slug}&asset=${assetName}&type=.pdf`
  } else {
    url = 'https:' + data.file && data.file.url
  }

  return <StyledLink to={url}>{title || data.title}</StyledLink>
}

export default ContentfulAsset
