import React from 'react'
import { graphql } from 'gatsby'

import InvestmentsPage from '../../components/investmentsPage'

const hasBeenIssued = investment =>
  investment.issueDate &&
  new Date(investment.issueDate) <= new Date().setUTCHours(0, 0, 0, 0)

const ActiveInvestmentsPage = ({
  data: {
    page,
    structuredInvestments: { edges: posts },
  },
  pageContext: { feedData },
}) => {
  // filter out structs that have not been issued
  return (
    <InvestmentsPage
      page={page}
      feedData={feedData}
      contentfulData={posts.map(({ node }) => node).filter(hasBeenIssued)}
      showValuationEur={false}
      showEffectiveInterest={false}
      back={{
        to: '/strukturoidut-sijoitukset/eraantyneet-strukturoidut-sijoitukset',
        text: 'Erääntyneiden tuotteiden tiedot',
      }}
    />
  )
}

export default ActiveInvestmentsPage

export const query = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
    structuredInvestments: allContentfulStructuredInvestment {
      edges {
        node {
          ...contentfulStructuredInvestmentExtendedInformation
        }
      }
    }
  }
`
