export class Year {
  constructor(year) {
    this.year = year
  }

  previous(ago) {
    return new Year(this.year - ago)
  }

  render() {
    return String(this.year)
  }
}

export class Quarter {
  constructor(date) {
    this.month = date.getMonth()
    this.year = date.getFullYear()
  }

  previous(ago) {
    const monthsAgo = ago * 3
    const date = new Date(this.year, this.month)
    date.setMonth(date.getMonth() - monthsAgo)

    return new Quarter(date)
  }

  renderYear() {
    return String(this.year).substr(-2)
  }

  renderQuarter() {
    return `Q${(this.month + 1) / 3}`
  }

  render() {
    return `${this.renderQuarter()}/${this.renderYear()}`
  }
}

export class Month {
  constructor(date) {
    this.month = date.getMonth()
    this.year = date.getFullYear()
  }

  previous(ago) {
    const date = new Date(this.year, this.month)
    date.setMonth(date.getMonth() - ago)

    return new Month(date)
  }

  render() {
    const date = new Date(this.year, this.month)
    return date.toLocaleString('fi-FI', { month: 'short' })
  }
}
