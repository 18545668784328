import React, { useEffect, useRef } from 'react'
import { func } from 'prop-types'
import { Manager, Reference, Popper } from 'react-popper'
import styled from 'styled-components'
import MenuDetail from '../images/menu-detail.png'

const propTypes = {
  renderButton: func.isRequired,
}

const MenuList = styled.div(
  ({ theme: { colors, spacing } }) => `
  border-radius: 16px;
  display: flex;
  flex-flow: column wrap;
  background-color: ${colors.primaryColor};
  color: ${colors.primaryColorInverted};
  padding: ${spacing.medium};
  position: relative;
  margin-top: 1em;

  input {
    color: inherit;
  }

  &:before {
    content: '';
    position: absolute;
    height: 16px;
    top: -16px;
    left: 0;
    width: 100%;
    background: transparent url('${MenuDetail}') center 0/32px no-repeat;
  }
`
)

const Wrapper = styled.div`
  z-index: 10;
  position: relative;
  display: block;

  ${MenuList} {
    min-width: 270px;
  }
`

const PopperMenu = ({ visible, renderButton, setVisible, children }) => {
  const wrapper = useRef()
  const handleClickOutside = event => {
    if (wrapper.current && !wrapper.current.contains(event.target) && visible) {
      setVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <Wrapper ref={wrapper}>
      <Manager>
        <Reference>
          {({ ref }) =>
            renderButton({ ref, onClick: () => setVisible(!visible) })
          }
        </Reference>
        {visible && (
          <Popper placement="bottom">
            {({ ref, style, placement, arrowProps }) => (
              <div ref={ref} style={style} data-placement={placement}>
                <MenuList>{children}</MenuList>
                <div ref={arrowProps.ref} style={arrowProps.style} />
              </div>
            )}
          </Popper>
        )}
      </Manager>
    </Wrapper>
  )
}

PopperMenu.propTypes = propTypes

export default PopperMenu
