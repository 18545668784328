import styled from 'styled-components'
import Spinner from '../loader'
import React from 'react'

const CenteredDiv = styled.div`
  top: 50%;
  left: 50%;
  color: ${({ theme }) => theme.colors.blue};
  position: absolute;
  max-width: 90%;
  transform: translate(-50%, -50%);
  text-align: center;

  > h3 {
    margin-bottom: 0.5em;
  }
`

export const CenteredContainer = styled.div`
  position: relative;
  height: 100%;
`

export const Loader = () => (
  <CenteredDiv>
    <Spinner />
  </CenteredDiv>
)

export default CenteredDiv
