import React from 'react'
import styled from 'styled-components'
import MediumText from '../typography/mediumText'
import Container from '../container'
import NewsReleases, { NewsReleaseItem } from '../cisionReleases/newsReleases'
import { graphql, useStaticQuery } from 'gatsby'

const StyledContainer = styled(Container)`
  max-width: ${({ theme }) => theme.width.content};
  background-color: ${({ theme }) => theme.colors.offWhite};
  padding-left: ${({ theme }) => theme.spacing.xxlarge};
  padding-right: ${({ theme }) => theme.spacing.xxlarge};
  padding-top: ${({ theme }) => theme.spacing.medium};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
  border-radius: ${({ theme }) => theme.radius.xxlarge};

  ${MediumText}, p {
    display: block;
    line-height: ${({ theme }) => theme.lineHeight.tight};
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }

  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`

const IPOReleases = () => {
  const {
    feed: { nodes: releases },
  } = useStaticQuery(
    graphql`
      query {
        feed: allCisionFeedItem(
          filter: { id: { in: ["3943587", "3954459"] } }
        ) {
          nodes {
            id
            title
            feedUrl
            serviceUrl
            lead
            publishDate
            url
            image {
              src
            }
          }
        }
      }
    `
  )

  return (
    <StyledContainer>
      <NewsReleases title="Tiedotteet">
        {releases.map(({ id, title, lead, publishDate, url }) => (
          <NewsReleaseItem
            {...{
              title,
              linkTo: url,
              lead,
              date: new Date(publishDate).toLocaleDateString('fi'),
              key: id,
            }}
          />
        ))}
      </NewsReleases>
    </StyledContainer>
  )
}

export default IPOReleases
