import React from 'react'
import { bool, func, oneOfType, node, string } from 'prop-types'
import styled from 'styled-components'
import Subheading from '../typography/headings'
import { ReactComponent as Arrow } from '../../images/arrow-down.svg'

const propTypes = {
  title: oneOfType([node, string]).isRequired,
  open: bool,
  onClick: func,
}

const getString = nodeOrString =>
  typeof nodeOrString === 'string' ? nodeOrString : nodeOrString.props.children

const Title = styled(Subheading).attrs({ as: 'span', headingSize: 3 })`
  ${({ overrideCss }) => overrideCss || ''};
`

// prettier-ignore
const Button = styled.button(({ theme: t, open }) => `
  display: flex;
  color: ${t.colors.blue};
  padding: ${t.spacing.xsmall} 0;
  text-align: left;
  width: 100%;
  align-items: center;
  gap: 10px;
  border-bottom: ${open ? 'none' : `1px solid ${t.colors.primaryColor}`};

  ${Title} {
    color: inherit;
    display: inline-block;
    padding: ${t.spacing.xsmall} ${t.spacing.medium} ${t.spacing.xsmall} 0;
    position: relative;

  }

  &:hover ${Title} {
    transform: scale(1.05);
    transition: transform .125s ease-in;
  }

  &:active ${Title}, &:focus ${Title} {
    transform: scale(1);
  }
`
)

const ArrowIcon = styled(Arrow)(
  ({ open }) => `
  width: 25px;
  height: 25px;
  transform: rotate(${open ? '-90deg' : '0deg'});
  transition: transform .125s ease-in;
`
)

const AccordionButton = ({ title, open, onClick, titleCss }) => {
  const handleButtonClick = () => {
    onClick()
  }

  return (
    <Button onClick={handleButtonClick} aria-expanded={open} open={open}>
      <ArrowIcon open={open} />
      <Title overrideCss={titleCss}>{getString(title)}</Title>
    </Button>
  )
}

AccordionButton.propTypes = propTypes

export default AccordionButton
