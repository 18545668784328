import React from 'react'
import styled from 'styled-components'
import Link from '../links/link'
import FooterList from './footerList'
import { linkTo } from '../../utils'

const FooterLink = styled(Link)`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 160px;

  li {
    display: block;
  }
`

const FooterLinks = ({ items, title }) => {
  return (
    <FooterList>
      <span>{title}</span>
      {items.map((link, j) => (
        <li key={j}>
          <FooterLink to={linkTo(link)}>{link.title}</FooterLink>
        </li>
      ))}
    </FooterList>
  )
}

export default FooterLinks
