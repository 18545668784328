import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ScoreFaceHappy } from '../../images/score-face-happy-bg.svg'
import { ReactComponent as ScoreFaceSatisfied } from '../../images/score-face-satisfied-bg.svg'
import { ReactComponent as ScoreFaceNeutral } from '../../images/score-face-neutral-bg.svg'
import { ReactComponent as ScoreFaceUnhappy } from '../../images/score-face-unhappy-bg.svg'

const FeedbackScoreWrapper = styled.div`
  display: flex;
  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }
`
const SizedFace = styled.div`
  height: 100%;
  width: 100%;
`

export const FeedbackScore = ({ score }) => {
  return (
    <FeedbackScoreWrapper>
      <div
        style={{
          borderRadius: '50%',
          width: '2.5rem',
          height: '2.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '-0.5rem',
        }}
      >
        <SizedFace role="img" aria-label="emoji">
          {score >= 9 ? (
            <ScoreFaceHappy />
          ) : score >= 7 ? (
            <ScoreFaceSatisfied />
          ) : score >= 5 ? (
            <ScoreFaceNeutral />
          ) : (
            <ScoreFaceUnhappy />
          )}
        </SizedFace>
      </div>
      <div
        style={{
          backgroundColor: 'rgb(0, 4, 80)',
          borderRadius: '50%',
          width: '2.5rem',
          height: '2.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            fontSize: '1.4rem',
            fontFamily: '"Saol Display", sans-serif',
            color: '#DAECE1',
            marginTop: '0.2rem',
          }}
        >
          {score}
        </span>
      </div>
    </FeedbackScoreWrapper>
  )
}
