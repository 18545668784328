import React from 'react'

import Percentage from './percentage'

const ReturnPercentage = ({ value }) => {
  const percentage = <Percentage value={value} />

  return value < 0 ? <>{percentage}</> : <>+{percentage}</>
}

export default ReturnPercentage
