/* eslint-disable max-len */
import React from 'react'
import styled from 'styled-components'
import { arrayOf, bool, func, number, oneOfType, string } from 'prop-types'
import Small from '../../styles/mixins/small'
import { sanitizeString } from '../../utils'
import { getLabelByText } from '../../utils/segments'

const propTypes = {
  value: oneOfType([string, number]),
  name: string.isRequired,
  onChange: func.isRequired,
  label: string,
  options: arrayOf(string),
  defaultOptionLabel: string,
  defaultOptionValue: oneOfType([string, number]),
  defaultOptionIsDisabled: bool,
}

const SelectContainer = styled.div(
  ({ theme }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  width: 200px;

  @media (max-width: ${theme.breakpoints.md.max}) {
    margin: 0 auto;
  }
`
)

const Label = styled.label`
  ${Small};
  position: absolute;
  top: -7px;
  left: 0;
`

const StyledSelect = styled.select`
  cursor: pointer;
  width: 200px;
  padding: 20px 0 3px 0;
  border-radius: 0;
  background: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primaryColor};
  border-top: none;
  border-left: none;
  border-right: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.primaryColor};
  }

  &:focus {
    outline: none;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 20px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-chevron-down" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>');
`

const SelectInput = ({
  value,
  name,
  onChange,
  label,
  options,
  defaultOptionLabel,
  defaultOptionValue,
  defaultOptionIsDisabled,
}) => {
  const hasDefaultOption = !!(
    defaultOptionLabel && typeof defaultOptionValue !== 'undefined'
  )

  return (
    <SelectContainer>
      <Label>{label}</Label>
      <StyledSelect name={name} onChange={onChange} value={value}>
        {hasDefaultOption && (
          <option
            value={defaultOptionValue}
            label={defaultOptionLabel}
            disabled={!!defaultOptionIsDisabled}
          >
            {defaultOptionLabel}
          </option>
        )}
        {options.map((option, index) => (
          <option
            key={`option-${index}`}
            value={sanitizeString(String(option))}
            label={getLabelByText(option)}
          >
            {getLabelByText(option)}
          </option>
        ))}
      </StyledSelect>
    </SelectContainer>
  )
}

SelectInput.propTypes = propTypes

export default SelectInput
