import React from 'react'
import styled, { css as _css } from 'styled-components'
import Accordion from '../accordion/accordion'
import AccordionBody from '../accordion/accordionBody'
import AccordionButton from '../accordion/accordionButton'
import { Heading } from '../typography/headings'

const StyledHeading = styled(Heading)`
  font-size: 1.375em;
  margin-bottom: 0.25em;
`

const accordionBtnTitleStyles = _css`
  font-family: inherit;
  font-weight: 500;
  font-size: 1em;
`

const SustainabilityInfoAccordion = ({ fund }) => {
  const { html: sustainabilityNegativeEffects } =
    fund.sustainabilityNegativeEffects?.childMarkdownRemark ?? {}
  const { html: sustainableFundsInfo } =
    fund.sustainableFundsInfo?.childMarkdownRemark ?? {}
  const { html: environmentalFundsInfo } =
    fund.environmentalFundsInfo?.childMarkdownRemark ?? {}

  if (
    !sustainabilityNegativeEffects &&
    !sustainableFundsInfo &&
    !environmentalFundsInfo
  ) {
    return null
  }

  return (
    <>
      <StyledHeading headingSize="4" as="h2">
        Kestävyyteen liittyvien tietojen antaminen
      </StyledHeading>
      <Accordion
        renderTrigger={props => (
          <AccordionButton {...props} titleCss={accordionBtnTitleStyles} />
        )}
        sections={[
          sustainabilityNegativeEffects
            ? {
                title:
                  'Ilmoitus sijoituspäätösten pääasiallisista haitallisista vaikutuksista kestävyystekijöihin',
                data: (
                  <AccordionBody
                    dangerouslySetInnerHTML={{
                      __html: sustainabilityNegativeEffects,
                    }}
                  />
                ),
              }
            : null,
          sustainableFundsInfo
            ? {
                title:
                  'Tuotetiedot rahoitustuotteista, joiden tavoitteena on kestävien sijoituksien tekeminen',
                data: (
                  <AccordionBody
                    dangerouslySetInnerHTML={{
                      __html: sustainableFundsInfo,
                    }}
                  />
                ),
              }
            : null,
          environmentalFundsInfo
            ? {
                title:
                  'Tuotetiedot ympäristöön tai yhteiskuntaan liittyviä ominaisuuksia edistävistä tuotteista',
                data: (
                  <AccordionBody
                    dangerouslySetInnerHTML={{
                      __html: environmentalFundsInfo,
                    }}
                  />
                ),
              }
            : null,
        ].filter(Boolean)}
      />
    </>
  )
}

export default SustainabilityInfoAccordion
