import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { shape, string } from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { imagePropTypes } from '../utils/prop-types'
import { getHeroThemeByContentType, getLabelByText } from '../utils/segments'
import { useBreakpoints } from '../utils/dom'
import Layout from '../components/layout'
import Body from '../components/body'
import Container from '../components/container'
import Main from '../components/main'
import EmbedResponsive from '../components/articlePages/embedResponsive'
import ImageFooter from '../components/articlePages/imageFooter'
import Tags from '../components/articlePages/tags'
import ContentPageHero from '../components/heroes/contentPageHero'
import { Heading } from '../components/typography/headings'
import SmallText from '../components/typography/smallText'
import AuthorCard from '../components/cards/authorCard'
import Lead from '../components/typography/lead'
import ReservationContainer from '../components/reservationContainer/reservationContainer'
import { formatDateWithoutZeros } from '../utils/dates'
import ProductInfoSection from '../components/productInfoSection/productInfoSection'

const propTypes = {
  data: shape({
    article: shape({
      leadParagraph: shape({ leadParagraph: string }),
      title: string,
      heading: string.isRequired,
      previewHeading: string,
      category: string,
      description: shape({ description: string }),
      childMarkdownRemark: shape({ html: string }),
      image: imagePropTypes,
    }).isRequired,
  }).isRequired,
}

const OuterWrapper = styled(Container)`
  position: relative;
  padding: 0;
`

const HeroContainer = styled(Container)(
  ({ theme }) => `
  width: 860px;
  padding: 0;
  margin-top: 20px;

  @media (max-width: ${theme.breakpoints.md.max}) {
    width: 100%;
    padding: 6px 20px 0 20px;
    margin-top: 50px;
  }
`
)

const ArticleLead = styled(Lead).attrs({
  cssOnShortLead: () => 'font-size: 1.333em;',
})`
  &:not(:only-child) {
    margin-bottom: 1.5em;
  }
`

const TextColumnWrapper = styled.div(
  ({ theme, tags }) => `
  flex-grow: 1;
  max-width: 860px;
  margin: ${tags ? '0 auto' : '0 auto 20px auto'};
  z-index: 10;

  @media (max-width: ${theme.breakpoints.md.max}) {
    margin: ${tags ? '0 auto' : '0 auto 30px auto'};
  }

  ${Heading} {
    word-break: break-word;
  }
`
)

const ImageContainer = styled.div(
  ({ theme }) => `
  position: relative;
  margin-top: calc(-250px);
  width: 860px;
  height: 620px;

  @media (max-width: ${theme.breakpoints.md.max}) {
    width: 100%;
    height: 252px;
  }
`
)

const HeroImage = styled(GatsbyImage)(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  border-radius: 30px;
`
)

const FlexWrapper = styled(Container)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.lg.min}) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0;
  }
`
)

const StyledAuthorCard = styled(AuthorCard)`
  margin-bottom: 2em;
`

const ColorText = styled(SmallText)`
  color: ${({ theme }) => theme.colors.red};
  text-transform: uppercase;
`

const ContactMethodsWrapper = styled.div(
  ({ theme: { spacing, breakpoints } }) => `
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
`
)

const ProductInfoSectionContainer = styled.div(
  ({ theme }) => `
  margin-bottom: 2em;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
`
)

const renderHero = contentType => (
  <ThemeProvider theme={getHeroThemeByContentType(contentType)}>
    <ContentPageHero contentType={contentType} />
  </ThemeProvider>
)

const ArticleTemplate = ({
  data: {
    article: {
      metadata: { tags },
      title,
      description: { description },
      previewHeading,
      body,
      publicationDate: date,
      updatedAtDate,
      leadParagraph,
      heading,
      image,
      contentType,
      category,
      videoEmbed,
      cards,
      showCtaCard,
      author,
      productInfoSections,
    },
    related,
  },
}) => {
  const bodyHtml = body?.childMarkdownRemark?.html ?? ''
  const breakpoints = useBreakpoints()
  const imageUrl = `https:${image.file.url}`

  return (
    <Layout
      {...{
        title,
        previewHeading,
        description,
        productInfoSections,
      }}
      hero={renderHero(contentType)}
      containerWidth="content"
      image={imageUrl}
    >
      <OuterWrapper>
        <HeroContainer>
          {contentType === 'video' ? (
            <EmbedResponsive>
              <div
                dangerouslySetInnerHTML={{ __html: videoEmbed.videoEmbed }}
              />
            </EmbedResponsive>
          ) : (
            <ImageContainer>
              <HeroImage
                image={image.gatsbyImageData}
                alt={image.title || ''}
              />
            </ImageContainer>
          )}
          <ImageFooter>
            {category && <ColorText>{getLabelByText(category)}</ColorText>}
            {date && <SmallText>{formatDateWithoutZeros(date)}</SmallText>}
            {!breakpoints?.xs && updatedAtDate && (
              <SmallText>
                {updatedAtDate && ` (päivitetty: ${updatedAtDate})`}
              </SmallText>
            )}
          </ImageFooter>
        </HeroContainer>
        <FlexWrapper>
          <TextColumnWrapper tags={tags.length > 0}>
            <Heading headingSize="3">{heading}</Heading>
            {leadParagraph && (
              <ArticleLead>{leadParagraph.leadParagraph}</ArticleLead>
            )}
            <Main>
              <Body dangerouslySetInnerHTML={{ __html: bodyHtml }} />
            </Main>
            {productInfoSections && (
              <ProductInfoSectionContainer>
                <ProductInfoSection productInfoSections={productInfoSections} />
              </ProductInfoSectionContainer>
            )}
            {author && <StyledAuthorCard data={author} />}
            <Tags contentType={contentType} tags={tags} />
          </TextColumnWrapper>
        </FlexWrapper>
        <ContactMethodsWrapper>
          <ReservationContainer />
        </ContactMethodsWrapper>
      </OuterWrapper>
    </Layout>
  )
}

ArticleTemplate.propTypes = propTypes

export default ArticleTemplate

export const query = graphql`
  query ($slug: String!, $category: String!) {
    article: contentfulArticle(slug: { eq: $slug }) {
      metadata {
        tags {
          name
        }
      }
      id
      title
      description {
        description
      }
      category
      contentType
      heading
      leadParagraph {
        leadParagraph
      }
      videoEmbed {
        videoEmbed
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      author {
        firstName
        lastName
        email
        image {
          title
          gatsbyImageData(width: 600, placeholder: BLURRED)
        }
        articlePresentation {
          articlePresentation
        }
      }
      publicationDate(formatString: "DD.MM.YYYY")
      updatedAtDate(formatString: "DD.MM.YYYY")
      image {
        file {
          url
        }
        title
        gatsbyImageData(width: 1200, placeholder: BLURRED)
      }
      showCtaCard
      cards {
        __typename
        ... on Node {
          ... on ContentfulFund {
            __typename
            id
            slug
            image {
              gatsbyImageData(width: 768, placeholder: BLURRED)
            }
            previewHeading
            riskRating
            previewBody {
              previewBody
            }
          }
          ... on ContentfulInsurance {
            __typename
            id
            slug
            image {
              gatsbyImageData(width: 768, placeholder: BLURRED)
            }
            previewHeading
            previewBody {
              previewBody
            }
          }
          ... on ContentfulStructuredInvestment {
            __typename
            id
            slug
            image {
              gatsbyImageData(width: 768, placeholder: BLURRED)
            }
            previewHeading
            previewBody {
              previewBody
            }
          }
          ... on ContentfulPage {
            __typename
            id
            slug
            image {
              gatsbyImageData(width: 768, placeholder: BLURRED)
            }
            previewHeading
            previewBody {
              previewBody
            }
          }
        }
      }
      productInfoSections {
        __typename
        ... on Node {
          ... on ContentfulProductInfoModule {
            name
            linksTitle
            linkTitle
            textField {
              childMarkdownRemark {
                html
              }
            }
            additionalTextField {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
    related: allContentfulArticle(
      limit: 2
      filter: {
        category: { eq: $category }
        slug: { nin: [$slug, "laajemmat-tiedot-yrityslainoista"] }
      }
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          __typename
          title
          id
          slug
          category
          contentType
          previewHeading
          description {
            description
          }
          publicationDate
          updatedAtDate
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
        }
      }
    }
  }
`
