import React from 'react'
import { func, shape, string } from 'prop-types'
import { BLOCKS } from '@contentful/rich-text-types'
import styled from 'styled-components'
import { getHeadingFontBySize } from '../typography/headings'
import ContentfulRichText from '../contentfulRichText'

const propTypes = {
  teaser: shape({
    richTextJson: string.isRequired,
    cta: shape({
      to: string.isRequired,
    }),
  }).isRequired,
  renderCta: func.isRequired,
}

// export const formatRichText = string => JSON.parse(string)

// when rendering children, render first bolded paragraph as a title. The rest as paragraphs
const renderChildren = (children, i) =>
  !i && children.type === 'b' ? (
    <h3 key="title">{children.props.children}</h3>
  ) : (
    <p key={`text-${i}`}>{children}</p>
  )
const renderOptions = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (node, children) => {
      /* NOTE:
       * contentful lets user set only one child to a list-item.
       * One can't have ie. <li><h3>title</h3><p>lead</p></li> -structure for this reason.
       * Here we can make one tough, by using <b> as a setter for a title
       */
      return <li>{children[0].props.children.map(renderChildren)}</li>
    },
  },
}

const StyledContentfulRichText = styled(ContentfulRichText).attrs({
  renderOptions,
})`
  margin: 0 auto 3.5em;

  ol {
    list-style: none;
    counter-reset: content-teaser;

    li {
      counter-increment: content-teaser;
      padding-left: 3.5em;
      margin-bottom: 1em;

      p {
        margin-bottom: 0.125em;
      }
    }

    b,
    h3 {
      color: ${({ theme }) => theme.colors.primaryColor};
      font: ${getHeadingFontBySize(1, 'subheading')};
      display: flex;
      align-items: center;
      margin-bottom: 0.125em;

      &:before {
        content: counter(content-teaser);
        color: inherit;
        border: 1px solid currentColor;
        border-radius: 50%;
        display: inline-block;
        font-size: 1.5em;
        width: 1.5em;
        height: 1.5em;
        text-align: center;
        line-height: 1.5;
        margin-left: -2em;
        margin-right: 0.5em;
        flex-shrink: 0;
      }
    }
  }
`

const ContentTeaser = ({ teaser: { richTextJson, cta }, renderCta }) => (
  <>
    {/* <StyledContentfulRichText richText={formatRichText(richTextJson)} /> */}
    <StyledContentfulRichText richText={{ raw: richTextJson }} />
    {renderCta({ to: cta.to, children: 'Lue lisää' })}
  </>
)

ContentTeaser.propTypes = propTypes

export default ContentTeaser
