import React from 'react'
import styled from 'styled-components'
import { useLocation } from '@reach/router'
import ShareButtonGroup from './buttons/shareButtonGroup'
import MediumText from './typography/mediumText'

const ShareBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Wrapper = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: ${theme.spacing.medium};
`
)

const Title = styled(MediumText)(
  ({ theme }) => `
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0 ${theme.spacing.medium};
  letter-spacing: 1.6px;
`
)

const Divider = styled.div(
  ({ theme }) => `
  border-bottom: ${theme.colors.blue} 1px solid;
  width: 100%;
`
)

const StyledShareButtonGroup = styled(ShareButtonGroup)`
  ul {
    list-style: none;
  }
  li {
    margin: 0 1em 1em 0.5em;
  }
`

const ContentShare = ({ title = 'Jaa artikkeli' }) => {
  const location = useLocation()

  return (
    <ShareBlock>
      <Wrapper>
        <Divider />
        <Title>{title}</Title>
        <Divider />
      </Wrapper>
      <StyledShareButtonGroup location={location && location.href} />
    </ShareBlock>
  )
}

export default ContentShare
