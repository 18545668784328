import React from 'react'
import styled from 'styled-components'
import Container from '../container'
import BlueText from '../typography/blueText'
import Heading from '../typography/headings'
import { ReactComponent as FileDownload } from '../../images/file-download.svg'

const StyledContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  max-width: ${({ theme }) => theme.width.content};
`

const Material = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.small};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primaryColor};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const StyledHeading = styled(Heading)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`

const StyledBlueText = styled(BlueText)`
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    margin-right: ${({ theme }) => theme.spacing.medium};
  }
`
const IPOMaterials = ({ title, notFoundText, downloadText, materials }) => {
  return (
    <StyledContainer>
      <Heading as="h2" headingSize={1}>
        {title}
      </Heading>
      {materials?.length > 0 ? (
        materials.map(i => (
          <Material key={i.id}>
            <StyledHeading>{i.title}</StyledHeading>
            <StyledBlueText as="a" href={i.file.url} target="_blank">
              <span>{downloadText}</span>
              <FileDownload />
            </StyledBlueText>
          </Material>
        ))
      ) : (
        <StyledHeading>{notFoundText}</StyledHeading>
      )}
    </StyledContainer>
  )
}

export default IPOMaterials
