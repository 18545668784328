import React from 'react'
import ExtLinkIconWrapper from '../links/extLinkIconWrapper'
import { ReactComponent as Icon } from '../../images/linkedin.svg'

const LinkedinShareButton = ({ location }) => {
  const url = `https://www.linkedin.com/shareArticle?mini=true&url=${location}`
  return (
    <ExtLinkIconWrapper to={url}>
      <Icon />
    </ExtLinkIconWrapper>
  )
}

export default LinkedinShareButton
