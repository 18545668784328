import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import ContentfulPage from '../components/contentfulPage'
import Link from '../components/links/link'
import FundTable from '../components/tables/fundTable'
import segments from '../utils/segments'
import { latestDate } from '../utils/dates'

const LocalizedAndFixed = styled(({ number, className }) => (
  <span className={className}>
    {number !== undefined
      ? number.toLocaleString('fi-FI', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : '-'}
  </span>
))`
  color: ${({ number, theme }) =>
    number >= 0 || number === undefined
      ? theme.colors.primaryColor
      : theme.colors.red};
`

const getTabOneRow = (
  contentfulFund,
  { ClosePrice, ReturnD1, ReturnW1, ReturnM1, ReturnM3, ReturnM6, ReturnM0 }
) => [
  <Link to={`${segments.ContentfulFund}/${contentfulFund.slug}`}>
    {contentfulFund.title}
  </Link>,
  <LocalizedAndFixed number={ClosePrice} />,
  <LocalizedAndFixed number={ReturnD1} />,
  <LocalizedAndFixed number={ReturnW1} />,
  <LocalizedAndFixed number={ReturnM1} />,
  <LocalizedAndFixed number={ReturnM3} />,
  <LocalizedAndFixed number={ReturnM6} />,
  <LocalizedAndFixed number={ReturnM0} />,
]

const getTabTwoRow = (
  contentfulFund,
  { ClosePrice, ReturnM0, ReturnM12, ReturnM36UA, ReturnM60UA, ReturnM120UA }
) => [
  <Link to={`${segments.ContentfulFund}/${contentfulFund.slug}`}>
    {contentfulFund.title}
  </Link>,
  <LocalizedAndFixed number={ClosePrice} />,
  <LocalizedAndFixed number={ReturnM0} />,
  <LocalizedAndFixed number={ReturnM12} />,
  <LocalizedAndFixed number={ReturnM36UA} />,
  <LocalizedAndFixed number={ReturnM60UA} />,
  <LocalizedAndFixed number={ReturnM120UA} />,
]

const getTableData = (fundData, contentfulFunds, hint) => ({
  profit: {
    hint: hint,
    headers: [
      'Nimi',
      'Arvo',
      '1 d %',
      '1 vk %',
      '1 kk %',
      '3 kk %',
      '6 kk %',
      'va %',
    ],
    cells: contentfulFunds.map(contentfulFund =>
      getTabOneRow(
        contentfulFund,
        fundData.find(fund => fund.ISIN === contentfulFund.isin) || {}
      )
    ),
  },
  history: {
    hint: hint,
    headers: ['Nimi', 'Arvo', 'va %', '1v %', '3v %', '5v %', '10v %'],
    cells: contentfulFunds.map(contentfulFund =>
      getTabTwoRow(
        contentfulFund,
        fundData.find(fund => fund.ISIN === contentfulFund.isin) || {}
      )
    ),
  },
})

const FundValues = ({
  data: { page, funds },
  pageContext: { fundData, cards },
}) => {
  const hint = 'Arvo ' + latestDate(fundData, 'ClosePriceDate')
  const contentfulFunds = cards
    .filter(card => card.__typename === 'ContentfulFund')
    .map(card => {
      return funds.edges.find(({ node }) => {
        return node.slug === card.slug
      }).node
    })

  return (
    <ContentfulPage
      page={page}
      table={
        <FundTable
          cellWithLabels={true}
          data={getTableData(fundData, contentfulFunds, hint)}
        />
      }
    />
  )
}

export default FundValues

export const query = graphql`
  query {
    page: contentfulPage(slug: { eq: "rahastot-tanaan" }) {
      ...contentfulPageInformation
    }
    funds: allContentfulFund {
      edges {
        node {
          title
          slug
          isin
          image {
            title
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
