import React from 'react'
import { graphql } from 'gatsby'
import ContentfulPage from '../components/contentfulPage'
import ContentfulCard from '../components/contentfulCard'
import { CardForTable } from '../components/tables/table'
import Calculator from '../components/calculators/calculator'
import Container from '../components/container'
import { Heading } from '../components/typography/headings'
import SplitHero from '../components/heroes/splitHero'

const ProfitCalculator = ({ data: { contentfulPage: page } }) => {
  const HeroContainer = () => {
    const item = page.cards.find(i => i.slug === 'varaa-aika')
    const heroProps = {
      image: item.previewImage,
      heading: item.previewHeading,
      lead: item.previewBody.previewBody,
      cta: {
        to: item.slug,
        text: 'Varaa aika tapaamiseen',
      },
    }
    if (!item) return null
    return <SplitHero {...heroProps} />
  }

  const cardGroups = page.cards && [
    {
      items: page.cards
        .filter(item => item.slug !== 'varaa-aika')
        .map(item => <ContentfulCard card={item} />),
      hero: (
        <Heading as="h3" headingSize="3">
          Tutustu tuotevalikoimaan
        </Heading>
      ),
      itemsPerRow: 2,
    },
  ]
  return (
    <ContentfulPage cardGroups={cardGroups} page={page}>
      <Container isFlowing>
        <CardForTable>
          <Calculator />
        </CardForTable>
        <HeroContainer />
      </Container>
    </ContentfulPage>
  )
}

export default ProfitCalculator

export const query = graphql`
  query ($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      description {
        description
      }
      leadParagraph {
        leadParagraph
      }
      heading
      body {
        childMarkdownRemark {
          html
        }
      }
      seoMarkdown {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
        title
        gatsbyImageData(width: 1200, placeholder: BLURRED)
      }
      heroType
      heroTheme
      cards {
        ... on ContentfulFund {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
        }
        ... on ContentfulPage {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
          previewImage {
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
