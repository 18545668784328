import React from 'react'
import styled from 'styled-components'
import { Heading } from '../typography/headings'

const chartData = [
  {
    image: 'img',
    header: 'Asiakastyytyväisyytemme on alan huippua',
    body: 'Taloustutkimus Oy ja NPS-tietopankki, rahoitus- ja vakuutustoiminta, n=320 mittausta, Alexandrian toimeksiantama bränditutkimus, tammikuu 2024.',
  },
  {
    image: 'img',
    header: 'Suurin osa asiakkaistamme suosittelee meitä',
    body: 'Tapaamme keskimäärin 70 000 asiakasta vuodessa ja keräämme palautetta palvelustamme jokaisen kohtaamisen jälkeen. Saimme tänä vuonna yli 6 000 asiakaspalautetta ensimmäisen puolen vuoden aikana. Käytämme asiakastyytyväisyyden mittaamiseen yleisesti käytössä olevaa NPS-asteikkoa (Net Promoter Score).',
  },
  {
    image: 'img',
    header: 'Asiakastyytyväisyys kaikkien aikojen korkeimmalla tasolla ',
    body: 'Asiakastyytyväisyyskyselyymme vastaa vuosittain yli 10 000 asiakastamme. Emme kerää palautetta vain huvin ja markkinoinnin vuoksi, vaan palaute kertoo meille kaunistelematta, mikä palvelussamme toimii ja missä on kehitettävää. Näin pystymme aidosti kehittymään.',
  },
]

const ChartStripWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.width.max};
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 1rem;
  gap: 1rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    flex-direction: column;
    padding: 0;
  }
`

const ContentBox = styled.div`
  display: flex;
  flex: 1 1 0;

  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    margin-bottom: 1rem;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1;
  background-color: rgb(220, 233, 226);
  border-radius: ${props => props.theme.radius.xxlarge};
  padding: 2.5rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    border-radius: 0;
    padding: ${({ theme }) => theme.spacing.large};
  }
`
const TextWrapper = styled.div`
  padding: 2rem;
`

export const ChartStrip = ({ images }) => {
  return (
    <ChartStripWrapper>
      <ContentWrapper>
        {chartData.map((data, index) => (
          <ContentBox>
            <ImageWrapper>
              <img src={images[index].url} />
            </ImageWrapper>
            <TextWrapper>
              <Heading headingSize={4}>{data.header}</Heading>
              <p>{data.body}</p>
            </TextWrapper>
          </ContentBox>
        ))}
      </ContentWrapper>
    </ChartStripWrapper>
  )
}
