import React, { useState } from 'react'
import styled from 'styled-components'
import { SliderInput } from '../formInputs'
import { Heading } from '../typography/headings'
import SmallText from '../typography/smallText'
import CtaButton from '../buttons/ctaButton'

/* eslint-disable */
const calcMonthly = (amountValue, timeValue) => {
  const amount = parseInt(amountValue)
  const time = parseInt(timeValue)

  const f = 0.07 / 12
  const g = 0.01 / 12
  const b = 0.06
  const l = 1 + f
  const o = Math.round(
    amount * Math.pow(l, time) * ((1 - l) / (1 - Math.pow(l, time)))
  )
  const t = Math.round(amount * g)
  return o + t
}

const defaultDisclaimer =
  'Laskurissa on käytetty esimerkkinä 7% korkoa, lopullinen korko määräytyy kuitenkin yrityskohtaisesti luottopäätösprosessin perusteella. Muut kulut ovat 6% järjestelypalkkio sekä 1 % vuotuinen hoitopalkkio.'

const formatValue = val => {
  return parseInt(val, 10)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

const reCalculate = (updatedValues, cb) => {
  const state = {
    ...updatedValues,
    ...{
      monthly: calcMonthly(updatedValues.loanAmount, updatedValues.loanTime),
    },
  }
  cb(state)
}

const loanAmountField = {
  defaultValue: 100000,
  min: 20000,
  max: 200000,
  step: 1000,
  unit: '€',
  marks: {
    20000: `${formatValue(20000)}`,
    200000: `${formatValue(200000)}`,
  },
  name: 'loanAmount',
}

const loanTimeField = {
  defaultValue: 12,
  min: 6,
  max: 60,
  step: 1,
  unit: 'kk',
  name: 'loanTime',
  marks: {
    6: `${formatValue(6)}`,
    60: `${formatValue(60)}`,
  },
}

const Disclaimer = styled(SmallText)`
  padding-top: 3rem;
  max-width: 800px;
  margin: 0 auto;
`

const FieldResult = styled(Heading).attrs(({ headingSize }) => ({
  as: 'p',
  headingSize: headingSize || 2,
}))(
  ({ theme: { colors } }) => `
  color: ${colors.blue};
  display: block;
  margin-bottom: .5em;
  white-space: nowrap;
`
)

const ResultWrap = styled.div`
  margin: 0 auto 3rem;

  p:first-of-type {
    color: ${p => p.theme.colors.grey};
    margin-bottom: 1rem;
  }
`

const StyledSliderInput = styled(SliderInput)`
  max-width: 385px;

  label {
    color: ${p => p.theme.colors.grey};
    margin-bottom: 1rem;
    justify-content: center;
  }

  .rc-slider {
    cursor: grab;
  }

  .rc-slider-handle {
    width: 1rem;
    height: 1rem;
    margin-top: calc(-1rem / 2 + 1px);
  }

  .rc-slider-mark-text {
    white-space: nowrap;
    &:last-of-type {
      transform: translateX(-100%) !important;
    }
  }
`

const InputsWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: 1rem;

  ${StyledSliderInput} {
    flex: 1 1 45%;
    min-width: 200px;
    margin: 0 0 2rem;
  }
`

const Wrapper = styled.div`
  padding: calc(2em + 2%) 0 0;
  margin: 0 auto calc(2em + 2%);
  text-align: center;
`

const LoanCalculator = ({ title, children, disclaimer, ...rest }) => {
  const [values, setValues] = useState({
    loanAmount: 100000,
    loanTime: 12,
    monthly: calcMonthly(100000, 12),
  })
  const onChange = ({ target: { value, name } }) => {
    reCalculate({ ...values, ...{ [name]: value } }, setValues)
  }

  return (
    <Wrapper {...rest}>
      {title || children
        ? children || (
            <Heading headingSize="3" as="h2">
              {title}
            </Heading>
          )
        : null}
      <InputsWrap>
        <StyledSliderInput
          label={() => 'Lainattava summa'}
          field={{
            ...loanAmountField,
            onChange,
          }}
          required
        >
          <FieldResult>
            {formatValue(values.loanAmount)} {loanAmountField.unit}
          </FieldResult>
        </StyledSliderInput>
        <StyledSliderInput
          label={() => 'Laina-aika'}
          field={{
            ...loanTimeField,
            onChange,
          }}
          required
        >
          <FieldResult>
            {formatValue(values.loanTime)} {loanTimeField.unit}
          </FieldResult>
        </StyledSliderInput>
      </InputsWrap>
      <ResultWrap>
        <p>Kuukausilyhennys*</p>
        <FieldResult headingSize="3">
          {formatValue(values.monthly)} {loanAmountField.unit}/
          {loanTimeField.unit}
        </FieldResult>
      </ResultWrap>
      <CtaButton
        to="https://lainat.yritysrahoitus.alexandria.fi/haku"
        large="true"
      >
        Hae yrityslainaa
      </CtaButton>
      <Disclaimer>*{disclaimer || defaultDisclaimer}</Disclaimer>
    </Wrapper>
  )
}

export default LoanCalculator
