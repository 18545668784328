import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styled, { css as _css } from 'styled-components'
import LabelText from '../typography/labelText'
import SmallText from '../typography/smallText'
import { stringToNumber } from '../../utils'
import Card from '../cards/card'
import sortArrowStyle from '../sortArrow'

const tablePadding = '1rem'

const SortinText = styled.div`
  ${p =>
    !p.sortingDir
      ? ''
      : _css`
          display: flex;
          align-items: center;
          cursor: pointer;

          :after {
            content: '';
            color: ${p => p.theme.colors.borderColor};
            ${sortArrowStyle};
          }
        `}
`

const TableHead = styled(SmallText.withComponent('th'))`
  vertical-align: top;
  text-align: left;
`

const TableBody = styled.tbody`
  td,
  a {
    color: ${({ theme }) => theme.colors.primaryColor};
  }

  a {
    font-weight: 500;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }
`

const StyledTable = styled.table(
  ({ theme }) => `
  border: 0;
  padding: 0;
  font-size: 1.333rem;
  line-height: 1.45em;
  border-collapse: collapse;
  width: 100%;
  outline: none;

  &:not(:only-child) {
    margin: 0 0 2em;
  }

  ${TableHead} {
    padding-bottom: ${theme.spacing.small};
  }

  td {
    padding-top: ${theme.spacing.small};
    padding-bottom: ${theme.spacing.small};
  }

  @media (min-width: ${theme.breakpoints.sm.min}) {
    font-size: 1rem;
  }

`
)

const TableHeaders = styled.thead`
  border-bottom: 1px solid ${p => p.theme.colors.borderColor};

  ${({ theme, responsive, isSortable }) => `
    @media (max-width: ${theme.breakpoints.md.max}) {
      ${
        responsive
          ? 'display: none;'
          : `
        ${TableHead} {
          &:nth-child(2),
          &:nth-child(3) {
            display: none;
          }
        }
      `
      };
    }


    ${TableHead} {
      cursor: ${isSortable ? 'pointer' : 'auto'};
    }
  `}
`

const TableCell = styled.td`
  ${props => {
    const responsive = _css`
      @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
        display: block;
        padding-left: ${tablePadding};

        :first-child {
          margin-top: ${({ theme }) => theme.spacing.medium};
        }
      }
    `
    const unresponsive = _css`
      @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
        min-width: 95px;
        :nth-child(2),
        :nth-child(3) {
          display: none;
        }
      }
    `
    return props.responsive ? responsive : unresponsive
  }}
`

const TableRow = styled.tr`
  background: none;
  transition: background 0.125s ease-in;
  vertical-align: top;

  &:not(:only-child):hover {
    background: ${p => p.theme.colors.primaryColorInverted};
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 2%,
      rgba(255, 255, 255, 1) 98%,
      rgba(255, 255, 255, 0) 100%
    );
    transition-timing-function: ease-out;
  }

  ${props => {
    if (props.responsive) {
      return _css`
        @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
          margin-top: ${({ theme }) => theme.spacing.medium};
          padding-top: ${({ theme }) => theme.spacing.medium};

          :first-child {
            ${TableCell} {
              :first-child {
                margin-top: 0;
              }
            }
          }
        }
      `
    }
  }}
`

const TableGapRow = styled.tr`
  background: none;
  transition: background 0.125s ease-in;
  vertical-align: top;
  height: 30px;
`

const TableCellLabel = styled(LabelText)`
  white-space: nowrap;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg.min}) {
    display: none;
  }
`

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  padding-bottom: calc(${tablePadding} - ${p => p.theme.spacing.small});

  &:only-child {
    padding-top: ${tablePadding};
  }

  ${TableHead},
  ${TableCell} {
    &:first-of-type {
      padding-left: ${tablePadding};
    }

    padding-right: ${tablePadding};
  }
`

export const sort = (rowA, rowB, column, direction, firstIteration = false) => {
  const value1 = firstIteration ? rowA[column] : rowA
  const value2 = firstIteration ? rowB[column] : rowB
  const isDate = moment(value1, moment.ISO_8601, true).isValid()
  const isString = typeof value1 === 'string'
  const isNumber = !isNaN(stringToNumber(value1))
  if (isDate) {
    return new Date(value1) >= new Date(value2) ? direction : -direction
  } else if (isNumber) {
    return direction === 1
      ? stringToNumber(value1) - stringToNumber(value2)
      : stringToNumber(value2) - stringToNumber(value1)
  } else if (isString) {
    if (value1 === value2) return 0
    return value1 > value2 ? direction : -direction
  } else if (value1.props && value1.props.value) {
    return sort(value1.props.value, value2.props.value, column, direction)
  } else if (value1.props && value1.props.children) {
    return sort(value1.props.children, value2.props.children, column, direction)
  } else {
    return 0
  }
}

const Table = ({
  cellWithLabels,
  children,
  headers,
  rows,
  responsive = true,
  isSortable = false,
  tableGapIndex,
  ...rest
}) => {
  const [sortedRows, setSortedRows] = useState(rows.slice(0))
  const [sortedColumn, setSortedColumn] = useState(0)
  const [isAscending, setIsAscending] = useState(true)

  useEffect(() => {
    setSortedRows(rows.slice(0))
    setSortedColumn(0)
    return () => {}
  }, [rows])

  const sortByColumn = column => {
    if (!isSortable) return
    const ascending = column === sortedColumn ? !isAscending : true
    const direction = ascending ? 1 : -1
    setSortedColumn(column)
    setIsAscending(ascending)
    setSortedRows(
      sortedRows.sort((rowA, rowB) => sort(rowA, rowB, column, direction, true))
    )
  }

  return (
    <Wrapper {...rest}>
      <StyledTable responsive={responsive}>
        <TableHeaders responsive={responsive} isSortable={isSortable}>
          <tr>
            {headers.map((header, i) => (
              <TableHead onClick={() => sortByColumn(i)} key={i}>
                <SortinText
                  sortingDir={
                    isSortable && i === sortedColumn && sortedRows.length > 1
                      ? isAscending
                        ? 'bottom'
                        : 'top'
                      : false
                  }
                >
                  {header}
                </SortinText>
              </TableHead>
            ))}
          </tr>
        </TableHeaders>
        <TableBody>
          {sortedRows.map((row, i) => {
            if (tableGapIndex && tableGapIndex === i) {
              return <TableGapRow key={i}></TableGapRow>
            }
            return (
              <TableRow key={i} responsive={responsive}>
                {row.map((item, j) => (
                  <TableCell key={j} responsive={responsive}>
                    {cellWithLabels ? (
                      <TableCellLabel responsive={responsive}>
                        {headers[j]}
                      </TableCellLabel>
                    ) : null}
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
        {children}
      </StyledTable>
    </Wrapper>
  )
}

/* NOTE:
 * Import CardTable if you're using table with controls or whatnot. Or just import Table and CardForTable and do custom
 * If you're doing a custom table for some reason, import CardForTable to wrap it with. (eg. DetailsTable)
 * The reason for this horrid padding tickery is to have consistent spacing, but also to have spacing inside TDs.
 * The reason for inside spacing is in the mobile overflow and focus styling.
 */

export const CardForTable = styled(Card)(
  ({ noSpacing }) => `padding: ${noSpacing ? 0 : tablePadding};`
)
const TableChildrenSpacing = styled.div`
  padding: ${tablePadding} ${tablePadding} 0;
`

export const CardTable = ({ className, children, ...props }) => (
  <CardForTable className={className} noSpacing>
    {children ? <TableChildrenSpacing>{children}</TableChildrenSpacing> : null}
    <Table {...props} />
  </CardForTable>
)

export { tablePadding, Table as default }
