import React from 'react'
import styled from 'styled-components'
import NavList from './navList'
import NavLink from './navLink'

const StyledNavLink = styled(NavLink)(
  ({ theme: { colors, breakpoints, spacing } }) => `
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    text-align: left;

    :after {
      content: '';
      margin-left: .25em;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: .2em .2em 0 .2em;
      border-color: currentColor transparent transparent transparent;
    }

    @media screen and (max-width: ${breakpoints.md.max}) {
      justify-content: space-between;
      width: 100%;

      &:after {
        font-size: 2em;
      }
    }
  }
`
)

const NavGroup = ({ className, open = false, children, title, onClick }) => {
  const id = `menu-list${title.toLowerCase().trim().replace(/\s/g, '-')}`
  return (
    <>
      <StyledNavLink
        onClick={onClick}
        aria-expanded={open}
        aria-controls={id}
        noLink
        className={className}
      >
        {title}
      </StyledNavLink>
      <NavList visible={open} id={id}>
        {children}
      </NavList>
    </>
  )
}

export default styled(NavGroup)``
