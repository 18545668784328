import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { tableTheme } from '../../styles/theme'
import Table, { CardForTable, tablePadding } from './table'
import TableTabs from './tableTabs'

const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1)

const StyledTableTabs = styled(TableTabs)`
  padding: calc(${tablePadding} + 0.5em) ${tablePadding} 0;
`
const tabKeyToLabel = key =>
  ({
    profit: 'Tuotto',
    history: 'Historia',
  }[key] || capitalize(key))

const FundTable = ({ cellWithLabels, data }) => {
  const tabs = Object.keys(data).map(key => ({
    key,
    label: tabKeyToLabel(key),
  }))
  const [activeTab, setActiveTab] = useState(tabs[0].key)
  const { headers = [], cells = [], hint } = data[activeTab]

  return (
    <ThemeProvider theme={tableTheme}>
      <CardForTable noSpacing>
        <StyledTableTabs tabs={tabs} onChange={setActiveTab} hint={hint} />
        {tabs.map(tab =>
          tab.key === activeTab ? (
            <Table
              key={tab.key}
              cellWithLabels={cellWithLabels}
              headers={headers}
              rows={cells}
              id={`panel-${tab.key}`}
              role="tabpanel"
              tabIndex="0"
              aria-labelledby={`tab-${tab.key}`}
            />
          ) : (
            <div
              key={tab.key}
              id={`panel-${tab.key}`}
              role="tabpanel"
              tabIndex="-1"
              aria-labelledby={`tab-${tab.key}`}
              hidden
            />
          )
        )}
      </CardForTable>
    </ThemeProvider>
  )
}

export default FundTable
