import React from 'react'
import styled from 'styled-components'

import Logo from '../../images/alexandria-logo'
import { visible, darkenBackground } from '../../utils'
import { zIndex } from '../../utils/constants'
import Button from '../buttons/button'
import AccessibilityText from '../typography/accessibilityText'
import NavItem from './navItem'
import NavLink from './navLink'
import MobileDrawer from './mobileDrawer'
import Search from './search'

import { ReactComponent as SearchIcon } from '../../images/icon_search.svg'

const Nav = styled.nav`
  z-index: ${zIndex.navbar};
  ${visible({ xs: true, sm: true, md: true })}
  position: fixed;
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  background: ${({ theme: t }) => t.colors.backgroundColor};
  height: ${p => p.theme.navHeight.min};
  width: 100%;
  align-items: stretch;
  justify-content: space-between;

  &.searchOpen {
    height: calc(${p => p.theme.navHeight.min} + 100px);
  }

  ${({ theme: { colors, breakpoints } }) => `
    a, button {
      :not(${Button}) {
        color: ${colors.primaryColor};
      }
    }

    @media screen and (max-width: ${breakpoints.md.max}) {
      a, button {
        :not(${Button})[aria-expanded] {
          &[aria-expanded="false"] {
            span {
              font-weight: 400;
            }
          }
          &[aria-expanded="true"] {
            span {
              font-weight: 500;
            }
          }
        }
      }
    }
  `}

  svg {
    path {
      fill: currentColor;
    }
  }

  ${NavItem} {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`

const Container = styled.div`
  display: flex;
  height: ${p => p.theme.navHeight.min};
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`

const LogoContainer = styled.div(
  ({ theme: { navHeight } }) => `
  display: flex;
  align-items: center;

  a {
    padding-left: 20px;

    &:first-child {
      height: 56px;
    }
  }

  svg {
    width: 140px;
    height: ${navHeight.min};
  }
`
)

const SearchButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 5px;

  &.active {
    svg {
      path {
        fill: white;
      }
    }
  }
`

const InputField = styled.div``

const SearchContainer = styled.div(
  ({ theme: { colors } }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    background: ${darkenBackground(colors.backgroundColor, 0.1)};

    ${InputField} {
      display: flex;
      align-items: center;
      border-radius: 50px;
      background: white;
      height: 55px;
      width: 90%;

      & > div {
        width: 100%;
      }

      &:focus-within {
        outline: 2px solid ${colors.primaryColor};
      }

      & > input {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 50px;
        padding-left: 19px;

        ::placeholder {
          color: ${colors.primaryColor};
        }

        &:focus {
          outline: none;
        }
      }
  
      & > svg {
        margin-right: 16px;
      }
    }
  }
`
)

const LoginAndDrawer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`

const MobileNavbar = ({ navGroups, pages, isSearchOpen, setIsSearchOpen }) => {
  const handleSearch = () => {
    setIsSearchOpen(!isSearchOpen)
  }

  return (
    <Nav className={isSearchOpen ? 'searchOpen' : ''} aria-label="Mobile main">
      {isSearchOpen && (
        <SearchContainer>
          <InputField>
            <Search pages={pages} />
          </InputField>
        </SearchContainer>
      )}
      <Container>
        <LogoContainer>
          <NavLink to="/">
            <Logo aria-label="Etusivu" id="main-header-logo-mobile" />
            <AccessibilityText>Etusivulle</AccessibilityText>
          </NavLink>
        </LogoContainer>
        <RightContainer>
          <SearchButton
            className={isSearchOpen ? 'active' : ''}
            onClick={handleSearch}
          >
            <SearchIcon />
          </SearchButton>
          <LoginAndDrawer>
            <MobileDrawer items={navGroups} setIsSearchOpen={setIsSearchOpen} />
          </LoginAndDrawer>
        </RightContainer>
      </Container>
    </Nav>
  )
}

export default MobileNavbar
