import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import ContentHeroCard from '../components/cards/contentHeroCard'
import PodcastCard from '../components/cards/podcastCard'
import SelectInput from '../components/formInputs/selectInput'
import { CardGroups, getHero } from '../components/contentfulPage'
import styled from 'styled-components'
import Layout from '../components/layout'
import ContentfulText from '../components/contentfulText'
import Container from '../components/container'
import { getContentfulLongTextAsNode, isString } from '../utils'
import TextContent from '../components/textContent'

const FooterTextContent = styled(TextContent)`
  padding-top: 1em;
`

const InputContainer = styled(Container)`
  margin-top: -40px;
`

const FooterContent = styled.p``

const mapHeroProps = ({
  image,
  publicationDate,
  heading,
  body,
  seasonNumber,
}) => ({
  image,
  title: heading,
  publicationDate,
  body: (
    <div dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }} />
  ),
  contentType: 'podcast',
  seasonNumber,

  // This property adjusts the image position in the page hero
  // You might want to adjust the value depending on the image to crop specific images properly
  imagePositionCss: '50% 20%',
})

const getUniqueValues = array => Array.from(new Set(array))

const PodcastPage = ({ data: { page, episodes, seasonPreviews } }) => {
  const image = page.image ? `https:${page.image.file.url}` : null
  const seoHtml = page.seoMarkdown?.childMarkdownRemark?.html
  const isDesktop = useMediaQuery({
    query: `(min-width: 960px)`,
  })

  const useConfetti = isDesktop

  console.log(isDesktop)
  const allEpisodes = episodes.edges.map(({ node }) => node)
  const defaultSeasonEpisodes = allEpisodes.filter(
    episode => episode.seasonNumber === 5
  )
  const [displayedItemCount, setDisplayedItemCount] = useState(
    defaultSeasonEpisodes.length
  )
  const filter = {
    type: 'season',
    label: 'Kausi',
    values: getUniqueValues(allEpisodes.map(item => String(item.seasonNumber))),
  }

  const [selectedFilters, setSelectedFilters] = useState({
    season: '5',
  })
  const [heroItem, setHeroItem] = useState(null)
  const [filteredEpisodes, setFilteredEpisodes] = useState(
    defaultSeasonEpisodes
  )
  const itemsToDisplay = filteredEpisodes.slice(0, displayedItemCount)

  const onFilterChange = e => {
    const filterKey = e.target.name
    const filterValue = e.target.value
    setSelectedFilters({
      [filterKey]: filterValue,
    })
  }

  useEffect(() => {
    const previews = seasonPreviews.edges.map(({ node }) => node)
    const preview = previews.find(
      p => String(p.seasonNumber) === String(selectedFilters.season)
    )
    setHeroItem(preview)
  }, [selectedFilters, seasonPreviews])

  useEffect(() => {
    const { season } = selectedFilters
    const filtered = episodes.edges
      .map(({ node }) => node)
      .filter(item => season === String(item.seasonNumber))
    setFilteredEpisodes(filtered)
    setDisplayedItemCount(filtered.length)
  }, [selectedFilters, episodes])

  const footerContent = getContentfulLongTextAsNode(page.footer?.footer)

  return (
    <Layout
      title={page.title}
      description={page.description.description}
      image={image}
      hero={getHero({ ...page, useConfettiHero: useConfetti })}
      shareable={page.shareable}
    >
      {heroItem && (
        <CardGroups
          cardGroups={[
            {
              hero: (
                <ContentHeroCard
                  {...mapHeroProps(heroItem)}
                  key={heroItem.slug}
                />
              ),
              items: [],
            },
          ]}
        />
      )}
      <InputContainer>
        <SelectInput
          key={filter.type}
          value={selectedFilters.season}
          options={filter.values}
          label={filter.label}
          name={filter.type}
          onChange={onFilterChange}
        />
      </InputContainer>
      <CardGroups
        cardGroups={[
          {
            hero: null,
            items: itemsToDisplay.map(item => (
              <PodcastCard key={item.slug} data={item} />
            )),
            itemsPerRow: 3,
          },
        ]}
      />
      <Container>
        {page.body && <ContentfulText text={page.body} />}
        {page.bodyTextSections && (
          <BodyTextSections sections={page.bodyTextSections} />
        )}
      </Container>
      {page.footer && (
        <Container isFlowing>
          {footerContent && (
            <FooterTextContent alignTo="center">
              <FooterContent as={isString(footerContent) ? undefined : 'div'}>
                {footerContent}
              </FooterContent>
            </FooterTextContent>
          )}
        </Container>
      )}
      {page.seoMarkdown && (
        <ContainerWithPadding>
          <SmallText as="div" dangerouslySetInnerHTML={{ __html: seoHtml }} />
        </ContainerWithPadding>
      )}
    </Layout>
  )
}

export default PodcastPage

export const query = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
    seasonPreviews: allContentfulPodcastSeasonPreview(
      sort: { fields: [seasonNumber], order: ASC }
    ) {
      edges {
        node {
          heading
          seasonNumber
          publicationDate
          image {
            title
            gatsbyImageData(width: 1800, placeholder: BLURRED)
          }
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    episodes: allContentfulPodcastEpisode(
      sort: { fields: [publicationDate, episodeNumber], order: ASC }
    ) {
      edges {
        node {
          __typename
          slug
          title
          description {
            description
          }
          category
          episodeNumber
          seasonNumber
          runTime
          isPublished
          contentType
          heading
          body {
            childMarkdownRemark {
              html
            }
          }
          publicationDate
          image {
            title
            gatsbyImageData(width: 800, placeholder: BLURRED)
          }
          previewBody {
            previewBody
          }
          previewHeading
          previewHeroHeading
        }
      }
    }
  }
`
