import React, { useState } from 'react'
import ReactMapGL, { Marker } from 'react-map-gl'
import styled, { withTheme } from 'styled-components'
import useMedia from 'react-use/lib/useMedia'
import 'mapbox-gl/dist/mapbox-gl.css'
import { ReactComponent as MarkerIcon } from '../images/marker-large.svg'

const token = process.env.GATSBY_MAPBOX_TOKEN

const StyledMap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export default withTheme(({ className, lat, lon, theme }) => {
  const [viewport, setViewport] = useState({
    latitude: lat,
    longitude: lon,
    zoom: 14,
  })
  const horizontal = useMedia(
    `screen and (min-width: ${theme.breakpoints.lg.min})`
  )

  return (
    <StyledMap className={className}>
      <ReactMapGL
        mapboxApiAccessToken={token}
        {...viewport}
        width={'100%'}
        height={horizontal ? '100%' : viewport.width}
        onViewportChange={vp => setViewport(vp)}
        mapStyle="mapbox://styles/alexandria-cms/ckfgsswog2j6n19pnjfc5h1og"
      >
        <Marker latitude={lat} longitude={lon} offsetLeft={-16} offsetTop={-44}>
          <MarkerIcon width={32} />
        </Marker>
      </ReactMapGL>
    </StyledMap>
  )
})
