import React, { useState, useRef, useEffect } from 'react'
import styled, { withTheme } from 'styled-components'
import { VictoryChart, VictoryArea, VictoryAxis, VictoryLine } from 'victory'
import { Subheading } from '../typography/headings'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 100%;
  padding: 0 ${props => props.theme.spacing.small};
  overflow: hidden;
`

const ResultNumber = styled(Subheading).attrs({ as: 'span', headingSize: 1 })``
const ResultBlock = styled.p`
  span:first-of-type {
    &:before {
      content: '';
      background: ${props => props['data-color']};
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      speak: none;
    }
  }

  ${ResultNumber} {
    padding-top: 0.35em;
    display: block;
  }
`

const SavingsData = styled.div`
  max-width: 700px;
  align-self: flex-end;
  padding: 1rem 0;
  display: flex;
  flex-flow: row wrap;

  @media screen and (min-width: ${props => props.theme.breakpoints.lg.min}) {
    order: 1;
  }
  margin: 0 -0.75em;

  ${ResultBlock} {
    padding: 0 0.75em;
  }
`

// numDigits and getMaxDomain are used to calculate valid domain range for the chart depending on current maxValue.
const numDigits = x => {
  // Polyfill IE
  Math.log10 =
    Math.log10 ||
    function (x) {
      return Math.log(x) * Math.LOG10E
    }

  return (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1
}
const getMaxDomainAndTickValues = maxValue => {
  const relativeValue = maxValue / Math.pow(10, numDigits(maxValue))
  let domain
  if (relativeValue < 0.25) {
    domain = 0.25 * Math.pow(10, numDigits(maxValue - 1))
    return [
      domain,
      [
        0 * domain,
        0.2 * domain,
        0.4 * domain,
        0.6 * domain,
        0.8 * domain,
        domain,
      ],
    ]
  } else if (relativeValue > 0.25 && relativeValue < 0.5) {
    domain = 0.5 * Math.pow(10, numDigits(maxValue - 1))
    return [
      domain,
      [
        0 * domain,
        0.2 * domain,
        0.4 * domain,
        0.6 * domain,
        0.8 * domain,
        domain,
      ],
    ]
  } else if (relativeValue > 0.5 && relativeValue < 0.75) {
    domain = 0.75 * Math.pow(10, numDigits(maxValue - 1))
    return [
      domain,
      [
        0 * domain,
        0.2 * domain,
        0.4 * domain,
        0.6 * domain,
        0.8 * domain,
        domain,
      ],
    ]
  } else if (relativeValue > 0.75) {
    domain = 1 * Math.pow(10, numDigits(maxValue))
    return [
      domain,
      [
        0 * domain,
        0.2 * domain,
        0.4 * domain,
        0.6 * domain,
        0.8 * domain,
        domain,
      ],
    ]
  }
}

const plotInitialAndInstallmentsData = ({
  year,
  initialCapital,
  monthlySaving,
}) => initialCapital + year * 12 * monthlySaving

const plotTotalSavings = ({
  initialCapital,
  monthlyRate,
  yearsOfSaving,
  monthlySaving,
  year,
}) =>
  (initialCapital > 0
    ? compoundInterestForInitialCapital({
        initialCapital,
        monthlyRate,
        yearsOfSaving: year,
      })
    : 0) +
  compoundInterestForInstallment({
    monthlyRate,
    yearsOfSaving: year,
    monthlySaving,
  })

const compoundInterestForInitialCapital = ({
  initialCapital,
  monthlyRate,
  yearsOfSaving,
}) => initialCapital * Math.pow(1 + monthlyRate, 12 * yearsOfSaving)

const compoundInterestForInstallment = ({
  monthlyRate,
  yearsOfSaving,
  monthlySaving,
}) =>
  (monthlySaving * (Math.pow(1 + monthlyRate, 12 * yearsOfSaving + 1) - 1)) /
    monthlyRate -
  monthlySaving

const getMonthlyRate = percentage =>
  Number((Math.pow(1 + percentage / 100, 1 / 12) - 1).toFixed(10))

const CalculatorGraph = styled(
  ({
    className,
    theme: {
      colors: { chart: chartColors },
    },
    values: {
      initialCapital = 0,
      profitPercentage = 4,
      yearsOfSaving = 10,
      monthlySaving = 150,
    },
  }) => {
    const [relativeFontSize, setRelativeFontSize] = useState(0)
    const wrapperRef = useRef()

    useEffect(() => {
      const fontSize = (450 / wrapperRef.current.clientWidth) * 12
      setRelativeFontSize(fontSize)
    }, [])

    const derivedMonthlyRate = getMonthlyRate(profitPercentage)
    const capitalWithInterest = compoundInterestForInitialCapital({
      initialCapital,
      monthlyRate: derivedMonthlyRate,
      yearsOfSaving,
    })
    const installmentWithInterest = compoundInterestForInstallment({
      monthlyRate: derivedMonthlyRate,
      yearsOfSaving,
      monthlySaving,
    })
    const totalSavings = initialCapital + monthlySaving * 12 * yearsOfSaving
    const totalWithInterest = capitalWithInterest + installmentWithInterest
    const [maxDomain, tickValues] = getMaxDomainAndTickValues(totalWithInterest)
    return (
      <Wrapper ref={wrapperRef} className={className}>
        <SavingsData>
          <ResultBlock data-color={chartColors.savingsColor}>
            <span>Säästetty pääoma</span>
            <ResultNumber>{totalSavings.toLocaleString('fi')}€</ResultNumber>
          </ResultBlock>
          <ResultBlock data-color={chartColors.profitColor}>
            <span>Jolle tuottoa ({profitPercentage} %)</span>
            <ResultNumber>
              {Number(
                Math.round(totalWithInterest) - Math.round(totalSavings)
              ).toLocaleString('fi')}
              €
            </ResultNumber>
          </ResultBlock>
          <ResultBlock data-color={chartColors.primaryColor}>
            <span>Yhteensä</span>
            <ResultNumber>
              {Math.round(totalWithInterest).toLocaleString('fi')}€
            </ResultNumber>
          </ResultBlock>
        </SavingsData>
        <VictoryChart
          padding={{
            left: 5 * relativeFontSize,
            top: 10,
            right: 0,
            bottom: 10,
          }}
          domain={{ x: [0, yearsOfSaving], y: [0, maxDomain] }}
          animate={{ duration: 250 }}
        >
          <VictoryAxis
            dependentAxis
            crossAxis={false}
            tickValues={tickValues}
            tickFormat={value => `${value.toLocaleString('fi')}€`}
            style={{
              axis: { stroke: 'transparent' },
              grid: { stroke: chartColors.borderColor },
              tickLabels: {
                fill: chartColors.textColor,
                fontFamily: 'inherit',
                fontSize: relativeFontSize,
                padding: 2,
              },
            }}
            animate={false}
          />
          <VictoryArea
            y={({ x }) =>
              plotTotalSavings({
                monthlySaving,
                initialCapital,
                monthlyRate: derivedMonthlyRate,
                year: x,
              })
            }
            style={{ data: { fill: chartColors.profitColor } }}
          />
          <VictoryArea
            y={({ x }) =>
              plotInitialAndInstallmentsData({
                monthlySaving,
                initialCapital,
                year: x,
              })
            }
            style={{ data: { fill: chartColors.savingsColor } }}
          />
          <VictoryLine
            y={({ x }) =>
              plotTotalSavings({
                monthlySaving,
                initialCapital,
                monthlyRate: derivedMonthlyRate,
                year: x,
              })
            }
            style={{
              data: { stroke: chartColors.primaryColor, strokeWidth: 1.5 },
            }}
          />
        </VictoryChart>
      </Wrapper>
    )
  }
)``

export default withTheme(CalculatorGraph)
