import { css } from 'styled-components'

export default css`
  font-size: 1em;
  background: none;
  display: inline-block;
  border: 2px solid currentColor;
  border-radius: 100px;
  padding: ${({ theme: { spacing } }) => `${spacing.xsmall} ${spacing.large}`};
  text-align: center;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.primaryColor};
  transition: color 0.125s ease-in, border-color 0.125s ease-in,
    background-color 0.125s ease-in;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  :hover,
  :focus,
  :active,
  &[aria-selected='true'],
  &[data-hover='true'] {
    text-decoration: none;
    outline: none;
    transition-timing-function: ease-out;
  }

  :hover,
  :focus,
  &[aria-selected='true'],
  &[data-hover='true'] {
    background-color: ${({ theme }) => theme.colors.primaryColor};
    border-color: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.backgroundColor};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    border-color: ${({ theme }) => theme.colors.backgroundColor};
    color: ${({ theme }) => theme.colors.primaryColor};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    border: 2px solid ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.primaryColor} !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
`
