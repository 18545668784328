import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import LayoutContent from '../components/layoutContent'
import ContentfulRichText from '../components/contentfulRichText'

const CookieSectionTitle = styled.h3``

const Legal = ({
  data: {
    contentfulLegal: { slug, title, heading, body },
  },
}) => {
  const scriptContainer = useRef()

  useEffect(() => {
    const isDataPrivacyPage = slug === 'tietosuoja'
    if (typeof window !== 'undefined' && isDataPrivacyPage) {
      const script = document.createElement('script')
      script.id = 'CookieDeclaration'
      script.src =
        'https://consent.cookiebot.com/1c21b6ad-a04d-4391-8c19-803d449e9e46/cd.js'
      script.type = 'text/javascript'
      script.async = true
      if (scriptContainer.current.childElementCount === 0) {
        scriptContainer.current.appendChild(script)
      }
    }
  }, [slug])

  return (
    <Layout title={title}>
      <LayoutContent heading={heading}>
        <ContentfulRichText richText={body} />
        {slug === 'tietosuoja' && (
          <CookieSectionTitle>Evästeet</CookieSectionTitle>
        )}
        <div ref={scriptContainer}></div>
      </LayoutContent>
    </Layout>
  )
}

export default Legal

export const query = graphql`
  query ($slug: String!) {
    contentfulLegal(slug: { eq: $slug }) {
      slug
      title
      heading
      body {
        raw
        references {
          __typename
          id
          contentful_id
          title
          file {
            url
          }
        }
      }
    }
  }
`
