import React, { useState } from 'react'
import styled from 'styled-components'
import { ScoreSliderWithLabel } from './scoreSlider'
import { ReactComponent as SpacerDots } from '../../images/spacer.svg'

const ScoreSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 3rem;
`

const ScoreDescription = styled.div`
  text-align: center;
  max-width: ${({ theme }) => theme.width.contentNew};
  margin-bottom: 3rem;
  padding: 0 ${({ theme }) => theme.spacing.medium};
`

const ScoreGrid = styled.div`
  display: grid;
  width: 100%;
  max-width: ${({ theme }) => theme.width.contentNew};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-auto-flow: row;
  grid-row-gap: 1rem;
  padding: 0 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    grid-template-columns: 1fr;
  }
`

const DisclaimerWrapper = styled.div`
  margin-top: 2rem;
  padding: 0 ${({ theme }) => theme.spacing.medium};
`

const DisclaimerLink = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  gap: 0.5rem;
  user-select: none;

  span {
    font-size: 1rem;
    text-decoration: underline;
  }
`

const DisclaimerText = styled.div`
  font-size: 1rem;
  text-align: center;
  max-width: ${props => props.theme.width.contentNew};
`

const DisclaimerBox = () => {
  const [extended, setExtended] = useState(false)

  return (
    <DisclaimerWrapper onClick={() => setExtended(prev => !prev)}>
      {extended ? (
        <DisclaimerText>
          Tällä sivustolla on julkaistu kaikki saamamme asiakaspalaute, joka on
          annettu asiakaspalautekyselyiden “Suosittelisitko Alexandrian
          palveluita ystävillesi tai perheenjäsenillesi? -kysymyksen yhteydessä.
          Kysely lähetetään jokaisen asiakastapaamisen jälkeen. Tarvittaessa
          kirjalliset palautteet on anonymisoitu siten, että vastaajaa ei voida
          tunnistaa vastauksesta. Alexandrian henkilöstön osalta palautteisiin
          on jätetty henkilön etunimi. Vuonna 2024 kysymykseen vastasi 9374
          asiakasta, sanallisia palautteita näistä oli yhteensä 1248 kappaletta.
          Vuoden 2024 tammi- kesäkuussa palautteita saatiin yhteensä 6080
          kappaletta, näistä sanallisia oli 775 kappaletta. Sekä vuoden 2023
          että H1 2024 palautteista on ennen julkaisua poistettu kaksi eri
          syistä julkaisukelvotonta palautetta. Seuraava erä palautteita
          julkaistaan arviolta alkuvuonna 2025.
        </DisclaimerText>
      ) : (
        <DisclaimerLink>
          <span>Mistä lukumme koostuvat?</span>
        </DisclaimerLink>
      )}
    </DisclaimerWrapper>
  )
}

const SpacerWrapper = styled.div`
  height: 0.5rem;
  margin-top: 2rem;
  svg {
    height: 100%;
  }
`

const Spacer = () => (
  <SpacerWrapper>
    <SpacerDots />
  </SpacerWrapper>
)

export const NpsScoreSummary = () => {
  return (
    <ScoreSummaryWrapper>
      <ScoreDescription>
        <h1>Katsele palautteita ruusuista risuihin</h1>
        <p>
          Me uskomme, että tuhannet sanat kertovat enemmän kuin pelkät numerot
          yksinään. Alta löydät kaikki saamamme sanalliset asiakaspalautteet
          ajalta 1.1.2023-30.6.2024.
        </p>
      </ScoreDescription>
      <ScoreGrid>
        <ScoreSliderWithLabel
          score={'8,9'}
          label={
            'Kuinka todennäköisesti suosittelisit Alexandrian palveluita lähipiirillesi?'
          }
        />
        <ScoreSliderWithLabel
          score={'9,4'}
          label={
            'Kuinka tyytyväinen olet henkilökohtaisen sijoitusneuvojasi palveluun?'
          }
        />
        <ScoreSliderWithLabel
          score={'9,1'}
          label={'Kuinka tyytyväinen olet sinulle suositeltuihin ratkaisuihin?'}
        />
        <ScoreSliderWithLabel
          score={'9,3'}
          label={'Miten vaivattomana koit asioinnin kanssamme?'}
        />
      </ScoreGrid>
      <DisclaimerBox />
      <Spacer />
    </ScoreSummaryWrapper>
  )
}
