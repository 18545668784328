import React from 'react'
import { node, oneOfType, string } from 'prop-types'
import styled from 'styled-components'

import SplitHero from './heroes/splitHero'
import Main from './main'
import Aside from './aside'
import Container from './container'

const propTypes = {
  heading: oneOfType([node, string]),
  lead: oneOfType([node, string]),
  children: oneOfType([node, string]),
  aside: oneOfType([node, string]),
}

const ContentAside = styled(Aside)``

const OuterWrapper = styled(Container)`
  margin-bottom: 1em;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg.min}) {
    display: flex;
    justify-content: space-between;
  }
`

const InnerWrapper = styled.div(
  ({ theme: { spacing, breakpoints }, hasAside }) => `
  flex-grow: 1;
  margin: ${spacing.xxlarge} auto 0;

  @media (min-width: ${breakpoints.lg.min}) {
    margin: ${
      hasAside ? `${spacing.xxlarge} 0 0 auto` : `${spacing.xxlarge} auto 0`
    };
  }
`
)

const LayoutContent = ({
  heading,
  lead,
  children,
  renderContent,
  aside,
  subNavigation,
  ...rest
}) =>
  !heading && !lead && !children && !aside && !renderContent ? null : (
    <OuterWrapper id="layout-content-outer-wrapper" {...rest}>
      {subNavigation}
      <InnerWrapper hasAside={!!aside}>
        {(heading || lead) && <SplitHero heading={heading} lead={lead} />}
        {renderContent
          ? renderContent(Main)
          : children && <Main>{children}</Main>}
      </InnerWrapper>
      {aside && <ContentAside>{aside}</ContentAside>}
    </OuterWrapper>
  )

LayoutContent.propTypes = propTypes

export default LayoutContent
