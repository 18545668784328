import React from 'react'
import styled from 'styled-components'
import { arrayOf, func, string } from 'prop-types'
import { lightenBackground, sanitizeString } from '../../utils'
import Button from '../buttons/button'
import { ReactComponent as CheckIcon } from '../../images/check-circle.svg'
import { getLabelByText } from '../../utils/segments'

const propTypes = {
  type: string,
  options: arrayOf(string),
  activeOptions: arrayOf(string),
  label: string,
  onOptionSelected: func,
}

const SHOW_ALL = 'kaikki'

// prettier-ignore
const Wrapper = styled.div`
  padding: 0 5px;
  font-size: 1rem;
  flex-grow: 1;
  ${({grouptype = ''}) => {
    switch (grouptype) {
      case 'contentType':
        return `flex-shrink: 2;`
      case 'category':
        return `
          flex-shrink: 2;
        `
      default:
        return ''
    }
  }}
`

const OptionsContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  gap: 5px;

  @media (max-width: ${theme.breakpoints.md.max}) {
    & > * {
      flex-grow: 1;
    }
  }
`
)

const OptionsRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 5px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg.max}) {
    display: none;
  }
`

const GroupLabel = styled.div(
  ({ theme }) => `
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  padding: 20px 0;

  @media (max-width: ${theme.breakpoints.md.max}) {
    padding: 10px 0;
  }
`
)

const OptionButton = styled(Button)(
  ({ theme, large, selected }) => `
  position: relative;
  display: flex;
  align-items: center;
  padding: ${large ? '10px 16px' : '10px 20px'};
  background-color: ${
    selected
      ? theme.colors.blueLight
      : lightenBackground(theme.colors.blueLight, 0.6)
  };
  color: ${theme.colors.primaryColor} !important;
  border: none;
  width: ${large ? '200px' : 'auto'};
  justify-content: center;

  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  
  svg {
    position: absolute;
    top: -5px;
    right: -5px;
  }
  
  &:focus {
    background-color: ${theme.colors.offWhite};
  }
  
  &:hover {
    background-color: ${lightenBackground(theme.colors.blueLight, 0.3)};
  }

  &:active, &:focus {
    background-color: ${theme.colors.blueLight} !important;
  }
`
)

const FilterOption = ({ type, option, onSelected, isActive }) => {
  return (
    <OptionButton
      key={option}
      onClick={() => onSelected({ type, value: sanitizeString(option) })}
      large={type === 'category' || type === 'contentType'}
      selected={isActive}
    >
      {getLabelByText(option)}
      {isActive && <CheckIcon />}
    </OptionButton>
  )
}

export const getLabelName = titleLabel => {
  switch (titleLabel) {
    case 'Kategoriat':
      return 'Aiheet'
    case 'Tagit':
      return 'Aihesanat'
    default:
      return titleLabel
  }
}

const FilterOptionsGroup = ({
  type,
  options,
  activeOptions,
  label,
  onOptionSelected,
}) => {
  if (!options?.length) return null

  const optionIsActive = option =>
    activeOptions && activeOptions.includes(sanitizeString(option))

  const handleOptionSelected = ({ type, value }) => {
    let newActiveOptions

    // If user selects 'show all' remove other active options
    if (value === SHOW_ALL) {
      newActiveOptions = [SHOW_ALL]
    } else {
      // Either add selected option to active options, or remove it, while also removing 'show all' option
      newActiveOptions = activeOptions.includes(value)
        ? [...activeOptions].filter(v => ![SHOW_ALL, value].includes(v))
        : [...activeOptions, value].filter(v => v !== SHOW_ALL)

      // If all options were toggled off, select 'show all'
      if (newActiveOptions.length === 0) {
        newActiveOptions = [SHOW_ALL]
      }
    }

    onOptionSelected({ type, values: newActiveOptions })
  }

  return (
    <Wrapper grouptype={type}>
      <GroupLabel>{getLabelName(label)}</GroupLabel>
      <OptionsContainer>
        {options?.map((option, index) => (
          <React.Fragment key={sanitizeString(option)}>
            {index % 4 === 0 && <OptionsRow />}
            <FilterOption
              isActive={optionIsActive(option)}
              type={type}
              option={option}
              onSelected={p => handleOptionSelected(p)}
            />
          </React.Fragment>
        ))}
      </OptionsContainer>
    </Wrapper>
  )
}

FilterOptionsGroup.propTypes = propTypes

export default FilterOptionsGroup
