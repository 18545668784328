import React from 'react'
import styled from 'styled-components'

import AsideCard from './cards/asideCard'
import textWidth from '../styles/mixins/width/text'

const OuterWrapper = styled.aside(
  ({ theme, minwidth }) => `
  margin: ${theme.spacing.xxlarge} auto;
  position: relative;
  ${textWidth}

  @media (min-width: ${theme.breakpoints.lg.min}) {
    margin: ${theme.spacing.xxlarge} 0 0 ${theme.spacing.xxlarge};
    max-width: ${minwidth || '285px'};
    width: 100%;
  }
`
)

const InnerWrapper = styled.div(
  ({ theme: { breakpoints, spacing, navHeight } }) => `
  @media (min-width: ${breakpoints.lg.min}) {
    position: sticky;
    top: calc(${navHeight.lg} + ${spacing.xxlarge});
  }
`
)

const Aside = ({ minWidth, children, ...rest }) => {
  return (
    <OuterWrapper minwidth={minWidth || ''} {...rest}>
      <InnerWrapper>
        <AsideCard minwidth={minWidth || ''}>{children}</AsideCard>
      </InnerWrapper>
    </OuterWrapper>
  )
}

export default Aside
