import React, { useState } from 'react'
import styled from 'styled-components'
import AutoSuggest from 'react-autosuggest'
import { lightenBackground, navigate } from '../utils'
import { zIndex } from '../utils/constants'
import { ReactComponent as SearchIcon } from '../images/icon_search.svg'

const officesSectionTitle = 'Toimipaikat'
const peopleSectionTitle = 'Ihmiset'

const SearchIconContainer = styled.div`
  position: absolute;
  display: flex;
  right: 16px;
  pointer-events: none;
  svg {
    position: relative;
  }
`

const Suggestion = styled.div`
  margin: ${props => props.theme.spacing.xsmall};
`

const SectionTitle = styled.div`
  color: ${props => props.theme.colors.grey};
  margin: ${props => props.theme.spacing.xsmall};
`

const SuggestionsContainer = styled.div(
  ({ theme }) => `
  &.react-autosuggest__suggestions-container--open {
    position: absolute;
    width: 100%;
    padding: 10px;
    z-index: ${zIndex.dropDown};
    box-shadow: ${theme.boxShadow.sm};
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.greyLight};
    margin-top: ${theme.spacing.xsmall};
    border-radius: ${theme.radius.md};
    overflow: scroll;
    max-height: 240px;
    ul {
      padding-inline-start: 0px;
      margin-block-start: 0;
      margin-block-end: 0;
      list-style-type: none;
      &:not(:last-of-type) {
        background-color: red;
      }
      li {
        margin-block-start: 0;
        margin-block-end: 0;
        cursor: pointer;
        &.react-autosuggest__suggestion--highlighted {
          color: ${props => props.theme.colors.blue};
        }
        &:hover {
          background-color: ${lightenBackground(theme.colors.blueLight, 0.5)};
        }
      }
    }
  }
`
)

const InputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: -200px auto 0 auto;
  z-index: 10;
  padding: 0 10px;
  max-width: 400px;

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    margin: -140px auto 0 auto;
  }

  input {
    line-height: 1.5;
    outline: 0;
    flex: 1 1 100%;
    padding: 16px 0;

    border-radius: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primaryColor};
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: transparent;

    ::placeholder {
      color: ${({ theme }) => theme.colors.primaryColor};
      margin-right: 40px;
      font-size: 20px;
      line-height: 28px;

      @media (max-width: 350px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`

const renderInputComponent = inputProps => (
  <InputContainer>
    <input {...inputProps} />
    <SearchIconContainer aria-hidden="true">
      <SearchIcon />
    </SearchIconContainer>
  </InputContainer>
)

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = ({ offices, people, value }) => {
  const escapedValue = escapeRegexCharacters(value.trim())
  if (escapedValue.length === 0) {
    return [{ sectionTitle: officesSectionTitle, items: offices }]
  }
  const inputValue = value.trim().toLowerCase()
  const regex = new RegExp(inputValue, 'i')
  const results = [
    {
      sectionTitle: officesSectionTitle,
      items: offices.filter(office => regex.test(office.city)),
    },
    {
      sectionTitle: peopleSectionTitle,
      items: people.filter(
        person => regex.test(person.firstName) || regex.test(person.lastName)
      ),
    },
  ].filter(section => section.items.length > 0)
  if (results.length) return results
  return [{ text: 'Haulla ei löytynyt tuloksia' }]
}

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => {
  if (suggestion.text) return ''
  return suggestion.city || `${suggestion.firstName} ${suggestion.lastName}`
}
const onSuggestionSelected = (_, { suggestion, sectionIndex }, offices) => {
  if (suggestion.city) {
    // suggestion is an office
    navigate(`/toimipaikat/${suggestion.slug}`)
  } else if (suggestion.firstName) {
    // suggestion is not an office
    const foundOffice = offices.find(office =>
      office.personnel.find(person => person.id === suggestion.id)
    )
    if (foundOffice) {
      // person has an office
      navigate(`/toimipaikat/${foundOffice.slug}#${suggestion.slug}`)
    } else {
      // person doesnt have an office
      navigate(`/alexandrialaiset/#${suggestion.slug}`)
    }
  }
}
const renderSectionTitle = section => {
  return <SectionTitle>{section.sectionTitle}</SectionTitle>
}

const renderSuggestionsContainer = ({ containerProps, children }) => {
  return (
    <div style={{ position: 'relative' }}>
      <SuggestionsContainer {...containerProps}>
        {children}
      </SuggestionsContainer>
    </div>
  )
}

const getSectionSuggestions = section => section.items

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => {
  if (suggestion.text) return suggestion.text
  return (
    <Suggestion>
      {suggestion.city
        ? suggestion.city
        : `${suggestion.firstName} ${suggestion.lastName}`}
    </Suggestion>
  )
}

const ContactSelect = ({ offices, people }) => {
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([
    { sectionTitle: officesSectionTitle, items: offices },
  ])

  const onSuggestionsClearRequested = () => {
    setValue('')
    setSuggestions([{ sectionTitle: officesSectionTitle, offices }])
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions({ offices, people, value }))
  }

  const isMultiSection = () => {
    return !(suggestions.length === 1 && suggestions[0].text)
  }

  const shouldHighlight = () => {
    return (
      suggestions &&
      suggestions.length === 1 &&
      suggestions[0].items &&
      suggestions[0].items.length === 1
    )
  }

  return (
    <AutoSuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      multiSection={isMultiSection()}
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
      renderInputComponent={renderInputComponent}
      onSuggestionSelected={(e, values) =>
        onSuggestionSelected(e, values, offices)
      }
      highlightFirstSuggestion={shouldHighlight()}
      inputProps={{
        placeholder: 'Hae toimistoa tai henkilöä...',
        value: value,
        type: 'search',
        onChange: (_, { newValue }) => setValue(newValue),
      }}
      shouldRenderSuggestions={() => true}
    />
  )
}

export default ContactSelect
