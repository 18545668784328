import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
import hexToRgba from 'hex-to-rgba'

import medium from './mixins/medium'

export default createGlobalStyle`
  ${normalize()}
  html {
    box-sizing: border-box;
    height: 100%;
    color: ${props => props.theme.colors.primaryColor};
    font-family: 'Scto Grotesk A', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    background: ${p => p.theme.colors.backgroundColor};
    scroll-padding-top: 1rem;

    @media(min-width: ${props => props.theme.breakpoints.sm.min}) {
      font-size: 16px
    }
  }
  *, *:before, *:after {
    box-sizing: border-box;
  }

  ::-moz-selection {
    background: ${p => hexToRgba(p.theme.colors.blueLight, 0.4)};
  }

  ::selection {
    background: ${p => hexToRgba(p.theme.colors.blueLight, 0.4)};
  }

  body {
    height: 100%;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1, h2, h3, h4, h5 {
    color: ${props => props.theme.colors.blue};
    font-family: 'Saol Display', sans-serif;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: .9091125em;
  }

  p {
    line-height: ${p => p.theme.lineHeight.normal};
    margin: 0 0 ${p => p.theme.lineHeight.normal}em;

    &:last-child:not(:only-child) {
      margin-bottom: 0;
    }
  }
  strong {
    ${medium}
  }

  em {
    font-weight: inherit;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  svg text {
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input, textarea {
    -webkit-appearance: none;
  }

  button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    color: inherit;

    :focus {
      outline: none;
    }
  }

  .underline-text {
    text-decoration: underline !important;
  }
`
