import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Heading } from '../typography/headings'
import { ReactComponent as InfoIcon } from '../../images/info.svg'
import { lightenBackground } from '../../utils'
import { ReactComponent as ArrowIcon } from '../../images/arrow-circle-down.svg'
import { ReactComponent as ArrowRightIcon } from '../../images/arrow-right.svg'
import { useLocation } from '@reach/router'

const Wrapper = styled.div(
  ({ theme: t }) => `
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  background-color: ${lightenBackground(t.colors.orange, 0.6)};
  padding: 74px 60px 73px 60px;
  justify-content: center;

  @media (max-width: ${t.breakpoints.md.max}) {
    padding: 74px 20px 26px 20px;
  }
`
)

const Content = styled.div(
  ({ theme: t }) => `
  display: flex;
  justify-content: space-evenly;
  max-width: ${t.width.max};
  width: 100%;
  gap: 60px;

  @media (max-width: ${t.breakpoints.md.max}) {
    flex-direction: column;
    gap: 30px;
  }
`
)

const Info = styled(InfoIcon)(
  ({ theme: t }) => `
  position: absolute;
  right: 63px;
  top: -28px;

  @media (max-width: 1100px) {
    transform: scale(0.57);
    right: -16px;
    top: -39px;
  }
`
)

const Links = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  width: 329px;

  @media (max-width: 400px) {
    width: 100%;
  }
`
)

const Link = styled.button(
  ({ theme: t }) => `
  display: flex;
  height: auto;
  padding: 10px 0;
  border-bottom: 1px solid ${t.colors.primaryColor};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  
  span {
    width: 22px;
    height: 22px;

    svg {
      transform: scale(0.88);
    }
  }

  &:hover {
    text-decoration: underline;
  }
`
)

const TextContent = styled.div(
  ({ theme: t, isArticlePage }) => `
    display: flex;
    flex-direction: column;
    ${isArticlePage ? `max-width: 800px;` : `width: 637px;`}
    gap: 20px;

    @media (max-width: ${t.breakpoints.md.max}) {
      width: 100%;
    }
  `
)

const Title = styled(Heading)(
  ({ theme: t, isArticlePage }) => `
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  margin: 0;
  ${isArticlePage ? 'max-width: 800px' : 'width: 500px'};

  @media (max-width: ${t.breakpoints.md.max}) {
    font-size: 28px;
    line-height: 33px;
    width: 100%;
  }
`
)

const Description = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  gap: 20px;

  a {
    color: ${t.colors.primaryColor};
    text-decoration: underline;
  }

  p {
    margin: 0;
  }

  p:has(span) {
    margin: 0 auto 30px auto;

    @media (max-width: ${t.breakpoints.md.max}) {
      margin: 0 auto 20px auto;
    }
  }

  span {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
  }

  img {
    max-width: 100%;
  }

  iframe {
    margin-top: 20px;

    @media (max-width: ${t.breakpoints.md.max}) {
      width: 100%;
      height: 260px;
    }

    @media (max-width: 480px) {
      height: auto;
    }
  }
`
)

const ShowMore = styled.button(
  ({ theme: t }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  gap: 15px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;

  @media (max-width: ${t.breakpoints.md.max}) {
    margin-top: 10px;
  }

  &:hover {
    text-decoration: underline;
  }
`
)

const LinksTitle = styled(Heading)(
  ({ theme: t }) => `
  font-size: 28px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 30px;

  @media (max-width: ${t.breakpoints.md.max}) {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 5px;
  }
`
)

const ProductInfoSection = ({ productInfoSections }) => {
  const [activeLink, setActiveLink] = useState(0)
  const [showMore, setShowMore] = useState(false)

  const activeSection = productInfoSections && productInfoSections[activeLink]
  const location = useLocation()
  const isArticlePage = location.pathname.includes('/sisallot/')
  const textContentRef = useRef(null)

  const handleLinkClick = index => {
    setActiveLink(index)
    setShowMore(false)

    if (textContentRef.current && isArticlePage) {
      textContentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const checkIfContentExists = fieldName => {
    const content = activeSection[fieldName]?.childMarkdownRemark?.html || ''
    return content.replace(/<\/?[^>]+(>|$)/g, '').length > 2
  }

  const replaceDoubleSpaces = text => {
    return text?.replace(/ {2}/g, '&nbsp;&nbsp;')
  }

  return (
    productInfoSections && (
      <Wrapper id="productInfo">
        {!isArticlePage && <Info />}
        <Content>
          <Links>
            <LinksTitle as="h3">{productInfoSections[0].linksTitle}</LinksTitle>
            {productInfoSections.map((section, index) => (
              <Link
                key={index}
                to={location.pathname + '#myheadline'}
                onClick={() => handleLinkClick(index)}
              >
                {section.linkTitle}
                {activeLink === index && (
                  <span>
                    <ArrowRightIcon />
                  </span>
                )}
              </Link>
            ))}
          </Links>
          <TextContent ref={textContentRef} isArticlePage={isArticlePage}>
            <Title as="h2" isArticlePage={isArticlePage}>
              {productInfoSections[activeLink].linkTitle}
            </Title>
            {checkIfContentExists('textField') && (
              <Description
                dangerouslySetInnerHTML={{
                  __html: replaceDoubleSpaces(
                    activeSection.textField?.childMarkdownRemark.html
                  ),
                }}
              />
            )}
            {!showMore &&
            activeSection.additionalTextField &&
            checkIfContentExists('additionalTextField') ? (
              <ShowMore onClick={() => setShowMore(true)}>
                <ArrowIcon />
                Näytä lisää
              </ShowMore>
            ) : (
              <Description
                dangerouslySetInnerHTML={{
                  __html: replaceDoubleSpaces(
                    activeSection.additionalTextField?.childMarkdownRemark.html
                  ),
                }}
              />
            )}
          </TextContent>
        </Content>
      </Wrapper>
    )
  )
}

export default ProductInfoSection
