import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import NavItem from './navItem'
import NavLink from './navLink'
import Button from '../buttons/button'
import Logo from '../../images/alexandria-logo'
import AccessibilityText from '../typography/accessibilityText'
import { zIndex } from '../../utils/constants'
import { visible, lightenBackground, darkenBackground } from '../../utils'
import CatchOutsideClick from '../catchOutsideClick'
import DesktopSubNavbar from '../subnavbar/desktopSubNavbar'
import Search from './search'

import { ReactComponent as LockIcon } from '../../images/lock.svg'
import { ReactComponent as SearchIcon } from '../../images/icon_search.svg'

const LogoContainer = styled.button`
  display: flex;
  align-items: center;
  margin-right: ${props => props.theme.spacing.xxxlarge};

  :hover,
  :active {
    svg {
      transform: scale(1.05);
      transition-timing-function: ease-out;
    }
  }

  a {
    margin-left: -0.5em;
  }

  svg {
    width: 140px;
    height: 100%;
    transition: transform 0.25s ease-in;

    path {
      fill: currentColor;
    }
  }
`

const InputField = styled.div``

const SearchContainer = styled.div(
  ({ theme: { colors } }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    background: ${darkenBackground(colors.backgroundColor, 0.1)};

    ${InputField} {
      display: flex;
      align-items: center;
      border-radius: 50px;
      background: white;
      width: 771px;
      height: 55px;

      & > div {
        width: 100%;
      }

      &:focus-within {
        outline: 2px solid ${colors.primaryColor};
      }

      & > input {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 50px;
        padding-left: 19px;

        ::placeholder {
          color: ${colors.primaryColor};
        }

        &:focus {
          outline: none;
        }
      }
  
      & > svg {
        margin-right: 16px;
      }
    }
  }
`
)

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  ${visible({ lg: true, xl: true })}
  align-items: center;
  height: ${p => p.theme.navHeight.lg};
  position: fixed;
  width: 100%;

  &.searchOpen {
    height: calc(${p => p.theme.navHeight.lg} + 100px);
  }

  ${({ theme: { colors, breakpoints }, drawShadow = '' }) => `
    ${
      drawShadow &&
      `
      box-shadow: 0 4px 11px -9px rgba(0,0,0,.15);
    `
    }

    background: ${colors.backgroundColor};
    z-index: ${zIndex.navbar};

    a, button {
      :not(${Button}) {
        color: ${colors.primaryColor};
      }
    }

    ul ul {
      a:not(${Button}) {
        color: ${colors.primaryColorInverted};
      }
    }
  `}
`

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  max-width: ${props => props.theme.width.max};
  padding: 0 ${props => props.theme.spacing.xlarge};
  /* This pseudo element exists to force safari to render nav element background transitions correctly.
    Without a child that extends of nav box model,
    safari sometimes transitions background to white,grey or black instead of transparent */
  :after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    transform: translateY(5px);
    opacity: 0;
  }

  /* IE11 fix that navbar stretches 100% */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
    flex: 0 1 auto;
  }
`
const NavGroupContainer = styled(CatchOutsideClick)`
  margin-right: auto;
  display: flex;
  align-items: center;
`

const SearchButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    svg {
      transform: scale(0.85);
      transition-timing-function: ease-out;
    }
  }

  &.active {
    svg {
      path {
        fill: white;
      }
    }
  }

  svg {
    transform: scale(0.75);
  }
`

const Contact = styled.div`
  font-weight: 500;
  margin-right: 16px;
`

// can use CatchOutsideClick with as or withComponent since ref will no longer match
const List = styled.ul`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-right: 20px;
`

const LoginButton = styled(Button)(
  ({ theme: { colors } }) => `
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 15px;
  font-weight: 500;
  &:hover {
    background: ${lightenBackground(colors.backgroundColor, 0.3)};
    color: ${colors.primaryColor};
  }
  & > svg {
    & > path {
      fill: ${colors.primaryColor};
    }
  }
`
)

const DesktopNavbar = ({
  navGroups,
  pages,
  isSearchOpen,
  setIsSearchOpen,
  ...rest
}) => {
  const [currentPath, setCurrentPath] = useState('')

  // Get current path
  useEffect(() => {
    const checkActive = () => {
      setCurrentPath(window.location.pathname)
    }
    checkActive()
    window.addEventListener('popstate', checkActive)
    return () => {
      window.removeEventListener('popstate', checkActive)
    }
  }, [])

  const handleSearch = () => {
    setIsSearchOpen(!isSearchOpen)
  }

  const handleLogoClick = () => {
    if (currentPath === '/') {
      window.location.href = '/'
    }
  }

  return (
    <>
      <Nav
        className={isSearchOpen ? 'searchOpen' : ''}
        aria-label="Main"
        {...rest}
      >
        {isSearchOpen && (
          <SearchContainer>
            <InputField>
              <Search pages={pages} />
            </InputField>
          </SearchContainer>
        )}
        <Container>
          <LogoContainer onClick={handleLogoClick}>
            <NavLink to="/">
              <Logo aria-label="Etusivu" id="main-header-logo" />
              <AccessibilityText>Etusivulle</AccessibilityText>
            </NavLink>
          </LogoContainer>
          <NavGroupContainer onClick={() => {}}>
            <List>
              {navGroups
                .filter((group, idx) => idx !== navGroups.length - 1)
                .map((group, idx) => (
                  <NavItem key={group.title}>
                    <NavLink data={group.items[0]}>{group.title}</NavLink>
                  </NavItem>
                ))}
            </List>
            <SearchButton
              className={isSearchOpen ? 'active' : ''}
              onClick={handleSearch}
            >
              <SearchIcon />
            </SearchButton>
          </NavGroupContainer>
          <List>
            <Contact>
              <NavItem>
                <NavLink data={navGroups[navGroups.length - 1].items[0]}>
                  {navGroups[navGroups.length - 1].items[0].title}
                </NavLink>
              </NavItem>
            </Contact>
            <NavItem>
              <LoginButton as="a" href="https://oma.alexandria.fi/login">
                Kirjaudu <LockIcon />
              </LoginButton>
            </NavItem>
          </List>
        </Container>
        {navGroups
          .filter((group, idx) => idx !== navGroups.length - 1)
          .map((group, idx) => (
            <React.Fragment key={group.title}>
              {group.items.length > 1 &&
                currentPath !== '/' &&
                group.items.map(item => (
                  <React.Fragment key={item.slug}>
                    {currentPath.substring(1).includes(item.slug) && (
                      <DesktopSubNavbar
                        subNavigation={group.items}
                        isSearchOpen={isSearchOpen}
                      />
                    )}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
      </Nav>
    </>
  )
}

export default DesktopNavbar
