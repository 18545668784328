import React from 'react'
import { bool, func, oneOfType, node, string } from 'prop-types'
import styled from 'styled-components'
import Button from '../buttons/button'

const propTypes = {
  open: bool,
  onClick: func,
  children: oneOfType([node, string]),
}

// prettier-ignore
const TriggerButton = styled(Button)(({ theme: t }) => `
  display: inline-block;
  background-color: transparent;
  border: 2px solid ${t.colors.primaryColor};
  color: ${t.colors.primaryColor} !important;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.16px;
  width: fit-content;
  height: 45px;

  @media (max-width: ${t.breakpoints.md.max}) {
    font-size: 14px;
    line-height: normal;
    margin: 0 auto;
  }
  
  &:hover {
    background-color: ${t.colors.primaryColor};
    border-color: ${t.colors.primaryColor};
    color: ${t.colors.white} !important;

    svg {
      path {
        fill: ${t.colors.white};
      }
    }
  }

  &:focus {
    background-color: ${t.colors.offWhite};
    border-color: ${t.colors.offWhite};
  }

  svg {
    margin-left: 8px;
    margin-bottom: 2px;
    path {
      fill: ${t.colors.primaryColor};
    }
  }
`
)

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`

const FilterMenuAccordionTrigger = ({ open, onClick, children }) => (
  <TriggerButton onClick={onClick} aria-expanded={open}>
    <ButtonContent>{children}</ButtonContent>
  </TriggerButton>
)

FilterMenuAccordionTrigger.propTypes = propTypes

export default FilterMenuAccordionTrigger
