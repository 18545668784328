import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import Link from '../links/link'
import Subheading from '../typography/headings'
import BackgroundImage from '../backgroundImage'
import {
  getIconByContentType,
  getColorByContentType,
} from '../../utils/segments'
import { useBreakpoints } from '../../utils/dom'
import SmallText from '../typography/smallText'

const CardContainer = styled(Link)(
  ({
    theme: {
      radius: { xxlarge },
      boxShadow,
      colors,
    },
  }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  border-radius: 0 ${xxlarge} ${xxlarge} ${xxlarge};
  box-shadow: ${boxShadow.md};
  color: ${colors.primaryColor};

  &:hover {
    transform: scale(1.03);
    transition: transform 0.5s;
  }
`
)

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  padding-top: 53%;
  width: 100%;
`

const IconTile = styled.div(
  ({
    color,
    theme: {
      spacing,
      radius: { xxlarge },
    },
  }) => `
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${spacing.xxlarge};
  height: ${spacing.xxlarge};
  background-color: ${color};
  border-radius: 0 0 ${xxlarge} 0;
`
)

const Content = styled.div(
  ({ theme: { spacing } }) => `
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: ${spacing.medium} .75em;
  width: 100%;
`
)

const ContentHeader = styled.div`
  width: 100%;
  padding-bottom: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    font-weight: 500;
    margin: 0;
  }
`

const makeSlug = (slug, contentType) =>
  contentType === 'podcast'
    ? `sisallot/rikasta-elamaa/${slug}`
    : `sisallot/${slug}`

const ContentCard = ({
  card: {
    image,
    slug,
    publicationDate,
    previewHeading,
    previewBody,
    contentType,
    category,
  },
  ...rest
}) => {
  const { previewBody: body } = previewBody || {}
  const breakpoints = useBreakpoints() || {}
  const large = !breakpoints.sm
  return (
    <CardContainer {...rest} to={makeSlug(slug, contentType)}>
      {image && (
        <ImageWrapper>
          <BackgroundImage
            image={image.gatsbyImageData}
            alt={image.title || previewHeading}
            large={large.toString()}
          />
          <IconTile color={getColorByContentType[contentType]}>
            {getIconByContentType(contentType)}
          </IconTile>
        </ImageWrapper>
      )}
      <Content>
        <ContentHeader>
          <SmallText>
            {format(new Date(publicationDate), 'dd.MM.yyyy')}
          </SmallText>
          {category && <SmallText>{category}</SmallText>}
        </ContentHeader>
        <Link to={makeSlug(slug, contentType)}>
          <Subheading as="h3" headingSize="3">
            {previewHeading}
          </Subheading>
        </Link>
        {body && <p>{body}</p>}
      </Content>
    </CardContainer>
  )
}

export default ContentCard
