import React from 'react'
import styled from 'styled-components'
import { FeedbackBubble } from './feedbackBubble'
import { VerticalFeedbackBubble } from './verticalFeedbackBubble'
import { FeedbackSummaryItem } from './feedbackSummaryItem'

const FeedbackItemContainer = styled.li`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-bottom: 1rem;
  user-select: none;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    flex-direction: column-reverse;
    height: auto;
  }

  transition: transform 0.1s;

  :hover {
    transform: scale(1.02);
  }

  }
`

const FeedbackItemContainerVertical = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  user-select: none;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    height: auto;
    width: 80vw;
  }

  transition: transform 0.1s;

  :hover {
    transform: scale(1.02);
  }
`

export const FeedbackItem = ({ feedback }) => {
  return (
    <FeedbackItemContainer>
      <FeedbackSummaryItem feedback={feedback} />
      <FeedbackBubble feedback={feedback} />
    </FeedbackItemContainer>
  )
}

export const FeedbackItemVertical = ({ feedback }) => {
  return (
    <FeedbackItemContainerVertical>
      <VerticalFeedbackBubble feedback={feedback} />
      <FeedbackSummaryItem feedback={feedback} />
    </FeedbackItemContainerVertical>
  )
}
