import styled from 'styled-components'

import input from '../../styles/mixins/input'

export default styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 0;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
  label {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    color: ${p => p.theme.colors.primaryColor};
    sub {
      color: ${props => props.theme.colors.grey};
      margin-left: auto;
    }
  }

  > div {
    display: flex;
    position: relative;
    input[type='text'],
    input[type='tel'],
    input[type='number'],
    input[type='email'] {
      ${input}
      position: relative;
      background: none;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid ${props => props.theme.colors.primaryColor};
      color: ${p => p.theme.colors.primaryColor};
      flex-grow: 1;
      line-height: 1.5;
      outline: 0;
      padding: 0 0 2px;
      font-size: 20px;
      &::placeholder {
        color: ${props => props.theme.colors.primaryColor};
        font-size: 20px;
      }
      ${props =>
        props.isWhite &&
        `
        border-bottom: 1px solid white;
        color: white;
        &::placeholder {
          color: white;
        }
      `}
      ${props =>
        props.invalid &&
        `
        border-color: ${props.theme.colors.errorColor}
      `}
    }
    input[type='file'] {
      border: 1px solid transparent;
      border-radius: ${props => props.theme.radius.md};
      ${props =>
        props.invalid && `border-color: ${props.theme.colors.errorColor}`}
    }
    textarea {
      ${input};
      border: none;
      border-radius: 0;
      border-bottom: 1px solid ${props => props.theme.colors.primaryColor};
      background: none;
      padding: 0;
      min-height: 80px;
      position: relative;
      flex-grow: 1;
      outline: 0;
      font-size: 20px;
      line-height: 28px;
      :focus {
        border-color: ${props => props.theme.colors.primaryColor};
        transition-timing-function: ease-out;
      }
      &::placeholder {
        display: flex;
        align-items: center;
        color: ${p => p.theme.colors.primaryColor};
        font-size: 20px;
        line-height: 28px;
      }
      ${props =>
        props.invalid &&
        `
        border-color: ${props.theme.colors.errorColor}
      `}
    }

    /* Styles for multiselect-react-dropdown */
    .multiSelectContainer {
      ${input}
    }

    .optionListContainer li:hover,
    .optionListContainer li.selected {
      background: ${({ theme }) => theme.colors.blue};
      color: white;
    }

    .optionListContainer {
      margin-left: -1em;
      margin-top: 0.5em;
      z-index: 100;
    }

    .optionListContainer .checkbox {
      display: none;
    }

    .searchWrapper {
      display: flex;
      align-items: baseline;
      flex-flow: row wrap;
      border: none;
      padding: 0;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-chevron-down" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>')
        transparent;
      background-repeat: no-repeat;
      background-position-x: calc(100% - 10px);
      background-position-y: 10px;

      input.searchBox {
        order: -1;
        flex: 1;
        margin: 0 5px 0 0;
        padding: 0;
        border: none;
        border-radius: 0;
        cursor: pointer;
        min-width: 80px;
      }

      span.chip {
        background: ${({ theme }) => theme.colors.blue};
        color: white;
        font-size: 16px;
        margin: 2px 5px;
      }
    }

    > span {
      /* suffix */
      color: ${props => props.theme.colors.grey};
      position: absolute;
      align-self: center;
      right: 16px;
    }
  }

  &[hidden] {
    display: none;
  }
`
