import React from 'react'
import styled from 'styled-components'
import { orangeTheme } from '../styles/theme'
import Layout from '../components/layout'
import { Heading } from '../components/typography/headings'
import Lead from '../components/typography/lead'
import Container from '../components/container'
import CtaButton from '../components/buttons/ctaButton'

const heading = 'Hups! Emme valitet&shy;tavasti löydä etsimääsi sivua'
const lead =
  'Vaikuttaa siltä, että sivua ei ole enää olemassa tai että linkki on virheellinen.'

const StyledContainer = styled(Container)`
  padding-top: calc(1em + 2%);
  padding-bottom: calc(1em + 2%);
  margin-top: 80px;
`

const StyledLayout = styled(Layout)`
  > article {
    background: ${({ theme }) => theme.colors.backgroundColor};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
  }

  ${CtaButton} {
    margin: 0 auto;
    display: block;
    max-width: 320px;
  }
`

const NotFoundPage = () => {
  return (
    <StyledLayout title="Hakemaasi sivua ei löytynyt" theme={orangeTheme}>
      <StyledContainer containerWidth="content">
        <Heading dangerouslySetInnerHTML={{ __html: heading }} />
        <Lead>{lead}</Lead>
        <p>
          <CtaButton to="/" large="true">
            Siirry etusivulle
          </CtaButton>
        </p>
      </StyledContainer>
    </StyledLayout>
  )
}

export default NotFoundPage
