import React from 'react'
import { arrayOf, string } from 'prop-types'
import styled from 'styled-components'
import segments from '../utils/segments'
import { linkPropTypes } from '../utils/prop-types'
import Link from '../components/links/link'
import CardTextContent from './cards/cardTextContent'
import { Subheading } from './typography/headings'
import { ReactComponent as SvgArrow } from '../images/arrow.svg'

const propTypes = {
  className: string,
  heading: string,
  links: arrayOf(linkPropTypes).isRequired,
}

const LinkList = styled.ul`
  li {
    margin: 0 0 1em;
  }

  a {
    display: inline-flex;
    align-items: center;
  }

  svg {
    margin-left: 0.5em;
    width: 24px;
    height: 24px; // IE
  }
`

const AsideLinks = ({ className, heading, links }) => (
  <CardTextContent className={className}>
    {heading && (
      <Subheading as="h3" headingSize="3">
        {heading}
      </Subheading>
    )}
    <LinkList>
      {(links || []).map(link => (
        <li key={link.slug}>
          <Link to={`${segments[link.__typename]}/${link.slug}`}>
            {link.title}
            <SvgArrow />
          </Link>
        </li>
      ))}
    </LinkList>
  </CardTextContent>
)

AsideLinks.propTypes = propTypes

export default AsideLinks
