import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ReactComponent as ArrowRight } from '../../images/arrow-right.svg'
import Link from '../links/link'
import { linkTo } from '../../utils'

const Card = styled(Link)(
  ({ theme: t, small }) => `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: ${small ? '149px' : '159px'};
  background: ${t.colors.white};
  color: ${t.colors.primaryColor};
  border-radius: 30px;
  padding: 20px;
  margin-top: ${small ? '10px' : '20px'};

  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s;
  }


  @media (min-width: ${t.breakpoints.md.max}) and (max-width: ${
    t.breakpoints.lg.max
  }) {
    height: ${small ? '149px' : '159px'};
  }

  @media (max-width: ${t.breakpoints.md.max}) {
    height: auto;
    margin-top: 10px;
  }
`
)

const CardContent = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: ${t.breakpoints.md.max}) {
    word-break: break-word;
  }

  h3 {
    margin: 0;
    font-size: 22px;
    line-height: 24px;
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    max-width: 400px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;

    @media (max-width: ${t.breakpoints.lg.max}) {
      font-size: 14px;
      line-height: normal;
    }

    @media (max-width: ${t.breakpoints.md.max}) {
      -webkit-line-clamp: unset;
    }
  }

  span {
    margin: 0;
    font-size: 12px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
  }
`
)

const CardImageWrap = styled.div(
  ({ theme: t }) => `
  width: 100px;
  height: 100px;
  margin-right: 20px;

  @media (max-width: ${t.breakpoints.lg.max}) {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
`
)

const StyledGatsbyImage = styled(GatsbyImage)(
  ({ theme: t }) => `
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  aspect-ratio: 1;

  @media (max-width: ${t.breakpoints.lg.max}) {
    width: 80px;
    height: 80px;
  }
`
)

const ArrowRightContainer = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  margin-left: auto;
`

const ProductCard = ({ product, fourCards }) => {
  return (
    <Card small={fourCards} to={linkTo(product)}>
      <CardImageWrap>
        <StyledGatsbyImage
          image={product.image.gatsbyImageData}
          alt={product.image.title || ''}
        />
      </CardImageWrap>
      <CardContent>
        <h3>{product.title}</h3>
        <p>{product.text.text}</p>
        <span>{product.footerText}</span>
      </CardContent>
      <ArrowRightContainer>
        <ArrowRight />
      </ArrowRightContainer>
    </Card>
  )
}

export default ProductCard
