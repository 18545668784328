import { css } from 'styled-components'

export default css`
  background: ${({ theme }) => theme.colors.fieldBackgroundColor};
  border: 1px solid ${({ theme }) => theme.colors.greyLight};
  border-radius: 100px;
  padding: ${({ theme }) => theme.spacing.small}
    ${({ theme }) => theme.spacing.medium};

  ::placeholder {
    color: ${({ theme }) => theme.colors.grey};
  }
`
