import React from 'react'
import styled from 'styled-components'
import { ReactComponent as RectangleIcon } from '../images/riskLevel_rect.svg'
import { ReactComponent as DotIcon } from '../images/riskLevel_dot.svg'

const MetaText = styled.p`
  flex: 1;
  text-transform: uppercase;
  margin: auto 0 0;
  font-size: 12px;
  font-weight: 500;
`

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  svg:not(:last-child) {
    margin-right: 3px;
  }
`

const renderRating = riskRating => {
  const rectangles = [...Array(riskRating).keys()].map(i => (
    <RectangleIcon key={`rect-${i}`} />
  ))
  const dots = [...Array(7 - riskRating).keys()].map(i => (
    <DotIcon key={`dot-${i}`} />
  ))
  return [...rectangles, ...dots]
}

const RiskRating = ({ riskRating }) => {
  if (!riskRating) return null

  return (
    <Container>
      <MetaText>Riskitaso {riskRating}</MetaText>
      {renderRating(riskRating)}
    </Container>
  )
}

export default RiskRating
