import styled from 'styled-components'
import button from '../../styles/mixins/button'

const isInputOrButton = ({ onClick, to, href, data }) =>
  onClick && !to && !href && !data

// Override default focus style coming from Link component if used with it
export default styled.button.attrs(props => ({
  noDefaultLinkFocus: true,
  type: props.type || (isInputOrButton(props) ? 'button' : undefined),
}))`
  ${button}
  ${({ large = '', theme: { spacing } }) =>
    large === 'true' &&
    `
      padding: ${spacing.small} 3em;
    `}

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  &[aria-selected='true'] {
    pointer-events: none;
    cursor: default;
  }
`
