import styled from 'styled-components'
import Link from '../links/link'

const BackButton = styled(Link)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${theme.colors.blue};

  svg {
    margin-right: ${theme.spacing.small};
  }
`
)

export default BackButton
