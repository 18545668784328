/* eslint-disable no-undef */
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled, { useTheme } from 'styled-components'
import { ReactComponent as ArrowRight } from '../../images/arrow-with-circle.svg'

const CardCarouselWrapper = styled.div(
  ({ theme: t }) => `
	display: flex;
  flex-direction: column;
	gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 12px;
  scroll-behaviour: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    width: 100%;
  }
	`
)

const CardCarouselContent = styled.div(
  ({ theme: t }) => `
  display: grid;
  grid-auto-flow: column;
  gap: 40px;
  overflow-y: hidden;

  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 12px;
  scroll-behaviour: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    gap: 20px;
  }

  @media screen and (max-width: ${t.breakpoints.sm.min}) {
    grid-auto-columns: 100%;
  }
`
)

const CardCarouselCard = styled.div(
  ({ theme: t }) => `
  display: flex;
  width: 420px;
  height: calc(100% - 40px);

  &:last-child {
    margin-right: 40px;

    @media screen and (max-width: ${t.breakpoints.md.max}) {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    height: auto;
  }

  @media screen and (max-width: ${t.breakpoints.sm.min}) {
    width: 100%;
  }
`
)

const ButtonsContainer = styled.div(
  ({ theme: t }) => `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin 0 221px 0 auto;
  padding-bottom: 5px;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    margin: 0 0 0 auto;
  }
`
)

const ArrowButton = styled.button(
  ({ left, dim }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 59px;
  height: 59px;
  border: none;
  ${left ? 'transform: rotate(180deg);' : ''}
  opacity: ${dim ? '0.5' : '1'};
  pointer-events: ${dim ? 'none' : 'auto'};

  &:hover {
    transform: ${left ? 'rotate(180deg) scale(1.1)' : 'scale(1.1)'};
    transition: transform 0.1s;
  }
`
)

const CardCarousel = ({ children }) => {
  const ref = useRef(null)
  const items = useRef([])
  const theme = useTheme()

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.md.max})`,
  })

  const [firstVisible, setFirstVisible] = useState(true)
  const [lastVisible, setLastVisible] = useState(false)
  const [cardWidth, setCardWidth] = useState(0)

  useLayoutEffect(() => {
    // Calculate card width after the component is laid out
    setCardWidth(ref.current.getBoundingClientRect().width)
  }, [isMobile])

  useEffect(() => {
    const firstItemRef = items.current[0]
    const lastItemRef = items.current[items.current.length - 1]

    const firstItemObserver = new IntersectionObserver(
      entries => setFirstVisible(entries[0].isIntersecting),
      { root: ref.current, threshold: 1 }
    )
    firstItemObserver.observe(firstItemRef)

    const lastItemObserver = new IntersectionObserver(
      entries => setLastVisible(entries[0].isIntersecting),
      { root: ref.current, threshold: 1 }
    )
    lastItemObserver.observe(lastItemRef)

    return () => {
      firstItemObserver.disconnect()
      lastItemObserver.disconnect()
    }
  }, [])

  const scrollAmount = isMobile ? cardWidth + 20 : 421 // +20px (width of the gap between cards) added to cardWidth for preventing overscroll

  const next = () => {
    ref.current.scrollBy({
      left: scrollAmount,
      behavior: 'smooth',
    })
  }

  const prev = () => {
    ref.current.scrollBy({
      left: -scrollAmount,
      behavior: 'smooth',
    })
  }

  return (
    <CardCarouselWrapper>
      <CardCarouselContent ref={ref}>
        {children.map((child, i) => (
          <CardCarouselCard
            key={child.key ?? i}
            ref={elem => (items.current[i] = elem)}
          >
            {child}
          </CardCarouselCard>
        ))}
      </CardCarouselContent>
      <ButtonsContainer>
        <ArrowButton left={true} dim={firstVisible} onClick={prev}>
          <ArrowRight />
        </ArrowButton>
        <ArrowButton dim={lastVisible} onClick={next}>
          <ArrowRight />
        </ArrowButton>
      </ButtonsContainer>
    </CardCarouselWrapper>
  )
}

export default CardCarousel
