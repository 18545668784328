import React, { useState } from 'react'
import styled from 'styled-components'
import HintText from '../typography/hintText'
import Tab from './tableTab'

const Wrapper = styled.div(
  ({ theme: { spacing, breakpoints } }) => `
  padding-top: ${spacing.small};
  margin-bottom: ${spacing.medium};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.sm.min}) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`
)

const TabContainer = styled.div`
  display: flex;
  flex-wrap: none;
`

const RightSideText = styled(HintText).attrs({ as: 'p' })`
  text-align: right;
  padding-bottom: ${({ theme }) => theme.spacing.small};
`

const TableTabs = ({ tabs, onChange, hint, ...rest }) => {
  const initial = tabs.length ? tabs[0].key : ''
  const [current, setCurrent] = useState(initial)
  const selectTab = key => {
    onChange(key)
    setCurrent(key)
  }

  return (
    <Wrapper {...rest}>
      <TabContainer role="tablist">
        {tabs.map(({ key, label }) => (
          <Tab
            key={key}
            onClick={() => selectTab(key)}
            aria-selected={`${key === current}`}
            aria-controls={`panel-${key}`}
            id={`tab-${key}`}
          >
            {label}
          </Tab>
        ))}
      </TabContainer>
      {hint && <RightSideText>{hint}</RightSideText>}
    </Wrapper>
  )
}

export default TableTabs
