import React from 'react'
import styled from 'styled-components'

import blue from '../../styles/mixins/blue'
import small from '../../styles/mixins/small'
import RatingLabel from './ratingLabel'

const Wrapper = styled.div`
  text-align: center;
`

const Star = styled.span`
  ${blue}
  ${small}

  :before {
    content: '\\2605';
  }
`
const starsAndLabel = value => (
  <Wrapper>
    {new Array(value).fill(value).map((_, index) => (
      <Star key={index} />
    ))}
    <RatingLabel>Morningstar</RatingLabel>
  </Wrapper>
)

const MorningstarRating = ({ value }) => {
  return value ? starsAndLabel(value) : <></>
}

export default MorningstarRating
