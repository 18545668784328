import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'

const Wrapper = styled.div(
  ({ theme: t }) => `
  display: flex;
  width: 100%;
  background: ${hexToRgba(t.colors.pink, 0.4)};
`
)

const Content = styled.div(
  ({ theme: t }) => `
  display: flex;
  width: ${t.width.max};
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  gap: 60px;
  height: 120px;
  
  @media (max-width: ${t.breakpoints.md.max}) {
    gap: 40px;
  }
  @media (max-width: ${t.breakpoints.md.max}) {
    gap: 0;
  }
`
)

const Rating = styled.div(
  ({ theme: t }) => `
    display: grid;
    grid-template-rows: 65% 35%;
    width: 25%;
    height: 100%;
    padding: 21px 0;
    justify-content: center;
    align-items: center;
    gap: 5px;
    @media (max-width: ${t.breakpoints.md.max}) {
      grid-template-rows: 50% 50%;
      width: 100%;
    }
    @media (max-width: ${t.breakpoints.xs.max}) {
      padding: 0 10px;
    }
    @media (max-width: 400px) {
      padding: 0;
    }
  `
)

const Value = styled.div(
  ({ theme: t }) => `
    display: flex;
    justify-content: center;
    font-family: Saol Display;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    align-self: flex-end;
    white-space: nowrap;
    line-height: 50px;
    @media (max-width: ${t.breakpoints.md.max}) {
      font-size: 28px;
      line-height: 33px;
    }
  `
)

const Text = styled.div(
  ({ theme: t }) => `
    display: flex;
    text-align: center;
    font-family: Scto Grotesk A;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    align-self: flex-start;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: ${t.breakpoints.md.max}) {
      font-size: 12px;
      line-height: normal;
      padding: 0 5px;
    }
  `
)

const Line = styled.div(
  ({ theme: t }) => `
    height: 70px;
    width: 0;
    border: 1px solid ${t.colors.pink}};
    &:last-child {
      display: none;
    }
  `
)

const RatingsBar = () => {
  const data = useStaticQuery(graphql`
    query {
      ratingsBar: contentfulRatingsBar {
        number
        text
      }
    }
  `)

  const ratings = data.ratingsBar

  return (
    <Wrapper>
      <Content>
        {ratings.number.map((number, index) => (
          <React.Fragment key={index}>
            <Rating>
              <Value>{number}</Value>
              <Text>{ratings.text[index]}</Text>
            </Rating>
            <Line />
          </React.Fragment>
        ))}
      </Content>
    </Wrapper>
  )
}

export default RatingsBar
