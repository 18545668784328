import React from 'react'
import styled from 'styled-components'
import NavItem from './navGroupItem'
import { zIndex } from '../../utils/constants'
import MenuDetail from '../../images/menu-detail.png'

const navItemWidth = '185px'

const ListContainer = styled.div(
  ({ theme, itemCount, visible: isVisible = '' }) => `
  &,
  ul {
    padding: 0;
  }

  @media screen and (min-width: ${theme.breakpoints.lg.min}) {
    display: none;
    position: absolute;
    top: 3.75rem;
    z-index: ${zIndex.dropDown};

    ul {
      border-radius: 16px;
      display: flex;
      flex-flow: column wrap;
      background-color: ${theme.colors.blue};
      padding-inline-start: 0px;
      margin: 0;
      padding: ${theme.spacing.small} ${theme.spacing.medium};
      list-style-type: none;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 16px;
        top: -16px;
        left: 0;
        width: 100%;
        background: transparent url('${MenuDetail}') 24px 0/32px no-repeat;
      }

      ${NavItem} {
        width: ${navItemWidth};
      }
    }

    ${
      !isVisible
        ? ''
        : `
      display: flex;
    `
    }
  }

  @media screen and (max-width: ${theme.breakpoints.md.max}) {
    overflow: hidden;
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 0 0 0 ${theme.spacing.xlarge};
    opacity: 0;
    max-height: 0;
    flex-direction: column;

    ${
      !isVisible
        ? ''
        : `
      opacity: 1;
      margin: 0 0 ${theme.spacing.small} ${theme.spacing.xlarge};
      display: flex;
      max-height: none;
    `
    };
  }
`
)

const NavList = ({ children, visible, id }) => (
  <ListContainer
    visible={visible}
    itemCount={React.Children.count(children)}
    id={id}
  >
    <ul className="list" role="menu">
      {children}
    </ul>
  </ListContainer>
)

export default NavList
