import React from 'react'
import styled from 'styled-components'
import { Heading } from '../typography/headings'
import Button from '../buttons/button'
import { Link } from 'gatsby'
import { ReactComponent as Tip } from '../../images/tip.svg'

const HeroWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  justify-items: center;
  align-items: center;
  width: 100%;

  background-color: #ffcc94;

  @media (min-width: ${props => props.theme.breakpoints.lg.min}) {
    margin-top: calc(
      ${props => props.theme.navHeight.lg} +
        ${props => props.theme.navHeight.min}
    );
  }
`
const BackgroundGrid = styled.div`
  justify-self: stretch;
  align-self: stretch;
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    height: 120vh;
    grid-template-rows: 40vh 80vh;
    grid-template-columns: 1fr;
  }
`

const BackgroundBlock = styled.div`
  justify-self: stretch;
  align-self: stretch;
`

const BackgroundMediaContainer = styled.div`
  height: 100%;
  width: 100%;

  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center;
`

const ContentBlock = styled.div``

const ContentGrid = styled.div`
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;

  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  column-gap: 20px;
  height: 100%;
  max-width: ${props => props.theme.width.max};

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    margin: 0;
    height: auto;
    grid-template-columns: 1fr;
    grid-template-rows: 70vh 1fr 2fr;
  }

  ${ContentBlock} {
    align-self: stretch;
    @media (max-width: ${props => props.theme.breakpoints.md.max}) {
      align-self: shrink;
    }
  }

  ${ContentBlock}:nth-child(1) {
    grid-column: 1 / span 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: ${props => props.theme.breakpoints.md.max}) {
      grid-column: 1 / span 1;
    }
  }
  ${ContentBlock}:nth-child(2) {
    grid-column: 5 / span 4;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: ${props => props.theme.breakpoints.md.max}) {
      grid-column: 1 / span 1;
      order: -2;
    }
  }
  ${ContentBlock}:nth-child(3) {
    grid-column: 9 / span 4;
    @media (max-width: ${props => props.theme.breakpoints.md.max}) {
      grid-column: 1 / span 1;
      order: -1;
    }
  }
`

const FeatureBox = styled.div`
  height: 70%;
  width: 80%;

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    height: 100%;
    width: 100%;

    padding: ${props => props.theme.spacing.large}
      ${props => props.theme.spacing.large};
  }

  border-radius: ${props => props.theme.radius.xxlarge};
  box-shadow: ${props => props.theme.boxShadow.heroCard};
  padding: ${props => props.theme.spacing.xxlarge}
    ${props => props.theme.spacing.medium};
  background-color: #ffe9e8;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`

const FeaturePadder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    margin-bottom: -136px;
    padding: ${props => props.theme.spacing.medium};
  }
`

const HeroTextBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.xxxlarge}
    ${props => props.theme.spacing.xxlarge};

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    background-color: #ffcc94;
    h1 {
      font-size: 2.5rem;
    }
  }

  ${Heading} p {
    line-height: 1.2;
    margin-bottom: 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${props => props.theme.spacing.xxlarge};

  @media (max-width: ${props => props.theme.breakpoints.md.max}) {
    margin-top: ${props => props.theme.spacing.large};
  }

  ${Button} {
    @media (max-width: ${props => props.theme.breakpoints.md.max}) {
      background-color: transparent;
    }
`

export const NPSLandingHero = ({
  media,
  heading,
  body,
  featureImage,
  ctaText,
  ctaTarget,
}) => {
  return (
    <HeroWrapper>
      <BackgroundGrid>
        <BackgroundBlock />
        <BackgroundBlock>
          <BackgroundMediaContainer url={media.url} />
        </BackgroundBlock>
      </BackgroundGrid>
      <ContentGrid>
        <ContentBlock>
          <HeroTextBox>
            <Heading headingSize={2}>Nyt puhuvat tuhannet sijoittajat</Heading>
            <div>
              Mitä asiakkaamme todella ajattelevat palvelustamme? Julkaisimme
              kaikki viimeisen 1,5 vuoden aikana saamamme asiakaspalautteet –
              niin hyvät kuin huonot ja kaikki siltä väliltä.
            </div>
            <ButtonWrapper>
              <Button
                as={Link}
                to={'/kokemuksia/asiakaspalautteet'}
                large={'true'}
              >
                Katso, mitä meistä puhutaan
              </Button>
            </ButtonWrapper>
          </HeroTextBox>
        </ContentBlock>
        <ContentBlock>
          <FeaturePadder>
            <FeatureBox>
              <FeaturePadder>
                <img src={featureImage.url} />
              </FeaturePadder>
            </FeatureBox>
            <Tip />
          </FeaturePadder>
        </ContentBlock>
        <ContentBlock></ContentBlock>
      </ContentGrid>
    </HeroWrapper>
  )
}
