import React from 'react'
import PersonnelCard from './personnelCard'
import { Heading } from '../typography/headings'

const personnelCardGroups = ({ page, hash, itemsPerRow = 2 }) => {
  let defaultOpenGroupIndex = 1

  if (!page.cards?.length) {
    return { cardGroups: null, defaultOpenGroupIndex }
  }

  const cardGroups = page.cards.map(({ title, people }, groupIndex) => ({
    key: title,
    hero: (
      <Heading as="h3" headingSize="3">
        {title}
      </Heading>
    ),
    items: people.map(person => {
      if (hash.replace('#', '') === person.slug) {
        defaultOpenGroupIndex = groupIndex
      }
      return <PersonnelCard key={person.slug} person={person} />
    }),
    itemsPerRow,
  }))

  return { cardGroups, defaultOpenGroupIndex }
}

export default personnelCardGroups
