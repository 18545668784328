import React from 'react'
import { arrayOf, shape } from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Container from '../components/container'
import Grid from '../components/cards/grid'
import VideoPreviewCard from '../components/cards/videoPreviewCard'
import Layout from '../components/layout'
import { getHero } from '../components/contentfulPage'
import { cardPropTypes } from '../utils/prop-types'
import CustomerFeedbackHero from '../components/heroes/customerFeedbackHero'

const propTypes = {
  data: shape({
    page: shape({
      cards: arrayOf(cardPropTypes),
    }).isRequired,
  }).isRequired,
}

const StyledContainer = styled(Container)`
  padding-bottom: 3rem;
`

const CustomerExperiencePage = ({ data: { page } }) => {
  const {
    customerExperienceStatsShortTitle: shortTitle,
    customerExperienceStatsLead: { customerExperienceStatsLead: statsLead },
    customerExperienceStats: { raw: statsRawText },
    customerExperienceStatsInfo: { customerExperienceStatsInfo: statsInfo },
  } = page

  return (
    <Layout
      title={page.title}
      description={page.description.description}
      image={`https:${page.image.file.url}`}
      hero={getHero(page)}
      shareable={page.shareable}
    >
      <StyledContainer>
        <Grid>
          {page.cards.map(card => (
            <VideoPreviewCard key={card.slug} data={card} />
          ))}
        </Grid>
      </StyledContainer>
      <CustomerFeedbackHero
        shortTitle={shortTitle}
        statsLead={statsLead}
        statsRawText={statsRawText}
        statsInfo={statsInfo}
        centerTitle={true}
      />
    </Layout>
  )
}

CustomerExperiencePage.propTypes = propTypes

export default CustomerExperiencePage

export const query = graphql`
  query ($slug: String!) {
    page: contentfulCustomerExperiencePage(slug: { eq: $slug }) {
      slug
      title
      description {
        description
      }
      heroType
      heroTheme
      heading
      image {
        title
        file {
          url
        }
        gatsbyImageData(width: 1200, placeholder: BLURRED)
      }
      leadParagraph {
        leadParagraph
      }
      customerExperienceStatsLead {
        customerExperienceStatsLead
      }
      customerExperienceStatsInfo {
        customerExperienceStatsInfo
      }
      customerExperienceStatsShortTitle
      customerExperienceStats {
        raw
      }
      cards {
        ... on ContentfulPage {
          __typename
          slug
          videoEmbed {
            videoEmbed
          }
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          description {
            description
          }
          previewHeading
          previewBody {
            previewBody
          }
          previewVideo {
            previewVideo
          }
        }
      }
    }
  }
`
