import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

const CXCardWrapper = styled.a(
  ({ theme: t, hasLink }) => `
	display: flex;
	width: 100%;
	height: 100%;
	border-radius: 30px;
	flex-direction: column;
	overflow: hidden;
	background-color: ${t.colors.pink};
  color: ${t.colors.primaryColor};
  margin: 20px 0;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    margin: 0;
  }

  &:hover {
    @media screen and (min-width: ${t.breakpoints.md.max}) {
      transform: ${hasLink ? 'scale(1.05)' : 'none'};
      transition: transform 0.5s;
    }
  }
`
)

const ImageWrap = styled.div(
  ({ theme: t, small }) => `
  width: 100%;
  max-height: 333px;
  min-height: 333px;

  transform: ${small ? 'scale(0.6)' : 'scale(1)'};

  div {
    img {
      object-fit: ${small ? 'contain !important' : 'cover !important'};
    }
  }

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    max-height: 266px;
    min-height: 266px;
  }
`
)

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`
const Content = styled.div(
  ({ theme: t }) => `
	display: flex;
	width: 100%;
	height: auto;
	padding: 20px 30px 30px 30px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	gap: 10px;
	flex-shrink: 0;
  margin-top: auto;
`
)
const PreTitle = styled.div(
  ({ theme: t }) => `
	font-family: Scto Grotesk A;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0.28px;
	text-transform: uppercase; 
    `
)

const Title = styled.div(
  ({ theme: t }) => `
	font-family: Saol Display;
	font-size: 28px;
	font-weight: 600;
	line-height: 33px;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    font-size: 24px;
    line-height: 28px;
  }
	`
)

const Description = styled.div(
  ({ theme: t }) => `
	font-family: Scto Grotesk A;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	`
)

const CustomerExperienceCard = ({ card }) => {
  return (
    <CXCardWrapper href={card.link} hasLink={card.link}>
      <ImageWrap small={card.smallImage}>
        <StyledGatsbyImage
          image={card.image.gatsbyImageData}
          alt={card.image.title || ''}
        />
      </ImageWrap>
      <Content>
        <PreTitle>{card.preTitle}</PreTitle>
        <Title>{card.title}</Title>
        <Description>{card.text.text}</Description>
      </Content>
    </CXCardWrapper>
  )
}

export default CustomerExperienceCard
