import React from 'react'
import { graphql } from 'gatsby'
import ContentfulPage from '../components/contentfulPage'
import ContactUsBar from '../components/contactUsBar/contactUsBar'
import SectionHeader from '../components/headers/sectionHeader'

const RequestContact = ({ data }) => {
  const { page } = data

  return (
    <ContentfulPage
      page={page}
      cardGroups={[
        {
          hero: (
            <SectionHeader title="Jätä *yhteystietosi* niin otamme yhteyttä" />
          ),
          items: [<ContactUsBar showDetails={true} />],
          itemsPerRow: 1,
          containerWidth: 'content',
          centered: true,
        },
      ]}
    />
  )
}

export default RequestContact

export const query = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
  }
`
