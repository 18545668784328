import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const CatchOutsideClick = styled(({ className, children, onClick }) => {
  const ref = useRef()
  const handleOutsideMouseClick = e => {
    const root = ref.current
    if (!root.contains(e.target)) {
      onClick()
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideMouseClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideMouseClick)
    }
  })
  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  )
})``

export default CatchOutsideClick
