import React from 'react'
import { func, number, shape, string } from 'prop-types'
import styled from 'styled-components'
import Tab from '../tables/tableTab'
import ReturnPercentage from '../format/returnPercentage'
import Small from '../typography/small'

const propTypes = {
  labels: shape({
    label: string,
    value: number,
  }),
  frequency: string,
  onChange: func.isRequired,
}

const isValidReturnValue = value => typeof value !== 'undefined'

const MenuList = styled.ul`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  flex-flow: row wrap;
`

const TabTextWrap = styled.p`
  margin: 0;
  font-weight: 500;
  white-space: nowrap;

  small {
    font-weight: 400;
  }
`

const MenuItem = styled.li(
  ({ itemCount = 4, isDisabled }) => `
  display: flex;
  margin: 0 0 .5em;
  flex: 1 1 ${100 / itemCount}%;
  max-width: ${100 / itemCount}%;
  min-width: 110px;
  ${
    isDisabled
      ? `
      order: 1;
    `
      : ''
  }

  button {
    width: 95%;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
  }
`
)

const Menu = ({ activeTab, labels, onChange }) => (
  <MenuList role="tablist">
    {Object.keys(labels).map(key => {
      const { value, title } = labels[key]
      const disabled = !isValidReturnValue(value)

      const handleClick = key => {
        if (disabled) {
          return
        }
        onChange(key)
      }

      return (
        <MenuItem
          key={key}
          itemCount={Object.keys(labels).length}
          isDisabled={disabled}
        >
          <Tab
            key={key}
            onClick={() => handleClick(key)}
            aria-selected={`${key === activeTab}`}
            aria-controls={`panel-${key}`}
            id={`tab-${key}`}
            disabled={disabled}
            isLarge
          >
            {typeof value === 'number' ? (
              <TabTextWrap>
                {title}
                <br />
                <Small>
                  <ReturnPercentage value={value} />
                </Small>
              </TabTextWrap>
            ) : (
              title
            )}
          </Tab>
        </MenuItem>
      )
    })}
  </MenuList>
)

Menu.propTypes = propTypes

export default Menu
