import React from 'react'
import ExtLinkIconWrapper from '../links/extLinkIconWrapper'
import { ReactComponent as Icon } from '../../images/twitter.svg'

const TwitterShareButton = ({ location }) => (
  <ExtLinkIconWrapper to={`https://twitter.com/intent/tweet?url=${location}`}>
    <Icon />
  </ExtLinkIconWrapper>
)

export default TwitterShareButton
