import styled from 'styled-components'

export default styled.ul`
  list-style: none;
  margin: 0;
  line-height: 20px;

  span {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    line-height: 18px;
  }

  li {
    margin: 10px 0;
  }
`
