import React from 'react'
import styled from 'styled-components'

import SquareCard from './squareCard'

export const OuterGridWrapper = styled.div`
  margin: 10px auto 0;
  max-width: ${({ theme }) => theme.width.max};

  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    margin: 20px auto 0;
  }
`

export const InnerGridWrapper = styled.div(
  ({ theme: { breakpoints }, centered }) => `
  justify-content: ${centered ? 'center' : 'left'};

  @media (min-width: ${breakpoints.sm.min}) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: ${breakpoints.lg.min}) {
    ${SquareCard} {
      max-width: none;
    }
  }

  @media (max-width: ${breakpoints.md.max}) {
    justify-content: center;
  }
`
)

const Grid = ({ children, centered }) => (
  <OuterGridWrapper>
    <InnerGridWrapper centered={centered}>{children}</InnerGridWrapper>
  </OuterGridWrapper>
)

export default Grid
