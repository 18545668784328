import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'

import Menu from './menu'
import { CardForTable } from '../tables/table'
import FundGraph from './fundGraph'
import PriceHistoryGraph from './priceHistoryGraph'
import SRRIRating from './srriRating'
import MorningstarRating from './morningstarRating'
import { Year, Quarter, Month } from '../../utils/timePeriods'
import { chartTheme } from '../../styles/theme'

const START_OF_YEAR = 'M0'
const START_OF_QUARTER = 'M3'
const START_OF_MONTH = 'M1'
const YEAR = 'Y'
const QUARTER = 'Q'
const MONTH = 'M'
const DAY_END = 'DayEnd'
const HISTORY = 'HISTORY'

const getSRRIRating = data => data && data['Rank']

const getMorningstarRating = data => data && data[0]['RatingValue']

const getHistoricalData = (data, frequency) => {
  return data['HistoricalPerformanceSeries']?.find(
    item => item['Frequency'] === frequency
  )
}

const getHistoricalReturnData = (data, frequency) => {
  const historicalData = getHistoricalData(data, frequency)
  return historicalData ? historicalData['Return'] : []
}

const getAnnualPerformanceData = data => {
  const trailingData = data['TrailingPerformance'].find(
    item => item['Type'] === DAY_END
  )
  const currentYear = new Date(trailingData['Date']).getFullYear()

  const getCurrentPerformanceData = () => {
    const value = trailingData['Return'].find(
      item => item['TimePeriod'] === START_OF_YEAR
    )['Value']
    const timePeriod = new Year(currentYear)

    return { timePeriod, value }
  }

  const getPastPerformanceData = () => {
    const series = getHistoricalReturnData(data, YEAR)
    const minYear = currentYear - 6

    return series
      .map(item => {
        const year = new Date(item['Date']).getFullYear()
        if (year < minYear) {
          return null
        }

        return { timePeriod: new Year(year), value: item['Value'] }
      })
      .reverse()
  }

  return [
    ...getPastPerformanceData().filter(item => item),
    getCurrentPerformanceData(),
  ]
}

const getPastPerformanceData = (data, frequency) => {
  const series = getHistoricalReturnData(data, frequency)
  const maxIndex = 6
  const result = series
    .map((item, index) => {
      if (index > maxIndex) {
        return null
      }

      const date = new Date(item['Date'])
      const timePeriod =
        frequency === MONTH ? new Month(date) : new Quarter(date)

      return { timePeriod, value: item['Value'] }
    })
    .reverse()
    .filter(item => item)

  return result
}

const getPriceHistory = data => data.PriceHistory[0]

const latestReturn = data => {
  const performanceData = data['TrailingPerformance'].find(
    item => item['Type'] === DAY_END
  )
  const returnData = performanceData['Return'].find(
    item => item['TimePeriod'] === START_OF_YEAR
  )

  return returnData && returnData['Value']
}

const getTrailingPerformanceReturnForHistoryGraph = data =>
  data['TrailingPerformance'].find(item => item.Type === 'DayEnd').Return

const latestQMReturn = (data, timePeriod) => {
  const performanceData = data['TrailingPerformance'].find(
    item => item['Type'] === DAY_END
  )
  const returnData = performanceData['Return'].find(
    item => item['TimePeriod'] === timePeriod
  )

  return returnData && returnData['Value']
}

const RatingList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;

  > li {
    display: inline-block;
    margin: 0 ${({ theme }) => theme.spacing.medium};

    :first-child {
      margin-left: 0;
    }

    :last-child {
      margin-right: 0;
    }
  }
`

const Performance = ({ data, ...rest }) => {
  const [frequency, setFrequency] = useState(HISTORY)
  const labels = {
    [HISTORY]: { title: 'Historia', value: null, hasData: 'HISTORY' },
    [YEAR]: {
      title: 'YTD',
      value: latestReturn(data),
      hasData: !!getHistoricalData(data, YEAR),
    },
    [QUARTER]: {
      title: 'Kvartaali',
      value: latestQMReturn(data, START_OF_QUARTER),
      hasData: !!getHistoricalData(data, QUARTER),
    },
    [MONTH]: {
      title: 'Kuukausi',
      value: latestQMReturn(data, START_OF_MONTH),
      hasData: !!getHistoricalData(data, MONTH),
    },
  }
  let performanceData, priceHistory
  if (frequency === HISTORY) {
    performanceData = getTrailingPerformanceReturnForHistoryGraph(data)
    priceHistory = getPriceHistory(data)
  } else {
    performanceData =
      frequency === YEAR
        ? getAnnualPerformanceData(data)
        : getPastPerformanceData(data, frequency)
  }

  return (
    <ThemeProvider theme={chartTheme}>
      <CardForTable {...rest}>
        <Menu
          activeTab={frequency}
          labels={labels}
          onChange={frequency => setFrequency(frequency)}
        />
        {frequency === HISTORY ? (
          <PriceHistoryGraph
            priceHistory={priceHistory}
            performanceData={performanceData}
          />
        ) : (
          <FundGraph data={performanceData} />
        )}

        <RatingList>
          <li>
            <SRRIRating value={getSRRIRating(data['CollectedSRRI'])} />
          </li>
          <li>
            <MorningstarRating
              value={getMorningstarRating(data['RiskAndRating'])}
            />
          </li>
        </RatingList>
      </CardForTable>
    </ThemeProvider>
  )
}

export default Performance
