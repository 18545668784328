import React from 'react'
import styled from 'styled-components'
import { Heading } from '../../typography/headings'
import { lightenBackground } from '../../../utils'
import { ReactComponent as CheckIcon } from '../../../images/check-blue.svg'
import { ReactComponent as ChatClosedIcon } from '../../../images/chat-closed.svg'

const FlowContainer = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 7px;
  gap: 25px;

  @media (max-width: ${t.breakpoints.md.max}) {
    width: 100%;
    margin-bottom: 20px;
    gap: 20px;
  }
`
)

const Title = styled(Heading)(
  ({ theme: t }) => `
  @media (min-width: ${t.breakpoints.lg.min}) and (max-width: 1300px) {
    font-size: 50px !important;
    line-height: 50px !important;
    min-width: 400px;
  }
`
)

const Description = styled.p(
  ({ theme: t }) => `
  font-size: 20px;
  line-height: 28px;
  margin: 0;

  @media (max-width: ${t.breakpoints.md.max}) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
`
)

const FlowButtonsContainer = styled.div(
  ({ theme: t, buttonsAmount }) => `
  display: grid;
  grid-template-columns: ${
    buttonsAmount === 2
      ? 'repeat(auto-fit, minmax(100px, 1fr));'
      : 'repeat(3, 1fr);'
  }
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  gap: 20px;

  @media (min-width: ${t.breakpoints.md.max}) and (max-width: 1100px) {
    gap: 10px;
  }

  @media (max-width: ${t.breakpoints.md.max}) {
    width: 100%;
    gap: 5px;
  }

  @media (max-width: 360px) {
    grid-template-columns: ${
      buttonsAmount === 2
        ? 'repeat(auto-fit, minmax(100px, 1fr));'
        : 'repeat(auto-fill, minmax(80px, 1fr));'
    }
  }
`
)

const FlowButton = styled.button(
  ({ theme: t, isActive, step2view2, step3, disabled }) => `
  display: flex;
  position: relative;
  width 100%;
  min-height: 160px;
  height: auto;
  padding: ${
    step2view2
      ? '20px 19px 20px 20px'
      : step3
      ? '20px 8px 20px 8px'
      : '29px 9px 26px 10px'
  };
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${isActive ? t.colors.white : t.colors.pinkLight};
  border-radius: 30px;
  gap: ${step2view2 ? 0 : '6px'};
  word-break: break-word;
  pointer-events: ${disabled ? 'none' : 'auto'};
  opacity: ${disabled ? 0.5 : 1};

  span {
    width: 40px;
    height: 40px;

    @media (max-width: ${t.breakpoints.md.max}) {
      transform: scale(0.8);
    }
  }

  h3 {
    font-size: 38px;
    line-height: 42px;
    margin: 0;

    @media (max-width: ${t.breakpoints.md.max}) {
      font-size: 28px;
      line-height: 33px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 35px;
    margin: 0;
    margin-top: -12px;
  }

  h5 {
    font-family: Scto Grotesk A;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;

    @media (max-width: ${t.breakpoints.md.max}) {
      font-size: 14px;
      line-height: normal;
    }
  }

  p {
    font-size: 16px;
    line-height: 20px;
    font-size: 16px;
    line-height: ${step3 ? '19px' : '20px'};
    letter-spacing: ${step3 ? '-0.64px' : '0px'};

    @media (max-width: ${t.breakpoints.md.max}) {
      font-size: 14px;
      line-height: normal;
    }
  }

  &:hover {
    background-color: ${
      !isActive && lightenBackground(t.colors.pinkLight, 0.5)
    };
  }

  @media (min-width: ${t.breakpoints.md.max}) and (max-width: 1220px) {
    height: auto;
  }

  @media (max-width: ${t.breakpoints.md.max}) {
    width: 100%;
    height: auto;
    min-height: 150px;
    padding: 22px 4px 8px 4px;
    gap: 3px;
  }
`
)

const FlowButtonCheckedIcon = styled(CheckIcon)(
  ({ theme: t }) => `
  position: absolute;
  top: 11px;
  left: 11px;

  @media (max-width: ${t.breakpoints.md.max}) {
    top: 7px;
    left: 7px;
  }
`
)

const FlowButtonChatClosedIcon = styled(ChatClosedIcon)(
  ({ theme: t }) => `
  position: absolute;
  top: 11px;
  left: 11px;

  @media (max-width: ${t.breakpoints.md.max}) {
    top: 7px;
    left: 7px;
  }
`
)

const TextButton = styled.button`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`

const Flow = ({
  heading,
  description,
  flowButtons,
  buttonStates,
  setButtonStates,
  onSelectedFlowOptionsChange,
  previousSteps,
  setPreviousSteps,
  currentStep,
  setCurrentStep,
  step2view1,
  step2view2,
  step3,
  disabled,
}) => {
  const toggleButton = index => {
    let newButtonStates = [...buttonStates]

    if (step2view1 || step2view2 || step3) {
      newButtonStates = newButtonStates.map((state, idx) =>
        idx === index ? !state : false
      )
    } else {
      newButtonStates[index] = !newButtonStates[index]
    }

    setButtonStates(newButtonStates)

    const newSelectedOptions = newButtonStates
      .map((state, idx) => (state ? idx : -1))
      .filter(idx => idx !== -1)

    onSelectedFlowOptionsChange(currentStep, newSelectedOptions)
  }

  const handleTextButtonClick = () => {
    const newPreviousSteps = [...previousSteps, currentStep]
    setPreviousSteps(newPreviousSteps)
    setCurrentStep(3)
  }

  return (
    <FlowContainer>
      <Title headingSize="2">{heading}</Title>
      {description && <Description>{description}</Description>}
      <FlowButtonsContainer buttonsAmount={flowButtons.length}>
        {flowButtons.map((flowButton, index) => (
          <FlowButton
            key={index}
            isActive={buttonStates[index]}
            onClick={() => toggleButton(index)}
            step2view2={step2view2}
            step3={step3}
            disabled={index === 2 && disabled}
          >
            {buttonStates[index] && <FlowButtonCheckedIcon />}
            {index === 2 && disabled && <FlowButtonChatClosedIcon />}
            {step2view2 && (
              <>
                <h3>{flowButton.title}</h3>
                <h4>vuotta</h4>
              </>
            )}
            {!step2view2 && (
              <span>
                <flowButton.icon />
              </span>
            )}
            {step3 && <h5>{flowButton.title}</h5>}
            <p>{flowButton.text}</p>
          </FlowButton>
        ))}
      </FlowButtonsContainer>
      {step2view2 && (
        <TextButton onClick={handleTextButtonClick}>En tiedä</TextButton>
      )}
    </FlowContainer>
  )
}

export default Flow
