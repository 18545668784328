import styled from 'styled-components'
import Link from './link'
import PdfIcon from '../../images/file-pdf.svg'

const gap = '.25em'
const iconSize = '20px'

export const linkItemStyles = `
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;

  &:not(:last-child):not(:only-child) {
    margin-bottom: .25em;
  }

  &[href$=".pdf"]:after {
    content: '';
    speak: none;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: ${iconSize};
    display: inline-block;
    height: ${iconSize};
    width: ${iconSize};
  }

  &[href$=".pdf"] {
    position: relative;
    text-decoration: underline;

    &:after {
      padding-left: ${iconSize};
      margin-left: ${gap};
    }
  }

  &[href$=".pdf"]:after {
    background-image: url('${PdfIcon}');
  }

  &[hidebefore="true"]:before {
    display: none;
  }
`
export default styled(Link)`
  ${linkItemStyles}
`
