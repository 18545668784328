import React from 'react'
import { arrayOf, bool, node, oneOfType, shape, string } from 'prop-types'
import styled from 'styled-components'
import { Heading, resolvePropsForHeading } from '../typography/headings'
import SmallText from '../typography/smallText'
import Container from '../container'
import HeroSectionTeasers from './heroSectionTeasers'

const propTypes = {
  heading: oneOfType([node, string]).isRequired,
  className: string,
  category: string,
  teasers: arrayOf(
    shape({
      heading: string,
      richTextJson: string.isRequired,
      cta: shape({
        text: string,
        to: string.isRequired,
      }),
    })
  ),
  isHighlighted: bool,
}

const MetaText = styled(SmallText)`
  text-transform: uppercase;
  font-size: 100%;
  width: 100%;
`

const ContentGroup = styled.div``

const SectionWrapper = styled.section(
  ({ theme: { colors, breakpoints, width }, isHighlighted }) => `
  background: ${colors.backgroundColor};
  color: ${colors.primaryColor};
  font-size: 1rem;

  ${Container},
  ${ContentGroup} {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  ${Heading} {
    text-align: center;
    max-width: 37.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  ${
    isHighlighted
      ? `
      ${Heading},
      ${MetaText},
      ol li h3 {
        color: ${colors.highlightColor};
      }
    `
      : ''
  }

  @media screen and (min-width: ${breakpoints.md.min}) {
    ${Container},
    ${ContentGroup} {
      padding-top: 3rem;
      padding-bottom: 2rem;
    }
  }
`
)

const HeroSection = ({
  heading,
  category,
  containerWidth,
  teasers,
  contentStyle,
  ...rest
}) => (
  <SectionWrapper {...rest}>
    <Container containerWidth={containerWidth}>
      {category && (
        <MetaText
          {...resolvePropsForHeading({ children: category, noCursive: true })}
        />
      )}
      <ContentGroup>
        <Heading headingSize="2" as="h2">
          {heading}
        </Heading>
        <HeroSectionTeasers {...{ teasers, contentStyle }} />
      </ContentGroup>
    </Container>
  </SectionWrapper>
)

HeroSection.propTypes = propTypes

export default HeroSection
