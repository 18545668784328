import styled from 'styled-components'
import Link from './link'

export default styled(Link).attrs({ noDefaultLinkFocus: true })(
  ({ theme: { spacing, colors } }) => `
    height: 32px;
    width: 32px;
    color: ${colors.primaryColor};
    display: block;
    transition: color .125s ease-in,
                background .125s ease-in;

    svg {
      circle,
      path:not(:only-child):first-of-type {
        transition: stroke 0s ease-in;
        transition-delay: .125s;
      }
    }

    &:focus,
    &:hover {
      color: ${colors.primaryColorInverted};
      transition-timing-function: ease-out;
      outline: none;

      svg {
        path {
          fill: ${colors.primaryColor};
        }
        circle,
        path:not(:only-child):first-of-type {
          stroke: none;
          transition: stroke .125s ease-ease-out;
          transition-delay: 0s;
        }
      }
    }
  `
)
