import React from 'react'

import Day from '../format/day'
import EUR from '../format/eur'

const NetAssetRow = ({ data }) => (
  <tr>
    <td>
      Hallinnoitavat varat{' '}
      {data && data['MonthEndDate'] && <Day value={data['MonthEndDate']} />}
    </td>
    <td>
      {data && data['MonthEndValue'] ? (
        <EUR value={data['MonthEndValue']} minimumFractionDigits={0} />
      ) : (
        '-'
      )}
    </td>
  </tr>
)

export default NetAssetRow
