import React from 'react'
import styled from 'styled-components'
import Container from '../container'
import { getHeadingStyle } from '../typography/headings'
import smallCaps from '../../styles/mixins/smallCaps'
import medium from '../../styles/mixins/medium'
import hint from '../../styles/mixins/hint'

const StyledIPOBody = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing.xxlarge};
  padding-bottom: ${({ theme }) => theme.spacing.xxlarge};

  > *:not(ol) {
    max-width: ${({ theme }) => theme.width.content};
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .video-container {
    &-outer {
      padding-bottom: ${({ theme }) => theme.spacing.xxlarge};
      margin-left: auto;
      margin-right: auto;
    }

    &-inner {
      position: relative;
      padding-bottom: 75%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
    text-align: center;
  }

  p + h2 {
    margin-top: ${({ theme }) => theme.spacing.xxlarge};
  }

  ol {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-top: ${({ theme }) => theme.spacing.xxlarge};
    margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
    padding-left: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm.min}) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg.min}) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    li {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: ${({ theme }) => theme.spacing.xxlarge};
      background-color: ${({ theme }) => theme.colors.offWhite};
      border-radius: ${({ theme }) => theme.radius.xxlarge};

      em {
        ${smallCaps}
        font-style: normal;
      }

      strong {
        ${getHeadingStyle(1)}
        padding-top: ${({ theme }) => theme.spacing.xlarge};
        padding-bottom: ${({ theme }) => theme.spacing.xlarge};
      }
    }
  }

  table {
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;

    thead {
      display: none;
    }

    tr {
      border-bottom: 1px solid ${({ theme }) => theme.spacing.primaryColor};

      @media (max-width: ${props => props.theme.breakpoints.sm.min}) {
        display: flex;
        flex-direction: column;
      }

      td {
        &:first-of-type {
          ${medium}
          ${hint}
          padding-right: ${({ theme }) => theme.spacing.medium};
        }

        ${getHeadingStyle(4)}

        padding-top: ${({ theme }) => theme.spacing.medium};
        padding-bottom: ${({ theme }) => theme.spacing.medium};
      }
    }
  }
`

const IPOBody = ({ text }) => {
  if (!text) {
    return null
  }
  return <StyledIPOBody dangerouslySetInnerHTML={{ __html: text }} />
}

export default IPOBody
