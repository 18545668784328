import React, { useState } from 'react'
import styled from 'styled-components'
import { useLocation } from '@reach/router'
import {
  arrayOf,
  func,
  node,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import AccordionSection from './accordionSection'

const propTypes = {
  sections: arrayOf(
    shape({
      title: oneOfType([node, string]).isRequired,
      data: node,
    })
  ).isRequired,
  openIdx: number,
  renderTrigger: func,
  renderCloseTrigger: func,
}

const AccordionContainer = styled.div`
  margin-bottom: 30px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md.min}) {
    margin-bottom: 30px;
  }
`

const Accordion = ({
  sections,
  openIdx = -1,
  renderTrigger,
  renderCloseTrigger,
  ...rest
}) => {
  const location = useLocation()
  const [openSectionIndex, setOpenSectionIndex] = useState(openIdx)

  const toggleSection = index => {
    if (index !== openSectionIndex) {
      setOpenSectionIndex(index)
    } else {
      setOpenSectionIndex(null)
    }
  }

  return (
    <AccordionContainer {...rest}>
      {sections.map((section, index) => (
        <AccordionSection
          renderTrigger={renderTrigger}
          renderCloseTrigger={renderCloseTrigger}
          key={index}
          open={
            openSectionIndex === index || location.hash.includes('#suodatus')
          }
          title={section.title}
          onClick={() => toggleSection(index)}
        >
          {section.data}
        </AccordionSection>
      ))}
    </AccordionContainer>
  )
}

Accordion.propTypes = propTypes

export default Accordion
