import React from 'react'

/* eslint-disable */
export default ({ id = 'clip0', ...rest }) => (
  <svg
    width="100%"
    height="69"
    viewBox="0 0 659 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    alt="Alexandria -logo"
    {...rest}
  >
    <g clipPath={`url(#${id})`}>
      <path
        d="M407.174 69H393.54L358.033 12.6044V69H347.708V0H361.342L396.849 56.4708V0H407.174V69Z"
        fill="#000450"
      />
      <path
        d="M197.737 69L223.212 34.5987L197.737 0H210.878L229.631 26.4495L248.783 0H261.421L236.078 34.4013L261.677 69H248.584L229.536 42.5505L210.28 69H197.737Z"
        fill="#000450"
      />
      <path
        d="M78.7593 0H89.4918V59.3564H125.994V69H78.7593V0Z"
        fill="#000450"
      />
      <path
        d="M137.2 0H187.346V9.34287H147.904V29.1377H183.705V38.2832H147.904V59.5537H187.914V68.953H137.2V0Z"
        fill="#000450"
      />
      <path
        d="M450.53 69H424.751V0H450.521C473.2 0 485.43 14.4185 485.43 34.5047C485.43 54.5909 473.2 69 450.53 69ZM450.227 9.24888H435.484V59.7511H450.227C465.179 59.7511 474.1 50.0135 474.1 34.5047C474.1 18.9959 465.179 9.24888 450.227 9.24888Z"
        fill="#000450"
      />
      <path
        d="M499.652 0H530.039C545.986 0 554.813 7.05885 554.813 20.1802C554.813 30.228 549.001 36.6853 539.871 39.1761L557.885 69H545.749L528.683 40.868H510.413V69H499.652V0ZM529.043 31.9575C538.164 31.9575 543.682 28.8839 543.682 20.528C543.682 12.172 538.192 8.9481 529.043 8.9481H510.413V31.9575H529.043Z"
        fill="#000450"
      />
      <path d="M569.982 0H580.715V69H569.982V0Z" fill="#000450" />
      <path
        d="M46.533 42.2967L56.6683 69H67.9982L40.6169 0H30.2635L33.8948 9.44626H23.5415L0 69H11.036L18.014 51.3012H43.0535L46.533 42.2967ZM33.8948 9.44626L46.533 42.2967H21.2565L33.8948 9.44626Z"
        fill="#000450"
      />
      <path
        d="M315.482 42.2967L325.608 69.0376H336.938L309.557 0H299.203L302.844 9.44626H292.491L268.94 69H279.976L286.992 51.3012H311.993L315.482 42.2967ZM302.844 9.44626L315.473 42.2967H290.206L302.844 9.44626Z"
        fill="#000450"
      />
      <path
        d="M637.535 42.2967L647.67 69.0376H659.048L631.619 0H621.266L624.897 9.44626H614.543L591.002 69H602.047L609.063 51.3012H634.065L637.535 42.2967ZM624.897 9.44626L637.535 42.2967H612.268L624.897 9.44626Z"
        fill="#000450"
      />
    </g>
    <defs>
      <clipPath id={id}>
        <rect width="659" height="69" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
