import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

export default styled(GatsbyImage)(
  ({ theme, covercss, imgposition }) => `
  height: 100%;
  left: 0;
  position: absolute !important;
  top: 0;
  width: 100%;
  ${
    covercss
      ? `
      :before {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        ${covercss};
      }
    `
      : ''
  }

  img {
    object-position: ${imgposition || '50% 50%'} !important;
  }
`
)
