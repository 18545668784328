import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import FacebookShareButton from './facebookShareButton'
import TwitterShareButton from './twitterShareButton'
import LinkedinShareButton from './linkedinShareButton'
import WhatsappShareButton from './whatsappShareButton'

const propTypes = {
  location: string.isRequired,
}

const List = styled.ul(
  ({ theme: { spacing, colors } }) => `
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  list-style: none;

  li {
    margin: 0 .5em 1em .5em;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`
)

const ShareButtonGroup = ({ location, ...rest }) => (
  <List {...rest}>
    <li key="facebook">
      <FacebookShareButton location={location} />
    </li>
    <li key="twitter">
      <TwitterShareButton location={location} data-dnt="true" />
    </li>
    <li key="linkedin">
      <LinkedinShareButton location={location} />
    </li>
    <li key="whatsapp">
      <WhatsappShareButton location={location} />
    </li>
  </List>
)

ShareButtonGroup.propTypes = propTypes

export default ShareButtonGroup
