import React from 'react'
import styled from 'styled-components'
import Link from '../links/link'

import { ReactComponent as CoinsIcon } from '../../images/coins.svg'
import { ReactComponent as GraphIcon } from '../../images/graph.svg'
import { ReactComponent as CalculatorIcon } from '../../images/calculator.svg'
import { ReactComponent as TreeIcon } from '../../images/tree.svg'
import { ReactComponent as UserInfoIcon } from '../../images/user-info.svg'
import { ReactComponent as HeartIcon } from '../../images/heart.svg'
import { ReactComponent as LeafIcon } from '../../images/leaf-new.svg'
import { ReactComponent as DocumentIcon } from '../../images/document.svg'

const Wrapper = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  max-width: ${t.width.max};
  width: 100%;
  gap: 20px;
  padding: 50px 42px 50px 42px;
  margin: 0 auto;

  @media (max-width: ${t.breakpoints.md.max}) {
    padding: 0 20px 20px 20px;
    gap: 10px;
    margin-bottom: 10px;
  }
`
)

const Row = styled.div(
  ({ theme: t }) => `
  display: flex;
  gap: 20px;

  @media (max-width: ${t.breakpoints.md.max}) {
    flex-direction: column;
    gap: 10px;
  }
`
)

const QuickLink = styled(Link)(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 20px;
  background: rgba(153, 153, 153, 0.20);
  color: ${t.colors.primaryColor};
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s;
  }

  span {
    width: 40px;
    height: 40px;

    svg {
      path {
        fill: ${t.colors.red};
      }
    }
  }
`
)

const LinkText = styled.div`
  width: 100%;
  text-align: center;
`

const quickLinks = [
  {
    name: 'Rahastojen arvot',
    link: '/rahastot-tanaan',
    icon: CoinsIcon,
  },
  {
    name: 'Strukturoitujen arvot',
    link: '/strukturoidut-sijoitukset/strukturoitujen-sijoitusten-arvostukset',
    icon: GraphIcon,
  },
  {
    name: 'Tuottolaskuri',
    link: '/tuottolaskuri',
    icon: CalculatorIcon,
  },
]

const quickLinksMeista = [
  {
    name: 'Konsernirakenne',
    link: '/konsernirakenne',
    icon: TreeIcon,
  },
  {
    name: 'Asiakasinformaatio',
    link: '/asiakasinformaatio',
    icon: UserInfoIcon,
  },
  {
    name: 'Palkitseminen',
    link: '/palkitseminen',
    icon: HeartIcon,
  },
  {
    name: 'Vastuullisuus ja kestävän sijoittamisen periaatteet',
    link: '/vastuullisuus-ja-kestavan-sijoittamisen-periaatteet',
    icon: LeafIcon,
  },
  {
    name: 'Julkaistut dokumentit',
    link: '/julkaistut-dokumentit',
    icon: DocumentIcon,
  },
]

const ToolsBar = ({ meista }) => {
  const linksToRender = meista ? quickLinksMeista : quickLinks

  return (
    <Wrapper>
      <Row>
        {linksToRender.slice(0, 3).map((link, index) => (
          <QuickLink key={index} to={link.link}>
            <span>
              <link.icon />
            </span>
            <LinkText>{link.name}</LinkText>
          </QuickLink>
        ))}
      </Row>
      <Row>
        {linksToRender.slice(3).map((link, index) => (
          <QuickLink key={index} to={link.link}>
            <span>
              <link.icon />
            </span>
            <LinkText>{link.name}</LinkText>
          </QuickLink>
        ))}
      </Row>
    </Wrapper>
  )
}

export default ToolsBar
