import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { lightenBackground } from '../../utils'
import ContactForm from '../contactForm'
import CurlyBraceImg from '../../images/curly-brace.svg'
import Heading from './../typography/headings'

const Wrapper = styled.div(
  ({ theme: t, showDetails, isSubmitted }) => `
  width: 100%;
  color: ${t.colors.white};
  background: ${showDetails ? 'transparent' : t.colors.primaryColor};

  @media (max-width: ${t.breakpoints.md.max}) {
    padding: 0;
  }
`
)

const Content = styled.div(
  ({ theme: t, showDetails, isSubmitted }) => `
  display: flex;
  max-width: ${t.width.max};
  margin: 0 auto;
  padding: ${showDetails || isSubmitted ? 0 : '0 206px 0 91px'};
  height: ${showDetails ? 'auto' : '220px'};
  justify-content: space-between;
  flex-direction: ${showDetails ? 'column' : 'row'};
  gap: 20px;
  align-items: ${showDetails ? 'left' : 'center'};

  @media (max-width: ${t.breakpoints.md.max}) {
    padding: ${showDetails || isSubmitted ? 0 : '0 30px'};
    justify-content: ${showDetails ? 'flex-start' : 'center'};
  }

  @media (max-width: ${t.breakpoints.md.min}) {
    flex-direction: column;
    height: 100%;
    padding: ${showDetails || isSubmitted ? 0 : '30px'};
    justify-content: flex-start;
    gap: 7px;
  }
`
)

const LeftSide = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;

  @media (min-width: 769px) and (max-width: ${t.breakpoints.md.max}) {
    width: 270px;
  }

  @media (max-width: 769px) {
    width: 410px;
  }

  @media (max-width: 470px) {
    width: 100%;
  }
`
)

const Title = styled(Heading)(
  ({ theme: t, showDetails }) => `
  display: ${showDetails ? 'none' : 'block'};
  font-size: 28px;
  line-height: 33px;
  height: 52px;
  margin-bottom: 0;

  @media (min-width: 959px) {
    white-space: nowrap;
    margin-bottom: 10px;
  }

  @media (max-width: ${t.breakpoints.md.max}) {
    margin-bottom: 20px;
  }

  @media (min-width: 450px) and (max-width: ${t.breakpoints.sm.max}) {
    margin-bottom: 0;
  }
`
)

const CurlyBrace = styled.svg(
  ({ theme: t }) => `
  background-image: url('${CurlyBraceImg}');
  background-repeat: no-repeat;
  width: 17px;

  @media (max-width: ${t.breakpoints.xl.min}) {
    display: none;
  }
`
)

const Legend = styled.legend(
  ({ theme: t, showDetails }) => `
  font-size: 16px;
  margin-bottom: 12px;
  color: ${showDetails ? t.colors.primaryColor : t.colors.white};

  @media (max-width: ${t.breakpoints.md.min}) {
    margin-top: 10px;
  }

  @media (min-width: 450px) {
    margin-top: 0;
  }
`
)

const Buttons = styled.div(
  ({ showDetails }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: ${showDetails ? '47px' : '7px'};

  > * {
    padding: 0 1em;
  }
`
)

const Button = styled.button(
  ({ theme: t, isSelected, showDetails }) => `
  height: 36px;
  padding: 0 20px;
  background-color: ${
    showDetails
      ? isSelected
        ? t.colors.primaryColor
        : 'rgba(255, 255, 255, 0.20)'
      : isSelected
      ? t.colors.pink
      : 'transparent'
  };
  color: ${
    showDetails
      ? isSelected
        ? t.colors.white
        : t.colors.primaryColor
      : isSelected
      ? t.colors.primaryColor
      : t.colors.pink
  };
  border: none;
  border-radius: 40px;
  outline: none;
  font-size: 16px;

  &:hover {
    background-color: ${
      showDetails
        ? isSelected
          ? lightenBackground(t.colors.primaryColor, 0.5)
          : lightenBackground(t.colors.primaryColor, 0.9)
        : isSelected
        ? t.colors.pinkLight
        : lightenBackground(t.colors.primaryColor, 0.3)
    };
  }
`
)

const FormContainer = styled.div(
  ({ theme: t, showDetails, isSubmitted }) => `
  align-items: center;
  margin: ${isSubmitted ? '0 auto' : 0};

  input {
    width: ${showDetails ? '100%' : '410px'};
    max-width: ${showDetails ? '100%' : '410px'};
  }

  @media (min-width: ${t.breakpoints.md.max}) {
    width: ${showDetails ? '100%' : isSubmitted ? '100%' : '50%'};
  }
  
  @media (max-width: ${t.breakpoints.xl.min}) {
    input {
      width: 330px;
      transition: width 0.5s ease;
    }
  }

  @media (max-width: ${t.breakpoints.md.min}) {
    width: auto;
  }

  @media (max-width: ${t.breakpoints.md.min}) {
    width: 100%;

    input {
      margin: 0 auto;
    }
  }
`
)

const ContactUsBar = props => {
  const data = useStaticQuery(graphql`
    query {
      contactUsBar: contentfulContactUsBar {
        title
        description
        contactForm {
          name
          phone
          email
        }
      }
    }
  `)

  const contact = data.contactUsBar

  const [contactType, setContactType] = useState('tel')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleFormSubmit = success => {
    setIsSubmitted(success)
  }

  return (
    <Wrapper showDetails={props.showDetails} isSubmitted={isSubmitted}>
      <Content showDetails={props.showDetails} isSubmitted={isSubmitted}>
        {!isSubmitted && (
          <LeftSide>
            <Title showDetails={props.showDetails}>{contact.title}</Title>
            <Legend showDetails={props.showDetails}>
              {contact.description}
            </Legend>
            <Buttons showDetails={props.showDetails}>
              <Button
                isSelected={contactType === 'tel'}
                showDetails={props.showDetails}
                onClick={() => setContactType('tel')}
              >
                Puhelimitse
              </Button>
              <Button
                isSelected={contactType === 'email'}
                showDetails={props.showDetails}
                onClick={() => setContactType('email')}
              >
                Sähköpostitse
              </Button>
            </Buttons>
          </LeftSide>
        )}
        {!isSubmitted && !props.showDetails && <CurlyBrace />}
        <FormContainer
          showDetails={props.showDetails}
          isSubmitted={isSubmitted}
        >
          <ContactForm
            contactType={contactType}
            showDetails={props.showDetails}
            onSubmit={handleFormSubmit}
            whiteTheme={true}
          />
        </FormContainer>
      </Content>
    </Wrapper>
  )
}

export default ContactUsBar
