import React from 'react'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/contentfulPage'
import ContentfulCard from '../components/contentfulCard'

const JobPostingsPage = ({
  data: {
    page,
    jobPostings: { edges: jobsData },
  },
}) => {
  const jobPostings = jobsData.map(({ node }) => node)
  return (
    <ContentfulPage
      page={page}
      cardGroups={[
        {
          items: jobPostings.map(posting => <ContentfulCard card={posting} />),
        },
      ]}
    />
  )
}

export default JobPostingsPage

export const query = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
    jobPostings: allContentfulJobPosting(
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          ...contentfulJobPostingInformation
        }
      }
    }
  }
`
