import React from 'react'
import styled from 'styled-components'

import { personPropTypes } from '../../utils/prop-types'
import { bool } from 'prop-types'
import Card from './card'
import PersonCardContent from './personCardContent'
import { lightenBackground } from '../../utils'

const propTypes = {
  person: personPropTypes.isRequired,
  isAsideCard: bool,
}

// wrapping components makes sure that sticky navbar is taken into consideration
// when following a link with fragment to a personnel card
const CardWrapper = styled(Card)(
  ({ theme: t, isEven }) => `
  width: 100%;
  max-width: 500px;
  border-radius: 30px;
  padding: 15px;
  background-color: ${
    isEven
      ? lightenBackground(t.colors.pinkLight, 0.4)
      : lightenBackground(t.colors.blueLight, 0.6)
  };
  @media (max-width: ${t.breakpoints.lg.max}) {
    max-height: unset;
  }
`
)

const PersonnelCard = ({ person, index, isAsideCard, ...rest }) => {
  const isEven = index % 2 === 0

  return (
    <CardWrapper isEven={isEven} {...rest}>
      <PersonCardContent data={person} isAsideCard={isAsideCard} />
    </CardWrapper>
  )
}

PersonnelCard.propTypes = propTypes

export default PersonnelCard
