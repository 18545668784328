import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Form, Formik, Field } from 'formik'
import { number, object } from 'yup'
import { visible } from '../../utils'
import { TextInput, SliderInput } from '../formInputs'
import CalculatorGraph from './calculatorGraph'
import Component from '@reach/component-component'
import useDebounce from 'react-use/lib/useDebounce'
import { chartTheme } from '../../styles/theme'
import SmallText from '../typography/smallText'

const Disclaimer = styled(({ className }) => (
  <SmallText as="p" className={className}>
    Laskurissa ei ole otettu huomioon inflaation vaikutusta, verotusta, eikä
    mahdollisia kuluja, kuten merkintä-/lunastus, kaupankäynti- ja
    hallinnointipalkkioita. Laskuri antaa vain teoreettisen tuoton, sillä
    sijoitusaikana sijoitusten arvo ja sijoituksista saatava tuotto saattavat
    yhtä hyvin laskea tai nousta. Laskurin antamat laskelmat eivät muodosta
    sijoitussuositusta tai -neuvoa.
  </SmallText>
))`
  color: ${props => props.theme.colors.grey};
  padding-top: 2em;
`
const DesktopDisclaimer = styled(Disclaimer)`
  ${visible({ lg: true, xl: true })}
`
const MobileDisclaimer = styled(Disclaimer)`
  ${visible({ xs: true, sm: true, md: true })}
`

const dropEmptyStringsAndZeros = obj =>
  Object.keys(obj)
    .filter(
      k =>
        obj[k] !== null &&
        obj[k] !== undefined &&
        !(typeof obj[k] === 'string' && !obj[k].length)
    )
    .reduce((newObj, k) => {
      return Object.assign(newObj, { [k]: obj[k] })
    }, {})

// validation messges are not used atm.
const calculatorSchema = object().shape({
  monthlySaving: number()
    .typeError('Arvon on oltava numero')
    .integer('Arvon on oltava kokonaisluku'),
  initialCapital: number()
    .typeError('Arvon on oltava numero')
    .min(0, 'Arvo ei voi olla negatiivinen')
    .integer('Arvon on oltava kokonaisluku'),
})

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.small};
  flex: 1 0 auto;
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  div > input[type='number'] {
    border-radius: 100px;
    border-color: transparent;
    padding: 0.5em 1em;
    border-width: 2px;
    transition: border-color 0.125s ease-in;

    &:focus {
      border-color: ${p => p.theme.colors.chart.profitColor};
    }
  }
`

const FormAndGraph = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  flex: 1 0 100%;
  @media screen and (min-width: ${props => props.theme.breakpoints.lg.min}) {
    flex-direction: row;
    align-items: stretch;
    ${StyledForm} {
      justify-content: space-around;
      flex: 1 2 100%;
    }
    ${CalculatorGraph} {
      flex: 2 1 100%;
    }
  }
`

const Grey = styled.span`
  display: inline-block;
  color: ${props => props.theme.colors.grey};
`

const yearsOfSavingMarks = {
  0: '0v',
  5: '',
  10: '10v',
  15: '',
  20: '20v',
  25: '',
  30: '30v',
}
const profitPercentageMarks = {
  4: 'Matala 4%',
  6: 'Kohtalainen (6%)',
  8: 'Korkea (8%)',
}

const initialFormValues = {
  monthlySaving: '',
  initialCapital: '',
  yearsOfSaving: 10,
  profitPercentage: 4,
}

const CalculatorForm = () => {
  const [graphData, setGraphData] = useState(initialFormValues)
  const [debouncedGraphData, setDebounced] = useState(initialFormValues)
  useDebounce(() => setDebounced(graphData), 1000, [graphData])
  return (
    <ThemeProvider theme={chartTheme}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={calculatorSchema}
      >
        {formik => (
          <FormAndGraph>
            <Component
              formik={formik}
              didUpdate={({ props }) => setGraphData(props.formik.values)}
            />
            <StyledForm>
              <Field
                type="number"
                placeholder={150}
                name="monthlySaving"
                label="Paljonko voin säästää kuukaudessa"
                component={TextInput}
                suffix="€/kk"
                required
              />
              <Field
                type="number"
                placeholder={0}
                name="initialCapital"
                label="Alkusijoitus"
                component={TextInput}
                suffix="€"
              />
              <Field
                value={formik.values.yearsOfSaving}
                name="yearsOfSaving"
                label={() => (
                  <span>
                    <span>Säästöaika: </span>
                    <Grey>{formik.values.yearsOfSaving}v</Grey>
                  </span>
                )}
                component={SliderInput}
                marks={yearsOfSavingMarks}
                min={0}
                max={30}
                required
              />
              <Field
                value={formik.values.profitPercentage}
                name="profitPercentage"
                label="Millaista tuottoa tavoittelet?"
                component={SliderInput}
                marks={profitPercentageMarks}
                min={4}
                max={8}
                step={2}
                required
              />
              <DesktopDisclaimer />
            </StyledForm>
            <CalculatorGraph
              values={dropEmptyStringsAndZeros(debouncedGraphData)}
            />
            <MobileDisclaimer />
          </FormAndGraph>
        )}
      </Formik>
    </ThemeProvider>
  )
}

export default CalculatorForm
