import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ScoreFaceHappy } from '../../images/score-face-happy-bg.svg'

const SliderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 0.5rem;
`

const BackgroundBar = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: lightgray;
  border-radius: 0.25rem;
`

const ActiveBar = styled.div`
  position: absolute;
  height: 100%;
  width: ${props => props.score * 100}%;
  background-color: green;
  border-radius: 0.25rem;
`

const FaceContainer = styled.div`
  position: absolute;
  left: calc(${props => props.score * 100}% - 0.75rem);
  height: 1.5rem;
  width: 1.5rem;
  background-color: white;
  border-radius: 50%;
`

/* const ScoreSliderWithLabelWrapper = styled.div`
  width: 100%;
`

const Label = styled.div`
  font-size: 1rem;
  margin-bottom: 0.5rem;
` */

const SliderFace = ({ score }) => {
  return (
    <FaceContainer score={score}>
      <ScoreFaceHappy />
    </FaceContainer>
  )
}

// Use normalized score (0...1) as input
export const ScoreSlider = ({ score }) => {
  return (
    <SliderWrapper>
      <BackgroundBar />
      <ActiveBar score={score} />
      <SliderFace score={score} />
    </SliderWrapper>
  )
}

// Slider implementation, to be used if we have relevant data available
/* export const ScoreSliderWithLabel = ({ score, label }) => {
  return (
    <ScoreSliderWithLabelWrapper>
      <Label>{label}</Label>
      <ScoreSlider score={score} />
    </ScoreSliderWithLabelWrapper>
  )
} */

const ScoreBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 1.5rem;
  background-color: #deebe1;
  border-radius: ${props => props.theme.radius.xxlarge};
  align-items: center;
  gap: 1.5rem;

  h2 {
    margin: 0;
  }
`
const ScoreLabel = styled.div`
  font-size: 1rem;
`

export const ScoreSliderWithLabel = ({ score, label }) => {
  return (
    <ScoreBox>
      <h2>{score}</h2>
      <ScoreLabel>{label}</ScoreLabel>
    </ScoreBox>
  )
}
