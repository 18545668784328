import { math } from 'polished'

const breakpoints = ['0px', '600px', '768px', '960px', '1200px']

// Define hard brand colors here
const palette = {
  black: '#000029',
  blue: '#000450',
  offBlue: '#074983',
  blueLight: '#7AB8FF',
  cyan: '#AFEAFF',
  gray: '#999999',
  grey: '#5B5B5B',
  greyLight: '#CCCCCC',
  white: '#FFFFFF',
  offWhite: '#F7F7F7',
  pink: '#FFB6B6',
  pinkLight: '#FFCCCC',
  red: '#FF6063',
  green: '#00AB66',
  greenDark: '#007040',
  orange: '#FF992A',
}

/*
 * Apply brand colors to practical variables. colors['color'] should not be used due to
 * theming. Color blue can be white in inverted theme eg.
 * Try to get rid of all hard colors from baseTheme in due time if possible.
 */
const baseTheme = {
  boxShadow: {
    sm: '0 0 5px 1px rgba(0, 0, 0, 0.1)',
    md: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    table: '0 0 10px 0 rgba(177, 177, 177, 0.16)',
    heroCard: '0 3rem 3rem -3rem rgba(0, 0, 0, 0.16)',
  },
  colors: {
    blue: palette.blue,
    blueLight: palette.blueLight,
    cyan: palette.cyan,
    blueChart: '#D9E5EF',
    black: palette.black,
    blackDark: '#0C0C0C',
    blackDarker: '#000000',
    gray: palette.gray,
    grey: palette.grey,
    greyDark: '#747474',
    greyMedium: '#D9D9D9',
    greyLight: palette.greyLight,
    greyLighter: '#EBE8e8',
    greyLightBorder: '#EBEAEB',
    greyChartBackground: '#FCFCFC',
    greyText: '#777777',
    white: palette.white,
    offWhite: palette.offWhite,
    fieldBackgroundColor: palette.offWhite,
    red: palette.red,
    orange: palette.orange,
    pink: palette.pink,
    pinkLight: palette.pinkLight,
    greenDark: palette.greenDark,
    errorColor: palette.red,
    primaryColor: palette.blue,
    secondaryColor: palette.greyLight,
    highlightColor: palette.red,
    backgroundColor: palette.white,
    primaryColorInverted: palette.white,
    confettiColors: [palette.red, palette.greenDark, palette.orange],
    chart: {
      savingsColor: palette.blueLight,
      profitColor: palette.greenDark,
      deficitColor: palette.red,
      backgroundColor: '#FCFCFC',
      borderColor: palette.greyLight,
      labelColor: palette.grey,
    },
  },
  lineHeight: {
    tight: 1.2,
    normal: 1.45,
  },
  spacing: {
    xsmall: '.3125em',
    small: '.5em',
    medium: '1em',
    large: '1.5em',
    xlarge: '2rem',
    xxlarge: '48px',
    xxxlarge: '80px',
  },
  radius: {
    md: '3px',
    xxlarge: '24px',
  },
  navHeight: {
    min: '56px',
    lg: '66px',
  },
  transition: {
    duration: '.25s',
    timingFunction: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
  width: {
    max: '1364px',
    content: '60em',
    contentInside: '37.5em',
    contentNew: '900px',
  },
  breakpoints: {
    xs: {
      max: math(`${breakpoints[1]} - 1px`),
    },
    sm: {
      min: breakpoints[1],
      max: math(`${breakpoints[2]} - 1px`),
    },
    md: {
      min: breakpoints[2],
      max: math(`${breakpoints[3]} - 1px`),
    },
    lg: {
      min: breakpoints[3],
      max: math(`${breakpoints[4]} - 1px`),
    },
    xl: {
      min: breakpoints[4],
    },
  },
}

const createTheme = fn => ({
  ...baseTheme,
  ...{
    colors: {
      ...baseTheme.colors,
      ...fn(baseTheme),
    },
  },
})

export const highlightTheme = createTheme(({ colors }) => ({
  backgroundColor: colors.highlightColor,
  highlightColor: colors.white,
}))

export const pinkTheme = createTheme(({ colors }) => ({
  backgroundColor: palette.pink,
  highlightColor: colors.white,
}))

export const subtleTheme = createTheme(({ colors }) => ({
  backgroundColor: colors.offWhite,
}))

export const lightTheme = createTheme(({ colors }) => ({
  backgroundColor: colors.blueLight,
}))

export const invertedTheme = createTheme(({ colors }) => ({
  backgroundColor: colors.primaryColor,
  primaryColor: colors.backgroundColor,
  primaryColorInverted: colors.primaryColor,
}))

export const blueRedTheme = createTheme(({ colors }) => ({
  backgroundColor: colors.primaryColor,
  primaryColor: colors.red,
  primaryColorInverted: colors.red,
}))

export const darkBlueTheme = createTheme(({ colors }) => ({
  backgroundColor: colors.blue,
  primaryColor: colors.red,
  primaryColorInverted: colors.red,
}))

export const redBlueTheme = createTheme(({ colors }) => ({
  backgroundColor: colors.red,
  primaryColor: colors.primaryColor,
  primaryColorInverted: colors.primaryColor,
}))

export const orangeTheme = createTheme(({ colors }) => ({
  backgroundColor: palette.orange,
  primaryColor: colors.blue,
  primaryColorInverted: colors.white,
}))

export const chartTheme = createTheme(({ colors }) => ({
  blue: palette.offBlue,
  fieldBackgroundColor: palette.white,
}))

/* TODO: remove tableTheme entirely from use if there wont be any benefits from it */
export const tableTheme = createTheme(({ colors }) => ({
  borderColor: colors.chart.borderColor,
  secondaryColor: colors.grey,
  fieldBackgroundColor: palette.white,
}))

export default baseTheme
