import styled from 'styled-components'

export default styled.div`
  p {
    font-size: 20px;
    line-height: 28px;

    margin-bottom: 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
      margin-bottom: 20px;
    }
  }

  h2 {
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;

    @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
      font-size: 28px;
      line-height: 33px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
      margin-bottom: 20px;
    }

    :first-child {
      margin-top: 0;
    }
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }

  iframe {
    max-width: 100%;
  }
`
