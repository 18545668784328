import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { dangerouslyMakePropsFromString } from '../utils'
import ContentfulPage from '../components/contentfulPage'
import LoanCalculator from '../components/calculators/loanCalculator'
import Container from '../components/container'
import ContentfulText from '../components/contentfulText'
import Card from '../components/cards/card'
import Main from '../components/main'
import AsideLinks from '../components/asideLinks'
import { Subheading } from '../components/typography/headings'

const StyledCalculator = styled(Card.withComponent(LoanCalculator))``

const getCalculatorNode = ({ title, footer }) => (
  <StyledCalculator disclaimer={footer}>
    {title && (
      <Subheading headingSize="1" as="h2">
        {title}
      </Subheading>
    )}
  </StyledCalculator>
)

const getContentNode = body => (
  <Main>
    <ContentfulText text={body} marginTop={true} />
  </Main>
)

const calculatorShortCodeRegex = /\[LoanCalculator(.*)\]/ // '[LoanCalculator]'
const calculatorLongCodeRegex = /<p>\[LoanCalculator(.*)\]<\/p>/ // '[LoanCalculator]'

const getPageContentNode = html => {
  // quick and dirty for now. content can have a shortcode which marks the place to render the loanCalculator
  if (!html) {
    return null
  }

  const calcMatch = html.match(calculatorShortCodeRegex)
  if (!calcMatch) {
    return getContentNode(html)
  }

  // Matches if shortcode is within paragraph
  const longCalcMatch = html.match(calculatorLongCodeRegex)

  // Get the match from which ever. Match can't be null anymore. Map keys to readable values.
  const {
    index: calcPos,
    0: shortcodeContent,
    1: calculatorPropsString,
  } = longCalcMatch || calcMatch

  return (
    <>
      {getContentNode(html.substr(0, calcPos))}
      {getCalculatorNode(dangerouslyMakePropsFromString(calculatorPropsString))}
      {getContentNode(html.substr(calcPos + shortcodeContent.length))}
    </>
  )
}

const PageAsideCard = styled(Card).attrs({ as: 'aside' })``

const CorporateFinancingPage = ({
  data: {
    contentfulPage: { body, aside, asideHeading, ...page },
  },
}) => {
  const { html } = body.childMarkdownRemark || {}

  return (
    <ContentfulPage page={page}>
      <Container containerWidth="content">{getPageContentNode(html)}</Container>
      <Container containerWidth="content" isFlowing>
        <PageAsideCard>
          <AsideLinks links={aside} heading={asideHeading} />
        </PageAsideCard>
      </Container>
    </ContentfulPage>
  )
}

export default CorporateFinancingPage

export const query = graphql`
  query ($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
  }
`
