import React from 'react'
import { bool, node, number, oneOfType, string } from 'prop-types'
import { format } from 'date-fns'
import styled, { useTheme } from 'styled-components'
import CtaButton from '../buttons/ctaButton'
import Image from '../backgroundImage'
import DateContainer from '../DateContainer'
import { Heading } from '../typography/headings'
import SmallText from '../typography/smallText'
import { imagePropTypes } from '../../utils/prop-types'
import { getIconByContentType } from '../../utils/segments'
import Link from '../links/link'
import { ReactComponent as ArrowRight } from '../../images/arrow-right.svg'
import { useMediaQuery } from 'react-responsive'

const propTypes = {
  image: imagePropTypes,
  title: oneOfType([node, string]).isRequired,
  body: node,
  seasonNumber: oneOfType([string, number]),
  publicationDate: string,
  to: string,
  className: string,
  imagePositionCss: string,
  metaText: string,
  smallHero: bool,
}

const ImageWrap = styled.div(
  ({ issquare }) => `
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 50%;
`
)

const ImageWrapperLink = styled(Link)(
  ({ issquare }) => `
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 50%;
`
)

const StyledDateContainer = styled(DateContainer)`
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing.large};

  svg {
    margin-right: ${({ theme }) => theme.spacing.small};
  }
`

const ContentGroup = styled.div`
  margin-bottom: 20px;
  word-break: break-word;
  text-align: left;
`

const ImageColumn = styled.div(
  ({ theme, ispodcast }) => `
  border-radius: 30px 0 0 30px;
  overflow: hidden;

  @media (min-width: ${theme.breakpoints.lg.min}) {
    ${ispodcast === 'true' && 'max-width: 50%;'}
  }

  @media (max-width: ${theme.breakpoints.md.max}) {
    border-radius: 0;
  }
`
)

const ContentColumn = styled.div(
  ({ theme: { colors, breakpoints } }) => `
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  padding: 74.5px 80px 74.5px 60px !important;

  p:not(:last-child) {
    margin-bottom: 1em;
  }

  @media (max-width: ${breakpoints.md.max}) {
    padding: 20px !important;
  }

  ${CtaButton} {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;

    display: flex;
    width: 200px;
    height: 45px;
    padding: 13px 14px 12px 11px;
    background-color: transparent;
    color: ${colors.primaryColor};
    border-width: 2px;
    align-items: center;

    span {
      width: 154px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;

      @media (max-width: ${breakpoints.md.max}) {
        font-size: 14px;
        line-height: normal;
      }
    }

    &:hover {
      background-color: ${colors.primaryColor};
      color: ${colors.white};

      svg {
        path {
          fill: ${colors.white};
        }
      }
    }
  }
`
)

const ContentHeroContainer = styled.div(
  ({ theme }) => `
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  transform: translate(0, -85px);
  margin-bottom: -80px;
  z-index: 10;
  border-radius: 30px;

  @media (min-width: ${theme.breakpoints.lg.min}) {
    transform: translate(0, -130px);
    margin-bottom: -60px;

    flex-flow: row nowrap;
    min-height: 375px;
    border-radius: 30px;

    ${ImageColumn} {
      flex: 1.5;
    }

    ${ImageWrap} {
      padding-bottom: 0;
    }

    ${ImageWrapperLink} {
      padding-bottom: 0;
    }

    ${ContentColumn} {
      flex: 1;
      padding: ${theme.spacing.large};
      border-radius: 0 30px 30px 0;
    }

    ${ContentGroup} {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      margin-bottom: 45px;

      @media (max-width: ${theme.breakpoints.md.max}) {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.md.max}) {
    padding: 0;
    border-radius: 0;
    margin-left: -20px;
    margin-right: -20px;
  }
`
)

const Title = styled(Heading)`
  margin-bottom: 45px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    margin-bottom: 20px;
  }
`

const getPodcastSeasonText = (seasonNumer, date) => {
  return `Kausi ${seasonNumer} — ${new Date(date).getFullYear()}`
}

const ContentHeroCard = ({
  image,
  mobileImage,
  imagePositionCss,
  title,
  body,
  to,
  className,
  contentType,
  publicationDate,
  seasonNumber,
}) => {
  const isPodcast = contentType === 'podcast' || to.includes('rikasta-elamaa')
  const hasPublicationDate = !!publicationDate
  const theme = useTheme()
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.md.max})`,
  })

  const heroImage = mobileImage && isMobile ? mobileImage : image

  return (
    <ContentHeroContainer className={className}>
      <ImageColumn ispodcast={isPodcast.toString()}>
        {to ? (
          <ImageWrapperLink to={to}>
            <Image
              image={heroImage.gatsbyImageData}
              alt={image.title}
              imgposition={imagePositionCss}
            />
          </ImageWrapperLink>
        ) : (
          <ImageWrap>
            <Image
              image={heroImage.gatsbyImageData}
              alt={image.title}
              imgposition={imagePositionCss}
            />
          </ImageWrap>
        )}
      </ImageColumn>
      <ContentColumn>
        <ContentGroup>
          {hasPublicationDate && (
            <StyledDateContainer>
              {getIconByContentType(contentType)}
              {isPodcast ? (
                <SmallText>
                  {getPodcastSeasonText(seasonNumber, publicationDate)}
                </SmallText>
              ) : (
                <SmallText>
                  {format(new Date(publicationDate), 'dd.MM.yyyy')}
                </SmallText>
              )}
            </StyledDateContainer>
          )}
          {to ? (
            <Link to={to}>
              <Title as="h2" headingSize="3">
                {title}
              </Title>
            </Link>
          ) : (
            <Title as="h2" headingSize="3">
              {title}
            </Title>
          )}
          {body || null}
        </ContentGroup>
        {to && (
          <CtaButton to={to} large="true">
            <span>{isPodcast ? 'Siirry podcastiin' : 'Lue lisää'}</span>
            <ArrowRight />
          </CtaButton>
        )}
      </ContentColumn>
    </ContentHeroContainer>
  )
}

ContentHeroCard.propTypes = propTypes

export default ContentHeroCard
