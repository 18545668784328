import React from 'react'
import { node, oneOfType, string } from 'prop-types'
import styled from 'styled-components'
import { Heading } from '../typography/headings'
import Lead from '../typography/lead'

const propTypes = {
  title: oneOfType([node, string]),
  lead: string,
}

const SectionTitle = styled(Heading).attrs({ as: 'h2', headingSize: 3 })`
  text-align: center;
`

const SectionLead = styled(Lead)`
  text-align: left;
  font-size: 1.5em;
  max-width: 620px;
`

const SectionHeader = ({ title, lead }) => (
  <>
    {title && <SectionTitle>{title}</SectionTitle>}
    {lead && <SectionLead>{lead}</SectionLead>}
  </>
)

SectionHeader.propTypes = propTypes

export default SectionHeader
