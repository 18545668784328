import React from 'react'
import styled from 'styled-components'
import { func, node, oneOfType, string } from 'prop-types'
import { getContentfulLongTextAsNode, isString } from '../../utils/'

const propTypes = {
  children: oneOfType([node, string]),
  cssOnShortLead: func,
}

const determineIsShortLead = lead => {
  if (isString(lead)) {
    return lead.length <= 280
  }
  return false
}

const LeadStyle = styled.p(
  ({ cssOnShortLead = () => '', isShortLead, theme: { colors } }) => `
  font-size: 1em;
  text-align: inherit;
  // color: ${colors.leadColor || 'inherit'} !important;

  ${isShortLead ? cssOnShortLead() : ''}

  + p {
    padding-top: .5em;
  }

  > div > p {
    margin-bottom: 20px !important;
  }

  a {
    color: inherit;
  }
`
)

const Lead = ({ children, ...rest }) => {
  if (!children) {
    return null
  }
  const leadContent = getContentfulLongTextAsNode(children)

  return (
    <LeadStyle
      as={isString(leadContent) ? undefined : 'div'}
      isShortLead={determineIsShortLead(leadContent)}
      {...rest}
    >
      {leadContent}
    </LeadStyle>
  )
}

Lead.propTypes = propTypes

export default Lead
