import styled from 'styled-components'

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;

  p:first-child {
    margin-right: ${({ theme }) => theme.spacing.medium};
  }
`
export default DateContainer
