import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { CardTable } from './table'
import TableSearch from './tableSearch'
import { tableTheme } from '../../styles/theme'
import { latestDate } from '../../utils/dates'

const Wrapper = styled.div``
const search = (item, event) => {
  const nameSearch =
    item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1
  const isinSearch =
    item.cells[1].toLowerCase().search(event.target.value.toLowerCase()) !== -1
  return nameSearch || isinSearch
}

const InvestmentsTable = ({
  data,
  showHint,
  showValuationEur,
  showEffectiveInterest,
}) => {
  const [rows, setRows] = useState(data.map(item => item.cells))
  const hint = showHint ? `Arvo ${latestDate(data)}` : null
  const filterList = event => {
    const filteredItems = data.filter(item => search(item, event))
    const filteredRows = filteredItems.map(item => item.cells)
    setRows(filteredRows)
  }

  return (
    <Wrapper>
      <ThemeProvider theme={tableTheme}>
        <CardTable
          headers={[
            'Nimi',
            'ISIN-koodi',
            'Liikkeeseenlasku',
            'Eräpäivä',
            'Arvostus %',
            showValuationEur ? 'Arvostus €' : null,
            showEffectiveInterest ? 'Tuotto p.a.' : null,
            'Katsaus',
          ].filter(x => x)}
          rows={rows}
          responsive={false}
          isSortable={true}
        >
          <TableSearch hint={hint} onChange={event => filterList(event)} />
        </CardTable>
      </ThemeProvider>
    </Wrapper>
  )
}

export default InvestmentsTable
