import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import Link from '../links/link'
import Subheading from '../typography/headings'
import BackgroundImage from '../backgroundImage'
import segments from '../../utils/segments'
import { useBreakpoints } from '../../utils/dom'
import SmallText from '../typography/smallText'
import Card from './card'
import { makeOctalNumber } from '../../utils'
import { ReactComponent as PodcastIcon } from '../../images/podcast.svg'

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

const ImageWrapper = styled.div`
  position: relative;
  height: 280px;
`

const CardContainer = styled(Card.withComponent(Link))(
  ({ theme: { radius, colors, boxShadow }, disabled }) => `
  display: block;
  flex-shrink: 2;
  padding: 0;
  min-width: 200px;
  text-align: left;
  box-shadow: ${boxShadow.md};
  border-radius: 0 0 ${radius.xxlarge} ${radius.xxlarge};
  color: ${colors.blue};
  background-color: ${colors.white};

  &:hover {
    box-shadow: ${boxShadow.md} !important;
  }

  :before {
    z-index: 11;
  }

  ${disabled && 'pointer-events: none;'}
`
)

const Content = styled.div(
  ({ theme }) => `
  padding: .75em .75em 1em;
  background: ${theme.colors.white};
  border-bottom-left-radius: ${theme.radius.xxlarge};
  border-bottom-right-radius: ${theme.radius.xxlarge};
`
)

const StyledSubHeading = styled(Subheading)`
  margin-bottom: 0;
`

const StyledSmallText = styled(SmallText)`
  font-weight: 500;
  margin-bottom: 0.75em;
`

const BlurOverlay = styled.div(
  ({ theme }) => `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  opacity: .65;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
  }

  & + div img {
    filter: blur(4px) !important;
  }
`
)

const IconTile = styled.div(
  ({
    color,
    theme: {
      spacing,
      radius: { xxlarge },
    },
  }) => `
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${spacing.xxlarge};
  height: ${spacing.xxlarge};
  background-color: ${color};
  border-radius: 0 0 ${xxlarge} 0;
`
)

const PodcastCard = ({
  data: {
    __typename,
    image,
    slug,
    previewHeading: heading,
    episodeNumber,
    publicationDate,
    isPublished,
    runTime,
  },
  ...rest
}) => {
  const episodeIsTeaser = !isPublished
  const breakpoints = useBreakpoints() || {}
  const large = !breakpoints.sm

  return (
    <CardContainer
      {...rest}
      to={`${segments[__typename]}/${slug}`}
      disabled={episodeIsTeaser}
    >
      {image && (
        <ImageWrapper>
          {episodeIsTeaser && (
            <BlurOverlay>
              <p>Julkaistaan</p>
              <p>{format(new Date(publicationDate), 'dd.MM.yyyy')}</p>
            </BlurOverlay>
          )}
          <BackgroundImage
            image={image.gatsbyImageData}
            alt={image.title || heading}
            large={large ? 'true' : null}
          />
          <IconTile color="pink">
            <PodcastIcon />
          </IconTile>
        </ImageWrapper>
      )}
      <Content>
        <FlexContainer>
          <StyledSmallText>
            Jakso {makeOctalNumber(episodeNumber)}
          </StyledSmallText>
          <StyledSmallText>{runTime}</StyledSmallText>
        </FlexContainer>
        <StyledSubHeading as="h3" headingSize="3">
          {heading}
        </StyledSubHeading>
      </Content>
    </CardContainer>
  )
}

export default PodcastCard
