import React from 'react'
import { graphql } from 'gatsby'
import ContentfulPage from '../components/contentfulPage'
import personnelCardGroups from '../components/cards/personnelCardGroups'
import NotFound from '../pages/404'

const AlexandriaPersonnelPage = ({
  data: { page },
  location: { hash = '' },
}) => {
  if (!page) {
    return <NotFound />
  }

  const { cardGroups, defaultOpenGroupIndex } = personnelCardGroups({
    page,
    hash,
  })

  return (
    <ContentfulPage
      page={page}
      cardGroups={cardGroups}
      cardGroupsToggle={{ enabled: true, defaultIndex: defaultOpenGroupIndex }}
    />
  )
}

export default AlexandriaPersonnelPage

export const query = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
  }
`
