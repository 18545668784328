import React from 'react'
import styled from 'styled-components'
import CtaButton from '../buttons/ctaButton'
import { Heading } from '../typography/headings'

import Product from './product'
import { ReactComponent as ArrowRightIcon } from '../../images/arrow-right.svg'

const Wrapper = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  max-width: ${t.width.max};
  padding: 50px 42px 0 42px;
  gap: 40px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    padding: 30px 12px 0 12px;
    gap: 0;
  }
`
)

const Title = styled(Heading)(
  ({ theme: t }) => `
  font-size: 45px;
  line-height: 50px;
  text-align: center;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    font-size: 28px;
    line-height: 33px;
  }
`
)

const Products = styled.div(
  ({ theme: t, productsAmount }) => `
  display: flex;
  flex-direction: ${productsAmount === 4 ? 'row' : 'column'};
  gap: ${productsAmount === 4 ? '30px' : '40px'};
  margin: ${productsAmount === 4 ? '0 auto' : '0'};

  @media screen and (max-width: 1260px) {
    flex-direction: row;
  }

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    gap: ${productsAmount === 4 ? '5px' : '10px'};
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${t.breakpoints.sm.max}) {
    flex-direction: column;
  }
`
)

const ProductRow = styled.div(
  ({ theme: t, productsAmount }) => `
  display: flex;
  justify-content: center;
  gap: ${productsAmount === 4 ? '30px' : '40px'};

  @media screen and (max-width: 1260px) {
    flex-direction: column;
    align-items: center;
    max-width: 50%;
  }

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    gap: ${productsAmount === 4 ? '5px' : '10px'};
  }

  @media screen and (max-width: ${t.breakpoints.sm.max}) {
    max-width: 100%;
  }

  &:nth-of-type(2) {
    @media screen and (min-width: ${
      t.breakpoints.sm.max
    }) and (max-width: 1260px) {
      justify-content: flex-start;
    }
  }
`
)

const LinkButton = styled(CtaButton)(
  ({ theme: t }) => `
  display: flex;
  height: 45px;
  padding: 0 20px;
  white-space: nowrap;
  align-items: center;
  align-self: center;
  border-radius: 80px;
  border: 2px solid ${t.colors.primaryColor};
  background-color: ${t.colors.primaryColor};
  color: ${t.colors.white} !important;
  font-size: 16px;
  font-weight: 500;
  gap: 15px;

  svg {
    min-width: 25px;
    path {
      fill: ${t.colors.white};
    }
  }

  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s;
  }
`
)

const ProductGroup = ({ productGroup }) => {
  const productsAmount = productGroup.products.length
  const sliceAmount = productsAmount === 4 ? 2 : 3

  return (
    <Wrapper>
      <Title as="h2" headingSize="3">
        {productGroup.title}
      </Title>
      <Products productsAmount={productsAmount}>
        <ProductRow productsAmount={productsAmount}>
          {productGroup.products.slice(0, sliceAmount).map((product, index) => (
            <Product
              key={index}
              product={product}
              productsAmount={productsAmount}
              index={index}
              remainder={1}
            />
          ))}
        </ProductRow>
        <ProductRow productsAmount={productsAmount}>
          {productGroup.products.slice(sliceAmount).map((product, index) => (
            <Product
              key={index}
              product={product}
              productsAmount={productsAmount}
              index={index}
              remainder={productsAmount === 4 ? 1 : 0}
            />
          ))}
        </ProductRow>
      </Products>
      {productGroup.link && (
        <LinkButton to={productGroup.link} large="true">
          {productGroup.linkText || 'Lue lisää'} <ArrowRightIcon />
        </LinkButton>
      )}
    </Wrapper>
  )
}

export default ProductGroup
