import styled from 'styled-components'

export default styled.li`
  margin: 0 0.46em;
  position: relative;

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
`
