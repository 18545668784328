import styled from 'styled-components'

export const getHeadingFontSize = size =>
  ({
    1: '4.5rem',
    2: '3.5rem',
    3: '2.5rem',
    4: '1.5rem',
    5: '1.125rem',
  }[size || 1])

export const getHeadingFontBySize = (size, headingType) =>
  ({
    subheading: {
      1: "600 1.75em/1.4 'Saol Display'",
      2: "400 1.75rem/1.4 'Scto Grotesk A'",
      3: "600 1.375em/1.4 'Saol Display'",
      4: "600 1.125em/1.4 'Saol Display'",
      5: "600 1em/1.4 'Saol Display'",
    }[parseInt(size, 10)],
    heading: `600 ${getHeadingFontSize(size)}/1.2 'Saol Display'`,
  }[headingType] || '100%')

/* NOTE: Use function below when you need to style html */
export const getHeadingStyle = (size, subheading = true) => `
  font: ${getHeadingFontBySize(size, subheading ? 'subheading' : 'heading')};
  line-height: ${subheading ? 1.4 : 1.2};
  margin-top: 0;
`

const getHeadingProps = ({ children, noCursive = false }) => {
  if (typeof children !== 'string') {
    return { children }
  }
  // capture every word that is wrapped with *
  const cursivePattern = /\*(\\.|[^*])*\*+/g

  if (!cursivePattern.test(children)) {
    const entitiesPattern = /&[a-z]+;/g
    if (entitiesPattern.test(children)) {
      // if there's html entities like &shy;, we wanna render the string as html
      return {
        dangerouslySetInnerHTML: {
          __html: children,
        },
        children: null,
      }
    } else {
      return { children }
    }
  }
  // children are a string with words that need to be wrapped within <em>s.
  // NOTE: I really suck with regex, so please make this cleaner if you can
  // Also this can be made without setting inner html by returning with children as a fragment, but it requires importing React.
  const replacePattern = noCursive ? '$&' : '<em>$&</em>'

  return {
    dangerouslySetInnerHTML: {
      __html: children
        .replace(cursivePattern, replacePattern)
        .replace(/\*/g, ''),
    },
    children: null,
  }
}

export const Heading = styled.h1.attrs(getHeadingProps)(({ headingSize = 1 }) =>
  getHeadingStyle(headingSize, false)
)
export const Subheading = styled.h1.attrs(getHeadingProps)(
  ({ headingSize = 1 }) => getHeadingStyle(headingSize, true)
)
export const resolvePropsForHeading = getHeadingProps

export default styled.p.attrs(getHeadingProps)(
  ({ headingSize = 3, isSubheading = true }) =>
    getHeadingStyle(headingSize, isSubheading)
)
