import React from 'react'
import styled from 'styled-components'
import Link from '../links/link'
import Button from '../buttons/button'
import Subheading from '../typography/headings'
import Datetime from '../format/datetime'
import DateContainer from '../DateContainer'
import BackgroundImage from '../backgroundImage'
import segments from '../../utils/segments'
import { useBreakpoints } from '../../utils/dom'
import DateText from '../typography/dateText'

const ButtonStyle = styled(Button).attrs({ as: 'p' })``

const Content = styled.div(
  ({ theme: { colors, spacing } }) => `
  padding-top: ${spacing.medium};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p:first-of-type {
    margin-bottom: 1em;
  }

  > *:not(${ButtonStyle}) {
    color: ${colors.primaryColor};
    max-width: 100%; // IE 11
  }

  ${ButtonStyle} {
    margin: auto 0 0;
  }
`
)

const ImageWrapper = styled.div`
  padding-top: 53%;
  position: relative;
  width: 100%;
  overflow: hidden;
`

const elementOverflowOnHover = '-.5em'

// NOTE: as this is a link, there's a default focus style coming with it. Use it to minimize css
const ArticleLink = styled(Link)(
  ({ theme: { colors } }) => `
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &:before {
    background: transparent;
    z-index: -1;
    transition: background .125s ease-in;
  }

  :hover,
  :active {
    &:before {
      transition-timing-function: ease-out;
    }
  }

  ${BackgroundImage} {
    transition: transform .5s ease-in;
    transform: perspective(1px);
  }

  :hover,
  :active {
    &:before {
      background: ${colors.offWhite};
    }

    ${BackgroundImage} {
      transition-timing-function: ease-out;
      transform: perspective(1px) scale(1.025);
    }
  }

  ${ButtonStyle} {
    &:before {
      content: '';
      position: absolute;
      top: 0; bottom: 0; left: 0; right: 0;
      margin: ${elementOverflowOnHover};
      background: none;
    }
  }
`
)

const StyledDatetime = styled(Datetime)(
  ({ withfontvariant }) => `
  ${!withfontvariant && 'font-variant-numeric: unset;'}
`
)

const ArticleCard = ({
  data: {
    image,
    slug,
    publicationDate: date,
    updatedAtDate,
    previewHeading: heading,
    previewBody,
    __typename,
  },
  useDateFontVariant = true,
  showLinkButton = true,
  ...rest
}) => {
  const { previewBody: body } = previewBody || {}
  const breakpoints = useBreakpoints() || {}
  const large = !breakpoints.sm
  const { title: alt, gatsbyImageData: imageData } = image

  return (
    <ArticleLink {...rest} to={`${segments[__typename]}/${slug}`}>
      <ImageWrapper>
        <BackgroundImage
          image={imageData}
          alt={alt || heading}
          large={large.toString()}
        />
      </ImageWrapper>
      {body && (
        <Content>
          <DateContainer>
            <p>
              <StyledDatetime withfontvariant={useDateFontVariant}>
                {date}
              </StyledDatetime>
            </p>
            {updatedAtDate && (
              <p>
                <DateText>(Päivitetty: {updatedAtDate})</DateText>
              </p>
            )}
          </DateContainer>
          <Subheading as="h3" headingSize="3">
            {heading}
          </Subheading>
          <p>{body}</p>
          {showLinkButton && <ButtonStyle>Lue lisää</ButtonStyle>}
        </Content>
      )}
    </ArticleLink>
  )
}

export default ArticleCard
