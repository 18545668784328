import React from 'react'
import { bool, func, node, oneOfType, shape, string } from 'prop-types'
import FieldWrapper from './fieldWrapper'
import HelpText from './helpText'
import Slider from './slider'

const propTypes = {
  label: oneOfType([func, string]).isRequired,
  field: shape({
    name: string,
    onChange: func.isRequired,
  }).isRequired,
  required: bool,
  helpText: string,
  children: node,
  className: string,
}

const SliderInput = ({
  field,
  form,
  helpText,
  label,
  required,
  children,
  className,
  ...props
}) => (
  <FieldWrapper className={className}>
    <label htmlFor={field.name}>
      {typeof label === 'string' ? label : label()}
      {!required && <sub>Vapaaehtoinen</sub>}
    </label>
    {children}
    <div>
      <Slider
        {...field}
        {...props}
        id={field.name}
        onBlur={() => {}}
        onChange={step =>
          field.onChange({ target: { name: field.name, value: step } })
        }
      />
    </div>
    {helpText && <HelpText>{helpText}</HelpText>}
  </FieldWrapper>
)

SliderInput.propTypes = propTypes

export default SliderInput
