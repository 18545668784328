import React from 'react'
import Layout from '../components/layout'
import { orangeTheme } from '../styles/theme'
import { MicrosoftClarityScript } from '../lib/ms-clarity'
import { graphql } from 'gatsby'
import { NPSLandingHero } from '../components/nps/npsLandingHero'
import { CommentStrip } from '../components/nps/commentStrip'
import { ChartStrip } from '../components/nps/chartStrip'
import CustomerExperienceSection from '../components/customerExperienceSection/customerExperienceSection'
import FeaturedArticleHighlight from '../components/featuredArticleHighlight/featuredArticleHighlight'

const NpsLanding = ({ data }) => {
  const pageData = data.contentfulNpsPage
  return (
    <Layout title={pageData.title} navbarTheme={orangeTheme}>
      <NPSLandingHero
        media={pageData.headerBackground}
        featureImage={pageData.heroFeatureImage}
      />
      <CommentStrip />
      <ChartStrip images={pageData.chartImages} />
      <CustomerExperienceSection />
      {pageData.featuredPages.map((page, index) => (
        <FeaturedArticleHighlight
          key={'featured' + index}
          featuredArticle={page}
        />
      ))}
    </Layout>
  )
}

export function Head() {
  return <MicrosoftClarityScript />
}

export default NpsLanding

export const query = graphql`
  query ($slug: String!) {
    contentfulNpsPage(slug: { eq: $slug }) {
      title
      headerBackground {
        url
      }
      heroFeatureImage {
        url
      }
      headerTitle {
        childMarkdownRemark {
          html
        }
      }
      headerBody {
        childMarkdownRemark {
          html
        }
      }
      featuredPages {
        __typename
        ... on ContentfulFeaturedArticleHighlight {
          __typename
          name
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          title
          text {
            text
          }
          linkText
          link
          displayImageOnRight
        }
      }
      chartImages {
        url
      }
    }
  }
`
