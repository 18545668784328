import React from 'react'
import styled from 'styled-components'
import Card from './card'
import CtaButton from '../buttons/ctaButton'
import GridItem from './gridItem'
import Subheading from '../typography/headings'
import Image from '../backgroundImage'
import { cardPropTypes } from '../../utils/prop-types'
import Body from '../body'

const propTypes = { data: cardPropTypes }

const Item = styled(GridItem)`
  width: 100% !important;
  max-width: 100% !important;
  padding: 20px !important;

  &:nth-child(1) {
    padding: 50px 20px 20px 20px !important;
  }

  @media (max-width: 1400px) {
    padding: 20px 10px !important;

    &:nth-child(1) {
      padding: 50px 10px 20px 10px !important;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    padding: 20px 0 !important;

    &:nth-child(1) {
      padding: 30px 0 20px 0 !important;
    }
  }
`

const StyledCtaButton = styled(CtaButton)`
  cursor: pointer;
  align-self: flex-end;
`

const StyledSubheading = styled(Subheading)`
  word-break: break-word;
`

const ImageWrap = styled.div`
  position: relative;
  margin-bottom: 1em;

  @media (max-width: 48em) {
    padding-bottom: 50%;
  }
`

const Content = styled.div(
  ({ theme }) => `
  font-size: 1rem;
  width: 100%;
  height: 100%;
  
  p {
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  
  &:last-child {
    justify-content: space-between;
  }
  
  @media (min-width: ${theme.breakpoints.md.min}) {
    margin-left: ${theme.spacing.large};
  }

  @media (min-width: ${theme.breakpoints.lg.min}) {
    margin-left: 0;
  }
`
)

const EmbedResponsive = styled.div(
  ({ theme }) => `
  position: relative;
  margin-bottom: ${theme.spacing.small};
  width: 100%;

  &:before {
    content: "";
    display: block;
  }

  @media (max-width: ${theme.breakpoints.sm.max}) {
    padding: 30%;
    margin-bottom: 1em;
  }

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: none;
  }
`
)

const VideoCardItem = styled(Card)`
  @media (min-width: 48em) {
    min-height: 342px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    ${ImageWrap},
    ${ImageWrap} + ${Content} {
      width: calc(50% - 0.75rem);
      margin-bottom: 0;
    }
    ${Content} {
      display: flex;
      flex-direction: column;
    }
    &:hover {
      box-shadow: 0 2px 8px rgba(0, 4, 80, 0.3);
      background: ${({ theme }) => theme.colors.backgroundColor};
    }
  }
`

const makeExperiencePageSlug = slug => `/kokemuksia/${slug}`

const CardContent = ({ data }) => (
  <Content>
    <StyledSubheading as="h2" headingSize="1">
      {data.previewHeading}
    </StyledSubheading>
    <p style={{ whiteSpace: 'pre-line' }}>{data.description.description}</p>
    <p style={{ fontWeight: 'bold', whiteSpace: 'pre-line' }}>
      {data.previewBody.previewBody}
    </p>
    <StyledCtaButton to={makeExperiencePageSlug(data.slug)}>
      Näytä lisää
    </StyledCtaButton>
  </Content>
)

const PreviewItem = ({ data }) => {
  return (
    <>
      {data.previewVideo ? (
        <EmbedResponsive>
          <Body
            dangerouslySetInnerHTML={{ __html: data.previewVideo.previewVideo }}
          />
        </EmbedResponsive>
      ) : (
        <ImageWrap>
          <Image fluid={data.image.fluid} alt={data.image.title} />
        </ImageWrap>
      )}
      <CardContent data={data} />
    </>
  )
}

const VideoPreviewCard = ({ data }) => {
  return (
    <Item key={data.id}>
      <VideoCardItem>
        <PreviewItem data={data} />
      </VideoCardItem>
    </Item>
  )
}

VideoPreviewCard.propTypes = propTypes

export default VideoPreviewCard
