import React, { useState, useEffect } from 'react'
import Flow from './flow'

const FlowStep2View2 = ({
  step,
  selectedOptions,
  onSelectedFlowOptionsChange,
  previousSteps,
  setPreviousSteps,
  currentStep,
  setCurrentStep,
}) => {
  const [buttonStates, setButtonStates] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const updateButtonStates = options => {
    const updatedStates = [...buttonStates]

    options.forEach(option => {
      if (option >= 0 && option < updatedStates.length) {
        updatedStates[option] = true
      }
    })

    setButtonStates(updatedStates)
  }

  useEffect(() => {
    selectedOptions && updateButtonStates(selectedOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  const flowButtons = [
    {
      title: '0-5',
      text: step.buttonTexts[0],
    },
    {
      title: '5-10',
      text: step.buttonTexts[1],
    },
    {
      title: '10+',
      text: step.buttonTexts[2],
    },
  ]

  return (
    <Flow
      heading={step.heading}
      flowButtons={flowButtons}
      buttonStates={buttonStates}
      setButtonStates={setButtonStates}
      onSelectedFlowOptionsChange={onSelectedFlowOptionsChange}
      previousSteps={previousSteps}
      setPreviousSteps={setPreviousSteps}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      step2view2={true}
    />
  )
}

export default FlowStep2View2
