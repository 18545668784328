import styled from 'styled-components'

export default styled.div`
  padding-top: ${({ theme }) => theme.spacing.small};

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: ${({ theme }) => theme.spacing.large};
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }

  ul {
    padding-inline-start: 2rem;
    list-style-type: revert;
  }

  ul:first-child {
    padding-top: 0.75em;
  }

  li {
    list-style-type: revert;
    padding-bottom: 0.75em;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
`
