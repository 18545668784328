import React from 'react'
import { Formik, Form, Field } from 'formik'
import styled from 'styled-components'
import { TextInput } from '../formInputs'
import HintText from '../typography/hintText'

const Wrapper = styled.div`
  align-items: center;
  border-bottom: 0;
  padding: ${({ theme }) => theme.spacing.small} 0 0;
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  @media (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    input {
      margin-bottom: ${({ theme }) => theme.spacing.small};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm.min}) {
    display: flex;
    justify-content: space-between;

    input {
      width: 320px;
    }
  }
`

const RightSideText = styled(HintText).attrs({ as: 'p' })`
  text-align: right;
`

const TableSearch = ({ hint, onChange }) => (
  <Wrapper>
    <Formik initialValues={{ query: '' }}>
      {({ values, handleChange }) => {
        const handleQueryChange = event => {
          onChange(event)
          handleChange(event)
        }

        return (
          <Form>
            <Field
              placeholder="Hakusana"
              type="text"
              name="query"
              required
              value={values.query}
              onChange={handleQueryChange}
              component={TextInput}
            />
          </Form>
        )
      }}
    </Formik>
    {hint && <RightSideText>{hint}</RightSideText>}
  </Wrapper>
)

export default TableSearch
