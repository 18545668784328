import React, { useState } from 'react'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/contentfulPage'
import ArticleCard from '../components/cards/articleCard'
import LoadMoreButton from '../components/cards/loadMoreButton'

const Briefings = ({
  data: {
    page,
    articles: { edges },
  },
}) => {
  const articlesData = edges.map(({ node }) => node)
  const [itemCount, setItemCount] = useState(8)
  const items = articlesData.slice(0, itemCount)

  return (
    <ContentfulPage
      page={page}
      cardGroups={[
        {
          items: items.map(item => <ArticleCard data={item} />),
          itemsPerRow: 3,
          footer: (
            <LoadMoreButton
              currentCount={itemCount}
              updateItemCount={setItemCount}
              totalItemCount={articlesData.length}
            />
          ),
        },
      ]}
    />
  )
}

export default Briefings

export const query = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
    articles: allContentfulArticle(
      filter: {
        category: { eq: "Tiedotteet" }
        slug: { ne: "laajemmat-tiedot-yrityslainoista" }
      }
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          __typename
          slug
          title
          description {
            description
          }
          publicationDate(formatString: "DD.MM.YYYY")
          image {
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
        }
      }
    }
  }
`
