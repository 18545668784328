import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { tableTheme } from '../../styles/theme'
import { CardForTable } from './table'

const Table = styled.table`
  border: none;
  box-shadow: none;

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyMedium};

    :last-child {
      border-bottom: none;
    }
  }

  td {
    padding-left: 0;
    vertical-align: top;

    :last-child {
      padding-right: 0;
    }
  }
`

const DetailsTable = ({ tbody, colgroup }) => (
  <ThemeProvider theme={tableTheme}>
    <CardForTable>
      <Table>
        {colgroup || null}
        <tbody>{tbody}</tbody>
      </Table>
    </CardForTable>
  </ThemeProvider>
)

export default DetailsTable
