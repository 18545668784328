import React from 'react'
import styled from 'styled-components'
import Button from './button'
import { ReactComponent as ArrowIcon } from '../../images/arrow-white.svg'

const UpArrowIcon = styled(ArrowIcon)`
  transform: rotate(270deg);
  width: 48px;
  height: 48px;
  margin: 0;
`

// prettier-ignore
const StyledButton = styled(Button)(({ theme }) => `
  display: flex;
  align-items: center;
  padding: 0;
  background-color: ${theme.colors.primaryColor};
  border: 1px solid ${theme.colors.white} !important;
  color: ${theme.colors.backgroundColor};
  width: 100%;
  transition: all ${theme.transition.duration} ${theme.transition.timingFunction};

  p {
    transition: opacity ${theme.transition.duration} ${theme.transition.timingFunction};
    opacity: 0;
    width: 0;
    overflow: hidden;
    line-height: 1;
  }

  @media (min-width: ${theme.breakpoints.md.max}) {
    &:hover {
      width: 165px;
      p {
        opacity: 1;
        width: auto;
      }
    }
  }
`
)

const UpArrowButton = ({ onClick, label }) => {
  return (
    <StyledButton onClick={onClick}>
      <UpArrowIcon />
      <p>{label}</p>
    </StyledButton>
  )
}

export default UpArrowButton
