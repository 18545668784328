import React from 'react'
import styled from 'styled-components'
import { lightenBackground } from '../../utils'

import { ReactComponent as Coffee } from '../../images/coffee-black.svg'
import { ReactComponent as Crown } from '../../images/crown-black.svg'
import { ReactComponent as Diamond } from '../../images/diamond-black.svg'

const Wrapper = styled.div(
  ({ theme: t }) => `
  width: 100%;
  height: auto;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    height: auto;
  }
`
)

const Content = styled.div(
  ({ theme: t }) => `
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
  padding: 40px 60px 0 60px;
  justify-content: center;
  gap: 50px;
  text-align: center;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    flex-direction: column;
    align-items: center;
    padding: 30px 20px 0 20px;
    gap: 10px;
  }
`
)

const InfoBox = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 326px;
  align-items: center;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    width: 100%;
    gap: 15px;
  }
`
)

const Icon = styled.div(
  ({ theme: { colors }, index }) => `
  display: flex;
  width: 90px;
  min-height: 90px;
  max-height: 90px;
  background-color:
    ${
      index === 0
        ? lightenBackground(colors.orange, 0.7)
        : index === 1
        ? lightenBackground(colors.pinkLight, 0.5)
        : lightenBackground(colors.blueLight, 0.6)
    };
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`
)

const Title = styled.h3(
  ({ theme: t }) => `
  font-size: 28px;
  line-height: 33px;
  margin: 0;

  @media screen and (max-width: ${t.breakpoints.md.min}) {
    font-size: 22px;
    line-height: 26px;
  }
`
)

const Text = styled.div(
  ({ theme: t }) => `
  font-size: 16px;
  line-height: 20px;
  
  @media screen and (max-width: ${t.breakpoints.md.min}) {
    font-size: 14px;
    line-height: 17px;
  }
`
)

const infoBoxes = [
  {
    title: 'Rikasta elämää',
    text: 'Sijoittaminen on kiinnostavaa, mutta rikas elämä on kiinnostavampaa. Tarjoamme avuksesi henkilökohtaisen sijoitusneuvojan, joka pitää sinut ajan tasalla ja tekee sijoittamisesta sinulle helppoa sekä ymmärrettävää.',
    icon: Crown,
  },
  {
    title: 'Henkilökohtainen palvelu',
    text: 'Palvelemme aina henkilökohtaisesti, silloin kun sinulle sopii, joko kasvotusten, puhelimessa tai verkon välityksellä. Keskustelu kanssamme on aina maksuton eikä velvoita sinua mihinkään.',
    icon: Coffee,
  },
  {
    title: 'Palkitut ja menestyneet sijoitustuotteet',
    text: 'Tarjoamme palkitut, erinomaisella tuotto-riski-suhteella varustetut sijoitustuotteet. Aina ajan tasalla oleva valikoimamme auttaa muodostamaan juuri sinun tavoitteisiisi sopivan kokonaisuuden.',
    icon: Diamond,
  },
]

const InfoBar = () => {
  return (
    <Wrapper>
      <Content>
        {infoBoxes.map((infoBox, index) => (
          <InfoBox key={index}>
            <Icon index={index}>
              <infoBox.icon />
            </Icon>
            <Title>{infoBox.title}</Title>
            <Text>{infoBox.text}</Text>
          </InfoBox>
        ))}
      </Content>
    </Wrapper>
  )
}

export default InfoBar
