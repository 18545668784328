import React from 'react'
import Body from './body'
import Accordion from './accordion/accordion'
import AccordionTableContent from './accordion/accordionTableContent'
import DetailsTable from './tables/detailsTable'
import DetailsTableListItem from './tables/detailsTableListItem'
import ContentfulAsset from './contentfulAsset'
import StyledLink from './links/linkItemStyle'

const StructuredInvestmentDetails = ({ data }) => {
  // need to check for date existence since optional date fields cannot handle formatString in query
  const issueDate = data.issueDate
    ? new Date(data.issueDate).toLocaleDateString('fi-FI')
    : null
  const expirationDate = data.expirationDate
    ? new Date(data.expirationDate).toLocaleDateString('fi-FI')
    : null
  const overviewLink =
    data.overviewLink || (data.overview && data.overview.file.url)

  return (
    <Body>
      <Accordion
        openIdx={0}
        sections={[
          {
            title: 'Dokumentit',
            data: (
              <AccordionTableContent>
                <DetailsTable
                  tbody={
                    <tr>
                      <td>
                        {data.documents &&
                          data.documents.map(document => (
                            <DetailsTableListItem key={document.title}>
                              <ContentfulAsset
                                data={document}
                                title={document.description}
                              />
                            </DetailsTableListItem>
                          ))}
                      </td>
                    </tr>
                  }
                />
              </AccordionTableContent>
            ),
          },
          {
            title: 'Perustiedot',
            data: (
              <AccordionTableContent>
                <DetailsTable
                  tbody={
                    <>
                      <tr>
                        <td>Nimi</td>
                        <td>{data.name}</td>
                      </tr>
                      <tr>
                        <td>Liikkeeseenlaskija</td>
                        <td>{data.issuer}</td>
                      </tr>
                      <tr>
                        <td>ISIN</td>
                        <td>{data.isin}</td>
                      </tr>
                      <tr>
                        <td>Liikkeeseenlaskupäivä</td>
                        <td>{issueDate}</td>
                      </tr>
                      <tr>
                        <td>Eräpäivä</td>
                        <td>{expirationDate}</td>
                      </tr>
                      {overviewLink && (
                        <tr>
                          <td>Katsaus</td>
                          <td>
                            <StyledLink to={overviewLink}>
                              Tuotteen katsaus
                            </StyledLink>
                          </td>
                        </tr>
                      )}
                    </>
                  }
                />
              </AccordionTableContent>
            ),
          },
        ]}
      />
    </Body>
  )
}

export default StructuredInvestmentDetails
