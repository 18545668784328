import styled from 'styled-components'
import { getHeadingFontBySize } from '../components/typography/headings'
import links from '../styles/mixins/links'
import medium from '../styles/mixins/medium'
import SvgPlusUrl from '../images/plus.svg'

export default styled.div`
  font-size: 1em;
  text-align: left;
  ${links};

  ${({
    theme: { lineHeight, colors, breakpoints, width },
    alignTo = 'center',
  }) => `
    max-width: 100%;
    width: 100%;
    line-height: ${lineHeight.normal};
    color: ${colors.primaryColor};
    margin-left: ${
      !alignTo || alignTo === 'center'
        ? 'auto'
        : `${alignTo === 'left' ? '0' : 'auto'}`
    };
    margin-right: ${
      !alignTo || alignTo === 'center'
        ? 'auto'
        : `${alignTo === 'left' ? 'auto' : '0'}`
    };

    h2 {
      font: ${getHeadingFontBySize(1, 'subheading')};
    }

    h3 {
      font: ${getHeadingFontBySize(3, 'subheading')};
    }

    ul,
    ol {
      padding: .5em 0 1px 2em;
      margin: 0;
    }

    img + em {
      font-size: 75%;
      font-weight: 500;
      font-style: normal;
      color: ${colors.grey};
    }

    ul {
      list-style: outside disc;
    }

    li {
      margin: 0 0 25px;
      list-style: inherit;
    }

    blockquote {
      ${medium};
      padding: calc(1em + 2%) 0 0;
      margin: 0 0 1.5em;
      font-size: 1.5rem;
      display: flex;
      align-items: flex-start;

      &:before {
        content: '\\201D';
        margin: 0 .5em 0 .125em;
        font: ${getHeadingFontBySize(1, 'heading')};
      }
    }

    strong {
      margin-bottom: ${lineHeight.normal}em;
      display: block;
    }
    
    li > strong {
      margin-bottom: ${lineHeight.normal}em;
      display: initial;
    }

    p > strong {
      margin-bottom: 0;
      display: initial;
    }

    .button {
      display: flex;
    }

    .pdl-list,
    .ksp-list {
      list-style: none;
      padding-left: 2em;

      li {
        font-size: 1em;
        position: relative;
        margin: 0 0 3em 1em;

        &:before {
          display: inline-block;
          width: 2em;
          position: absolute;
          left: -3em;
          speak: none;
        }

        h3 {
          margin-bottom: .25em;
        }
      }
    }

    .ksp-list {
      li {
        &:before {
          content: url('${SvgPlusUrl}');
        }
      }
    }

    .pdl-list {
      counter-reset: pdl-list;

      li {
        counter-increment: pdl-list;

        &:before {
          content: counter(pdl-list);
          border-radius: 50%;
          border: 1px solid currentColor;
          display: flex;
          align-items: center;
          height: 2em;
          justify-content: center;
        }
      }
    }

    table {
      width: 100%;
      border-spacing: 0;
      padding: .25rem .75rem .75rem;
      background: ${colors.offWhite};
      border-radius: 1rem;
      font-size: .888em;
      margin: 0 0 1.5rem;

      th:empty {
        display: none;
      }

      th {
        padding-top: .5rem;
        padding-bottom: .5rem;
        vertical-align: top;

        &:not(:first-of-type) {
          padding-left: .5rem;
        }
      }

      td {
        padding-top: .5rem;
        padding-bottom: .5rem;
        vertical-align: top;

        &:first-of-type {
          font-weight: 500;
        }

        &:not(:first-of-type) {
          padding-left: .5rem;
        }
      }
    }
  `}
`
