import React from 'react'
import styled, { ThemeProvider, css as _css } from 'styled-components'
import BackgroundImage from '../backgroundImage'
import ContentfulRichText from '../contentfulRichText'
import blueTheme, {
  invertedTheme,
  subtleTheme,
  pinkTheme,
  orangeTheme,
  lightTheme,
} from '../../styles/theme'
import Container from '../container'
import CTAButton from '../buttons/ctaButton'

const CardWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  padding-top: ${({ theme }) => theme.spacing.xxlarge};
  padding-bottom: ${({ theme }) => theme.spacing.xxlarge};

  &[data-bg-color='Blue on white'] + [data-bg-color='Blue on white'] {
    padding-top: 0;
  }
`

const StyledContainer = styled(Container)`
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  ${({ columns }) => columns === 2 && 'min-height: 560px;'};
  gap: ${({ theme }) => theme.spacing.xxlarge};

  ${CTAButton} {
    display: block;
    width: fit-content;
    margin-top: ${({ theme }) => theme.spacing.xxlarge};
    margin-left: auto;
    margin-right: auto;
  }

  > div:nth-child(2) {
    grid-row: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg.min}) {
    grid-template-rows: 1fr;
    grid-template-columns: ${({ columns }) => '1fr '.repeat(columns).trim()};

    > div:nth-child(2) {
      grid-column: ${({ imagePosition }) =>
        imagePosition === 'Left side' ? 1 : 2};
    }

    ${({ columns }) =>
      columns === 1 &&
      _css`
        > div {
          > div {
            h2 {
              text-align: center;
            }
          }
        }
      `};
  }
`

const ImageWrap = styled.div`
  position: relative;
  padding-bottom: 50%;
  margin-bottom: 1em;
`

const colorToTheme = {
  'Blue on white': blueTheme,
  'White on blue': invertedTheme,
  Subtle: subtleTheme,
  Pink: pinkTheme,
  Orange: orangeTheme,
  Light: lightTheme,
}

const IPOCard = ({
  ctaText,
  ctaTarget,
  backgroundColor,
  bodyText,
  image,
  imagePosition,
}) => {
  const theme = colorToTheme[backgroundColor] || blueTheme
  const imagePos = imagePosition?.trim() || 'Left side'

  return (
    <ThemeProvider theme={theme}>
      <CardWrapper data-bg-color={backgroundColor}>
        <StyledContainer columns={image ? 2 : 1} imagePosition={imagePos}>
          <div>
            <ContentfulRichText richText={bodyText}></ContentfulRichText>
            {ctaText && <CTAButton to={ctaTarget || '#'}>{ctaText}</CTAButton>}
          </div>
          {image && (
            <ImageWrap>
              <BackgroundImage
                objectFit="cover"
                image={image.gatsbyImageData}
                alt={image.title || ''}
              />
            </ImageWrap>
          )}
        </StyledContainer>
      </CardWrapper>
    </ThemeProvider>
  )
}

export default IPOCard
