exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-alexandria-kokemuksia-js": () => import("./../../../src/templates/alexandria-kokemuksia.js" /* webpackChunkName: "component---src-templates-alexandria-kokemuksia-js" */),
  "component---src-templates-alexandrialaiset-js": () => import("./../../../src/templates/alexandrialaiset.js" /* webpackChunkName: "component---src-templates-alexandrialaiset-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-asset-management-page-js": () => import("./../../../src/templates/asset-management-page.js" /* webpackChunkName: "component---src-templates-asset-management-page-js" */),
  "component---src-templates-avoimet-tyopaikat-js": () => import("./../../../src/templates/avoimet-tyopaikat.js" /* webpackChunkName: "component---src-templates-avoimet-tyopaikat-js" */),
  "component---src-templates-campaign-page-js": () => import("./../../../src/templates/campaign-page.js" /* webpackChunkName: "component---src-templates-campaign-page-js" */),
  "component---src-templates-fund-page-js": () => import("./../../../src/templates/fund-page.js" /* webpackChunkName: "component---src-templates-fund-page-js" */),
  "component---src-templates-insurance-js": () => import("./../../../src/templates/insurance.js" /* webpackChunkName: "component---src-templates-insurance-js" */),
  "component---src-templates-ipo-marketing-page-js": () => import("./../../../src/templates/ipo-marketing-page.js" /* webpackChunkName: "component---src-templates-ipo-marketing-page-js" */),
  "component---src-templates-jata-yhteydenottopyynto-js": () => import("./../../../src/templates/jata-yhteydenottopyynto.js" /* webpackChunkName: "component---src-templates-jata-yhteydenottopyynto-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-nps-data-js": () => import("./../../../src/templates/nps-data.js" /* webpackChunkName: "component---src-templates-nps-data-js" */),
  "component---src-templates-nps-landing-js": () => import("./../../../src/templates/nps-landing.js" /* webpackChunkName: "component---src-templates-nps-landing-js" */),
  "component---src-templates-office-page-js": () => import("./../../../src/templates/office-page.js" /* webpackChunkName: "component---src-templates-office-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-with-subnavigation-js": () => import("./../../../src/templates/page-with-subnavigation.js" /* webpackChunkName: "component---src-templates-page-with-subnavigation-js" */),
  "component---src-templates-podcast-episode-js": () => import("./../../../src/templates/podcastEpisode.js" /* webpackChunkName: "component---src-templates-podcast-episode-js" */),
  "component---src-templates-rahastot-tanaan-js": () => import("./../../../src/templates/rahastot-tanaan.js" /* webpackChunkName: "component---src-templates-rahastot-tanaan-js" */),
  "component---src-templates-rikasta-elamaa-js": () => import("./../../../src/templates/rikasta-elamaa.js" /* webpackChunkName: "component---src-templates-rikasta-elamaa-js" */),
  "component---src-templates-sisallot-js": () => import("./../../../src/templates/sisallot.js" /* webpackChunkName: "component---src-templates-sisallot-js" */),
  "component---src-templates-structured-investment-js": () => import("./../../../src/templates/structured-investment.js" /* webpackChunkName: "component---src-templates-structured-investment-js" */),
  "component---src-templates-strukturoidut-sijoitukset-eraantyneet-strukturoidut-sijoitukset-js": () => import("./../../../src/templates/strukturoidut-sijoitukset/eraantyneet-strukturoidut-sijoitukset.js" /* webpackChunkName: "component---src-templates-strukturoidut-sijoitukset-eraantyneet-strukturoidut-sijoitukset-js" */),
  "component---src-templates-strukturoidut-sijoitukset-strukturoitujen-sijoitusten-arvostukset-js": () => import("./../../../src/templates/strukturoidut-sijoitukset/strukturoitujen-sijoitusten-arvostukset.js" /* webpackChunkName: "component---src-templates-strukturoidut-sijoitukset-strukturoitujen-sijoitusten-arvostukset-js" */),
  "component---src-templates-teemme-sijoittamisesta-sinulle-helppoa-js": () => import("./../../../src/templates/teemme-sijoittamisesta-sinulle-helppoa.js" /* webpackChunkName: "component---src-templates-teemme-sijoittamisesta-sinulle-helppoa-js" */),
  "component---src-templates-tiedotteet-js": () => import("./../../../src/templates/tiedotteet.js" /* webpackChunkName: "component---src-templates-tiedotteet-js" */),
  "component---src-templates-tilaa-uutiskirje-js": () => import("./../../../src/templates/tilaa-uutiskirje.js" /* webpackChunkName: "component---src-templates-tilaa-uutiskirje-js" */),
  "component---src-templates-toimipaikat-ja-yhteystiedot-js": () => import("./../../../src/templates/toimipaikat-ja-yhteystiedot.js" /* webpackChunkName: "component---src-templates-toimipaikat-ja-yhteystiedot-js" */),
  "component---src-templates-tuottolaskuri-js": () => import("./../../../src/templates/tuottolaskuri.js" /* webpackChunkName: "component---src-templates-tuottolaskuri-js" */),
  "component---src-templates-yrityslainat-js": () => import("./../../../src/templates/yrityslainat.js" /* webpackChunkName: "component---src-templates-yrityslainat-js" */),
  "component---src-templates-yritysrahoitus-js": () => import("./../../../src/templates/yritysrahoitus.js" /* webpackChunkName: "component---src-templates-yritysrahoitus-js" */)
}

