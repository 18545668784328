import { css } from 'styled-components'

const reverseDir = direction =>
  ({
    top: 'bottom',
    bottom: 'top',
  }[direction])

export default css`
  ${({ size = '4px', sortingDir = 'bottom' }) => `
    margin-left: ${size};
    border: ${size} solid transparent;
    border-${sortingDir}: none;
    border-${reverseDir(sortingDir)}-color: currentColor;
  `}
`
