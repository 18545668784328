import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { shape, string, number } from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import YouTube from 'react-youtube'
import { ReactComponent as PodcastIcon } from '../images/podcast.svg'
import { ReactComponent as LeftIcon } from '../images/left-small.svg'
import { ReactComponent as VideoIcon } from '../images/videoPlayButton.svg'
import { imagePropTypes } from '../utils/prop-types'
import { makeOctalNumber } from '../utils'
import { getHeroTheme } from '../utils/segments'
import Layout from '../components/layout'
import Body from '../components/body'
import Container from '../components/container'
import Spacer from '../components/spacer'
import Image from '../components/backgroundImage'
import ContentShare from '../components/contentShare'
import ContentPageHero from '../components/heroes/contentPageHero'
import { Heading } from '../components/typography/headings'
import SmallText from '../components/typography/smallText'
import CTAButton from '../components/buttons/ctaButton'
import BackButton from '../components/buttons/backButton'
import EmbedResponsive from '../components/articlePages/embedResponsive'
import ImageFooter from '../components/articlePages/imageFooter'
import Tags from '../components/articlePages/tags'
import NextEpisodeCard from '../components/cards/podcastCard'
import ReservationContainer from '../components/reservationContainer/reservationContainer'

const propTypes = {
  data: shape({
    episode: shape({
      title: string,
      slug: string,
      heading: string.isRequired,
      previewHeading: string,
      category: string,
      episodeNumber: number,
      seasonNumber: number,
      previewBody: shape({ previewBody: string }),
      videoEmbed: shape({ videoEmbed: string }),
      image: imagePropTypes,
      thumbnailImage: imagePropTypes,
      publicationDate: string,
      contentType: string,
    }).isRequired,
  }).isRequired,
}

const StyledBackButton = styled(BackButton)(
  ({ theme }) => `
  position: absolute;
  top: -3em;
  left: ${theme.spacing.small};
  
  @media (max-width: ${theme.breakpoints.md.max}) {
    top: -2.5em;
    left: 16px;
  }
`
)

const CustomPlayButton = styled.button`
  width: 100% !important;
  height: 100% !important;
  display: block;
  padding: 0;
  z-index: 10;
  position: absolute;

  &,
  svg {
    transition: all 0.125s ease-in;
    width: 5rem;
  }

  &:hover {
    transform: scale(1.1);
  }
`

const ThumbnailImageWrap = styled.div(
  ({ theme }) => `
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: calc(-3em - 200px);
  padding-bottom: 56.25%;
  z-index: 0;
  justify-content: center;

  @media (max-width: ${theme.breakpoints.sm.max}) {
    margin-top: calc(-200px);
    width: 100%;
  }
`
)

const OuterWrapper = styled(Container)`
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg.min}) {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
`

const TextColumnWrapper = styled.div(
  ({ theme: { width, spacing, breakpoints }, hasAside }) => `
  flex-grow: 1;
  max-width: 860px;
  margin: ${spacing.xxlarge} auto 0;
  z-index: 10;

  @media (min-width: ${breakpoints.lg.min}) {
    margin: ${hasAside ? '0 0 0 auto' : '0 auto'};
  }
`
)

const ThumbnailWrapper = styled.div`
  display: ${props => (props.showThumbnail ? 'block' : 'none')};
`

const StyledEmbedResponsive = styled(EmbedResponsive)`
  display: ${props => (props.showVideo ? 'block' : 'none')};
`

const ArticleContainer = styled(Container).attrs({ containerWidth: 'content' })`
  padding: 0;
`

const PodcastDetails = styled.div(
  ({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
`
)

const BodyContainer = styled.div(
  ({ theme, hasAsideCard }) => `
  order: 1;
  margin-bottom: 0;

  ul {
    margin: 0 0 2em 2em;
    li {
      list-style: outside disc;
      margin-bottom: 1em;
    }
  }

  @media (min-width: ${theme.breakpoints.md.min}) {
    ${
      hasAsideCard &&
      `
      width: 64%;
      padding-right: ${theme.spacing.medium};
    `
    }
  }
`
)

const NextEpisodeCardContainer = styled.div(
  ({ theme }) => `
  order: 2;
  width: 36%;
  text-align: center;

  ${CTAButton} {
    margin-top: ${theme.spacing.medium};
  }

  @media (max-width: ${theme.breakpoints.sm.max}) {
    order: 3;
    width: 100%;
    margin-bottom: ${theme.spacing.xxlarge};
  }
`
)

const SpotifyEmbed = styled.div(
  ({ theme }) => `
  order: 3;
  width: 100%;
  margin: ${theme.spacing.xxlarge} 0;
  
  @media (max-width: ${theme.breakpoints.sm.max}) {
    order: 2;
    margin: ${theme.spacing.large} 0;
  }
  
  iframe {
    height: 152px;
    width: 100%;
  }
`
)

const Play = ({ handleClick }) => {
  return (
    <CustomPlayButton onClick={handleClick}>
      <VideoIcon />
    </CustomPlayButton>
  )
}

const Video = ({ videoID, thumbnailImage }) => {
  const [showThumbnail, setShowThumbnail] = useState(true)
  const [showVideoEmbed, setShowVideoEmbed] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [videoElement, setVideoElement] = useState(null)

  useEffect(() => {
    if (videoElement) {
      isPlaying
        ? videoElement.target.playVideo()
        : videoElement.target.pauseVideo()
    }
  }, [isPlaying, videoElement])

  const stopVideo = e => {
    const videoEnded = 0 // comes from Youtube API
    const playerState = e.target.playerInfo.playerState
    if (playerState === videoEnded) {
      setShowThumbnail(true)
      setShowVideoEmbed(false)
      setIsPlaying(!isPlaying)
    }
  }

  const togglePlay = () => {
    videoElement.target.playVideo()
    setIsPlaying(!isPlaying)
    setShowThumbnail(false)
    setShowVideoEmbed(true)
  }

  const onReady = event => {
    setVideoElement(event)
  }

  return (
    <>
      {thumbnailImage && (
        <ThumbnailWrapper showThumbnail={showThumbnail}>
          <ThumbnailImageWrap>
            <Image
              image={thumbnailImage.gatsbyImageData}
              alt={thumbnailImage.title}
            />
            <Play handleClick={togglePlay} />
          </ThumbnailImageWrap>
        </ThumbnailWrapper>
      )}
      <StyledEmbedResponsive showVideo={thumbnailImage ? showVideoEmbed : true}>
        <YouTube
          videoId={videoID}
          containerClassName="embed embed-youtube"
          onStateChange={e => stopVideo(e)}
          onReady={onReady}
        />
      </StyledEmbedResponsive>
    </>
  )
}

const PodcastEpisode = ({
  data: {
    episode: {
      metadata: { tags },
      title,
      description: { description },
      episodeNumber,
      runTime,
      body,
      heading,
      image,
      contentType,
      videoEmbed,
      thumbnailImage,
      spotifyEmbed,
      previewHeading,
      featuredArticle,
      cards,
    },
    nextEpisode,
    sortedArticles,
  },
}) => {
  const bodyHtml = body?.childMarkdownRemark?.html ?? ''

  /* eslint-disable no-useless-escape */
  const getYoutubeVideoId = video => {
    const regexPattern = /<iframe\s+.*?\s+src=(".*?").*?<\/iframe>/
    const trim = video.match(regexPattern)
    const youtubeUrl = trim[1].replace(/(^"|"$)/g, '')
    return youtubeUrl.replace(/(.*)embed\//, '')
  }

  return (
    <Layout
      {...{ title, previewHeading, description }}
      image={`https:${image.file.url}`}
      hero={
        <ThemeProvider theme={getHeroTheme('pink')}>
          <ContentPageHero contentType={contentType} />
        </ThemeProvider>
      }
      containerWidth="content"
    >
      <OuterWrapper navigate={true}>
        <StyledBackButton to="/sisallot/rikasta-elamaa">
          <LeftIcon />
          Rikasta elämää -podcast
        </StyledBackButton>
        <TextColumnWrapper hasAside={false}>
          <Video
            videoID={getYoutubeVideoId(videoEmbed?.videoEmbed)}
            thumbnailImage={thumbnailImage}
          />
          <ImageFooter>
            <SmallText>
              <PodcastIcon />
              Jakso {makeOctalNumber(episodeNumber)}
            </SmallText>
            <SmallText>{runTime}</SmallText>
          </ImageFooter>
          <Heading headingSize="3">{heading}</Heading>
          <ArticleContainer>
            <PodcastDetails>
              <BodyContainer hasAsideCard={!!nextEpisode}>
                <Body dangerouslySetInnerHTML={{ __html: bodyHtml }} />
              </BodyContainer>
              {nextEpisode && (
                <NextEpisodeCardContainer>
                  <NextEpisodeCard data={nextEpisode} />
                  <CTAButton to="/sisallot/rikasta-elamaa">
                    Kaikki jaksot
                  </CTAButton>
                </NextEpisodeCardContainer>
              )}
              {spotifyEmbed && (
                <SpotifyEmbed
                  dangerouslySetInnerHTML={{
                    __html: spotifyEmbed.spotifyEmbed,
                  }}
                />
              )}
            </PodcastDetails>
            <Tags contentType={contentType} tags={tags} />
            <ContentShare />
            <Spacer height="large" />
            <ReservationContainer />
          </ArticleContainer>
        </TextColumnWrapper>
      </OuterWrapper>
    </Layout>
  )
}

PodcastEpisode.propTypes = propTypes

export default PodcastEpisode

export const query = graphql`
  fragment contentfulPodcastInformation on ContentfulPodcastEpisode {
    __typename
    metadata {
      tags {
        name
      }
    }
    title
    description {
      description
    }
    slug
    category
    episodeNumber
    seasonNumber
    runTime
    isPublished
    contentType
    heading
    videoEmbed {
      videoEmbed
    }
    thumbnailImage {
      title
      file {
        url
      }
      gatsbyImageData(width: 768, placeholder: BLURRED)
    }
    spotifyEmbed {
      spotifyEmbed
    }
    body {
      childMarkdownRemark {
        html
      }
    }
    publicationDate
    image {
      title
      file {
        url
      }
      gatsbyImageData(width: 768, placeholder: BLURRED)
    }
    previewBody {
      previewBody
    }
    previewHeading
    featuredArticle {
      ... on Node {
        ... on ContentfulArticle {
          __typename
          id
          slug
          category
          contentType
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewHeroHeading
          previewBody {
            previewBody
          }
          publicationDate
        }
      }
    }
    cards {
      __typename
      ... on Node {
        ... on ContentfulFund {
          __typename
          id
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          riskRating
          previewBody {
            previewBody
          }
        }
        ... on ContentfulInsurance {
          __typename
          id
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
        }
        ... on ContentfulStructuredInvestment {
          __typename
          id
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
        }
        ... on ContentfulPage {
          __typename
          id
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
        }
      }
    }
  }

  query (
    $slug: String!
    $nextEpisodeNumber: Int
    $nextEpisodeSeasonNumber: Int
  ) {
    episode: contentfulPodcastEpisode(slug: { eq: $slug }) {
      ...contentfulPodcastInformation
    }
    nextEpisode: contentfulPodcastEpisode(
      episodeNumber: { eq: $nextEpisodeNumber }
      seasonNumber: { eq: $nextEpisodeSeasonNumber }
    ) {
      __typename
      slug
      image {
        title
        file {
          url
        }
        gatsbyImageData(width: 768, placeholder: BLURRED)
      }
      previewHeading
      previewBody {
        previewBody
      }
      episodeNumber
      publicationDate
      isPublished
      runTime
    }
    sortedArticles: allContentfulArticle(
      sort: { fields: [publicationDate], order: DESC }
      filter: { category: { ne: "Tiedotteet" } }
      limit: 1
    ) {
      edges {
        node {
          __typename
          id
          slug
          category
          contentType
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewHeroHeading
          previewBody {
            previewBody
          }
          publicationDate
        }
      }
    }
  }
`
