import styled from 'styled-components'

import Card from './card'

export default styled(Card)(
  ({ theme, minwidth }) => `
  @media (min-width: ${theme.breakpoints.lg.min}) {
    max-width: 425px;
    min-width: ${minwidth || '280px'};
    position: relative;
  }
`
)
