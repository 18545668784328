import React from 'react'

const Label = ({ target, value, required }) => (
  <label htmlFor={target}>
    {typeof value === 'string' ? value : value()}
    {!required && <sub>Vapaaehtoinen</sub>}
  </label>
)

export default Label
