import React from 'react'
import styled from 'styled-components'
import CardTextContent from './cardTextContent'
import CtaButton from '../buttons/ctaButton'
import Heading from '../typography/headings'

// prettier-ignore
const CtaButtonBlueBg = styled(CtaButton).attrs(props => ({data: props.card.buttonTarget[0], children: props.card.buttonText}))`
  background: ${({ theme }) => theme.colors.primaryColor};
  border-color: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.backgroundColor} !important;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s;
  }
`

const CTACard = ({ data }) => {
  const card = data.contentfulCallToActionCard || data[0]
  const { heading, body } = card

  return (
    <CardTextContent>
      {heading && (
        <Heading headingSize="3" as="h3">
          {heading}
        </Heading>
      )}
      {body && (
        <div
          dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}
        />
      )}
      <p>
        <CtaButtonBlueBg card={card} />
      </p>
    </CardTextContent>
  )
}

export default CTACard
