import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import { bool, string, object, lazy } from 'yup'
import { TextInput, Textarea } from './formInputs'
import Card from './cards/card'
import CtaButton from './buttons/ctaButton'
import { encodeForm, lightenBackground } from '../utils'
import { Loader, CenteredContainer } from './centered/absolutelyCentered'
import Heading from './typography/headings'
import { ReactComponent as ArrowRight } from '../images/arrow-right.svg'
import { ReactComponent as Check } from '../images/check-big.svg'

const FormWrapper = styled.div(
  ({ theme: t, showDetails, popup }) => `
  height: ${popup ? 'auto' : '100%'};

  h3 {
    font-weight: normal;
  }

  > * sub {
    visibility: hidden;
  }

  > * form {
    display: flex;
    justify-content: space-between;
    gap: ${showDetails ? 0 : '20px'};

    flex-direction: ${showDetails ? 'column' : 'row'};

    @media (max-width: ${t.breakpoints.md.min}) {
      flex-direction: column;
    }
  }
`
)

const ThankYouTitle = styled(Heading).attrs({
  as: 'p',
  isSubheading: false,
  headingSize: 3,
})``

const Message = styled.p``

const ThankYou = styled(Card)(
  ({ theme: t, showDetails, popup }) => `
  display: flex;
  margin: 0 auto;
  gap: 20px;
  width: fit-content;
  align-items: center;
  background: transparent;
  color: ${showDetails || popup ? t.colors.primaryColor : t.colors.white};
`
)

const ThankYouIcon = styled.div`
  width: 32px !important;
  height: 32px !important;
  transform: scale(1.4);
`

const CircleWrapper = styled.div(
  ({ theme: t }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px !important;
  height: 70px !important;
  border-radius: 50%;
  background: ${t.colors.pink};

  @media (max-width: ${t.breakpoints.sm.min}) {
    display: none;
  }
`
)

const ThankYouText = styled.div(
  ({ popup }) => `
  display: flex;
  flex-direction: column;

  a {
    width: 180px;
    align-self: center;
    margin-top: 20px;
  }

  > * {
    margin: 0;

    &:first-child {
      margin-bottom: ${popup ? '10px' : '20px'};
      font-size: ${popup ? '20px' : '28px'};

      @media (max-width: 350px) {
        font-size: ${popup ? '20px' : '24px'};
      }
    }

    &:last-child {
      ${popup && 'margin-top: 10px;'}
      margin-bottom: 0;
      line-height: 20px;
    }
  }
`
)

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.red};
`

const StyledForm = styled(Form)(
  ({ theme: t }) => `
  display: flex;

  @media (max-width: ${t.breakpoints.md.min}) {
    flex-direction: column;
  }
`
)

const Fields = styled.div(
  ({ theme: t, showDetails, popup }) => `
  justify-content: center;
  margin-bottom: ${showDetails ? '20px' : '0'};

  > * div {
    margin: ${showDetails ? '15px 0' : '10px 0'};
    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin 0;
    }
  }

  p {
    font-size: ${showDetails ? '14px' : '12px'};
    margin-top: ${showDetails ? '0' : '20px'};
  }

  a {
    color: ${showDetails || popup ? t.colors.primaryColor : t.colors.white};
    text-decoration: underline;
  }
`
)

const SubmitWrapper = styled.div(
  ({ theme: t, showDetails, popup }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  ${popup && 'margin-left: auto;'}

  @media (max-width: ${t.breakpoints.md.max}) {
    max-width: 410px;
    margin: ${showDetails ? '0 0 0 auto' : '0 auto'};
    width: 100%;
  }
`
)

const SubmitButton = styled.button(
  ({ theme: t, showDetails, popup }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${showDetails || popup ? 'transparent' : t.colors.pink};
  color: ${t.colors.primaryColor};
  border-radius: ${showDetails || popup ? '80px' : '100%'};
  width: ${showDetails ? '180px' : popup ? '130px' : '70px'};
  height: ${showDetails ? '45px' : popup ? '35px' : '70px'};
  border: ${
    showDetails
      ? `2px solid ${t.colors.primaryColor}`
      : popup
      ? `2px solid ${t.colors.red}`
      : 'none'
  };
  margin-right: ${showDetails ? 'auto' : '0'};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-left: ${popup ? 'auto' : '0'};

  &:hover {
    transform: ${popup || showDetails ? 'scale(1)' : 'scale(1.1)'};
    transition: transform 0.1s;
    background: ${
      showDetails ? t.colors.primaryColor : popup ? t.colors.red : t.colors.pink
    };
    color: ${
      showDetails
        ? t.colors.white
        : popup
        ? t.colors.primaryColor
        : 'transparent'
    };
  }

  span {
    width: 91px;
  }

  svg {
    transform: ${popup ? 'scale(1)' : 'scale(1.5)'};
  }

  @media (max-width: ${t.breakpoints.md.max}) {
    width: ${showDetails ? '180px' : popup ? '130px' : '59px'};
    height: ${showDetails ? '45px' : popup ? '35px' : '59px'};
    margin-left: auto;
    margin-right: 0;
    ${popup && 'font-size: 14px;'}
    ${popup && 'line-height: normal;'}

    svg {
      path {
        width: 37px;
        height: 37px;
      }
    }
`
)

const LabelText = styled.span``

const Label = styled.label`
  color: ${p => p.theme.colors.primaryColor};
  cursor: pointer;
  margin-bottom: 1.5em;
  display: block;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
    ${LabelText} {
      pointer-events: none;
    }
  }

  input {
    &[type='radio'],
    &[type='checkbox'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    &[type='radio'] + ${LabelText} {
      &:after,
      &:before {
        border-radius: 50%;
        height: 1.5em;
      }

      &:after {
        transform: scale(0.3);
      }
    }

    &[type='checkbox'] + ${LabelText} {
      &:before {
        border-radius: 4px;
        height: 1.5em;
      }

      &:after {
        // prettier-ignore
        transform: matrix(0.20,-0.23,0.23,0.20,0,2); // scaled .3, rotate 45deg or so
        height: 0.9em;
        border-width: 0 0 2px 2px;
        border-color: transparent;
        border-style: solid;
      }
    }
  }

  ${LabelText} {
    position: relative;
    padding-left: 2em;
    display: inline-flex;
    align-items: center;

    &:after,
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 1.5em;
      color: inherit;
      background: transparent;
    }

    &:before {
      content: '';
      border: 1px solid currentColor;
    }

    &:after {
      content: '';
      transition: background 0.125s ease-in, transform 0.1s ease-in;
    }
  }

  input:checked + ${LabelText}:after {
    transition-timing-function: ease-out;
  }

  input[type='radio']:checked + ${LabelText}:after {
    background: currentColor;
    transform: scale(0.6);
  }

  input[type='checkbox']:checked + ${LabelText}:after {
    border-color: currentColor;
    // prettier-ignore
    transform: matrix(0.39,-0.45,0.45,0.39,0,2); // scaled .6, rotate 45deg or so
  }

  + p a {
    margin: -1em 0 0 36px;
    display: block;
  }
`

const Choices = styled.div`
  display: flex;
  color: #333;
  font-size: 16px;
  line-height: 22px;

  span {
    font-weight: 500;
  }
`

const Legend = styled.legend(
  ({ theme: t }) => `
  font-size: 16px;
  margin-bottom: 12px;
  color: ${t.colors.primaryColor};

  @media (max-width: ${t.breakpoints.md.min}) {
    margin-top: 10px;
  }

  @media (min-width: 450px) {
    margin-top: 0;
  }
`
)

const Buttons = styled.div(
  ({ showDetails }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 47px;
  gap: 5px;

  > * {
    padding: 0 1em;
  }
`
)

const Button = styled.button(
  ({ theme: t, isSelected }) => `
  height: 36px;
  padding: 0 20px;
  background-color: ${
    isSelected ? t.colors.primaryColor : 'rgba(255, 255, 255, 0.20)'
  };
  color: ${isSelected ? t.colors.white : t.colors.primaryColor};
  border: none;
  border-radius: 40px;
  outline: none;
  font-size: 16px;

  &:hover {
    background-color: ${
      isSelected
        ? lightenBackground(t.colors.primaryColor, 0.5)
        : lightenBackground(t.colors.primaryColor, 0.9)
    };
  }
`
)

const EtusivulleButton = styled(CtaButton)`
  background: transparent;
`

const RadioField = ({
  label,
  type,
  name,
  value,
  className,
  hidden,
  disabled,
}) =>
  hidden ? null : (
    <Label className={className} disabled={disabled}>
      <Field type="radio" {...{ name, value, type, disabled }} />
      <LabelText>{label}</LabelText>
    </Label>
  )

const ContactForm = props => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submitMessage, setSubmitMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(true)
  const [selectedContactType, setSelectedContactType] = useState('tel')
  const [contactTypeMessage, setContactTypeMessage] = useState('puhelimitse')
  const [contactType, setContactType] = useState('tel')

  useEffect(() => {
    setSelectedContactType(props.contactType)
  }, [props.contactType])

  useEffect(() => {
    setContactTypeMessage(
      props.contactType === 'tel' ? 'puhelimitse' : 'sähköpostitse'
    )
  }, [props.contactType])

  // validation messges are not used atm.
  const contactDetailsSchema = lazy(() => {
    const validationModel = {
      name: string().typeError('').required('Nimi on pakollinen'),
      legal: bool().oneOf([true], 'Suostumus tarvitaan'),
      copyEmail: bool(),
      contactType: string().required(''),
    }
    if (selectedContactType === 'email') {
      validationModel.email = string()
        .email('Invalid email')
        .required('Required')
    } else {
      validationModel.phoneNumber = string()
        .matches(/^[0-9]/)
        .typeError('Puhelinnumero ei ole validi')
        .required('Puhelinnumero on pakollinen')
    }

    return object().shape(validationModel)
  })

  const submitForm = (values, actions) => {
    const finishSubmit = (message, success) => {
      setSubmitMessage(message)
      setIsSubmitted(success)
      setIsSuccess(success)
      actions.setSubmitting(false)
      props.onSubmit && props.onSubmit(success)
    }
    const body = encodeForm({ 'form-name': 'contactRequest', ...values })
    window
      .fetch('/', {
        method: 'POST',
        body,
      })
      .then(() =>
        finishSubmit(
          {
            title: 'Kiitos yhteydenottopyynnöstä!',
            message: `Olemme sinuun yhteydessä ${contactTypeMessage} seuraavan arkipäivän aikana.`,
          },
          true
        )
      )
      .catch(err => {
        console.log(err)
        finishSubmit(
          'Jotain meni valitettavasti pieleen. Yritä hetken päästä uudestaan.',
          false
        )
      })
  }

  return (
    <FormWrapper showDetails={props.showDetails} popup={props.popup}>
      <Formik
        initialValues={{
          name: '',
          phoneNumber: '',
          email: '',
          contactType: selectedContactType,
          recipient: process.env.GATSBY_CONTACT_FORM_RECIPIENT,
          legal: true,
          copyEmail: false,
        }}
        onSubmit={submitForm}
        validationSchema={contactDetailsSchema}
        render={({
          handleChange,
          values,
          isSubmitting,
          isValid,
          handleSubmit,
          submitCount,
          ...args
        }) => (
          <CenteredContainer>
            {isSubmitting && <Loader />}
            {(isSubmitted && isSuccess) || props.popupFormSubmitted ? (
              <ThankYou showDetails={props.showDetails} popup={props.popup}>
                <CircleWrapper>
                  <ThankYouIcon>
                    <Check />
                  </ThankYouIcon>
                </CircleWrapper>
                <ThankYouText popup={props.popup}>
                  <ThankYouTitle>
                    {submitMessage.title || props.thankYouTitle}
                  </ThankYouTitle>
                  <Message>
                    {submitMessage.message || props.thankYouMessage}
                  </Message>
                  {props.showDetails && (
                    <EtusivulleButton to="/">Etusivulle</EtusivulleButton>
                  )}
                </ThankYouText>
              </ThankYou>
            ) : (
              <StyledForm
                name="contactRequest"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <Fields showDetails={props.showDetails} popup={props.popup}>
                  {props.showDetails && (
                    <>
                      <Legend>Valitse yhteydeonttotapa:</Legend>
                      <Buttons>
                        <Button
                          isSelected={contactType === 'tel'}
                          onClick={() => setContactType('tel')}
                        >
                          Puhelimitse
                        </Button>
                        <Button
                          isSelected={contactType === 'email'}
                          onClick={() => setContactType('email')}
                        >
                          Sähköpostitse
                        </Button>
                      </Buttons>
                    </>
                  )}
                  <input
                    type="hidden"
                    name="form-name"
                    value="contactDetailsForm"
                  />
                  <input
                    type="hidden"
                    name="recipient"
                    value={process.env.GATSBY_CONTACT_FORM_RECIPIENT}
                  />
                  <input type="hidden" name="bot-field" />
                  <div
                    style={
                      isSuccess && (isSubmitting || isSubmitted)
                        ? { visibility: 'hidden' }
                        : {}
                    }
                  >
                    <Field
                      type="text"
                      name="name"
                      placeholder="Nimi"
                      component={TextInput}
                      required
                      whiteTheme={!props.showDetails && props.whiteTheme}
                    />
                    <Field
                      type="text"
                      name="phoneNumber"
                      component={TextInput}
                      placeholder={`Puhelinnumero ${
                        selectedContactType === 'email' ? '(vapaaehtoinen)' : ''
                      }`}
                      required={selectedContactType === 'tel'}
                      whiteTheme={!props.showDetails && props.whiteTheme}
                    />
                    <Field
                      type="text"
                      name="email"
                      component={TextInput}
                      placeholder={`Sähköposti ${
                        selectedContactType === 'tel' ? '(vapaaehtoinen)' : ''
                      }`}
                      required={selectedContactType === 'email'}
                      whiteTheme={!props.showDetails && props.whiteTheme}
                    />
                    {props.showDetails ? (
                      <>
                        <Field
                          as="textarea"
                          placeholder="Viesti"
                          name="message"
                          component={Textarea}
                        />
                        <RadioField
                          label="Lähetä kopio viestistä yllä olevaan sähköpostiosoitteeseen"
                          type="checkbox"
                          name="copyEmail"
                          disabled={selectedContactType !== 'email'}
                        />
                        <RadioField
                          label={`Täyttämällä lomakkeen annan Alexandrialle suostumukseni tietojeni käsittelyyn
                            Alexandrian tietojenkäsittelyperiaatteen mukaisesti.`}
                          type="checkbox"
                          name="legal"
                          required
                        />
                        <p>
                          <a href="/yleista/tietosuoja/" target="_blank">
                            Alexandrian tietojenkäsittelyperiaatteet
                          </a>
                        </p>
                      </>
                    ) : (
                      <p>
                        {' '}
                        Lähettämällä lomakkeen hyväksyn tietojeni käsittelemisen
                        Alexandrian{' '}
                        <a href="/yleista/tietosuoja/" target="_blank">
                          tietosuojaperiaatteiden mukaisesti
                        </a>
                      </p>
                    )}
                  </div>
                </Fields>
                <Choices>{props.choices}</Choices>
                <SubmitWrapper showDetails={props.showDetails}>
                  {!isSuccess && <ErrorMessage>{submitMessage}</ErrorMessage>}
                  <SubmitButton
                    type="submit"
                    disabled={
                      !isValid || (isSuccess && (isSubmitting || isSubmitted))
                    }
                    showDetails={props.showDetails}
                    popup={props.popup}
                  >
                    {props.showDetails ? (
                      'Jätä pyyntö'
                    ) : props.popup ? (
                      <>
                        <span>Lähetä</span> <ArrowRight />
                      </>
                    ) : (
                      <ArrowRight />
                    )}
                  </SubmitButton>
                </SubmitWrapper>
              </StyledForm>
            )}
          </CenteredContainer>
        )}
      />
    </FormWrapper>
  )
}

export default ContactForm
