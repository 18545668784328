import styled from 'styled-components'

const ImageFooter = styled.div(
  ({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  padding-top: 38px;
  padding-bottom: 30px;
  gap: 10px;

  @media (max-width: ${theme.breakpoints.md.max}) {
    padding-top: 43px;
    padding-bottom: 20px;
  }

  p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  svg {
    margin-right: ${theme.spacing.small};
  }
`
)

export default ImageFooter
