import React from 'react'
import styled from 'styled-components'
import CtaButton from '../buttons/ctaButton'
import Link from '../links/link'
import { ReactComponent as HashtagIcon } from '../../images/hashtag.svg'
import { getLabelByText } from '../../utils/segments'
import { ReactComponent as ArrowRightIcon } from '../../images/arrow-right.svg'

const Wrapper = styled.div(
  ({ theme: t }) => `
  display: flex;
  max-width: ${t.width.max};
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 32px 50px 32px;
  gap: 20px;

  @media (max-width: ${t.breakpoints.md.max}) {
    padding: 0 16px 30px 16px;
    gap: 10px;
  }
`
)

const Line = styled.span(
  ({ theme: t }) => `
  width: 100%;
  height: 1px;
  background-color: ${t.colors.primaryColor};
`
)

const Content = styled.div(
  ({ theme: t }) => `
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;

  @media (max-width: ${t.breakpoints.lg.max}) {
    flex-direction: column;
  }

  @media (max-width: ${t.breakpoints.md.max}) {
    margin-top: 10px;
  }

  @media (max-width: 400px) {
    align-items: flex-start;
  }
`
)

const ContentLeft = styled.div(
  ({ theme: t }) => `
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: ${t.breakpoints.md.max}) {
    gap: 4px;
  }

  @media (max-width: ${t.breakpoints.md.min}) {
    width: 232px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
`
)

const ContentRight = styled.div(
  ({ theme: t }) => `
  margin-left: auto;

  @media (max-width: ${t.breakpoints.lg.max}) {
    margin-left: 0;
  }
`
)

const Title = styled.h2`
  font-size: 28px;
  max-width: 280px;
  margin: 10px 0;
`

const Categories = styled.div(
  ({ theme: t }) => `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 94px;

  @media (max-width: ${t.breakpoints.md.max}) {
    gap: 5px 0px;
  }

  @media (max-width: ${t.breakpoints.md.min}) {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`
)

const Category = styled(Link)(
  ({ theme: t }) => `
    display: flex;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: ${t.breakpoints.md.max}) {
      font-size: 14px;
    }
  `
)

const LinkButton = styled(CtaButton)(
  ({ theme: t }) => `
  display: flex;
  height: 45px;
  width: 232px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  background-color: ${t.colors.primaryColor};
  color: ${t.colors.white} !important;
  gap: 15px;
  border: none;

  svg {
    min-width: 25px;
    path {
      fill: ${t.colors.white};
    }
  }


  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s;
  }
`
)

const ArticleCategories = ({ articleCategories }) => {
  let categories = articleCategories

  if (!articleCategories) {
    categories = {
      categories: [
        'Eläkesäästäminen',
        'Markkinakommentit ja trendit',
        'Sijoittamisen aloittaminen',
        'Tiedotteet',
        'Rikasta elämää -podcast',
        'Yrittäjälle',
        'Sijoittaminen',
      ],
    }
  }

  return (
    <Wrapper>
      <Line />
      <Content>
        <ContentLeft>
          <HashtagIcon />
          <Title>{categories.title || 'Selaa sijoittamisen aiheitamme'}</Title>
          <Categories>
            {categories.categories.map((category, index) => (
              <Category
                key={index}
                to={`sisallot?category=${encodeURIComponent(
                  category
                )}#suodatus`}
              >
                {category === 'Rikasta elämää -podcast'
                  ? category
                  : getLabelByText(category)}
              </Category>
            ))}
          </Categories>
        </ContentLeft>
        <ContentRight>
          <LinkButton to={'/sisallot'} large={'true'}>
            {categories.ctaText || 'Tutustu sisältöihin'} <ArrowRightIcon />
          </LinkButton>
        </ContentRight>
      </Content>
    </Wrapper>
  )
}

export default ArticleCategories
