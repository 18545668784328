import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import NavLink from './navLink'
import NavGroup from './navGroup'
import Button from '../buttons/button'
import NavGroupItem from './navGroupItem'
import CatchOutsideClick from '../catchOutsideClick'
import { ReactComponent as MenuIcon } from '../../images/menu.svg'
import { ReactComponent as CloseMenuIcon } from '../../images/close.svg'
import { ReactComponent as LockIcon } from '../../images/lock.svg'
import { ReactComponent as DropdownIcon } from '../../images/dropdown.svg'
import ctaButton from '../buttons/ctaButton'

const MobileNavItem = styled.li``

const Drawer = styled.div`
  height: calc(100% - ${p => p.theme.navHeight.min});
  overflow-y: auto;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 0;
  width: 100%;
  padding: 0 10px;
  top: ${p => p.theme.navHeight.min};
  background-color: ${({ theme }) => theme.colors.backgroundColor};

  ${MobileNavItem} {
    display: flex;
    flex-direction: column;
    font-size: 20px;
  }
  transform: translateX(100%);
  &.drawer-enter {
    transform: translateX(100%);
  }
  &.drawer-enter-active {
    transform: translateX(0);
    transition: transform 0.25s ease-in;
  }
  &.drawer-enter-done {
    transform: translateX(0);
  }
  &.drawer-exit {
    transform: translateX(0);
  }
  &.drawer-exit-active {
    transform: translateX(100%);
    transition: transform 0.25s ease-out;
  }
`

const NavToggleButton = styled.button(
  ({ theme: { colors } }) => `
  height: 100%;
  width: 60px;
  padding: 1em;
  background: transparent !important;
  color: ${colors.primaryColor} !important;
  padding-top: 1.3em;

  &[aria-expanded="true"] {
    padding-top: 1.4em;
  }

  svg {
    width: 40px;
    stroke: currentColor;
    stroke-width: 0.5;
  }
`
)

const Overlay = styled.div`
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  top: ${p => p.theme.navHeight.min};
  ${props =>
    props.visible &&
    `
    display: block;
  `}
`

const NavGroups = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: ${p => p.theme.navHeight.min};
  height: inherit;

  ${MobileNavItem}:last-of-type {
    margin: 2em ${({ theme }) => theme.spacing.medium} 0;

    a {
      max-width: 400px;
      width: 100%;
      align-self: center;
    }
  }

  & > li:first-of-type {
    margin-top: 40px;

    & > div > svg {
      ${({ openGroupIdx }) => openGroupIdx === 0 && `transform: rotate(90deg)`};
    }
  }

  & > li:nth-of-type(4) > div > svg {
    ${({ openGroupIdx }) => openGroupIdx === 3 && `transform: rotate(90deg)`};
  }
`

const NavLinkGroup = styled.div`
  & > a {
    position: absolute;
    display: flex;
    width: calc(100% - 80px);
    & > span {
      visibility: hidden;
    }
  }

  & > svg {
    position: absolute;
    width: 27px;
    height: 27px;
    right: 30px;
    margin-top: 10px;
    stroke: currentcolor;
    stroke-width: 0.5;
    pointer-events: none;
  }
`

const LoginButton = styled(Button)`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 45px;
  margin-bottom: 30px;
  padding-left: 60px;

  & > span {
    flex-grow: 1;
    text-align: center;
  }
`

const ContactButton = styled(ctaButton).attrs({
  to: '/toimipaikat-ja-yhteystiedot',
})`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  font-size: 16px;
  font-weigth: 500 !important;
  line-height: 20px;
  height: 45px;
  padding-top: 10px;
`

const MobileDrawer = ({ items, setIsSearchOpen }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [openGroupIdx, setOpenGroupIdx] = useState(null)

  useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        document.body.style.overflow = 'hidden' // Prevent scrolling
      } else {
        document.body.style.overflow = 'auto'
      }
    }
    handleScroll()
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  const handleGroupOnClick = idx => {
    if (idx === openGroupIdx) {
      setOpenGroupIdx(null)
    } else {
      setOpenGroupIdx(idx)
    }
  }

  const handleToggle = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true)
    setIsSearchOpen(false)
  }

  return (
    <>
      <Overlay visible={isOpen} />
      <CatchOutsideClick onClick={() => setIsOpen(false)}>
        <NavToggleButton
          onClick={handleToggle}
          aria-controls="mobile-menu"
          aria-expanded={`${isOpen}`}
          type="button"
          aria-label="Valikko"
        >
          {isOpen ? (
            <CloseMenuIcon aria-label="Sulje" />
          ) : (
            <MenuIcon aria-label="Avaa" />
          )}
        </NavToggleButton>

        <CSSTransition in={isOpen} timeout={500} classNames="drawer">
          <Drawer>
            <NavGroups id="mobile-menu" openGroupIdx={openGroupIdx}>
              {items.map((group, idx) => (
                <MobileNavItem key={group.title}>
                  {group.items.length > 1 ? (
                    <NavLinkGroup>
                      <NavLink data={group.items[0]}>
                        {group.items[0].title}
                      </NavLink>
                      <DropdownIcon />
                      <NavGroup
                        title={group.title}
                        onClick={() => handleGroupOnClick(idx)}
                        open={openGroupIdx === idx}
                      >
                        {group.items.map((item, i) => (
                          <React.Fragment key={i}>
                            {i > 0 && (
                              <NavGroupItem>
                                <NavLink data={item}>{item.title}</NavLink>
                              </NavGroupItem>
                            )}
                          </React.Fragment>
                        ))}
                      </NavGroup>
                    </NavLinkGroup>
                  ) : (
                    <>
                      {idx !== items.length - 1 && (
                        <NavLink data={group.items[0]}>
                          {group.items[0].title}
                        </NavLink>
                      )}
                    </>
                  )}
                </MobileNavItem>
              ))}
              <MobileNavItem>
                <ContactButton>Ota yhteyttä</ContactButton>
                <LoginButton
                  as="a"
                  large="true"
                  href="https://oma.alexandria.fi/login"
                >
                  <span>Kirjaudu</span> <LockIcon />
                </LoginButton>
              </MobileNavItem>
            </NavGroups>
          </Drawer>
        </CSSTransition>
      </CatchOutsideClick>
    </>
  )
}

export default MobileDrawer
