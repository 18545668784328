import React from 'react'
import styled from 'styled-components'
import { useLocation } from '@reach/router'
import ShareButtonGroup from './buttons/shareButtonGroup'
import MediumText from './typography/mediumText'

const Wrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

const Title = styled(MediumText).attrs({ as: 'p' })`
  margin: 0 1em 1em 0;
`

const Share = ({ title = 'Jaa artikkeli' }) => {
  const location = useLocation()

  return (
    <Wrap>
      <Title>{title}</Title>
      <ShareButtonGroup location={location && location.href} />
    </Wrap>
  )
}

export default Share
