import React from 'react'
import { arrayOf, oneOf, shape, string } from 'prop-types'
import styled from 'styled-components'
import CtaButton from '../buttons/ctaButton'
import SectionTeaser from './sectionTeaser'
import ContentTeaser from './contentTeaser'

const propTypes = {
  contentStyle: oneOf(['content-teaser', 'section-teaser']),
  teasers: arrayOf(
    shape({
      heading: string,
      richTextJson: string,
      cta: shape({
        to: string.isRequired,
        text: string,
      }),
    }).isRequired
  ).isRequired,
}

const ContentWrap = styled.div`
  ${CtaButton} {
    display: block;
    max-width: 350px;
    margin: 0 auto;
    width: 100%;
  }
`

const renderCta = ({ to, children, className, hidden, ...rest }) => (
  <p {...{ className, hidden }}>
    <CtaButton {...{ to, children, ...rest }} large="true" />
  </p>
)

const HeroSectionTeasers = ({ contentStyle, teasers }) => (
  <ContentWrap>
    {(() => {
      switch (contentStyle) {
        case 'content-teaser':
          return <ContentTeaser teaser={teasers[0]} renderCta={renderCta} />
        case 'section-teaser':
          return <SectionTeaser teasers={teasers} renderCta={renderCta} />
        default:
          return null
      }
    })()}
  </ContentWrap>
)

HeroSectionTeasers.propTypes = propTypes

export default HeroSectionTeasers
