import React from 'react'
import styled from 'styled-components'
import { ReactComponent as SeparatorIcon } from '../images/separator.svg'

const SeparatorWrapper = styled.div(
  ({ theme: t, sisallot }) => `
  display: flex;
  height: 100%;
  justify-content: center;
  padding: ${sisallot ? '38px 0 0 0' : '50px 0 0 0'};

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    padding: 30px 0 0 0;
  }
`
)

const Separator = ({ sisallot }) => {
  return (
    <SeparatorWrapper sisallot={sisallot}>
      <SeparatorIcon />
    </SeparatorWrapper>
  )
}

export default Separator
