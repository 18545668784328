import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import { lightTheme } from '../styles/theme'
import { officePropTypes } from '../utils/prop-types'
import SplitHero from '../components/heroes/splitHero'
import Layout from '../components/layout'
import CardGroup from '../components/cards/cardGroup'
import PersonnelCard from '../components/cards/personnelCard'
import { Heading } from '../components/typography/headings'
import { ReactComponent as PhoneIcon } from '../images/mobiilipuhelin.svg'
import { lightenBackground } from '../utils'
import { getCityWordForm } from '../utils/segments'

const propTypes = {
  data: shape({
    contentfulOffice: officePropTypes,
  }),
}

const ContactBox = styled.a(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 124px;
  padding: 23px 29px 15px 30px;
  gap: 3px;
  background-color: ${lightenBackground(t.colors.blueLight, 0.5)};
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  margin-top: 45px;

  @media (max-width: ${t.breakpoints.md.max}) {
    margin-top: 20px;
  }

  span:first-of-type {
    width: 40px;
    height: 40px;
  }

  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s;
  }
`
)

const CtaButton = styled.a(
  ({ theme: t }) => `
  display: flex;
  width: 261px;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  border: 2px solid ${t.colors.primaryColor};
  border-radius: 80px;
  align-items: center;
  justify-content: center;
  margin: 0 auto 50px auto;
  color: ${t.colors.primaryColor};

  &:hover {
    background-color: ${t.colors.primaryColor};
    color: ${t.colors.white};
  }
`
)

const createMapHeroProps = ({
  description: { childMarkdownRemark: { html } } = {
    childMarkdownRemark: { html: '' },
  },
  address,
  city,
  faxNumber,
  location,
  name,
  phoneNumber,
  postalCode,
}) => ({
  heading: name,
  lead: (
    <>
      <p>
        <strong>
          {address} <br />
          {postalCode} {city}
        </strong>
      </p>
      {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
      {phoneNumber && (
        <ContactBox
          target="__blank"
          href={`tel:${phoneNumber[0].replace(/\s/g, '')}`}
        >
          <span>
            <PhoneIcon />
          </span>
          <span>Puhelin</span>
          <span>{phoneNumber[0]}</span>
        </ContactBox>
      )}
    </>
  ),
  map: {
    location,
  },
})

const OfficePage = ({ data: { contentfulOffice: office } }) => {
  return (
    <Layout
      title={office.name}
      image={office.image}
      description={office.description.description}
      hero={
        <ThemeProvider theme={lightTheme}>
          <SplitHero {...createMapHeroProps(office)} />
        </ThemeProvider>
      }
    >
      <CardGroup
        hero={
          <Heading as="h2" headingSize="3">
            {`${getCityWordForm(office.city)} sinua *palvelevat*`}
          </Heading>
        }
        items={office.personnel.map((person, index) => (
          <PersonnelCard person={person} index={index} />
        ))}
      />
      <CtaButton href={'/toimipaikat-ja-yhteystiedot'}>
        Hae muita yhteystietoja
      </CtaButton>
    </Layout>
  )
}

OfficePage.propTypes = propTypes

export default OfficePage

export const query = graphql`
  query ($slug: String!) {
    contentfulOffice(slug: { eq: $slug }) {
      id
      name
      slug
      address
      postalCode
      city
      phoneNumber
      faxNumber
      location {
        lat
        lon
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      personnel {
        firstName
        lastName
        slug
        title
        phoneNumber
        email
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`
