import React from 'react'
import { graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import Layout from '../components/layout'
import { blueRedTheme, redBlueTheme } from '../styles/theme'
import InBriefCard from '../components/cards/inBriefCard'
import IPOCard from '../components/ipo/ipoCard'
import IPOBody from '../components/ipo/ipoBody'
import IPOMaterials from '../components/ipo/ipoMaterials'
import IPOReleases from '../components/ipo/ipoReleases'
import ITFHero from '../components/heroes/itfHero'
import ClickThrough from '../components/click-through'

const IpoMarketingPage = ({ data: { page } }) => {
  const disclaimer = {
    data: {
      disclaimerCountries: page.disclaimerCountries,
      disclaimerBody: page.disclaimerBody,
      disclaimerContent: page.disclaimerContent,
      disclaimerAltContent: page.disclaimerAltContent,
      disclaimerOtherCountriesContent: page.disclaimerOtherCountriesContent,
    },
  }
  return (
    <Layout
      title={page.title}
      image={page.heroBackgroundImage?.file.url}
      description={page.description.childMarkdownRemark.rawMarkdownBody}
      navbarTheme={blueRedTheme}
    >
      <ITFHero
        image={page.heroBackgroundImage}
        text={page.heroText.childMarkdownRemark.html}
      />
      <ClickThrough
        disclaimer={disclaimer}
        renderPage={bodyRef => (
          <>
            <ThemeProvider theme={redBlueTheme}>
              <InBriefCard
                ref={bodyRef}
                heading={page.alexandriaInBriefHeadline}
                text={page.alexandriaInBriefText}
                image={page.alexandriaInBriefImage}
                stats={page.alexandriaInBriefStats}
              />
            </ThemeProvider>
            <IPOBody text={page.bodyText?.childrenMarkdownRemark[0]?.html} />
            {page.cards?.map(card => (
              <IPOCard key={card.id} {...card} />
            ))}
            <IPOMaterials
              title={page.materialsTitle}
              notFoundText={page.materialsNotFoundText}
              downloadText={page.materialsDownloadText}
              materials={page.materials}
            />
            <IPOReleases />
          </>
        )}
      />
    </Layout>
  )
}

export default IpoMarketingPage

export const query = graphql`
  query ($slug: String!) {
    page: contentfulIpoMarketingPage(slug: { eq: $slug }) {
      id
      title
      slug
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      heroText {
        childMarkdownRemark {
          html
        }
      }
      heroBackgroundImage {
        file {
          url
        }
        title
        gatsbyImageData(width: 1200, placeholder: BLURRED)
      }
      alexandriaInBriefHeadline
      alexandriaInBriefText {
        childMarkdownRemark {
          html
        }
      }
      alexandriaInBriefStats
      alexandriaInBriefImage {
        file {
          url
        }
        gatsbyImageData(width: 422, placeholder: BLURRED)
      }
      bodyText {
        childrenMarkdownRemark {
          html
        }
      }
      cards {
        id
        ctaText
        ctaTarget
        backgroundColor
        bodyText {
          raw
        }
        imagePosition
        image {
          title
          gatsbyImageData(width: 1200, placeholder: BLURRED)
        }
      }
      materials {
        id
        title
        file {
          url
        }
      }
      materialsDownloadText
      materialsNotFoundText
      materialsTitle
      disclaimerCountries
      disclaimerBody {
        raw
      }
      disclaimerContent {
        raw
      }
      disclaimerAltContent {
        raw
      }
      disclaimerOtherCountriesContent {
        raw
      }
    }
  }
`
