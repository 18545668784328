import React from 'react'
import { graphql } from 'gatsby'

import ContentfulPage from './contentfulPage'
import ContentfulCard from './contentfulCard'
import InvestmentsTable from './tables/investmentsTable'
import Link from '../components/links/link'
import Day from '../components/format/day'

const getTableRow = (
  feedItem,
  contentfulItem,
  showValuationEur,
  showEffectiveInterest
) => ({
  name: contentfulItem.name,
  date: feedItem.priceDate,
  cells: [
    <Link to={`/${contentfulItem.slug}`}>{contentfulItem.name}</Link>,
    feedItem.isinCode,
    <Day value={feedItem.issueDate} />,
    <Day value={feedItem.maturityDate} />,
    feedItem.pricePerUnitPer,
    showValuationEur ? feedItem.pricePerUnit : false,
    showEffectiveInterest ? feedItem.effectiveInterest : false,
    getOverviewLink(contentfulItem) ? (
      <Link to={getOverviewLink(contentfulItem)}>Katsaus</Link>
    ) : (
      '-'
    ),
  ].filter(x => x !== false),
})

const getOverviewLink = contentfulItem => {
  return (
    contentfulItem.overviewLink ||
    (contentfulItem.overview && contentfulItem.overview.file.url)
  )
}

const getTableData = (
  feedData,
  contentfulData,
  showValuationEur,
  showEffectiveInterest
) => {
  const data = feedData.map(feedItem => {
    const contentfulItem = contentfulData.find(
      contentfulItem => contentfulItem.isin === feedItem.isinCode
    )
    if (!contentfulItem) {
      return null
    }
    return getTableRow(
      feedItem,
      contentfulItem,
      showValuationEur,
      showEffectiveInterest
    )
  })

  return data.filter(item => item)
}

const InvestmentsPage = ({
  page,
  feedData,
  contentfulData,
  cardData,
  back,
  showHint = true,
  showValuationEur,
  showEffectiveInterest,
}) => {
  return (
    <ContentfulPage
      page={page}
      cardGroups={
        cardData && [
          { items: cardData.map(item => <ContentfulCard card={item} />) },
        ]
      }
      table={
        <InvestmentsTable
          showHint={showHint}
          showValuationEur={showValuationEur}
          showEffectiveInterest={showEffectiveInterest}
          data={getTableData(
            feedData,
            contentfulData,
            showValuationEur,
            showEffectiveInterest
          )}
          footer={<Link to={back.to}>{back.text}</Link>}
        />
      }
    />
  )
}

export default InvestmentsPage

export const query = graphql`
  fragment contentfulStructuredInvestmentExtendedInformation on ContentfulStructuredInvestment {
    ...contentfulStructuredInvestmentInformation
    name
    isin
    issueDate
    subscriptionPeriodEndDate
    expirationDate
    overviewLink
    overview {
      title
      description
      file {
        url
      }
    }
  }
`
