import styled from 'styled-components'

export default styled.div`
  padding: 0 32px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${({ theme, containerWidth }) =>
    theme.width[containerWidth || 'max']};

  ${p =>
    p.isFlowing &&
    `
      margin-bottom: 2em;
    `}

  ${p =>
    p.navigate &&
    `
      align-items: flex-start;
      flex-direction: row;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints.md.min}) {
    ${p =>
      p.isFlowing &&
      `
        margin-bottom: 0;
      `}
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    margin-bottom: 0;
    padding: 0 20px;
  }
`
