import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import hexToRgba from 'hex-to-rgba'

const ProductWrapper = styled.a(
  ({ theme: t, productTheme, productsAmount }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${productsAmount === 4 ? 'auto' : '400px'};
  height: ${productsAmount === 4 ? 'auto' : '255px'};
  max-width: ${productsAmount === 4 ? '297.5px' : '400px'};
  padding: 34px 20px 20px 20px; 
  padding-top: ${productsAmount === 4 ? '90px' : '34px'};
  border-radius: 30px;
  background-color: ${hexToRgba(
    t.colors[productTheme],
    themeOpacities[productTheme]
  )};

  @media screen and (max-width: 1260px) {
    width: 100%;
    max-width: 100%;
    height: 246px;
  }

  @media screen and (max-width: ${t.breakpoints.sm.max}) {
    flex: 1;
    padding-top: 34px;
  }

  &:hover {
    @media screen and (min-width: ${t.breakpoints.md.max}) {
      transform: scale(1.05);
      transition: transform 0.5s;
    }
  }

  &:nth-of-type(3) {
    @media screen and (min-width: ${
      t.breakpoints.sm.max
    }) and (max-width: 1260px) {
    margin-left: 100%;
  }
`
)

const Content = styled.div(
  ({ theme: t, isEven }) => `
  display: flex;
  flex-direction: column;
  color: ${t.colors.blue};
  gap: 15px;
  text-align: left;
  height: 100%;
  justify-content: flex-end;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    width: 100%;
    gap: 10px;
  }

  @media screen and (max-width: ${t.breakpoints.sm.max}) {
    text-align: ${isEven ? 'right' : 'left'};
  }

  @media screen and (max-width: ${t.breakpoints.sm.max}) {
    text-align: ${isEven ? 'right' : 'left'};
    padding-left: ${isEven ? '65px' : '0'};
    padding-right: ${isEven ? '0' : '62px'};
  }
`
)

const ProductTitle = styled.p(
  ({ theme: t, isEven }) => `
  font-size: 28px;
  font-weight: 600;
  line-height: 33px;
  margin: 0;
  max-width: 100%;
  padding-right: 82px;
  overflow-wrap: break-word;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    font-size: 20px;
    line-height: normal;
    padding-right: 0;
    word-wrap: break-word;
  }

  @media screen and (max-width: ${t.breakpoints.sm.max}) {
    margin-left: ${isEven ? 'auto' : '0'};
    margin-right: ${isEven ? '0' : 'auto'};
    padding-left: ${isEven ? '12px' : '0'};
    padding-right: ${isEven ? '0' : '12px'};
  }
`
)

const ImageWrap = styled.div(
  ({ theme: t, isEven }) => `
  position: absolute;
  top: -20.5px;
  right: -13px;
  width: 140px;
  height: 140px;

  @media screen and (max-width: 1260px) {
    top: -8px;
    right: -5px;
    width: 100px;
    height: 100px;
  }

  @media screen and (max-width: ${t.breakpoints.sm.max}) {
    right: ${isEven ? 'auto' : '-5px'};
    left: ${isEven ? '-5px' : 'auto'};
  }
`
)

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`

const Description = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding-right: 5px;

  @media screen and (max-width: ${({ theme: t }) => t.breakpoints.md.max}) {
    padding-right: 0;
  }
`

const FooterText = styled.p(
  ({ theme: t, product }) => `
  color: ${t.colors[footerTextColor[product.theme]]};
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.28px;
  text-transform: uppercase;
`
)

const themeOpacities = {
  pink: 0.3,
  blueLight: 0.2,
  orange: 0.2,
  greenDark: 0.15,
  gray: 0.2,
}

const footerTextColor = {
  pink: 'red',
  blueLight: 'blueLight',
  orange: 'orange',
  greenDark: 'greenDark',
  gray: 'primaryColor',
}

const Product = props => {
  const [splitTitle, setSplitTitle] = useState(
    typeof window !== 'undefined' &&
      (window.innerWidth >= 1260 || window.innerWidth < 343)
  )

  useEffect(() => {
    const handleResize = () => {
      setSplitTitle(
        typeof window !== 'undefined' &&
          (window.innerWidth >= 1260 || window.innerWidth < 343)
      )
    }

    typeof window !== 'undefined' &&
      window.addEventListener('resize', handleResize)

    return () => {
      typeof window !== 'undefined' &&
        window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <ProductWrapper
      productTheme={props.product.theme}
      productsAmount={props.productsAmount}
      href={props.product.link}
    >
      <ImageWrap isEven={props.index % 2 === props.remainder}>
        <StyledGatsbyImage
          image={props.product.image.gatsbyImageData}
          alt={props.product.image.title || ''}
        />
      </ImageWrap>
      <Content isEven={props.index % 2 === props.remainder}>
        <ProductTitle
          as="h2"
          headingSize="3"
          isEven={props.index % 2 === props.remainder}
        >
          {props.product.title === 'Vakuutussijoittaminen' && splitTitle
            ? 'Vakuutus- sijoittaminen'
            : props.product.title}
        </ProductTitle>
        <Description isEven={props.index % 2 === props.remainder}>
          {props.product.text.text}
        </Description>
        <FooterText product={props.product}>
          {props.product.footerText}
        </FooterText>
      </Content>
    </ProductWrapper>
  )
}

export default Product
