import styled from 'styled-components'

export default styled.div`
  margin: ${({ theme }) => theme.spacing.small} 0;

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
`
