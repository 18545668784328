import React from 'react'
import styled from 'styled-components'

const FilterContainer = styled.div`
  margin-bottom: 2rem;
  width: 100%;
`

const FilterGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
  margin-bottom: 2rem;
`

const FeedbackFilterGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`

const FilterLabel = styled.label`
  display: block;
  font-size: 0.75em;
  width: 100%;
  text-transform: uppercase;
`

const FilterButton = styled.button`
  padding: 8px 40px;
  background-color: ${({ active, theme }) =>
    active ? '#7ab8ff' : theme.colors.cyan};
  border-radius: ${({ theme }) => theme.radius.xxlarge};

  font-size: 0.75em;
  font-family: 'Scto Grotesk A', sans-serif;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02em;

  span {
    font-weight: 700;
  }

  :hover {
    background-color: #7ab8ff;
  }

  transition: background-color ${({ theme }) => theme.transition.duration};
`
const isActive = (a, b) => {
  return a.lower === b.lower && a.upper === b.upper
}

const FeedbackFilterGroup = ({ filter, current, handler }) => {
  return (
    <FeedbackFilterGroupContainer>
      <FilterLabel>{filter.title}</FilterLabel>
      <FilterGroup>
        {filter.filters.map((f, index) => (
          <FilterButton
            key={`${filter.filterKey}-${index}`}
            active={isActive(f.value, current)}
            onClick={() => handler(f.value)}
          >
            <span>{f.label}</span>
            {` (${f.count} kpl)`}
          </FilterButton>
        ))}
      </FilterGroup>
    </FeedbackFilterGroupContainer>
  )
}

export const FeedbackFilters = ({
  setYearFilter,
  setScoreFilter,
  filters,
  yearFilter,
  scoreFilter,
}) => {
  return (
    <FilterContainer>
      <FeedbackFilterGroup
        filter={filters.year}
        current={yearFilter}
        handler={setYearFilter}
      />
      <FeedbackFilterGroup
        filter={filters.score}
        current={scoreFilter}
        handler={setScoreFilter}
      />
    </FilterContainer>
  )
}
