import React, { useState } from 'react'
import styled from 'styled-components'
import ContactForm from '../contactForm'
import Heading from '../typography/headings'

const Wrapper = styled.div(
  ({ theme: t, isSubmitted }) => `
  width: 100%;
  color: ${t.colors.white};
  background: ${t.colors.orange};
  padding: 108px 183px 91px 145px;

  @media (max-width: ${t.breakpoints.md.max}) {
    padding: ${isSubmitted ? 0 : '62px 25px 58px 25px'};
  }
`
)

const Content = styled.div(
  ({ theme: t }) => `
  display: flex;
  max-width: ${t.width.max};
  margin: 0 auto;
  gap: 130px;
  justify-content: center;

  @media (max-width: ${t.breakpoints.md.max}) {
    flex-direction: column;
    gap: 40px;
  }
`
)

const TextContent = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  max-width: 401px;
  gap: 20px;
  color: ${t.colors.primaryColor};

  @media (max-width: ${t.breakpoints.md.max}) {
    gap: 10px;
  }
`
)

const Title = styled(Heading)(
  ({ theme: t }) => `
  font-size: 38px;
  line-height: 42px;
  margin-bottom: 40px;

  @media (max-width: ${t.breakpoints.md.max}) {
    margin-bottom: 0;
  }
`
)

const Description = styled.p`
  font-size: 20px;
  line-height: 28px;
  margin: 0;
`

const ContactContainer = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleFormSubmit = success => {
    setIsSubmitted(success)
  }

  return (
    <Wrapper isSubmitted={isSubmitted}>
      <Content>
        {!isSubmitted && (
          <TextContent>
            <Title>
              Kiinnostuitko? Jätä yhteystietosi niin *otamme sinuun* yhteyttä
            </Title>
            <Description>
              Kerro meille tavoitteistasi ja sijoitusneuvojamme ottavat sinuun
              yhteyttä haluamallasi tavalla.
            </Description>
          </TextContent>
        )}
        <ContactForm showDetails={true} onSubmit={handleFormSubmit} />
      </Content>
    </Wrapper>
  )
}

export default ContactContainer
