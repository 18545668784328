import React, { useState } from 'react'
import { bool, node, oneOfType, string } from 'prop-types'
import styled from 'styled-components'
import CtaButton from '../buttons/ctaButton'
import Link from '../links/link'
import Image from '../backgroundImage'
import Datetime from '../format/datetime'
import DateContainer from '../DateContainer'
import { Heading } from '../typography/headings'
import DateText from '../typography/dateText'
import Container from '../container'
import { imagePropTypes } from '../../utils/prop-types'

const propTypes = {
  image: imagePropTypes,
  title: oneOfType([node, string]).isRequired,
  children: node,
  date: string,
  to: string.isRequired,
  className: string,
  metaText: string,
  smallHero: bool,
}

const Content = styled.div(
  ({ theme: { colors } }) => `
  color: ${colors.blue};

  p:not(:last-child) {
    margin-bottom: 1em;
  }
`
)

const ImageWrap = styled(Link).attrs({ noDefaultLinkFocus: true })(
  ({ issquare }) => `
  position: relative;
  /* padding-bottom: ${issquare ? 50 : 30}%; */
  margin-bottom: 1em;
  display: block;
  &[issquare="true"] {
    padding-bottom: 50%;
  }
  &[issquare="false"] {
    padding-bottom: 30%;
  }
`
)

const Lead = styled.p`
  font-size: 1.35em;
`

const ContentGroup = styled.div`
  margin-bottom: 1.5em;
  word-break: break-word;
  text-align: left;
`

const SplitCardHeroContainer = styled.div`
  display: block;
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: 100%;
  position: relative;
  font-size: 1rem;

  @media (min-width: 48em) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    &:nth-of-type(2n),
    ${Container}:nth-of-type(2n) & {
      flex-direction: row-reverse;
    }

    ${ImageWrap},
    ${Content} {
      margin-bottom: 0;
    }

    ${ImageWrap} {
      width: calc(55% - 1.5rem);
    }

    ${Content} {
      width: calc(45% - 1.5rem);
      display: flex;
      flex-direction: column;
    }

    ${ContentGroup} {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      margin-bottom: 25%;

      > * {
        margin: 0 !important;
      }

      > *:nth-child(2) {
        padding: 1rem 0;
      }
    }

    ${CtaButton} {
      align-self: center;
      margin: auto auto 0;
    }
  }
`

const SplitCardHero = ({
  image,
  title,
  children,
  to,
  className,
  category,
  date,
  updatedAtDate,
  smallHero,
}) => {
  const [isHover, setHover] = useState(false)
  const isSquare = (!smallHero).toString()
  const { title: alt, gatsbyImageData: imageData, coverCss } = image

  return (
    <SplitCardHeroContainer className={className}>
      <ImageWrap
        issquare={isSquare}
        to={to}
        tabIndex="-1"
        onMouseOver={() => {
          setHover(true)
        }}
        onMouseOut={() => {
          setHover(false)
        }}
      >
        <Image image={imageData} alt={alt || ''} covercss={coverCss} />
      </ImageWrap>
      <Content>
        <ContentGroup>
          <Heading as="h2" headingSize="3">
            {title}
          </Heading>
          <DateContainer>
            {date && (
              <p>
                <Datetime>{date}</Datetime>
              </p>
            )}
            {updatedAtDate && (
              <p>
                <DateText>(Päivitetty: {updatedAtDate})</DateText>
              </p>
            )}
          </DateContainer>
          {children && <Lead>{children}</Lead>}
        </ContentGroup>
        <CtaButton to={to} data-hover={isHover} large="true">
          Lue lisää
        </CtaButton>
      </Content>
    </SplitCardHeroContainer>
  )
}

SplitCardHero.propTypes = propTypes

export default SplitCardHero
