import React from 'react'
import { ThemeProvider } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { invertedTheme } from '../../styles/theme'
import FooterComponent from './component'
import { getUniqueValues } from '../../utils'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        footerNav: contentfulNavigationGroup(name: { eq: "FOOTER" }) {
          items {
            __typename
            ... on Node {
              ... on ContentfulArticle {
                slug
                title
              }
              ... on ContentfulCareerPost {
                slug
                title
              }
              ... on ContentfulFund {
                slug
                title
              }
              ... on ContentfulInsurance {
                slug
                title
              }
              ... on ContentfulJobPosting {
                slug
                title
              }
              ... on ContentfulPage {
                slug
                title
              }
              ... on ContentfulLink {
                url
                title
              }
              ... on ContentfulLegal {
                slug
                title
              }
              ... on ContentfulInvestorMaterialPage {
                slug
                title
              }
            }
          }
          footerItems {
            ... on ContentfulFooter {
              buttons
              contactInformation
            }
          }
        }
        allContentfulPodcastEpisode(
          sort: { fields: [publicationDate], order: ASC }
          filter: { isPublished: { eq: true } }
        ) {
          edges {
            node {
              __typename
              slug
              category
              contentType
              publicationDate
              image {
                title
                gatsbyImageData(width: 768, placeholder: BLURRED)
              }
              previewHeroHeading
              previewHeading
              previewBody {
                previewBody
              }
              metadata {
                tags {
                  name
                  id
                }
              }
            }
          }
        }
        allContentfulArticle(
          sort: { fields: [publicationDate], order: DESC }
          filter: { customerGroups: { eq: null } }
        ) {
          edges {
            node {
              __typename
              contentType
              slug
              category
              publicationDate
              updatedAtDate
              image {
                title
                gatsbyImageData(width: 768, placeholder: BLURRED)
              }
              previewHeading
              previewHeroHeading
              previewBody {
                previewBody
              }
              metadata {
                tags {
                  name
                  id
                }
              }
            }
          }
        }
      }
    `}
    render={({
      footerNav: { items: footerNavItems, footerItems },
      allContentfulArticle: { edges: articles },
      allContentfulPodcastEpisode: { edges: podcasts },
    }) => {
      const sortedContent = [
        ...articles.map(({ node }) => node),
        ...podcasts.map(({ node }) => node),
      ].sort(
        (a, b) => new Date(b.publicationDate) - new Date(a.publicationDate)
      )

      const allItems = sortedContent.slice(0)

      const filterGroups = {
        type: 'tag',
        label: 'Suosittuja aihesanoja',
        values: [
          ...getUniqueValues(
            allItems.flatMap(item => item.metadata.tags).map(tag => tag.name)
          ).sort(),
        ],
      }

      const getPostsAmount = filterGroupValue => {
        if (filterGroupValue === 'all') {
          return allItems.length
        }
        const filteredItems = allItems.filter(item =>
          item.metadata.tags.some(tag => tag.name === filterGroupValue)
        )
        return filteredItems.length
      }

      const filterGroupsWithPostsAmount = {
        ...filterGroups,
        values: filterGroups.values.map(value => ({
          value,
          postsAmount: getPostsAmount(value),
        })),
      }

      return (
        <ThemeProvider theme={invertedTheme}>
          <FooterComponent
            {...{
              footerNavItems,
              footerItems,
              filterGroups: filterGroupsWithPostsAmount,
            }}
          />
        </ThemeProvider>
      )
    }}
  />
)

export default Footer
