import React from 'react'
import ExtLinkIconWrapper from '../links/extLinkIconWrapper'
import AccessibilityText from '../typography/accessibilityText'
import { ReactComponent as FacebookIcon } from '../../images/facebook.svg'
import { ReactComponent as YoutubeIcon } from '../../images/youtube.svg'
import { ReactComponent as LinkedinIcon } from '../../images/linkedin.svg'
import { ReactComponent as SpotifyIcon } from '../../images/spotify.svg'

const SocialMediaLinks = () => (
  <>
    <ExtLinkIconWrapper to="https://www.facebook.com/alexandria.group.oyj">
      <FacebookIcon />
      <AccessibilityText>To Alexandria Facebook page</AccessibilityText>
    </ExtLinkIconWrapper>
    <ExtLinkIconWrapper to="https://www.linkedin.com/company/alexandria-group-oyj">
      <LinkedinIcon />
      <AccessibilityText>To Alexandria LinkedIn page</AccessibilityText>
    </ExtLinkIconWrapper>
    <ExtLinkIconWrapper to="https://www.youtube.com/user/AlexandriaOyj">
      <YoutubeIcon />
      <AccessibilityText>To Alexandria Youtube page</AccessibilityText>
    </ExtLinkIconWrapper>
    <ExtLinkIconWrapper to="https://open.spotify.com/show/3DYhwwvvTTEyMJmeEhQ6wd?si=634f7c21ef33436a">
      <SpotifyIcon />
      <AccessibilityText>To Alexandria Spotify page</AccessibilityText>
    </ExtLinkIconWrapper>
  </>
)

export default SocialMediaLinks
