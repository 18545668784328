import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { invertedTheme } from '../../styles/theme'
import ContentfulCard from '../contentfulCard'
import Container from '../container'
import HeroSection from './heroSection'

const propTypes = {
  sections: arrayOf(
    shape({
      __typename: string.isRequired,
    })
  ),
}

const StyledContentfulCard = styled(ContentfulCard)``

const StyledContainer = styled(Container)`
  font-size: 1rem;

  &:first-of-type:not(:first-child)
    ${StyledContentfulCard},
    &
    + &:not(:last-of-type)
    ${StyledContentfulCard} {
    border-top: 1px solid ${({ theme }) => theme.colors.blue};
  }
`

const resolveHeroSectionProps = ({ contentStyle, ...props }) => ({
  theme: contentStyle === 'content-teaser' ? invertedTheme : null,
  isHighlighted: contentStyle === 'content-teaser',
  contentStyle,
  ...props,
})

const slugToCategory = s => (s || '').replace(/-/g, ' ').toLowerCase()

const getGeneratedCategory = ({ slug, previewHeading }) => {
  const category = slugToCategory(slug)
  return category && previewHeading.toLowerCase() !== category
    ? category
    : undefined
}

const resolveCardProps = ({ category, ...props }) => ({
  category: category || getGeneratedCategory(props),
  ...props,
})

const HeroSections = ({ sections }) =>
  sections &&
  sections.map((teaser, i) => {
    switch (teaser.__typename) {
      case 'ContentfulHeroSection': {
        const { theme: heroSectionTheme, ...heroSectionProps } =
          resolveHeroSectionProps(teaser)
        const heroSectionNode = (
          <HeroSection
            {...heroSectionProps}
            key={`${teaser.__typename}-${i}`}
          />
        )
        if (heroSectionTheme) {
          return (
            <ThemeProvider
              theme={heroSectionTheme}
              key={`${teaser.__typename}-${i}`}
            >
              {heroSectionNode}
            </ThemeProvider>
          )
        }
        return heroSectionNode
      }
      case 'ContentfulPage':
        return (
          <StyledContainer key={`${teaser.__typename}-${i}`}>
            <StyledContentfulCard card={resolveCardProps(teaser)} isHero />
          </StyledContainer>
        )
      default:
        console.error(
          `Missing definitions for hero of type ${teaser.__typename}`
        )
        return null
    }
  })

HeroSections.propTypes = propTypes

export default HeroSections
