import React from 'react'
import { graphql } from 'gatsby'
import iFrameResizer from 'iframe-resizer'
import styled from 'styled-components'

import personnelCardGroups from '../components/cards/personnelCardGroups'
import { getHero, CardGroups } from '../components/contentfulPage'
import SubNavigation from '../components/subNavigation'
import Layout from '../components/layout'
import LayoutContent from '../components/layoutContent'
import ContentfulText from '../components/contentfulText'
import CisionNewsReleases from '../components/cisionReleases/cisionNewsReleases'
import CisionStockPurchaseReleases from '../components/cisionReleases/cisionStockPurchaseReleases'
import smallCaps from '../styles/mixins/smallCaps'
import medium from '../styles/mixins/medium'
import hint from '../styles/mixins/hint'
import { getHeadingStyle } from '../components/typography/headings'

const StyledContentfulText = styled(ContentfulText)`
  iframe {
    width: 100%;
  }

  table {
    background-color: ${({ theme }) => theme.colors.white};
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;

    + ol {
      list-style: none;
      counter-reset: item;
      font-size: 75%;
      padding-left: 0;
      padding-bottom: ${({ theme }) => theme.spacing.medium};

      li {
        counter-increment: item;
        margin-bottom: 5px;

        &:before {
          margin-right: 10px;
          content: counter(item) ')';
          color: ${({ theme }) => theme.colors.primaryColor};
          text-align: center;
          display: inline-block;
        }
      }
    }

    tr {
      border-bottom: 1px solid ${({ theme }) => theme.spacing.primaryColor};

      td {
        &:first-of-type {
          ${medium}
          ${hint}
          padding-right: ${({ theme }) => theme.spacing.medium};

          em {
            ${smallCaps}
            font-style: normal;
          }
        }

        strong {
          ${getHeadingStyle(4)}
          margin-bottom: 0;
        }

        padding-top: ${({ theme }) => theme.spacing.medium};
        padding-bottom: ${({ theme }) => theme.spacing.medium};
      }
    }
  }
`

const PageWithSubnavigation = ({
  data: { page },
  pageContext: { subNavigation, pagePath, slug },
}) => {
  const image = `https:${page.image.file.url}`

  React.useEffect(() => {
    iFrameResizer.iframeResizer(
      { log: false, checkOrigin: false },
      'iframe[src*="investis"]'
    )
  })

  const peopleCards = page.cards?.filter(
    i => i.__typename === 'ContentfulDepartment'
  )

  const { cardGroups } = personnelCardGroups({
    page: { cards: peopleCards },
    hash: page.slug,
    itemsPerRow: 1,
  })

  return (
    <Layout
      title={page.title}
      description={page.description.description}
      image={image}
      hero={getHero(page)}
    >
      <LayoutContent
        subNavigation={
          <SubNavigation subNavigation={subNavigation} pagePath={pagePath} />
        }
        renderContent={MainComponent => (
          <>
            {page.body && (
              <MainComponent>
                <StyledContentfulText text={page.body} />
                {cardGroups && (
                  <CardGroups toggle={false} cardGroups={cardGroups} />
                )}
              </MainComponent>
            )}
            {slug === 'tiedotteet' && <CisionNewsReleases slug={slug} />}
            {slug === 'ilmoitukset-omien-osakkeiden-hankkimisesta' && (
              <CisionStockPurchaseReleases slug={slug} />
            )}
          </>
        )}
      />
    </Layout>
  )
}

export default PageWithSubnavigation

export const query = graphql`
  fragment contentfulInvestorMaterialPageInformation on ContentfulInvestorMaterialPage {
    slug
    hasSubNavigation
    heroType
    heroTheme
    callToActionText
    callToActionTarget
    title
    description {
      description
    }
    image {
      file {
        url
      }
      title
      gatsbyImageData(width: 1200, placeholder: BLURRED)
    }
    videoEmbed {
      videoEmbed
    }
    heading
    leadParagraph {
      leadParagraph
    }
    body {
      childMarkdownRemark {
        html
      }
    }
    footer {
      footer
    }
    shareable
    cards {
      __typename
      ... on Node {
        ... on ContentfulArticle {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewHeroHeading
          previewBody {
            previewBody
          }
          publicationDate
        }
        ... on ContentfulCareerPost {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
        }
        ... on ContentfulFund {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          riskRating
          previewBody {
            previewBody
          }
        }
        ... on ContentfulInsurance {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
        }
        ... on ContentfulJobPosting {
          ...contentfulJobPostingInformation
        }
        ... on ContentfulPage {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
        }
        ... on ContentfulCard {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
          longHeading {
            longHeading
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulCallToActionCard {
          __typename
          name
          heading
          body {
            childMarkdownRemark {
              html
            }
          }
          buttonText
          buttonTarget {
            ... on ContentfulPage {
              slug
            }
          }
        }
        ... on ContentfulDepartment {
          id
          title
          people {
            title
            firstName
            lastName
            slug
            phoneNumber
            email
            office {
              city
              location {
                lat
                lon
              }
            }
            image {
              title
              gatsbyImageData(height: 200, placeholder: BLURRED)
            }
          }
        }
        ... on ContentfulStructuredInvestment {
          ...contentfulStructuredInvestmentInformation
          leadParagraph {
            leadParagraph
          }
        }
        ... on ContentfulHeroSection {
          __typename
          heading
          contentStyle
          category
          teasers {
            heading
            id
            richTextJson
            cta {
              to
              text
            }
          }
        }
      }
    }
  }
  query ($slug: String!) {
    page: contentfulInvestorMaterialPage(slug: { eq: $slug }) {
      ...contentfulInvestorMaterialPageInformation
    }
  }
`
