import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'
import { ReactComponent as Coffee } from '../../images/coffee.svg'
import { ReactComponent as Crown } from '../../images/crown.svg'
import { ReactComponent as Diamond } from '../../images/diamond.svg'
import { ReactComponent as Leaf } from '../../images/leaf.svg'

const icons = [<Coffee />, <Crown />, <Diamond />, <Leaf />]

const Wrapper = styled.div(
  ({ theme: t }) => `
  width: 100%;
  background-color: ${hexToRgba(t.colors.blueLight, 0.5)};
`
)

const Content = styled.div(
  ({ theme: t }) => `
  display: flex;
  max-width: ${t.width.max};
  margin: 0 auto;
  flex-direction: row;
  padding: 20px 60px;
  height: 120px;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    flex-direction: column;
    height: auto;
    padding: 10px;
    gap: 10px;
  }
`
)

const LeftSide = styled.div(
  ({ theme: t }) => `
  position: relative;
  display: flex;
  flex-direction: row;
  width: 50%;
  align-items: center;

  &:after {
    content: '';
    right:0;
    flex-direction: row;
    position:absolute;
    height: 100%;
    width: 0px;
    border: 1px solid ${hexToRgba(t.colors.blueLight, 0.5)};
  }

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    width: 100%;
    gap: 10px;
    &:after {
      display: none;
    }
  }

  @media screen and (max-width: 370px) {
    flex-direction: column;
  }
`
)

const RightSide = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: row;
  width: 50%;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    width: 100%;
    gap: 10px;
    height: 100%;
  }

  @media screen and (max-width: 370px) {
    flex-direction: column;
  }
`
)

const Benefit = styled.div(
  ({ theme: t }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 50%;
  min-width: 170px;
  height: 100%;
  align-items: center;

  &:after {
    right:0;
    flex-direction: row;
    content: '';
    position:absolute;
    height: 100%;
    width: 0px;
    border: 1px solid ${hexToRgba(t.colors.blueLight, 0.5)};
  }

  &:last-child:after {
    display: none;
  }

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    width:100%;
    height: 78px;
    &:after {
      right: -6px;
    }
  }

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    &:after {
      display: none;
    }
  }
`
)

const Icon = styled.div(
  () => `
  display: flex;
  width: 32px;
  height: 32px;
`
)

const Text = styled.div(
  ({ theme: t }) => `
  display: flex;
  width: fit-content;
  max-width: 208px;
  max-height: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  transition: 100ms;
  
  @media screen and (max-width: ${t.breakpoints.md.min}) {
    font-size: 14px;
    line-height: 17px;
  }
`
)

const BenefitBar = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulBenefitBar {
          benefits
        }
      }
    `}
    render={({ contentfulBenefitBar: { benefits } }) => {
      const combineBenefitsAndIcons = () => {
        const benefitsWithIcon = []
        for (let i = 0; i < 4; i++) {
          benefitsWithIcon.push([icons[i], benefits[i]])
        }
        return benefitsWithIcon
      }

      const benefitsWithIcons = combineBenefitsAndIcons()
      return (
        <Wrapper>
          <Content>
            <LeftSide>
              {benefitsWithIcons.slice(0, 2).map((benefit, index) => (
                <Benefit key={`benefit-${index}`} id={`benefit-${index}`}>
                  <Icon key={`icon-${index}`}>{benefit[0]}</Icon>
                  <Text key={`text-${index}`}>{benefit[1]}</Text>
                </Benefit>
              ))}
            </LeftSide>
            <RightSide>
              {benefitsWithIcons.slice(2, undefined).map((benefit, index) => (
                <Benefit key={`benefit-${index + 2}`}>
                  <Icon key={`icon-${index + 2}`}>{benefit[0]}</Icon>
                  <Text key={`text-${index + 2}`}>{benefit[1]}</Text>
                </Benefit>
              ))}
            </RightSide>
          </Content>
        </Wrapper>
      )
    }}
  />
)

export default BenefitBar
