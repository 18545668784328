import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import Input from './input'
import HintText from '../typography/hintText'
import ButtonStyle from '../buttons/button'
import { ReactComponent as FileUploadSvg } from '../../images/file-upload.svg'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Button = styled(ButtonStyle)`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.small};
  font-size: 0.875em;
  padding-left: 1em;
  padding-right: 1.125em;

  svg {
    margin-right: 0.25em;
    width: 1.5em;
    height: 1.5em;
  }
`

const Filename = styled(HintText)`
  display: block;
`

const FileInput = ({
  field,
  form,
  helpText,
  label,
  required,
  accept,
  ...props
}) => {
  const [filename, setFilename] = useState('')
  const inputRef = useRef()

  const triggerInput = () => inputRef.current.click()
  const handleInputValueChange = event => {
    form.setFieldValue(field.name, event.currentTarget.files[0])
    setFilename(event.currentTarget.files[0].name)
  }

  return (
    <Input
      field={field}
      form={form}
      helpText={helpText}
      label={label}
      required={required}
    >
      <Wrapper>
        <Button
          onClick={e => {
            e.preventDefault()
            triggerInput()
          }}
        >
          <FileUploadSvg />
          Valitse
        </Button>
        <Filename>{filename}</Filename>
        <input
          type="file"
          name={field.name}
          id={field.name}
          accept={accept || '*'}
          onChange={event => handleInputValueChange(event)}
          {...props}
          ref={inputRef}
          hidden
        />
      </Wrapper>
    </Input>
  )
}

export default FileInput
