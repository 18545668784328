import moment from 'moment'
import finnishHolidays from 'finnish-holidays-js'
import { set, isToday } from 'date-fns'

/**
 * Returns the latest date found in the array of objects given as param
 * @param data: An array of objects
 * @param dateField: The key for the date field in the objects. Defaults to 'date'.
 * @returns {string}: The latest date in DD.MM.YYYY format.
 */
export const latestDate = (data, dateField = 'date') => {
  const date = data.reduce((latest, item) => {
    const newDate = new Date(item[dateField])
    if (!latest || latest < newDate) {
      return newDate
    }
    return latest
  }, new Date('1970-01-01'))
  return formatDate(date)
}

export const formatDate = (date, format = 'DD.MM.YYYY') => {
  return moment(new Date(date)).format(format)
}

export const formatDateWithoutZeros = inputDate => {
  let delimiter
  let formattedDate
  if (inputDate.includes('-')) {
    delimiter = '-'
    const [year, month, day] = inputDate.split(delimiter)
    formattedDate = `${parseInt(day)}.${parseInt(month)}.${year}`
  } else if (inputDate.includes('.')) {
    delimiter = '.'
    const [day, month, year] = inputDate.split(delimiter)
    formattedDate = `${parseInt(day)}.${parseInt(month)}.${year}`
  } else return

  return formattedDate
}

export const isPublicHoliday = () => {
  try {
    const nextHoliday = finnishHolidays.next()[0]
    const { year, month, day } = nextHoliday
    const nextHolidayDate = set(new Date(), {
      year,
      month: month - 1,
      date: day,
    })
    return isToday(nextHolidayDate)
  } catch (error) {
    console.error('Error while parsing next holiday')
    return false
  }
}
