import React, { useState, useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from '@reach/router'
import { Context as UiContext } from '../context/uiContext'
import ContentfulPage from '../components/contentfulPage'
import ContentHeroCard from '../components/cards/contentHeroCard'
import LoadMoreButton from '../components/cards/loadMoreButton'
import ContentCard from '../components/cards/contentCard'
import UpArrowButton from '../components/buttons/UpArrowButton'
import FilterOptionsGroup from '../components/filterOptions/filterOptionsGroup'
import { getUniqueValues, sanitizeString } from '../utils'
import {
  FILTER_OPTION_ALL,
  SORT_OPTION_NEWEST,
  SORT_OPTION_POPULAR,
} from '../utils/constants'
import MobileDropdownFilters from '../components/mobileDropdownFilters'

const makeSlug = (slug, contentType) =>
  contentType === 'podcast'
    ? `/sisallot/rikasta-elamaa/${slug}`
    : `/sisallot/${slug}`

const mapHeroProps = ({
  slug,
  image,
  mobileImage,
  publicationDate,
  contentType,
  previewHeroHeading,
  previewHeading,
  previewBody,
  previewHeroBody,
  seasonNumber,
}) => {
  const _previewHeroBody = previewHeroBody?.childMarkdownRemark?.html
  return {
    image,
    mobileImage,
    title: previewHeroHeading || previewHeading,
    to: makeSlug(slug, contentType),
    publicationDate,
    body: _previewHeroBody ? (
      <div dangerouslySetInnerHTML={{ __html: _previewHeroBody }} />
    ) : (
      <p>{previewBody.previewBody}</p>
    ),
    contentType,
    seasonNumber,

    // This property adjusts the image position in the page hero
    // You might want to adjust the value depending on the image to crop specific images properly
    // The current value crops people's faces into the frame for rikasta-elamaa page
    imagePositionCss: '50% 20%',
  }
}

const getSorterFunction = sortType => {
  switch (sortType) {
    case SORT_OPTION_POPULAR:
      return (a, b) => b.pageViews - a.pageViews
    case SORT_OPTION_NEWEST:
      return (a, b) => new Date(b.publicationDate) - new Date(a.publicationDate)
    default:
      return (a, b) => new Date(b.publicationDate) - new Date(a.publicationDate)
  }
}

const NoPostsMessage = styled.p`
  text-align: center;
  margin: 1em 0 0 0;
`

const ContentPage = ({ data: { page }, pageContext: { content } }) => {
  const {
    state: { articleFilters: selectedFilters, articleSorter: selectedSort },
    setArticleFilters: setSelectedFilters,
    setArticleSorter: setSelectedSort,
  } = useContext(UiContext)
  const location = useLocation()
  const isMobile = useMediaQuery({
    query: `(max-width: 768px)`,
  })
  const searchParams = new URLSearchParams(location.search)
  const [selectedCategory, setSelectedCategory] = useState(
    searchParams.get('category')
  )
  const [selectedTag, setSelectedTag] = useState(searchParams.get('tag'))
  const allItems = content.slice(0)
  const [displayedItemCount, setDisplayedItemCount] = useState(6)
  const filterGroups = [
    {
      type: 'contentType',
      label: 'Sisältötyypit',
      values: [
        'Kaikki',
        ...getUniqueValues(allItems.map(item => item.contentType)).sort(),
      ],
    },
    {
      type: 'category',
      label: 'Kategoriat',
      values: [
        'Kaikki',
        ...getUniqueValues(allItems.map(item => item.category)).sort(),
      ],
    },
    {
      type: 'tag',
      label: 'Tagit',
      values: [
        'Kaikki',
        ...getUniqueValues(
          allItems.flatMap(item => item.metadata.tags).map(tag => tag.name)
        ).sort(),
      ],
    },
  ]
  const sorter = {
    type: 'sort',
    label: 'Järjestä',
    values: [SORT_OPTION_NEWEST, SORT_OPTION_POPULAR],
  }
  const [filteredItems, setFilteredItems] = useState(allItems)
  const itemsToDisplay = filteredItems.slice(0, displayedItemCount)
  const [showScrollButton, setShowScrollButton] = useState(false)

  const onSelectedFilterChange = ({ type, values }) => {
    setSelectedFilters({
      ...selectedFilters,
      [type]: values,
    })
  }

  const onClearAllFilters = () => {
    const payload = {
      contentType: [FILTER_OPTION_ALL],
      category: [FILTER_OPTION_ALL],
      tag: [FILTER_OPTION_ALL],
    }
    setSelectedFilters(payload)
  }

  const hasSelectedFilters = () =>
    Object.entries(selectedFilters).reduce((acc, filterGroup) => {
      const [, values] = filterGroup
      if (values.filter(v => v !== FILTER_OPTION_ALL).length > 0) {
        acc = true
      }
      return acc
    }, false)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const categoryParam = params.get('category')
    const tagParam = params.get('tag')
    const payload = { ...selectedFilters, tag: [FILTER_OPTION_ALL] }

    function addToPayload(param, filterGroup) {
      if (!param) return
      payload[filterGroup] = getUniqueValues(
        [
          ...selectedFilters[filterGroup],
          sanitizeString(decodeURIComponent(param)),
        ].filter(v => v !== FILTER_OPTION_ALL)
      )
    }

    function removeFromPayload(filterGroup) {
      if (!payload[filterGroup] || payload[filterGroup].length <= 1) return
      payload[filterGroup] = [
        payload[filterGroup][payload[filterGroup].length - 1],
      ]
    }

    addToPayload(categoryParam, 'category')
    addToPayload(tagParam, 'tag')
    removeFromPayload('contentType')
    removeFromPayload('category')
    removeFromPayload('tag')
    payload.contentType = [FILTER_OPTION_ALL]
    payload.category = categoryParam
      ? [categoryParam.toLocaleLowerCase().replace(/\s/g, '')]
      : [FILTER_OPTION_ALL]
    payload.tag = tagParam
      ? [tagParam.toLocaleLowerCase().replace(/\s/g, '')]
      : [FILTER_OPTION_ALL]
    setSelectedFilters(payload)
  }, [location]) // eslint-disable-line

  useEffect(() => {
    if (!selectedSort) return
    setFilteredItems(prev =>
      prev.slice(0).sort(getSorterFunction(selectedSort))
    )
  }, [selectedSort])

  useEffect(() => {
    const hero = page.cards[0]
    const allContent = content.slice(0)
    const {
      tag: selectedTags,
      category: selectedCategories,
      contentType: selectedContentTypes,
    } = selectedFilters

    let filteredContent = allContent
      .filter(item => item.slug !== 'laajemmat-tiedot-yrityslainoista')
      .filter(item => {
        return selectedContentTypes.includes(FILTER_OPTION_ALL)
          ? item.slug !== hero.slug
          : selectedContentTypes.includes(sanitizeString(item.contentType)) &&
              item.slug !== hero.slug
      })
      .filter(item => {
        return selectedCategories.includes(FILTER_OPTION_ALL)
          ? item.slug !== hero.slug
          : selectedCategories.includes(sanitizeString(item.category)) &&
              item.slug !== hero.slug
      })
      .filter(item => {
        return selectedTags.includes(FILTER_OPTION_ALL)
          ? item.slug !== hero.slug
          : item.metadata.tags.some(tag =>
              selectedTags.includes(sanitizeString(tag.name))
            ) && item.slug !== hero.slug
      })

    if (selectedSort) {
      filteredContent = filteredContent.sort(getSorterFunction(selectedSort))
    }
    setFilteredItems(filteredContent)
    setDisplayedItemCount(6)
  }, [
    selectedFilters,
    selectedTag,
    selectedCategory,
    selectedSort,
    content,
    page,
  ]) // eslint-disable-line

  useEffect(() => {
    setSelectedCategory(searchParams.get('category'))
    setSelectedTag(searchParams.get('tag'))
  }, [location.search]) // eslint-disable-line

  useEffect(() => {
    const hero = page.cards[0]
    const allContent = content.slice(0)
    const {
      tag: selectedTags,
      category: selectedCategories,
      contentType: selectedContentTypes,
    } = selectedFilters

    let filteredContent = allContent
      .filter(item => {
        return selectedContentTypes.includes(FILTER_OPTION_ALL)
          ? item.slug !== hero.slug
          : selectedContentTypes.includes(sanitizeString(item.contentType)) &&
              item.slug !== hero.slug
      })
      .filter(item => {
        return selectedCategories.includes(FILTER_OPTION_ALL)
          ? item.slug !== hero.slug
          : selectedCategories.includes(sanitizeString(item.category)) &&
              item.slug !== hero.slug
      })
      .filter(item => {
        return selectedTags.includes(FILTER_OPTION_ALL)
          ? item.slug !== hero.slug
          : item.metadata.tags.some(tag =>
              selectedTags.includes(sanitizeString(tag.name))
            ) && item.slug !== hero.slug
      })

    if (selectedSort) {
      filteredContent = filteredContent.sort(getSorterFunction(selectedSort))
    }

    let updatedContent =
      selectedTag !== null
        ? filteredContent.filter(item =>
            item.metadata.tags.some(tag => tag.name === selectedTag)
          )
        : filteredContent.filter(item => item.category === selectedCategory)

    if (selectedSort) {
      updatedContent = updatedContent.sort(getSorterFunction(selectedSort))
    }

    setFilteredItems(updatedContent)
  }, [selectedTag, selectedCategory, selectedSort]) // eslint-disable-line

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 3000) {
        setShowScrollButton(true)
      } else {
        setShowScrollButton(false)
      }
    })
    return () => window.removeEventListener('scroll', () => {}, false)
  }, [])

  return (
    <ContentfulPage
      page={page}
      floatingElement={{
        visible: showScrollButton,
        render: () => (
          <UpArrowButton
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }}
            label={'Takaisin ylös'}
          />
        ),
      }}
      cardGroups={[
        {
          hero: (
            <ContentHeroCard
              {...mapHeroProps(page.cards[0])}
              key={page.cards[0].slug}
            />
          ),
          filterMenu: {
            optionGroups: isMobile
              ? filterGroups.map(filterGroup => (
                  <MobileDropdownFilters
                    key={filterGroup.type}
                    filterGroup={filterGroup}
                    onSelectedFilterChange={onSelectedFilterChange}
                  />
                ))
              : filterGroups.map(({ type, values, label }) => (
                  <FilterOptionsGroup
                    key={type}
                    type={type}
                    options={values}
                    label={label}
                    onOptionSelected={onSelectedFilterChange}
                    activeOptions={selectedFilters[type]}
                  />
                )),
            postsAmount: filteredItems.length,
            clearAllFilters: onClearAllFilters,
            hasSelectedFilters: hasSelectedFilters(),
            sort: {
              value: selectedSort,
              options: sorter.values,
              onChange: event => setSelectedSort(event.target.value),
            },
          },
          items:
            itemsToDisplay.length > 0
              ? itemsToDisplay.map(item => (
                  <ContentCard key={item.slug} card={item} />
                ))
              : [
                  <NoPostsMessage key="no-posts">
                    Valitsemillasi suodatusvalinnoilla ei löytynyt postauksia
                  </NoPostsMessage>,
                ],
          itemsPerRow: itemsToDisplay.length > 0 ? 3 : 1,
          footer: (
            <LoadMoreButton
              updateItemCount={setDisplayedItemCount}
              currentCount={displayedItemCount}
              totalItemCount={filteredItems.length}
              delta={6}
            />
          ),
        },
      ]}
    />
  )
}

export default ContentPage

export const query = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
  }
`
