import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { ReactComponent as BubbleTip } from '../../images/bubbletip.svg'
import { ReactComponent as BubbleTipHorizontal } from '../../images/bubbletip-horizontal.svg'

const solveBackgroundColor = score => {
  return score >= 9
    ? '#449F6B'
    : score >= 7
    ? '#AFEAFF'
    : score >= 5
    ? '#FFCA5D'
    : '#FFB6B6'
}

const FeedbackBubbleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0;

  @media screen and (min-width: ${props => props.theme.breakpoints.sm.min}) {
    max-width: ${props => props.theme.breakpoints.md.max};
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`

const ResponsiveBubbleTipContainer = styled.div`
  display: flex;

  @media screen and (min-width: ${props => props.theme.breakpoints.sm.min}) {
    flex-direction: column;
    flex: 0 0 1em;
    height: 100%;
    justify-content: center;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    flex-direction: row;
    align-items: flex-start;
    flex: 0 0 1.5em;
    width: 100%;
  }
`

const ResponsiveBubbleTip = styled(BubbleTip)`
  fill: ${props => solveBackgroundColor(props.score)};

  @media screen and (min-width: ${props => props.theme.breakpoints.sm.min}) {
    width: 1em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    display: none;
  }
`
const ResponsiveBubbleTipHorizontal = styled(BubbleTipHorizontal)`
  fill: ${props => solveBackgroundColor(props.score)};

  @media screen and (min-width: ${props => props.theme.breakpoints.sm.min}) {
    display: none;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    margin-left: 1em;
    height: 1.5em;
  }
`

const FeedbackBubbleStyled = styled.div`
  background-color: ${props => rgba(solveBackgroundColor(props.score), 0.2)};
  list-style-type: none;
  padding: 1.5rem 1rem;
  border-radius: 1.5rem;
  flex-grow: 1;
  box-sizing: border-box;
`

export const FeedbackBubble = ({ feedback }) => {
  return (
    <FeedbackBubbleWrapper>
      <ResponsiveBubbleTipContainer>
        <ResponsiveBubbleTip score={feedback.nps_score} />
        <ResponsiveBubbleTipHorizontal score={feedback.nps_score} />
      </ResponsiveBubbleTipContainer>
      <FeedbackBubbleStyled score={feedback.nps_score}>
        {feedback.comment}
      </FeedbackBubbleStyled>
    </FeedbackBubbleWrapper>
  )
}
