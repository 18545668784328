import React from 'react'
import styled from 'styled-components'

import NavItem from '../navbar/navItem'
import NavLink from '../navbar/navLink'
import Button from '../buttons/button'
import { visible, lightenBackground } from '../../utils'
import CatchOutsideClick from '../catchOutsideClick'

const Nav = styled.nav`
  display: flex;
  ${visible({ lg: true, xl: true })}
  align-items: center;
  height: ${p => p.theme.navHeight.min};
  position: fixed;
  width: 100%;
  margin-top: ${p => p.theme.navHeight.lg};
  margin-bottom: ${p => p.theme.navHeight.lg};

  &.searchOpen {
    margin-top: calc(${p => p.theme.navHeight.lg} + 100px);
  }

  ${({ theme: { colors, breakpoints }, drawShadow = '' }) => `
    ${
      drawShadow &&
      `
      box-shadow: 0 4px 11px -9px rgba(0,0,0,.15);
    `
    }

    background: ${lightenBackground(colors.backgroundColor, 0.3)};
    opacity: 1;
    z-index: 100;

    a, button {
      :not(${Button}) {
        color: ${colors.primaryColor};
      }
    }

    ul ul {
      a:not(${Button}) {
        color: ${colors.primaryColorInverted};
      }
    }
  `}
`

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0 auto;
  max-width: ${props => props.theme.width.max};
  padding: 0 ${props => props.theme.spacing.xlarge};
  /* This pseudo element exists to force safari to render nav element background transitions correctly.
    Without a child that extends of nav box model,
    safari sometimes transitions background to white,grey or black instead of transparent */
  :after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    transform: translateY(5px);
    opacity: 0;
  }

  /* IE11 fix that navbar stretches 100% */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
    flex: 0 1 auto;
  }
`
const NavGroupContainer = styled(CatchOutsideClick)`
  margin: auto;
  display: flex;
  align-items: center;
`

// can use CatchOutsideClick with as or withComponent since ref will no longer match
const List = styled.ul`
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, auto);
  grid-auto-flow: column;
  align-items: center;
  font-size: 16px;
`

const DesktopSubNavbar = ({ subNavigation, isSearchOpen, ...rest }) => {
  return (
    <Nav
      className={isSearchOpen ? 'searchOpen' : ''}
      aria-label="Main"
      {...rest}
    >
      <Container>
        <NavGroupContainer onClick={() => {}}>
          <List>
            {subNavigation.map(
              (item, idx) =>
                idx > 0 && (
                  <NavItem key={item.title}>
                    <NavLink data={item}>{item.title}</NavLink>
                  </NavItem>
                )
            )}
          </List>
        </NavGroupContainer>
      </Container>
    </Nav>
  )
}

export default DesktopSubNavbar
