import React, { useState } from 'react'
import { oneOf, node } from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { useLocation } from '@reach/router'
import { Heading } from '../components/typography/headings'

import SEO from './seo'
import Favicon from './favicon'
import Container from './container'
import Navbar from './navbar/navbar'
import ProductGroup from './productGroup/productGroup'
import FeaturedArticleHighlight from './featuredArticleHighlight/featuredArticleHighlight'
import ToolsBar from './toolsBar/toolsBar'
import StepsBar from './stepsBar/stepsBar'
import InfoBar from './infoBar/infoBar'
import RelatedPosts from '../components/cards/relatedPosts'
import ArticleCategories from '../components/articleCategories/articleCategories'
import CustomerExperienceSection from './customerExperienceSection/customerExperienceSection'
import BenefitBar from './benefitBar/benefitBar'
import Separator from './separator'
import ProductInfoSection from './productInfoSection/productInfoSection'
import ContactUsBar from './contactUsBar/contactUsBar'
import ContactMethods from './contactMethods/contactMethods'
import ContactContainer from './contactContainer/contactContainer'
import ReservationContainer from './reservationContainer/reservationContainer'
import Share from './share'
import Footer from './footer/footer'
import '../styles/fonts.css'
import defaultTheme from '../styles/theme'
import GlobalStyle from '../styles/globalStyle'
import { isCustomerExperiencePage } from '../utils/segments'

const shouldHeroDrawShadow = ({ props = {} }) => {
  if (props.children) {
    return props.children.props.useConfetti
  }
  return props.useConfetti
}

const Main = styled.main`
  margin: 0 auto;
  flex: 1 1 auto;
  width: 100%;

  > *:last-child:not(:only-child) {
    margin-bottom: 2em;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md.min}) {
    font-size: 1.125em;

    > *:last-child:not(:only-child) {
      margin-bottom: calc(3em + 1%);
    }
  }
`

const ContentHeader = styled.header(
  ({ hasCustomHero, isFrontPage, isSearchOpen }) => `
  margin-top: ${isSearchOpen ? '100px' : '0'};
  ${hasCustomHero ? 'margin-bottom: 0em;' : 'margin-bottom: 1.5em;'}
  ${isFrontPage && 'margin-bottom: 0;'}
`
)

const Article = styled.article``

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
`
const BottomShare = styled.div`
  margin-top: 0px;
`

const Layout = ({
  children,
  title,
  description,
  image,
  hero,
  navbarTheme,
  shareable,
  previewHeading,
  publicationDate,
  containerWidth,
  contentFooter,
  className,
  theme,
  renderNavbar = true,
  productGroup,
  featuredArticleHighlights,
  showToolsBar,
  stepsBar,
  articleCategories,
  showContactMethods,
  contactMethodsImage,
  contactMethodsShort,
  contactMethodsWithImage,
  contactMethodsLarge,
  reservationContainerImage,
  productInfoSections,
}) => {
  const location = useLocation()
  const isFrontPage = location.pathname === '/'
  const isSijoittaminen = location.pathname.includes('/sijoittaminen')
  const isMeista = location.pathname.includes('/alexandria-yrityksena')
  const isSisallot = location.pathname === '/sisallot'
  const isArticlePage = location.pathname.includes('/sisallot/')
  const isContactPage = location.pathname.includes(
    '/toimipaikat-ja-yhteystiedot'
  )
  const isOffice = location.pathname.includes('/toimipaikat/')
  const isVaraaAika = location.pathname.includes('/varaa-aika')
  const isCXPage = isCustomerExperiencePage(location.pathname) // Todo: should display ReservationContainer for these pages
  const isRahastot = location.pathname === '/rahastot'
  const isVakuutukset = location.pathname === '/vakuutukset'
  const isVastuullisuus = location.pathname.includes('/vastuullisuus')

  const landingPage = children[1]
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  return (
    <ThemeProvider theme={theme || defaultTheme}>
      <LayoutContainer shareable={shareable} className={className}>
        <SEO
          title={title}
          description={description}
          image={image}
          previewHeading={previewHeading}
        />
        <Favicon />
        <GlobalStyle />
        {renderNavbar && (
          <Navbar
            theme={navbarTheme || (hero && hero.props.theme)}
            drawShadow={hero && shouldHeroDrawShadow(hero)}
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
          />
        )}

        <Main>
          <Article>
            {hero && (
              <ContentHeader
                hasCustomHero={!!hero.props.theme}
                isFrontPage={isFrontPage}
                isSearchOpen={isSearchOpen}
              >
                {hero}
              </ContentHeader>
            )}
            {landingPage && children[1][0]}
            {productGroup && <ProductGroup productGroup={productGroup} />}
            {showToolsBar && !isMeista && <ToolsBar />}
            {stepsBar && isSijoittaminen && <StepsBar steps={stepsBar} />}
            {(isMeista || isVaraaAika) && !isVastuullisuus && <InfoBar />}
            {isVaraaAika && (
              <ReservationContainer
                calendarOpen={true}
                image={reservationContainerImage || image}
              />
            )}
            {(isMeista || isVaraaAika) && !isVastuullisuus && <Separator />}
            {(isFrontPage || isSijoittaminen) &&
              featuredArticleHighlights?.length > 0 &&
              featuredArticleHighlights.map(featuredArticleHighlight => (
                <FeaturedArticleHighlight
                  featuredArticle={featuredArticleHighlight}
                  sijoittaminen={isSijoittaminen}
                />
              ))}
            {landingPage && children[1].length ? (
              <React.Fragment>
                {children[1].slice(1).map((child, index) => (
                  <React.Fragment key={index}>{child}</React.Fragment>
                ))}
              </React.Fragment>
            ) : (
              children
            )}
            {location.pathname === '/strukturoidut-sijoitukset' && (
              <ProductInfoSection productInfoSections={productInfoSections} />
            )}
            {(isRahastot || isVakuutukset) && (
              <ProductInfoSection productInfoSections={productInfoSections} />
            )}
            {((stepsBar && !isSijoittaminen) || isOffice) && (
              <StepsBar steps={stepsBar} />
            )}
            {!isFrontPage &&
              !isSijoittaminen &&
              !isVaraaAika &&
              featuredArticleHighlights?.length > 0 &&
              featuredArticleHighlights.map(featuredArticleHighlight => (
                <FeaturedArticleHighlight
                  featuredArticle={featuredArticleHighlight}
                  sijoittaminen={isSijoittaminen}
                  fullImage={!!isMeista}
                />
              ))}
            {shareable && (
              <Container containerWidth={containerWidth} isFlowing>
                <BottomShare>
                  <Share />
                </BottomShare>
              </Container>
            )}
            {isSijoittaminen && <Separator />}
            {isSijoittaminen && (
              <RelatedPosts
                headerContent={
                  <Heading as="h2" headingSize="3">
                    Lue *asiantuntijoidemme* vinkkejä ja katsauksia
                  </Heading>
                }
              />
            )}
            {articleCategories && (
              <ArticleCategories articleCategories={articleCategories} />
            )}
            {showToolsBar && isMeista && <ToolsBar meista={isMeista} />}
            {(isSijoittaminen || isMeista) &&
              !isFrontPage &&
              !isVastuullisuus && <CustomerExperienceSection />}
            {(isMeista || isSisallot) && <ContactUsBar />}
            {(showContactMethods || isArticlePage) && (
              <ContactMethods
                short={contactMethodsShort || isVaraaAika || isCXPage}
                withImage={contactMethodsWithImage || isSisallot}
                withContactRequest={isArticlePage}
                large={contactMethodsLarge || isMeista || isContactPage}
                noMarginTop={isContactPage}
                image={contactMethodsImage || image}
                title={
                  isMeista || isSisallot
                    ? 'Kerromme mielellämme *lisää*'
                    : isArticlePage
                    ? ''
                    : isContactPage
                    ? 'Miten haluat ottaa *meihin* yhteyttä?'
                    : 'Muita tapoja ottaa *yhteyttä*'
                }
              />
            )}
            {isVaraaAika && <ContactUsBar />}
            {isVaraaAika &&
              featuredArticleHighlights?.length > 0 &&
              featuredArticleHighlights.map(featuredArticleHighlight => (
                <FeaturedArticleHighlight
                  featuredArticle={featuredArticleHighlight}
                  fullImage={true}
                />
              ))}
            {isContactPage && <ContactContainer />}
            {isArticlePage && <Separator />}
            {isArticlePage && (
              <RelatedPosts
                headerContent={
                  <Heading as="h2" headingSize="3">
                    Sijoittamisen aloittaminen
                  </Heading>
                }
              />
            )}
            {isArticlePage && (
              <ArticleCategories articleCategories={articleCategories} />
            )}
          </Article>
          {contentFooter}
        </Main>
        <BenefitBar />
        <Footer />
      </LayoutContainer>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: node.isRequired,
  contentFooter: node,
  containerWidth: oneOf(['content', 'max', 'contentInside']),
}

export default Layout
