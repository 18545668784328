import React, { useState } from 'react'
import styled, { withTheme } from 'styled-components'
import moment from 'moment'
import {
  format,
  isAfter,
  isSameDay,
  subMonths,
  isMonday,
  differenceInMonths,
} from 'date-fns'
import fi from 'date-fns/locale/fi'
import { stripUnit } from 'polished'
import { maxBy, minBy } from 'lodash'
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryArea,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory'
import DatePicker from '../datePicker'
import PopperMenu from '../popperMenu'
import Button from '../buttons/button'
import Datetime from '../format/datetime'
import { formatDate } from '../../utils/dates'

const DATA_FROM_BEGINNING = 'DATA_FROM_BEGINNING'

const TimeFrameButton = styled.button`
  text-align: left;
  padding: 0 0.5em 0 0;

  &[aria-selected='true'] {
    border-bottom: 2px solid currentColor;
  }
`

const CustomDates = styled.div`
  margin: 0.5em -0.5em 0;
`

const MenuWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const TimeframeWrapper = styled.div`
  margin-top: ${props => props.theme.spacing.medium};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`

const Performance = styled.div`
  color: ${props => props.theme.colors.primaryColor};
  text-align: right;
  ${props =>
    props.red &&
    `
    color: ${props.theme.colors.red}
  `}
`

const PopperButton = styled(Button)`
  margin-left: 1em;

  &:after {
    content: '';
    margin-left: 3px;
    border: 3px solid transparent;
    border-bottom: none;
    border-top-color: currentColor;
    display: inline-block;
    vertical-align: middle;
  }
`

const TimeFrameList = styled.ul`
  li {
    flex: 0 1 auto;
    width: 100%;
    margin: 0 0 0.5em;
  }
`

const PerformanceDate = styled(Datetime)`
  margin-right: 0.75em;
  vertical-align: middle;
`

const allowedTimeFrames = [
  { label: 'Alusta', value: DATA_FROM_BEGINNING },
  { label: '6 kuukautta', value: 'M6', type: 'months', count: 6 },
  { label: '3 kuukautta', value: 'M3', type: 'months', count: 3 },
  { label: '1 kuukausi', value: 'M1', type: 'months', count: 1 },
]

const fromBeginningAndOverM36 = ({ firstDate, lastDate, timePeriod }) =>
  timePeriod === DATA_FROM_BEGINNING &&
  differenceInMonths(lastDate, firstDate) >= 36

const getDomain = timeFrameData => {
  return {
    y: [minBy(timeFrameData, 'y').y * 0.9, maxBy(timeFrameData, 'y').y * 1.1],
  }
}

const getTimeFrames = performanceData =>
  [
    ...performanceData,
    {
      TimePeriod: DATA_FROM_BEGINNING,
      Value: DATA_FROM_BEGINNING,
    },
  ]
    .map(item => {
      const index = allowedTimeFrames.findIndex(
        timeFrame => timeFrame.value === item.TimePeriod
      )
      if (index === -1) {
        return null
      }
      return { ...item, label: allowedTimeFrames[index].label }
    })
    .filter(Boolean)
    .reverse()

const getCurrentData = (priceHistory, timeFrame, startDate, endDate) => {
  const lastDate = new Date(priceHistory.PriceDetails.slice(-1)[0].Date)
  const numberOfMonths = Number(timeFrame.TimePeriod.slice(1))
  let firstDate
  if (timeFrame.TimePeriod === DATA_FROM_BEGINNING) {
    firstDate = new Date(priceHistory.PriceDetails[0].Date) // Set first date as index 0 of priceHistory
  } else {
    firstDate = subMonths(lastDate, numberOfMonths) // Calculate first date subtracting from lastdate
  }
  // Find closest item after firstDate
  const index = priceHistory.PriceDetails.findIndex(item => {
    const date = new Date(item.Date)
    return isSameDay(date, firstDate) || isAfter(date, firstDate)
  })
  const slice = priceHistory.PriceDetails.slice(index).filter(item => {
    const itemDate = new Date(item.Date)
    return itemDate >= startDate && itemDate <= endDate
  })
  let data = null
  // If selected timeFrame is part of timeFramesWithWeeklyData OR if timeFrame is from beginning and lasts over 12 months
  if (
    fromBeginningAndOverM36({
      timePeriod: timeFrame.TimePeriod,
      firstDate,
      lastDate,
    })
  ) {
    // Include first and last day of selected range and mondays
    data = slice
      .filter((item, idx) =>
        idx === 0 || idx === slice.length - 1
          ? true
          : isMonday(new Date(item.Date))
      )
      .map(item => ({ x: new Date(item.Date), y: item.Value }))
  } else {
    data = slice.map(item => ({ x: new Date(item.Date), y: item.Value }))
  }
  const firstValue = data[0].y
  const lastValue = data[data.length - 1].y
  const performance = (lastValue - firstValue) / firstValue
  return {
    currentPerformance: performance,
    data,
  }
}

const getStartDate = (timeFrame, priceHistory) => {
  if (!timeFrame.type) {
    return new Date(priceHistory.PriceDetails[0].Date)
  } else {
    return moment().subtract(timeFrame.count, timeFrame.type).toDate()
  }
}

const PriceHistoryGraph = React.memo(
  ({ priceHistory, performanceData, theme: { radius, colors } }) => {
    const initialMinDate = new Date(priceHistory.PriceDetails[0].Date)
    const initialMaxDate = new Date()
    const [selectedTimeFrameIdx, setSelectedTimeFrameIdx] = useState(0)
    const [startDate, setStartDate] = useState(initialMinDate)
    const [endDate, setEndDate] = useState(initialMaxDate)
    const [popperVisible, setPopperVisible] = useState(false)
    const timeFrames = getTimeFrames(performanceData)
    const timeFrameData = getCurrentData(
      priceHistory,
      timeFrames[selectedTimeFrameIdx],
      startDate,
      endDate
    )
    const domain = getDomain(timeFrameData.data)

    const setTimeFrame = idx => {
      setSelectedTimeFrameIdx(idx)
      const timeFrame = allowedTimeFrames[idx]
      const endDate = new Date()
      const startDate = getStartDate(timeFrame, priceHistory)
      setStartDate(startDate)
      setEndDate(endDate)

      setPopperVisible(false)
    }

    const setCustomStartDate = customStartDate => {
      setSelectedTimeFrameIdx(0)
      setStartDate(customStartDate)
    }

    const setCustomEndDate = customEndDate => {
      setSelectedTimeFrameIdx(0)
      setEndDate(customEndDate)
    }

    return (
      <>
        <TimeframeWrapper>
          <MenuWrap>
            Valitse ajanjakso:
            <PopperMenu
              visible={popperVisible}
              setVisible={setPopperVisible}
              renderButton={btnProps => (
                <PopperButton {...btnProps}>
                  {allowedTimeFrames[selectedTimeFrameIdx].label}
                </PopperButton>
              )}
            >
              <TimeFrameList>
                {timeFrames.map((timeFrame, idx) => (
                  <li key={timeFrame.Value}>
                    <TimeFrameButton
                      aria-selected={idx === selectedTimeFrameIdx}
                      onClick={() => setTimeFrame(idx)}
                    >
                      {timeFrame.label}
                    </TimeFrameButton>
                  </li>
                ))}
              </TimeFrameList>
              <CustomDates>
                <DatePicker
                  onChange={customStartDate =>
                    setCustomStartDate(customStartDate)
                  }
                  value={startDate}
                  locale="fi"
                  format="dd.MM.y"
                  clearIcon={null}
                  calendarIcon={null}
                  minDate={initialMinDate}
                  maxDate={endDate}
                />
                <DatePicker
                  onChange={customEndDate => setCustomEndDate(customEndDate)}
                  value={endDate}
                  locale="fi"
                  format="dd.MM.y"
                  clearIcon={null}
                  calendarIcon={null}
                  minDate={startDate}
                  maxDate={initialMaxDate}
                />
              </CustomDates>
            </PopperMenu>
          </MenuWrap>

          <Performance red={timeFrameData.currentPerformance < 0}>
            <PerformanceDate>
              {formatDate(startDate)} - {formatDate(endDate)}{' '}
            </PerformanceDate>
            <span>{`${timeFrameData.currentPerformance.toLocaleString('fi', {
              style: 'percent',
              minimumFractionDigits: 2,
            })}`}</span>
          </Performance>
        </TimeframeWrapper>

        <VictoryChart
          height={300}
          width={600}
          padding={{ top: 10, bottom: 10, left: 50, right: 50 }}
          containerComponent={<VictoryVoronoiContainer voronoiDimension="x" />}
          scale={{ x: 'time', y: 'linear' }}
        >
          <VictoryAxis
            dependentAxis
            tickFormat={value => `${value.toLocaleString('fi')}`}
            style={{
              axis: { stroke: 'transparent' },
              grid: { stroke: colors.greyLight },
              tickLabels: {
                fill: colors.grey,
                fontFamily: 'inherit',
                fontSize: 14,
              },
            }}
          />
          <VictoryGroup
            data={timeFrameData.data}
            x="x"
            y="y"
            domain={domain}
            style={{ data: { stroke: colors.primaryColor, strokeWidth: 1 } }}
            labels={({ datum }) => [
              format(new Date(datum.x), 'dd.MM.yyyy', { locale: fi }),
              `Arvo: ${datum.y}`,
            ]}
            labelComponent={
              <VictoryTooltip
                centerOffset={{ y: -40 }}
                renderInPortal={true}
                cornerRadius={stripUnit(radius.md)}
                pointerWidth={0}
                pointerLength={0}
                dy={20}
                style={{
                  fontFamily: 'inherit',
                  fill: colors.primaryColor,
                  fontSize: 16,
                  padding: 10,
                }}
                flyoutStyle={{ stroke: colors.greyLight, fill: colors.white }}
              />
            }
          >
            <VictoryLine
              style={{ data: { stroke: colors.primaryColor, strokeWidth: 1 } }}
            />
            <VictoryArea
              style={{
                data: { fill: colors.chart.savingsColor },
              }}
            />
          </VictoryGroup>
        </VictoryChart>
      </>
    )
  }
)

export default withTheme(PriceHistoryGraph)
