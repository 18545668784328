import React from 'react'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/contentfulPage'
import Container from '../components/container'
import Main from '../components/main'
import ContentfulText from '../components/contentfulText'

const CorporateLoanPage = ({
  data: {
    contentfulPage: { body, aside, asideHeading, ...page },
  },
}) => {
  return (
    <ContentfulPage page={page}>
      <Container containerWidth="content">
        <Main>
          <ContentfulText text={body} marginTop={true} />
        </Main>
      </Container>
    </ContentfulPage>
  )
}

export default CorporateLoanPage

export const query = graphql`
  query ($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
  }
`
