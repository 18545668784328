import React from 'react'
import { string } from 'prop-types'

const propTypes = {
  slug: string,
}

const pagesWithBot = ['varainhoito', 'yrityksen-sijoitukset', 'sijoittaminen']

const slugToBotName = {
  varainhoito: 'varainhoito',
  'yrityksen-sijoitukset': 'yrityksen-sijoitukset',
  sijoittaminen: 'sijoittaminen',
  // 'meille-toihin': 'rekry', deploy this bot later
}

const InpageBot = ({ slug }) => {
  if (!pagesWithBot.includes(slug)) {
    return null
  }

  return <ld-slot name={slugToBotName[slug]}></ld-slot>
}

InpageBot.propTypes = propTypes

export default InpageBot
