import React from 'react'
import { css as _css } from 'styled-components'
import theme from '../styles/theme'

export { navigate } from 'gatsby-link'

const hideSize = size => {
  // min-key missing, expecting to be smallest breakpoint
  // eslint-disable-next-line no-prototype-builtins
  if (!theme.breakpoints[size].hasOwnProperty('min')) {
    return _css`
      @media (max-width: ${theme.breakpoints[size].max}) {
        display: none;
      }
    `
    // max-key missing, expecting to be largest breakpoint
    // eslint-disable-next-line no-prototype-builtins
  } else if (!theme.breakpoints[size].hasOwnProperty('max')) {
    return _css`
      @media (min-width: ${theme.breakpoints[size].min}) {
        display: none;
      }
    `
  } else {
    return _css`
      @media (min-width: ${theme.breakpoints[size].min}) and (max-width: ${theme.breakpoints[size].max}) {
        display: none;
      }
    `
  }
}

export const visible = sizes => {
  const hidable = Object.keys(theme.breakpoints).filter(
    breakpoint => !sizes[breakpoint]
  )
  return _css`
    ${hidable.map(size => hideSize(size))}
  `
}

export const getCurrentSection = location => {
  const { pathname } = location

  return pathname.split('/')[1]
}

export const encodeForm = data => {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }
  return formData
}

/**
 * Changes a string value to a number if the string is valid number
 * @param value: a string
 * @returns {number|*}: returns a number or NaN if the value is a string, else returns the value
 */
export const stringToNumber = value => {
  if (typeof value !== 'string') return value
  if (value === '-') return 0
  return Number(value.replace('%', '').replace(',', '.').replace(' ', ''))
}

export const isString = s => typeof s === 'string'

export const sanitizeString = str =>
  str.toLowerCase().trim().split(' ').join('')

export const makeOctalNumber = n =>
  String(n).length === 1 && String(n) !== '0' ? '0' + n : String(n)

/**
 * Changes multi-line string to partial of paragraphs. Non-string and single-line -strings are returned as they are.
 * @param children: a string
 * @returns {array|string|*}: returns an array of nodes or a string or whatever it was given.
 */
export const getContentfulLongTextAsNode = children => {
  if (!isString(children) || !children) {
    return children || null
  }

  /* string can be of many line breaks and line breaks can also have empty lines in between.
   * Empty lines will be empty array indexes.
   */
  const parts = children.split('\n')

  // If there's no parts, it's a single-line string so return as a node.
  if (parts.length < 2) {
    return parts[0]
  }

  /*
   * Go through all parts and bundle line-breaked parts in own arrays.
   * The reduce takes something like ['foo', 'bar', '', 'foobar']
   * and returns [['foo', 'bar'], ['foobar']]
   * the map turns that into [<p>foo<br />bar<p>, <p>foobar</p>]
   */
  return parts
    .reduce((acc, curr) => {
      // if not an empty line
      if (curr) {
        const prevIndx = acc.length - 1
        // if previous item is in an array...
        if (typeof acc[prevIndx] === 'object') {
          // bundle previous child with this one.
          return [...acc.slice(0, prevIndx), [...acc[prevIndx], curr]]
        }
        // this is basically the first child to be looped
        return [...acc, [curr]]
      }
      // empty line here, so we need to close the previous array and start a new
      return [...acc, []]
    }, [])
    .map((nodeArray, i) => (
      <p key={`ltn-${i}`}>
        {nodeArray.map((text, i) => (
          <React.Fragment key={`ltn-line-${i}`}>
            {text}
            {nodeArray.length > 1 && i < nodeArray.length - 1 ? <br /> : null}
          </React.Fragment>
        ))}
      </p>
    ))
}

const makeArrayChunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr]
  if (chunkSize <= 0) return cache
  while (tmp.length) cache.push(tmp.splice(0, chunkSize))
  return cache
}

/*
 * NOTE: no sanitation, but for now there's no point for one either.
 * Doesn't handle cases where it gets 'foo="" bar=""' or 'foo="" bar="foobar"'. Regex requires
 * fine tuning if such are needed in future
 */
export const dangerouslyMakePropsFromString = str => {
  if (!str) {
    return {}
  }
  const keyValueArray = str
    .trim()
    .split(/="|"+\s|"$/)
    .filter(Boolean)

  return Object.fromEntries(new Map(makeArrayChunk(keyValueArray, 2)))
}

export const getUniqueValues = array => Array.from(new Set(array))

export const linkTo = link => {
  if (link.__typename === 'ContentfulInvestorMaterialPage') {
    return `/sijoittajat/${link.slug}`
  } else if (link.__typename === 'ContentfulLegal') {
    return `/yleista/${link.slug}`
  } else if (link.__typename === 'ContentfulArticle') {
    return `/sisallot/${link.slug}`
  } else {
    return `/${link.slug}`
  }
}

export const lightenBackground = (hex, opacity) => {
  const cleanHex = hex.replace('#', '')
  const red = parseInt(cleanHex.substring(0, 2), 16)
  const green = parseInt(cleanHex.substring(2, 4), 16)
  const blue = parseInt(cleanHex.substring(4, 6), 16)
  const lightenedRed = Math.round(red + (255 - red) * opacity)
  const lightenedGreen = Math.round(green + (255 - green) * opacity)
  const lightenedBlue = Math.round(blue + (255 - blue) * opacity)
  const lightenedHex = `#${lightenedRed.toString(16)}${lightenedGreen.toString(
    16
  )}${lightenedBlue.toString(16)}`
  return lightenedHex
}

export const darkenBackground = (hex, opacity) => {
  const cleanHex = hex.replace('#', '')
  const red = parseInt(cleanHex.substring(0, 2), 16)
  const green = parseInt(cleanHex.substring(2, 4), 16)
  const blue = parseInt(cleanHex.substring(4, 6), 16)
  const darkenedRed = Math.round(red * (1 - opacity))
  const darkenedGreen = Math.round(green * (1 - opacity))
  const darkenedBlue = Math.round(blue * (1 - opacity))
  const darkenedHex = `#${darkenedRed.toString(16)}${darkenedGreen.toString(
    16
  )}${darkenedBlue.toString(16)}`
  return darkenedHex
}
