import React from 'react'
import styled from 'styled-components'
import BackgroundImage from '../backgroundImage'
import ContentfulText from '../contentfulText'
import { ImageWrap } from './splitHero'
import {
  getHeadingFontSize,
  getHeadingFontBySize,
} from '../typography/headings'

const Container = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg.min}) {
    width: 50%;

    br {
      display: block;
    }
  }

  br {
    display: none;
  }
`

const HeroWrapper = styled.div`
  margin-top: 48px;
  background-color: ${({ theme }) => theme.colors.blue};
  padding-bottom: ${({ theme }) => theme.spacing.xxlarge};

  @media (min-width: ${({ theme }) => theme.breakpoints.lg.min}) {
    margin-top: 80px;
    padding-top: ${({ theme }) => theme.spacing.xxlarge};
  }
`

const HeroInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
  padding-left: ${({ theme }) => theme.spacing.xlarge};
  padding-right: ${({ theme }) => theme.spacing.xlarge};

  max-width: ${({ theme }) => theme.width.max};
  margin-left: auto;
  margin-right: auto;

  & br {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg.min}) {
    flex-direction: row-reverse;

    ${ImageWrap} {
      width: calc(55% - 1em);
    }

    br {
      display: inline;
    }
  }

  h1 {
    font-size: ${getHeadingFontSize(1)};
    margin-top: ${({ theme }) => theme.spacing.xxlarge};
    margin-bottom: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg.min}) {
      margin-top: 0;
      margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
    }
  }

  h2 {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${getHeadingFontSize(3)};
  }

  h3 {
    font: ${getHeadingFontBySize(2, 'subheading')};
  }

  h1,
  h3 {
    color: ${({ theme }) => theme.colors.red};
  }

  h2 {
    color: ${({ theme }) => theme.colors.white};
  }

  a {
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.white};
    display: inline-block;
    border-radius: ${({ theme }) => theme.spacing.xxlarge};
    padding: ${({ theme }) => theme.spacing.medium};
    font-size: 16px;
    transition: color 0.125s ease-in, border-color 0.125s ease-in,
      background-color 0.125s ease-in;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.primaryColor};
      border: 1px solid ${({ theme }) => theme.colors.primaryColor};
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`

const ITFHero = ({ image, text }) => {
  const { gatsbyImageData: imageData, title: alt } = image
  return (
    <HeroWrapper>
      <HeroInner>
        <ImageWrap>
          <BackgroundImage
            objectPosition="50% 0%"
            image={imageData}
            alt={alt || ''}
          />
        </ImageWrap>
        <Container>
          <ContentfulText text={text} />
        </Container>
      </HeroInner>
    </HeroWrapper>
  )
}

export default ITFHero
