import styled from 'styled-components'

export default styled.li.attrs({ role: 'menuitem' })`
  display: flex;
  padding: 0;
  margin: 0;

  &:first-of-type {
    margin-top: 0.5em;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`
