import React from 'react'
import { graphql } from 'gatsby'
import ContentfulPage from '../components/contentfulPage'
import ContactSelect from '../components/contactSelect'
import SectionHeader from '../components/headers/sectionHeader'
import NotFound from '../pages/404'
import { ThemeProvider } from 'styled-components'
import SplitHero from '../components/heroes/splitHero'
import { lightTheme } from '../styles/theme'

const renderHero = ({ heading }) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <SplitHero hasSubNavigation={false} heading={heading} />
    </ThemeProvider>
  )
}

const ContactInformation = ({ data }) => {
  const page = data.page

  if (!page) {
    return <NotFound />
  }
  const offices = data.offices.nodes
  const people = data.people.nodes
  return (
    <>
      <ContentfulPage
        page={{ ...page, ...{ image: null, useConfettiHero: false } }}
        hero={renderHero({ heading: page.heading })}
        cardGroups={[
          {
            hero: <SectionHeader title="" />,
            items: [<ContactSelect offices={offices} people={people} />],
            itemsPerRow: 1,
            containerWidth: 'content',
            smallPadding: true,
            centered: true,
          },
        ]}
      />
    </>
  )
}

export default ContactInformation

export const query = graphql`
  query {
    page: contentfulPage(slug: { eq: "toimipaikat-ja-yhteystiedot" }) {
      ...contentfulPageInformation
    }
    offices: allContentfulOffice(
      sort: { fields: [city, address], order: ASC }
    ) {
      nodes {
        id
        name
        slug
        address
        postalCode
        city
        phoneNumber
        faxNumber
        personnel {
          id
        }
        description {
          id
        }
      }
    }
    people: allContentfulPerson(
      sort: { fields: [lastName, firstName], order: ASC }
    ) {
      nodes {
        id
        slug
        firstName
        lastName
        title
        phoneNumber
        email
        image {
          gatsbyImageData(width: 768, placeholder: BLURRED)
        }
      }
    }
  }
`
