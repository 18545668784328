import React from 'react'
import { node, number, oneOfType, string } from 'prop-types'
import styled, { css as _css } from 'styled-components'
import BallImg from '../../images/ball.png'
import Card from './card'
import Link from '../links/link'
import Subheading from '../typography/headings'
import Image from '../backgroundImage'
import SmallText from '../typography/smallText'
import { imagePropTypes } from '../../utils/prop-types'
import { lightenBackground } from '../../utils'

const propTypes = {
  image: imagePropTypes,
  title: string.isRequired,
  children: node,
  metaText: oneOfType([node, string]),
  riskRating: number,
}

export const ImageWrap = styled.div`
  position: relative;
  height: 280px;

  > div {
    height: 280px;
    border-radius: 30px 30px 0 0;
  }
`

const Content = styled.div(
  ({ theme: { colors } }) => `
  color: ${colors.blue};
  padding: 30px;

  h2 {
    line-height: 33px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    line-height: 18px;

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
`
)

const MetaText = styled(SmallText)`
  text-transform: uppercase;
  margin: auto 0 0;
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5em;
    display: none;
  }
`

const maxBalls = 7
const ballSizePx = 14
const ballGap = 2
const RiskLevel = styled(MetaText)(
  ({ riskRating = 0 }) => `
  position: relative;
  padding-right: ${maxBalls * ballSizePx + (maxBalls - 2) * ballGap}px;
  white-space: nowrap;
  
  &:after,
  &:before {
    content: '';
    background-image: url('${BallImg}');
    background-repeat: space;
    position: absolute;
    height: 100%;
    background-position: center left;
    background-size: ${ballSizePx}px;
  }

  &:before {
    right: 0;
    width: ${maxBalls * ballSizePx + (maxBalls - 2) * ballGap}px;
    filter: grayscale(1);
    opacity: .25;
  }

  ${
    riskRating &&
    `
    &:after {
      width: ${
        riskRating * ballSizePx + Math.max(1, riskRating - 2) * ballGap
      }px;
      right: ${
        (maxBalls - 1) * ballSizePx +
        (maxBalls - 2) * ballGap -
        ((riskRating - 1) * ballSizePx + Math.max(1, riskRating - 2) * ballGap)
      }px;
    }
  `
  }
`
)

const SplitCardLink = styled(Card.withComponent(Link))(
  ({ theme: { colors } }) => `
  display: block;
  max-width: 640px;
  padding: 0;
  margin: 0;
  background-color: ${lightenBackground(colors.blueLight, 0.6)};
  border-radius: 30px;
  text-decoration: none !important;

  &:hover {
    background-color: ${lightenBackground(colors.blueLight, 0.6)} !important;
    transform: scale(1.02);
    transition: transform 0.5s;
  }

  ${({ isstructured }) =>
    isstructured === 'false' &&
    _css`
      @media (min-width: 48em) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        ${ImageWrap},
        ${ImageWrap} + ${Content} {
          width: calc(50% - 0.75rem);
          margin-bottom: 0;
        }

        ${Content} {
          display: flex;
          flex-direction: column;
        }
      }
    `}
`
)

const SplitCard = ({
  image,
  title,
  children,
  to,
  className,
  riskRating,
  metaText,
  __typename,
}) => {
  const { gatsbyImageData: imageData, title: alt } = image
  return (
    <SplitCardLink
      {...{ to, className }}
      isstructured={(
        __typename === 'ContentfulStructuredInvestment'
      ).toString()}
    >
      {imageData && (
        <ImageWrap>
          <Image image={imageData} alt={alt || ''} />
        </ImageWrap>
      )}
      <Content>
        <Subheading as="h2" headingSize="1">
          {title}
        </Subheading>
        {children && <p style={{ whiteSpace: 'pre-line' }}>{children}</p>}
        {typeof riskRating === 'number' ? (
          <RiskLevel riskRating={riskRating}>Riskitaso {riskRating}</RiskLevel>
        ) : null}
        {metaText && <MetaText>{metaText}</MetaText>}
      </Content>
    </SplitCardLink>
  )
}

SplitCard.propTypes = propTypes

export default SplitCard
