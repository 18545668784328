const zIndeces = {
  1: 10,
  2: 20,
  4: 40,
  6: 60,
  8: 80,
  12: 120,
  16: 160,
  24: 240,
  32: 320,
}

export const zIndex = {
  navbar: zIndeces[32],
  fixedNavbar: zIndeces[1],
  coverImage: zIndeces[2],
  dropDown: zIndeces[32],
  floatingButton: zIndeces[32],
}

export const sections = {
  business: 'yritys',
  default: '',
}

export const FILTER_OPTION_ALL = 'kaikki'
export const SORT_OPTION_NEWEST = 'newest'
export const SORT_OPTION_POPULAR = 'mostpopular'
