import React from 'react'
import { withTheme } from 'styled-components'
import useWindowSize from 'react-use/lib/useWindowSize'
import ReactConfetti from 'react-confetti'

// NOTE: useWindowSize has an issue on server side. Do this until said issue is fixed.
const isClient = () =>
  typeof window !== 'undefined' && typeof window.innerWidth !== 'undefined'

const crawCircle = ctx => {
  ctx.beginPath()
  ctx.arc(1, 1, 8, 0, 2 * Math.PI)
  ctx.fill()
  ctx.closePath()
}

const SizedConfetti = React.forwardRef((passedProps, ref) => {
  const { width, height } = useWindowSize()
  return <ReactConfetti width={width} height={height} {...passedProps} />
})

const Confetti = ({ onComplete, theme, ...props }) =>
  isClient() ? (
    <SizedConfetti
      run
      recycle
      numberOfPieces={120}
      wind={0}
      gravity={0.05}
      initialVelocityX={4}
      initialVelocityY={10}
      opacity={100}
      onConfettiComplete={onComplete}
      colors={theme ? theme.colors.confettiColors : undefined}
      drawShape={crawCircle}
      {...props}
    />
  ) : null

export default withTheme(Confetti)
