import React from 'react'
import styled from 'styled-components'
import { arrayOf, string, number } from 'prop-types'
import { sanitizeString } from '../../utils'
import Link from '../links/link'

const propTypes = {
  values: arrayOf(string),
  postsAmount: arrayOf(number),
  label: string,
}

const OptionsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const GroupLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  line-height: 18px;
  margin-bottom: 15px;
`

const OptionLink = styled(Link)(
  ({ theme, postsamount }) => `
  display: flex;
  padding: 0 15px ${theme.spacing.xsmall} 0;
  font-size: ${postsamount < 10 ? '12px' : postsamount < 21 ? '16px' : '20px'};
  opacity: ${postsamount < 10 ? '0.7' : postsamount < 21 ? '0.85' : '1'};
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`
)

const FilterOption = ({ value, postsAmount }) => {
  return (
    <OptionLink
      to={`sisallot?tag=${encodeURIComponent(value)}#suodatus`}
      postsamount={postsAmount}
    >
      {value}
    </OptionLink>
  )
}

const FooterTagCloud = ({ values, postsAmount, label }) => {
  if (!values?.length) return null

  return (
    <div>
      <GroupLabel>{label}</GroupLabel>
      <OptionsContainer>
        {values?.map((value, index) => (
          <FilterOption
            key={sanitizeString(value)}
            value={value}
            postsAmount={postsAmount[index]}
          />
        ))}
      </OptionsContainer>
    </div>
  )
}

FooterTagCloud.propTypes = propTypes

export default FooterTagCloud
