import React, { useState, useEffect } from 'react'
import Flow from './flow'
import { ReactComponent as EuroIcon } from '../../../images/euro.svg'
import { ReactComponent as BoatIcon } from '../../../images/boat.svg'
import { ReactComponent as UmbrellaIcon } from '../../../images/umbrella.svg'
import { ReactComponent as PiggyBankIcon } from '../../../images/piggy-bank.svg'
import { ReactComponent as CompanyIcon } from '../../../images/company.svg'
import { ReactComponent as LightbulpIcon } from '../../../images/lightbulp.svg'

const FlowStep1 = ({ step, selectedOptions, onSelectedFlowOptionsChange }) => {
  const [buttonStates, setButtonStates] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const updateButtonStates = options => {
    const updatedStates = [...buttonStates]

    options.forEach(option => {
      if (option >= 0 && option < updatedStates.length) {
        updatedStates[option] = true
      }
    })

    setButtonStates(updatedStates)
  }

  useEffect(() => {
    selectedOptions && updateButtonStates(selectedOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  const flowButtons = [
    { icon: EuroIcon, text: step.buttonTexts[0] },
    {
      icon: BoatIcon,
      text: step.buttonTexts[1],
    },
    { icon: UmbrellaIcon, text: step.buttonTexts[2] },
    {
      icon: PiggyBankIcon,
      text: step.buttonTexts[3],
    },
    { icon: CompanyIcon, text: step.buttonTexts[4] },
    {
      icon: LightbulpIcon,
      text: step.buttonTexts[5],
    },
  ]

  return (
    <Flow
      heading={step.heading}
      flowButtons={flowButtons}
      buttonStates={buttonStates}
      setButtonStates={setButtonStates}
      onSelectedFlowOptionsChange={onSelectedFlowOptionsChange}
      currentStep={1}
    />
  )
}

export default FlowStep1
