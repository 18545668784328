import React from 'react'
import { graphql } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import { pinkTheme } from '../styles/theme'
import SplitHero from '../components/heroes/splitHero'
import Layout from '../components/layout'
import LayoutContent from '../components/layoutContent'
import CtaButton from '../components/buttons/ctaButton'
import StructuredInvestmentDetails from '../components/structuredInvestmentDetails'
import ReservationContainer from '../components/reservationContainer/reservationContainer'
import { lightenBackground } from '../utils'
import { formatDateWithoutZeros } from '../utils/dates'

const renderHero = ({ image, heading, title, leadParagraph }) => {
  const { leadParagraph: lead } = leadParagraph || {}
  return (
    <ThemeProvider theme={pinkTheme}>
      <SplitHero
        {...{ image, lead, heading: heading || title }}
        hasSubNavigation={false}
        productPage={true}
      />
    </ThemeProvider>
  )
}

const MainContent = styled.div(
  ({ theme: { breakpoints } }) => `
  display: flex;
  gap: 50px;

  @media (max-width: ${breakpoints.md.max}) {
    flex-direction: column;
    gap: 5px;
  }
`
)

const BodyTitle = styled.h3(
  ({ theme: { breakpoints } }) => `
  font-family: Scto Grotesk A;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 50px;

  @media (max-width: ${breakpoints.md.max}) {
    margin-bottom: 30px;
  }
`
)

const ColorBoxes = styled.div(
  ({ theme: { breakpoints } }) => `
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: ${breakpoints.md.max}) {
    margin-bottom: 30px;
  }
`
)

const ColorBox = styled.div(
  ({ theme: { colors, breakpoints } }) => `
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  background-color: ${lightenBackground(colors.blueLight, 0.7)};
  width: 308px;
  height: auto;

  @media ${breakpoints.md.max} {
    width: 100%;
  }

  & > span:first-child {
    font-family: Scto Grotesk A;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  & > span:last-child {
    font-family: Saol Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
  }
`
)

const TextContent = styled.div(
  ({ theme: { breakpoints }, small }) => `
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: auto;

  h3, p {
    max-width: ${small ? '203px' : '359px'};

    @media (max-width: ${breakpoints.md.max}) {
      max-width: 100%;
    }
  }
`
)

const Box = styled.div(
  ({ theme: { breakpoints } }) => `
  display: flex;
  flex-direction: column;
  border-radius: 30px;

  h3 {
    font-size: 38px;
    line-height: 42px; 

    @media (max-width: ${breakpoints.md.max}) {
      font-size: 28px;
      line-height: 33px;
    }
  }
  
  p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
`
)

const SmallBox = styled(Box)(
  ({ theme: { breakpoints, colors } }) => `
  position: relative;
  padding: 67px 42px 28px 30px;
  border-radius: 20px;

  @media (max-width: ${breakpoints.md.max}) {
    padding: 81px 82px 41px 29px;
    width: 100%;
  }

  h3 {
    max-width: 203px;
    margin-bottom: 0px;

    @media (max-width: ${breakpoints.md.max}) {
      max-width: 100%;
      font-size: 28px;
      line-height: 33px;
    }
  }

  p {
    max-width: 238px;

    @media (max-width: ${breakpoints.md.max}) {
      max-width: 100%;
    }
  }
`
)

const CtaWrapper = styled.div(
  ({ theme: { breakpoints } }) => `
  &:not(:empty) {
    margin-bottom: 50px;

    @media (max-width: ${breakpoints.md.max}) {
      margin-bottom: 30px;
    }
  }

  display: flex;
  gap: 10px;

  @media (max-width: ${breakpoints.md.max}) {
    flex-direction: column;
  }

  a {
    display: flex;
    height: 45px;
    width: fit-content;
    align-items: center;
    border-width: 2px;
    font-weight: 500;
  }
`
)

const SideCTA = styled(SmallBox)(
  ({ theme: { colors } }) => `
  background-color: ${lightenBackground(colors.orange, 0.6)};
`
)

const Cta = ({ theme: { colors } }) => `
  display: flex;
  height: 45px;
  width: 180px;
  align-items: center;
  justify-content: center;
  border: 2px solid ${colors.primaryColor};
  border-radius: 30px;
  color: ${colors.primaryColor};
  background-color: transparent;

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    background-color: ${colors.primaryColor};
    color: ${colors.white};
  }
`

const SideCtaButton = styled(CtaButton)`
  ${Cta}
`

const hasBeenIssued = ({ issueDate }) =>
  !!issueDate && new Date(issueDate) <= new Date().setUTCHours(0, 0, 0, 0)
const isSubscribable = ({ subscriptionPeriodEndDate }) =>
  new Date(subscriptionPeriodEndDate) >= new Date().setUTCHours(0, 0, 0, 0)

const StructuredInvestment = ({
  data: { contentfulStructuredInvestment: investment },
}) => {
  const image = `https:${investment.image.file.url}`
  const overviewLink =
    investment.overviewLink ||
    (investment.overview && investment.overview.file.url)

  return (
    <Layout
      title={investment.title}
      description={investment.description.description}
      image={image}
      hero={renderHero(investment)}
      showContactMethods={investment.showContactMethods}
      contactMethodsShort={true}
    >
      <LayoutContent
        renderContent={MainComponent => (
          <>
            {investment.bodyTitle && (
              <BodyTitle>{investment.bodyTitle.bodyTitle}</BodyTitle>
            )}
            <MainContent>
              <MainComponent alignTo="left">
                {investment.body && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: investment.body.childMarkdownRemark.html,
                    }}
                  />
                )}
                {hasBeenIssued(investment) && investment.confirmedTerms && (
                  <>
                    <h3>Vahvistetut ehdot</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          investment.confirmedTerms.childMarkdownRemark.html,
                      }}
                    />
                  </>
                )}
              </MainComponent>
              <ColorBoxes>
                <ColorBox>
                  <span>Merkintäaika päättyy</span>
                  <span>
                    {formatDateWithoutZeros(
                      investment.subscriptionPeriodEndDate
                    )}
                  </span>
                </ColorBox>
                {investment.sijoitusaika && (
                  <ColorBox>
                    <span>Sijoitusaika</span>
                    <span>{investment.sijoitusaika}</span>
                  </ColorBox>
                )}
                {investment.ctaCards &&
                  investment.ctaCards.map((card, index) => (
                    <SideCTA key={'ctaCard-' + index}>
                      <TextContent small={true}>
                        <h3>{card.heading}</h3>
                        <p>{card.body.body}</p>
                        <SideCtaButton
                          data={card.buttonTarget[0]}
                          key={'cardLink-' + index}
                        >
                          {card.buttonText}
                        </SideCtaButton>
                      </TextContent>
                    </SideCTA>
                  ))}
              </ColorBoxes>
            </MainContent>
            <CtaWrapper>
              {isSubscribable(investment) && (
                <CtaButton to={investment.documents[0].file.url} key="subslink">
                  Tutustu markkinointimateriaaliin
                </CtaButton>
              )}
              {overviewLink && (
                <CtaButton to={overviewLink} key="overviewlink">
                  Tuotteen katsaus
                </CtaButton>
              )}
            </CtaWrapper>
            <StructuredInvestmentDetails data={investment} />
            <ReservationContainer paddingTop={true} />
          </>
        )}
      />
    </Layout>
  )
}

export default StructuredInvestment

export const query = graphql`
  query ($slug: String!) {
    contentfulStructuredInvestment(slug: { eq: $slug }) {
      title
      description {
        description
      }
      heading
      leadParagraph {
        leadParagraph
      }
      bodyTitle {
        bodyTitle
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
        gatsbyImageData(width: 1200, placeholder: BLURRED)
      }
      name
      issuer
      isin
      sijoitusaika
      subscriptionPeriodEndDate
      issueDate
      expirationDate
      confirmedTerms {
        childMarkdownRemark {
          html
        }
      }
      documents {
        title
        description
        file {
          url
        }
      }
      overviewLink
      overview {
        title
        file {
          url
        }
      }
      showContactMethods
      ctaCards {
        heading
        body {
          body
        }
        buttonText
        buttonTarget {
          ... on Node {
            ... on ContentfulArticle {
              slug
              title
            }
            ... on ContentfulCareerPost {
              slug
              title
            }
            ... on ContentfulFund {
              slug
              title
            }
            ... on ContentfulInsurance {
              slug
              title
            }
            ... on ContentfulJobPosting {
              slug
              title
            }
            ... on ContentfulLegal {
              slug
              title
            }
            ... on ContentfulOffice {
              slug
              title
            }
            ... on ContentfulPage {
              slug
              title
            }
            ... on ContentfulStructuredInvestment {
              slug
              title
            }
            ... on ContentfulLink {
              url
              title
            }
          }
        }
      }
    }
  }
`
