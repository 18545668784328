import React, { useEffect, useState } from 'react'
import NewsReleases, { NewsReleaseItem } from './newsReleases'
import {
  fetchReleases,
  getNewReleases,
  LoadMoreButton,
  STOCK_PURCHASE_SLUG,
} from './cisionReleaseUtils'

const CisionReleases = ({ limit: LIMIT, slug, releases, total }) => {
  const initialFetch = releases.length
  const [state, setState] = useState({
    releases,
    itemsToBeShown: releases.length - LIMIT,
    initialFetch,
    maxLimit: total,
    limit: LIMIT,
    refreshed: false,
  })

  const showMore = () => {
    if (state.limit < state.initialFetch) {
      const newLimit = Math.min(state.maxLimit, LIMIT + state.limit)
      setState(s => ({
        ...s,
        itemsToBeShown: s.releases.length - newLimit,
        limit: newLimit,
      }))
    }
  }

  const feedUrl = releases[0].feedUrl

  useEffect(() => {
    if (!state.refreshed) {
      ;(async () => {
        const { refreshedData, refreshedTotal } = await fetchReleases(
          feedUrl,
          slug
        )

        // Check if there are new releases, which have not yet been there in the previous build process.
        if (refreshedData.length > initialFetch) {
          const newReleases = getNewReleases(refreshedData, releases)
          setState(s => {
            const refreshedReleases = [...newReleases, ...s.releases].sort(
              (a, b) => b.id - a.id
            )
            return {
              ...s,
              total: refreshedTotal,
              initialFetch: refreshedReleases.length,
              itemsToBeShown: refreshedReleases.length - s.limit,
              releases: refreshedReleases,
              refreshed: true,
            }
          })
        }
      })()
    }
  }, [
    setState,
    feedUrl,
    releases,
    total,
    initialFetch,
    state,
    state.refreshed,
    slug,
  ])

  const pageTitle =
    slug === STOCK_PURCHASE_SLUG
      ? 'Ilmoitukset omien osakkeiden hankkimisesta'
      : 'Tiedotteet'

  return (
    <NewsReleases title={pageTitle}>
      {[...state.releases.slice(0, state.limit)].map(
        ({ id, title, lead, publishDate, url }) => {
          return (
            <NewsReleaseItem
              {...{
                title,
                linkTo: url,
                lead,
                date: new Date(publishDate).toLocaleDateString('fi'),
                key: id,
              }}
            />
          )
        }
      )}
      {state.itemsToBeShown > 0 && (
        <LoadMoreButton onClick={showMore}>Näytä lisää</LoadMoreButton>
      )}
    </NewsReleases>
  )
}

export default CisionReleases
