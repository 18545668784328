import React from 'react'
import styled, { useTheme } from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import LargeText from './typography/largeText'
import BlueText from './typography/blueText'
import Link from './links/link'
import { ReactComponent as MenuIcon } from '../images/submenu.svg'
import { ReactComponent as CloseMenuIcon } from '../images/close.svg'

const SubNavigationWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.xxlarge};
  padding-bottom: ${({ theme }) => theme.spacing.xxlarge};

  @media (min-width: ${p => p.theme.breakpoints.lg.min}) {
    margin-right: ${({ theme }) => theme.spacing.large};
  }
`

const SubNavigationList = styled.ul`
  width: 100%;
  position: sticky;
  top: calc(128px);

  > li {
    border-bottom: ${({ theme, depth }) =>
      depth === 0 ? `1px solid ${theme.colors.blue}` : '1px solid transparent'};
    padding-top: ${({ theme }) => theme.spacing.medium};
    padding-bottom: ${({ theme }) => theme.spacing.medium};

    &:first-of-type {
      padding-top: 0;
    }
  }

  li > ul > li {
    padding-left: ${({ theme }) => theme.spacing.medium};
    padding-top: ${({ theme }) => theme.spacing.xsmall};
    padding-bottom: ${({ theme }) => theme.spacing.xsmall};

    &:first-of-type {
      padding-top: ${({ theme }) => theme.spacing.medium};
    }
  }

  ul > li:last-of-type {
    padding-bottom: 0;
  }

  ${({ isOpen }) => (!isOpen ? 'display: none' : '')}
`

const ItemTitle = styled.span`
  font-weight: ${({ isActive }) => (isActive ? 500 : 400)};
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
`

const ItemText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

const Toggle = styled.button`
  cursor: pointer;
  transform-origin: 50% 1rem;
  ${({ isOpen }) => (isOpen ? 'transform: rotate(180deg)' : '')};
  padding: 0.75rem;
  margin: -0.25rem -0.75rem -0.75rem 1rem;

  &:after {
    content: '';
    border-style: solid;
    border-width: 0.5rem;
    border-color: currentColor transparent transparent transparent;
    display: block;
  }
  transition: transform 0.2s ease-in;

  &:hover,
  &:focus {
    // matrix = rotate 180deg + scale 1.1
    transform: ${({ isOpen }) =>
      isOpen ? 'matrix(-1.20, 0, -0, -1.20,0,0)' : 'scale(1.2)'};
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }
`

const MobileMenuWrapperToggle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing.medium};
  margin-top: ${({ theme }) => `-${theme.spacing.medium}`};
  border-bottom: ${({ theme, isOpen }) =>
    !isOpen ? `1px solid ${theme.colors.blue}` : '1px solid transparent'};

  &[aria-expanded='true'] svg {
    transform: translateX(12px);
  }
`

const MobileMenuWrapper = styled.div`
  ${({ isOpen }) => (!isOpen ? 'display: none' : '')};
  width: 100%;
`

const MobileMenuIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  width: 40px;
`

const Item = ({ item, pagePath, depth = 0 }) => {
  const Text = depth === 0 ? LargeText : BlueText
  const isActive = pagePath === item.slug
  const isOpenInitial =
    isActive || item.children?.some(i => i.slug === pagePath)
  const [isOpen, setOpen] = React.useState(isOpenInitial)
  const id = `item-${depth}-${item.slug}`
  const hasChildren = item.children?.length

  return (
    <li>
      <ItemText>
        {item.hasContent ? (
          <Link to={item.slug}>
            <ItemTitle as={Text} isActive={isActive}>
              {item.title}
            </ItemTitle>
          </Link>
        ) : (
          <ItemTitle
            onClick={() => setOpen(!isOpen)}
            as={Text}
            isActive={isActive}
          >
            {item.title}
          </ItemTitle>
        )}
        {hasChildren ? (
          <Toggle
            isOpen={isOpen}
            onClick={() => setOpen(!isOpen)}
            aria-expanded={isOpen}
            role="button"
            aria-label={isOpen ? 'Sulje' : 'Avaa'}
            aria-controls={id}
          />
        ) : null}
      </ItemText>
      {hasChildren ? (
        <SubNavigationList depth={depth + 1} isOpen={isOpen} id={id}>
          {item.children.map((i, idx) => (
            <Item key={idx} item={i} pagePath={pagePath} depth={depth + 1} />
          ))}
        </SubNavigationList>
      ) : null}
    </li>
  )
}

const MobileMenu = ({ children }) => {
  const [isOpen, setOpen] = React.useState(false)
  const id = 'mobile-submenu'

  return (
    <>
      <MobileMenuWrapperToggle
        isOpen={isOpen}
        onClick={() => setOpen(!isOpen)}
        aria-expanded={isOpen}
        role="button"
        aria-label={isOpen ? 'Sulje' : 'Avaa'}
        aria-controls={id}
      >
        <LargeText>Menu</LargeText>
        <MobileMenuIconWrapper>
          {isOpen ? <CloseMenuIcon /> : <MenuIcon />}
        </MobileMenuIconWrapper>
      </MobileMenuWrapperToggle>
      <MobileMenuWrapper id="mobile-menu" isOpen={isOpen}>
        {children}
      </MobileMenuWrapper>
    </>
  )
}

const SubNavigation = ({ subNavigation, pagePath }) => {
  const theme = useTheme()
  const isMobileMenu = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.md.max})`,
  })

  const Nav = (
    <SubNavigationWrapper>
      <SubNavigationList depth={0} isOpen>
        {subNavigation.map((i, idx) => (
          <Item key={idx} item={i} pagePath={pagePath} />
        ))}
      </SubNavigationList>
    </SubNavigationWrapper>
  )

  if (isMobileMenu) {
    return <MobileMenu>{Nav}</MobileMenu>
  }

  return Nav
}

export default SubNavigation
