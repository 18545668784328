import DatePicker from 'react-date-picker'
import styled from 'styled-components'

const StyledDatePicker = styled(DatePicker)`
  margin-bottom: 0.5em;

  .react-calendar {
    width: 250px;
    box-shadow: ${props => props.theme.boxShadow.sm};
    border: 1px solid ${props => props.theme.colors.borderColor};
    position: absolute;
    top: 50px;
    font-family: 'Scto Grotesk A', sans-serif;
    background: ${props => props.theme.colors.backgroundColor};
    color: ${props => props.theme.colors.primaryColor};
  }

  input {
    color: inherit;
    vertical-align: top;

    &:focus {
      outline: none;
    }
  }

  .react-date-picker__wrapper {
    padding: 5px;
    border: 1px solid ${props => props.theme.colors.borderColor};
    margin-left: ${props => props.theme.spacing.small};
    border-radius: 4px;
  }

  .react-calendar__tile--active {
    background: ${p => p.theme.colors.chart.savingsColor};
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${p => p.theme.colors.chart.deficitColor};
  }

  .react-calendar__tile {
    padding: 0.75em 0;
  }

  button {
    height: auto;
  }

  .react-calendar__navigation {
    margin-bottom: 0;
  }

  .react-calendar__month-view__weekdays {
    border-bottom: 1px solid ${props => props.theme.colors.borderColor};
    border-top: 1px solid ${props => props.theme.colors.borderColor};

    abbr {
      font-weight: 500;
    }
  }

  abbr {
    text-decoration: none;
  }

  .react-calendar__navigation button:enabled:hover {
    background-color: transparent;
  }

  .react-calendar__navigation__label {
    text-align: center;
  }
`

export default StyledDatePicker
