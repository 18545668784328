import React, { useState } from 'react'
import styled from 'styled-components'
import AutoSuggest from 'react-autosuggest'
import { navigate, linkTo } from '../../utils'

import { ReactComponent as SearchIcon } from '../../images/icon_search.svg'

const SearchIconContainer = styled.div`
  position: absolute;
  display: flex;
  right: 16px;
  pointer-events: none;
  svg {
    position: relative;
    transform: scale(0.75);
  }
`

const Suggestion = styled.div`
  margin: ${props => props.theme.spacing.xsmall};
  &:hover {
    background-color: ${props => props.theme.colors.pinkLight};
  }
`

const SuggestionsContainer = styled.div`
  &.react-autosuggest__suggestions-container--open {
    position: absolute;
    width: 100%;
    padding: 10px;
    z-index: 10000000000000;
    box-shadow: ${props => props.theme.boxShadow.sm};
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.greyLight};
    margin-top: ${props => props.theme.spacing.xsmall};
    border-radius: ${props => props.theme.radius.md};
    overflow: scroll;
    max-height: 240px;
    ul {
      padding-inline-start: 0px;
      margin-block-start: 0;
      margin-block-end: 0;
      list-style-type: none;
      &:not(:last-of-type) {
        background-color: red;
      }
      li {
        margin-block-start: 0;
        margin-block-end: 0;
        cursor: pointer;
        &.react-autosuggest__suggestion--highlighted {
          color: ${props => props.theme.colors.blue};
        }
      }
    }
  }
`

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  input {
    line-height: 1.5;
    outline: 0;
    flex: 1 1 100%;
    padding: 1em ${({ theme }) => theme.spacing.xxlarge} 1em 1em;

    border-radius: 100px;
    background: ${({ theme }) => theme.colors.offWhite};
    border: 0;

    ::placeholder {
      color: ${({ theme }) => theme.colors.primaryColor};
    }
  }
`
const renderInputComponent = inputProps => (
  <InputContainer>
    <input {...inputProps} />
    <SearchIconContainer aria-hidden="true">
      <SearchIcon />
    </SearchIconContainer>
  </InputContainer>
)

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const alexandriaKokemuksiaPages = [
  'kuukausisaastamalla-oma-alppimaja',
  'turvattua-elamaa-myos-lastenlapsille',
  'tavoitteena-taloudellinen-vapaus',
  'sijoittamaan-jo-nuorena',
]

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = ({ pages, value }) => {
  const escapedValue = escapeRegexCharacters(value.trim())
  if (escapedValue.length === 0) {
    return pages
  }
  const inputValue = value.trim().toLowerCase()
  const regex = new RegExp(inputValue, 'i')
  const results = [pages.filter(page => regex.test(page.title))]
  if (results[0].length) return results[0]
  return ['Haulla ei löytynyt tuloksia']
}

const getSuggestionValue = suggestion => {
  return suggestion.title
}

const onSuggestionSelected = (_, { suggestion, sectionIndex }, pages) => {
  if (suggestion !== 'Haulla ei löytynyt tuloksia') {
    if (alexandriaKokemuksiaPages.includes(suggestion.slug)) {
      const transformedPath = `/kokemuksia/${suggestion.slug}`
      navigate(transformedPath, { replace: true })
    } else {
      navigate(linkTo(suggestion), { replace: true })
    }
  }
}

const renderSuggestionsContainer = ({ containerProps, children }) => {
  return (
    <div style={{ position: 'relative' }}>
      <SuggestionsContainer {...containerProps}>
        {children}
      </SuggestionsContainer>
    </div>
  )
}

const renderSuggestion = suggestion => {
  if (typeof suggestion === 'string') return suggestion
  return <Suggestion>{suggestion.title}</Suggestion>
}

const Search = ({ pages }) => {
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([pages])

  const onSuggestionsClearRequested = () => {
    setValue('')
    setSuggestions([pages])
  }

  const onSuggestionsFetchRequested = () => {
    if (value.length < 3) {
      setSuggestions([])
    } else {
      setSuggestions(getSuggestions({ pages, value }))
    }
  }

  return (
    <AutoSuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      multiSection={false}
      renderInputComponent={renderInputComponent}
      onSuggestionSelected={(e, values) =>
        onSuggestionSelected(e, values, pages)
      }
      inputProps={{
        placeholder: 'Kirjoita hakusana',
        value: value,
        type: 'search',
        onChange: (_, { newValue }) => setValue(newValue),
      }}
      shouldRenderSuggestions={() => true}
    />
  )
}

export default Search
