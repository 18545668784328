import styled from 'styled-components'

import Card from './card'

export default styled(Card)`
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl.min}) {
    min-width: 280px;
  }
`
