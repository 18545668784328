import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import ContentCard from './contentCard'
import RelatedPostCard from './relatedPostCard'
import ContentfulCard from '../contentfulCard'
import { ImageWrap } from './splitCard'
import hexToRgba from 'hex-to-rgba'

const StyledOuterGridWrapper = styled.div(
  ({ theme: t, noPodcast }) => `
  display: flex;
  flex-direction: column;
  max-width: ${t.width.max} !important;
  padding: ${noPodcast ? '50px 32px 40px 32px' : '50px 0 40px 0'};
  margin: 0 auto;
  text-align: center;

  @media (max-width: ${t.breakpoints.md.max}) {
    padding: ${noPodcast ? '30px 15px 0 15px' : '30px 0 0 0'};
    margin-bottom: 0;
  }

  h2 {
    font-size: 45px;
    line-height: 50px;

    @media (max-width: ${t.breakpoints.md.max}) {
      font-size: 28px;
      line-height: 33px;
    }
  }
`
)

const Cards = styled.div(
  ({ theme: t, noPodcast }) => `
  display: ${noPodcast ? 'flex' : 'grid'};
  grid-template-columns: 50% 50%;
  gap: 20px;
  margin-right: ${noPodcast ? '0' : '20px'};
  margin-bottom: 0;

  @media (max-width: ${t.breakpoints.md.max}) {
    display: flex;
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 20px;
  }

  a {
    div:nth-child(2) {
      h3 {
        padding-bottom: 0;
        word-break: break-word;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media (max-width: ${t.breakpoints.md.max}) {
          -webkit-line-clamp: unset;
        }
      }
    }

    &:hover {
      transform: scale(1.05);
      transition: transform 0.5s;
      box-shadow: none !important;
    }
  }
`
)

const PodcastWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ArticlesWrapper = styled.div(
  ({ theme: t, noPodcast }) => `
  display: flex;
  flex-direction: ${noPodcast ? 'row' : 'column'};
  gap: 20px;

  @media (max-width: ${t.breakpoints.md.max}) {
    flex-direction: column;
  }
`
)

const LatestPodcast = styled.div(
  ({ theme: t }) => `
  width: 100%;
  height: 100%;

  a {
    height: 660px;

    &:first-child {
      @media (max-width: ${t.breakpoints.md.max}) {
        height: 490px !important;
      }
    }

    div:nth-child(2) {
      background: ${t.colors.blueLight};

      h3 {
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
      }
    }
  }
`
)

const TopArticle = styled.div(
  ({ theme: t, noPodcast }) => `
  width: 100%;
  ${noPodcast ? 'max-width: 640px;' : ''}

  @media (max-width: ${t.breakpoints.md.max}) {
    max-width: 100%;
  }

  a {
    max-width: ${noPodcast ? '640px' : '100%'};
    height: 320px;

    @media (max-width: ${t.breakpoints.md.max}) {
      max-width: 100%;
      height: 395px;
    }

    div:nth-child(2) {
      background: ${hexToRgba(t.colors.blueLight, 0.4)};

      h3 {
        font-size: 28px;
        line-height: 33px;
      }
    }
  }
`
)

const BottomArticles = styled.div(
  ({ theme: t }) => `
  display: flex;
  gap: 20px;
  
  @media (max-width: ${t.breakpoints.md.max}) {
    flex-direction: column;
  }
`
)

const BottomArticle = styled.div(
  ({ theme: t, noPodcast }) => `
  display: flex;
  width: 100%;

  a {
    position: relative;
    max-width: ${noPodcast ? '640px' : '100%'};
    height: 320px;

    @media (max-width: ${t.breakpoints.md.max}) {
      max-width: 100%;
    }

    div:first-child {
      position: relative;
      height: 0;

      div {
        position: absolute;
        top: -11.5px;
        left: calc(100% - 128px);
        width: 140px;
        height: 140px;
      }
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    div:nth-child(2) {
      border-radius: 30px;
      max-height: 320px !important;

      @media (max-width: ${t.breakpoints.md.max}) {
        max-height: unset !important;
      }

      h3 {
        align-items: flex-end;
        display: flex;

        p {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin: 0;
          font-size: 24px;
          line-height: 28px;
        }

        @media (max-width: ${t.breakpoints.md.max}) {
          -webkit-line-clamp: unset;
        }
      }
    }
  }

  &:first-of-type {
    a {

      div:nth-child(2) {
        background: ${hexToRgba(t.colors.pink, 0.4)};
      }

      @media (max-width: ${t.breakpoints.md.max}) {
        height: auto;
      }
    }
  }

  &:nth-of-type(2) {
    a {
      div:nth-child(2) {
        background: ${hexToRgba(t.colors.orange, 0.3)};
      }

      @media (max-width: ${t.breakpoints.md.max}) {
        width: 100%;
        height: auto;
      }
    }
  }
`
)

const StyledContentfulCard = styled(ContentfulCard)`
  max-width: 100%;
  ${ImageWrap} {
    padding-bottom: 40%;
  }
  h2 {
    font-size: 1.375em;
  }
`

const getCardComponent = cardType => {
  switch (cardType) {
    case 'portrait':
      return ContentCard
    case 'landscape':
      return StyledContentfulCard
    case 'related-post':
      return RelatedPostCard
    default:
      return RelatedPostCard
  }
}

const RelatedPosts = ({
  latestArticles,
  latestPodcast,
  headerContent = null,
  className,
  contentType,
  cardType,
  itemsPerRow = 3,
}) => {
  const articlesData = useStaticQuery(graphql`
    query {
      articles: allContentfulArticle(
        limit: 3
        sort: { fields: [publicationDate], order: DESC }
        filter: { category: { eq: "Sijoittaminen" } }
      ) {
        edges {
          node {
            __typename
            id
            previewHeading
            slug
            previewHeading
            description {
              description
            }
            category
            publicationDate
            contentType
            image {
              title
              gatsbyImageData(width: 768, placeholder: BLURRED)
            }
            previewHeading
            previewBody {
              previewBody
            }
          }
        }
      }
    }
  `)

  latestArticles =
    latestArticles || articlesData.articles.edges.map(({ node }) => node)

  const CardComponent = getCardComponent(cardType)

  return (
    <StyledOuterGridWrapper className={className} noPodcast={!latestPodcast}>
      {headerContent}
      <Cards noPodcast={!latestPodcast}>
        {latestPodcast && (
          <PodcastWrapper>
            <LatestPodcast>
              <CardComponent
                card={latestPodcast[0]}
                useDateFontVariant={false}
                highlightedCard={true}
                contentType={contentType}
              />
            </LatestPodcast>
          </PodcastWrapper>
        )}
        {latestArticles && (
          <ArticlesWrapper noPodcast={!latestPodcast}>
            <TopArticle
              key={latestArticles[0].id}
              itemsPerRow={itemsPerRow}
              noPodcast={!latestPodcast}
            >
              <CardComponent
                card={latestArticles[0]}
                useDateFontVariant={false}
                contentType={contentType}
              />
            </TopArticle>
            <BottomArticles>
              {latestArticles.slice(1).map((article, idx) => (
                <BottomArticle
                  key={article.id}
                  itemsPerRow={itemsPerRow}
                  noPodcast={!latestPodcast}
                >
                  <CardComponent
                    card={article}
                    useDateFontVariant={false}
                    contentType={contentType}
                    footer={true}
                    isFirst={idx === 0}
                  />
                </BottomArticle>
              ))}
            </BottomArticles>
          </ArticlesWrapper>
        )}
      </Cards>
    </StyledOuterGridWrapper>
  )
}

export default RelatedPosts
