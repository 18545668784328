/* Styles for inputs pre facelift */

import styled from 'styled-components'

import input from '../../styles/mixins/input'

export default styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    color: ${p => p.theme.colors.primaryColor};
    sub {
      color: ${props => props.theme.colors.grey};
      margin-left: auto;
    }
  }

  margin: 1rem 0;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    position: relative;
    input[type='text'],
    input[type='tel'],
    input[type='number'],
    input[type='email'] {
      ${input}
      position: relative;
      flex-grow: 1;
      line-height: 1.5;
      outline: 0;
      :focus {
        border-color: ${props => props.theme.colors.primaryColor};
        transition-timing-function: ease-out;
      }
      ${props =>
        props.invalid &&
        `
        border-color: ${props.theme.colors.errorColor}
      `}
    }
    input[type='file'] {
      border: 1px solid transparent;
      border-radius: ${props => props.theme.radius.md};
      ${props =>
        props.invalid && `border-color: ${props.theme.colors.errorColor}`}
    }
    textarea {
      ${input};
      border-radius: ${({ theme }) => theme.radius.md};
      padding: ${({ theme }) => theme.spacing.xsmall}
        ${({ theme }) => theme.spacing.small};
      min-height: 80px;
      position: relative;
      flex-grow: 1;
      line-height: 1.5;
      outline: 0;
      :focus {
        border-color: ${props => props.theme.colors.primaryColor};
        transition-timing-function: ease-out;
      }
      ${props =>
        props.invalid &&
        `
        border-color: ${props.theme.colors.errorColor}
      `}
    }

`
