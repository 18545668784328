import React from 'react'
import styled from 'styled-components'

import TextContent from './textContent'

const Wrapper = styled(TextContent)`
  margin-bottom: 30px;

  > * h2,
  h3,
  p,
  ul {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    margin-bottom: 20px;
  }
`

const Main = ({ children, ...rest }) => <Wrapper {...rest}>{children}</Wrapper>

export default Main
