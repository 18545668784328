import { css } from 'styled-components'

import padding from './padding'

export default css`
  ${padding}

  @media (min-width: ${({ theme }) => theme.breakpoints.lg.min}) {
    height: 300px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl.min}) {
    height: 325px;
  }

  @media (min-width: ${({ theme }) => theme.width.max}) {
    height: 300px;
  }
`
