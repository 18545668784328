import React, { forwardRef } from 'react'
import { Location } from '@reach/router'
import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'
import AccessibilityText from '../typography/accessibilityText'
import { isExternalUrl, isAnchorLink } from '../../utils/link'

const FILE_EXTENSIONS = ['.pdf']
const getHrefLastPart = href => href.substring(href.lastIndexOf('/') + 1)
const getFileExtByHref = href =>
  FILE_EXTENSIONS.find(fExt => getHrefLastPart(href).includes(fExt))

const determineTitle = href => {
  if (typeof href !== 'string') {
    return undefined
  }
  const hrefLastPart = getHrefLastPart(href)
  /* If the href includes a certain string, return the last part for the title.
   * FILE_EXTENSIONS is basically AUTOMATIC_TITLE_ENABLING_HREF_PARTS
   */
  return FILE_EXTENSIONS.find(ext => hrefLastPart.includes(ext))
    ? hrefLastPart
    : undefined
}

// Following should work once Styled Components upgraded to 5.10. this is to get rid of console warnings.
const StyledLink = styled.a
  .withConfig({
    shouldForwardProp: prop =>
      !['noDefaultLinkFocus', 'focusAfter'].includes(prop),
  })
  .attrs(({ href, title, children }) => {
    const titleByFileExtension = determineTitle(href)
    const fileExt = titleByFileExtension ? getFileExtByHref(href) : false
    return {
      title: title || titleByFileExtension,
      children: fileExt ? (
        <>
          {children}
          <AccessibilityText>{fileExt.replace('.', ' ')}</AccessibilityText>
        </>
      ) : (
        children
      ),
    }
  })(
  ({ noDefaultLinkFocus, focusAfter, theme }) => `
  ${
    noDefaultLinkFocus
      ? ''
      : `
      position: relative;

      &:${focusAfter ? 'after' : 'before'} {
        content: '';
        border: 2px solid transparent;
        position: absolute;
        border-radius: 6px;
        left: 0; right: 0; bottom: 0; top: 0;
        margin: -.35em;
      }

      :focus,
      :hover,
      :active {
        outline: none;
      }

      &:focus:not(:hover):not(:active) {
        &:${focusAfter ? 'after' : 'before'} {
          border-color: ${theme.colors.highlightColor};
          transition-timing-function: ease-out;
        }
      }
    `
  }`
)

const StyledGatsbyLink = styled(StyledLink.withComponent(GatsbyLink))``

const isSlug = s => s && !/^\w+:+/.test(s) && s[0] !== '#'
const slugify = s => s && (s[0] !== '/' ? `/${s}` : s).replace(/\s/, '-')

const Link = ({ children, data, to, state, ...rest }, ref) => {
  if (!data && !to) {
    return null
  }
  const { slug, __typename, url } = data || {}

  /* link can be an external link from contentful, but someone can
   * also use this component directly with an external url or tel: etc.
   */
  if (
    __typename === 'ContentfulLink' ||
    isExternalUrl(to || slug) ||
    !isSlug(to || slug)
  ) {
    return (
      <StyledLink
        href={url || to || slug}
        {...rest}
        target={isAnchorLink(url || to || slug) ? '_self' : '_blank'}
        rel="nofollow"
      >
        {children}
      </StyledLink>
    )
  }
  const linkTo = slugify(to || slug)

  return (
    <Location>
      {({ location }) => (
        <StyledGatsbyLink
          to={linkTo}
          {...rest}
          state={{ prevUrl: location.href, ...state }}
          ref={ref}
        >
          {children}
        </StyledGatsbyLink>
      )}
    </Location>
  )
}

export const FocusStyle = StyledLink.withComponent('span')

export default forwardRef(Link)
