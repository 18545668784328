import React from 'react'
import styled from 'styled-components'
import Link from '../links/link'
import Subheading from '../typography/headings'
import BackgroundImage from '../backgroundImage'
import segments, { getLabelByText } from '../../utils/segments'
import { useBreakpoints } from '../../utils/dom'
import { format } from 'date-fns'
import RiskRating from '../riskRating'
import SmallText from '../typography/smallText'

const CardContainer = styled(Link)(
  ({ theme, bgcolor }) => `
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 30px;
  background: ${theme.colors[bgcolor]} !important;
  color: ${theme.colors.blue};
  box-shadow: ${theme.boxShadow.md};
  
  :before {
    z-index: 11;
  }
`
)

const ImageWrapper = styled.div(
  ({ roundImage, highlightedCard }) => `
  position: relative;
  height: ${
    !roundImage
      ? highlightedCard
        ? '498px !important'
        : '280px !important'
      : '0'
  };

  ${BackgroundImage} {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
  }
`
)

const Content = styled.div(
  ({ theme: { colors, breakpoints }, footer }) => `
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${footer ? '95px 30px 30px 30px' : '30px'};
  background: ${colors.offWhite};
  border-radius: 0 0 30px 30px;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.md.max}) {
    padding: ${footer ? '95px 20px 20px 20px' : '20px'};
  }
`
)

const ColorText = styled.p`
  text-transform: uppercase;
  padding-bottom: 0;
  margin: 0;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.28px;
  color: ${({ theme, isFirst }) =>
    isFirst ? theme.colors.red : theme.colors.orange};
`

const ContentHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-transform: uppercase;
  font-size: 14px;

  p:first-child {
    max-width: 50%;
    text-align: left;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }
`

const StyledSubHeading = styled(Subheading)(
  ({ footer }) => `
  margin-bottom: 0;
  padding-top: ${footer ? '30px' : '10px'};
`
)

const ContentFooter = styled.div`
  width: 100%;
  padding: 10px 0 0 0 !important;
  display: flex;
  font-size: 14px;
  background: transparent !important;
  align-items: flex-start;
  gap: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg.max}) {
    flex-direction: column;
    gap: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    flex-direction: row;
    gap: 10px;
  }

  @media (max-width: 320px) {
    flex-direction: column;
    gap: 0;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }
`

const RelatedPostCard = ({
  card: {
    __typename,
    image,
    slug,
    previewHeading: heading,
    riskRating,
    category,
    contentType,
    episodeNumber,
    publicationDate,
  },
  footer,
  isFirst,
  headingSize = '5',
  bgColor = 'offwhite',
  highlightedCard,
  ...rest
}) => {
  const breakpoints = useBreakpoints() || {}
  const large = !breakpoints.sm

  return (
    <CardContainer to={`${segments[__typename]}/${slug}`} bgcolor={bgColor}>
      {image && (
        <ImageWrapper roundImage={!!footer} highlightedCard={highlightedCard}>
          <BackgroundImage
            image={image.gatsbyImageData}
            alt={image.title || heading}
            large={large ? 'true' : null}
          />
        </ImageWrapper>
      )}
      <Content footer={footer}>
        {!footer ? (
          <ContentHeader>
            {category && (
              <SmallText>
                {contentType === 'podcast'
                  ? 'Podcast'
                  : getLabelByText(category)}
              </SmallText>
            )}
            {episodeNumber && <SmallText>{'Jakso ' + episodeNumber}</SmallText>}
            {contentType !== 'podcast' && (
              <SmallText>
                {format(new Date(publicationDate), 'dd.MM.yyyy')}
              </SmallText>
            )}
          </ContentHeader>
        ) : (
          <>
            <StyledSubHeading as="h3" headingSize="3" footer={footer}>
              {footer ? <p>{heading}</p> : <>{heading}</>}
            </StyledSubHeading>
            <ContentFooter>
              <ColorText isFirst={isFirst}>
                {contentType === 'podcast'
                  ? 'Podcast'
                  : getLabelByText(category)}
              </ColorText>
              <SmallText>
                {format(new Date(publicationDate), 'dd.MM.yyyy')}
              </SmallText>
            </ContentFooter>
          </>
        )}
        {!footer && (
          <StyledSubHeading as="h3" headingSize="3">
            {heading}
          </StyledSubHeading>
        )}
        {typeof riskRating === 'number' ? (
          <RiskRating riskRating={riskRating} />
        ) : null}
      </Content>
    </CardContainer>
  )
}

export default RelatedPostCard
