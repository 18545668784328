/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import { Provider } from './src/context/uiContext'

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    originalLocation:
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search,
  })

  if (location && location.state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null
  }
}

export const wrapRootElement = ({ element }) => {
  return <Provider>{element}</Provider>
}
