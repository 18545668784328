import React from 'react'
import { graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { pinkTheme } from '../styles/theme'
import Layout from '../components/layout'
import LayoutContent from '../components/layoutContent'
import Body from '../components/body'
import ReservationContainer from '../components/reservationContainer/reservationContainer'
import SplitHero from '../components/heroes/splitHero'
import ContentfulCard from '../components/contentfulCard'
import CardGroup from '../components/cards/cardGroup'

const NONE = 'none'
const isNoneHero = heroType => heroType === NONE

const renderHero = ({ heroType, image, heading, title, leadParagraph }) => {
  const { leadParagraph: lead } = leadParagraph || {}
  const heroImage = isNoneHero(heroType) || !image ? null : image
  return (
    <ThemeProvider theme={pinkTheme}>
      <SplitHero {...{ image: heroImage, lead, heading: heading || title }} />
    </ThemeProvider>
  )
}

const InsurancesPage = ({ data }) => {
  const insurance = data.contentfulInsurance
  const image = `https:${insurance.image.file.url}`
  const cards = insurance.cards

  return (
    <Layout
      title={insurance.title}
      description={insurance.description.description}
      image={image}
      hero={renderHero(insurance)}
      showContactMethods={insurance.showContactMethods}
      contactMethodsShort={true}
    >
      <LayoutContent>
        {cards?.length > 0 && (
          <CardGroup
            items={cards.map((card, index) => (
              <ContentfulCard card={card} index={index} />
            ))}
            smallPadding={true}
            itemsPerRow={2}
          />
        )}
        {insurance.body && (
          <Body
            dangerouslySetInnerHTML={{
              __html: insurance.body.childMarkdownRemark.html,
            }}
          />
        )}
        <ReservationContainer paddingTop={true} />
      </LayoutContent>
    </Layout>
  )
}

export default InsurancesPage

export const query = graphql`
  query ($slug: String!) {
    contentfulInsurance(slug: { eq: $slug }) {
      title
      description {
        description
      }
      heading
      leadParagraph {
        leadParagraph
      }
      heroType
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
        gatsbyImageData(width: 1200, placeholder: BLURRED)
      }
      showContactMethods
      cards {
        __typename
        slug
        image {
          title
          gatsbyImageData(width: 768, placeholder: BLURRED)
        }
        previewHeading
        previewBody {
          previewBody
        }
      }
    }
  }
`
