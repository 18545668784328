import { arrayOf, number, oneOf, shape, string } from 'prop-types'

export const __typenamePropType = oneOf([
  'ContentfulLegal',
  'ContentfulLink',
  'ContentfulCard',
  'ContentfulArticle',
  'ContentfulCareerPost',
  'ContentfulFund',
  'ContentfulInsurance',
  'ContentfulJobPosting',
  'ContentfulNewsItem',
  'ContentfulPage',
  'ContentfulStructuredInvestment',
  'ContentfulDepartment',
  'ContentfulCallToActionCard',
  'ContentfulPerson',
  'ContentfulInvestorMaterialPage',
  'ContentfulAssetManagementPortfolio',
])

export const postsPropTypes = shape({
  edges: arrayOf(
    shape({
      node: shape({}),
    })
  ),
})

export const imagePropTypes = shape({
  title: string,
  fluid: shape({
    base64: string,
    aspectRatio: number,
    src: string,
    srcSet: string,
    srcWebp: string,
    srcSetWebp: string,
    sizes: string,
  }),
  coverCss: string,
})

export const personPropTypes = shape({
  slug: string.isRequired,
  image: imagePropTypes,
  firstName: string.isRequired,
  lastName: string.isRequired,
  title: string,
  phoneNumber: string,
  email: string,
})

export const linkPropTypes = shape({
  __typename: __typenamePropType,
  url: string,
  slug: string,
  title: string,
})

export const navItemsPropTypes = arrayOf(linkPropTypes)

export const cardPropTypes = shape({
  __typename: __typenamePropType,
  slug: string,
  longHeading: shape({
    longHeading: string,
  }),
  previewHeroHeading: string,
  previewHeading: string,
  image: imagePropTypes,
  previewBody: shape({
    previewBody: string,
  }),
})

export const locationPropTypes = shape({
  lat: number.isRequired,
  lon: number.isRequired,
})

export const officePropTypes = shape({
  address: string,
  city: string,
  description: shape({
    childMarkdownRemark: shape({
      html: string,
    }),
  }),
  faxNumber: arrayOf(string),
  id: string,
  image: imagePropTypes,
  location: locationPropTypes,
  name: string,
  personnel: arrayOf(personPropTypes),
  phoneNumber: arrayOf(string),
  postalCode: string,
  slug: string,
})
