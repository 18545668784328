import React from 'react'
import styled from 'styled-components'
import { getTagCircleColorByContentType } from '../../utils/segments'
import { ReactComponent as TagIcon } from '../../images/tag_icon.svg'
import Link from '../links/link'
import { sanitizeString } from '../../utils'

const TagsWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
  gap: 15px;

  @media (max-width: ${theme.breakpoints.md.max}) {
    margin-bottom: 30px;
  }
`
)

const Line = styled.span(
  ({ theme }) => `
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.primaryColor};
`
)

const Box = styled.div(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: transparent;
  padding: 0;

  ${Circle} {
    color: ${theme.colors.primaryColor}
  }
`
)

const Circle = styled.div(
  ({ theme }) => `
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  background: ${theme.colors.pinkLight};
`
)

const TagNameWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  a {
    color: ${({ theme }) => theme.colors.primaryColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.28px;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Tags = ({ contentType, tags }) => {
  if (!tags?.length) return null

  return (
    <TagsWrapper>
      <Line />
      <Box>
        <Circle color={getTagCircleColorByContentType[contentType]}>
          <TagIcon />
        </Circle>
        <TagNameWrapper>
          {tags.map(tag => (
            <Link
              key={tag.name}
              to={`/sisallot?tag=${encodeURIComponent(
                sanitizeString(tag.name)
              )}`}
            >
              {tag.name}
            </Link>
          ))}
        </TagNameWrapper>
      </Box>
    </TagsWrapper>
  )
}

export default Tags
