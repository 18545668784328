import React, { useState } from 'react'
import { arrayOf, func, shape, string } from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as SvgArrow } from '../../images/arrow.svg'
import MediumText from '../typography/mediumText'
import ContentfulRichText from '../contentfulRichText'
import { resolvePropsForHeading } from '../typography/headings'

const propTypes = {
  renderCta: func.isRequired,
  teasers: arrayOf(
    shape({
      heading: string,
      richTextJson: string,
      cta: shape({
        to: string.isRequired,
        text: string,
      }),
    })
  ).isRequired,
}

// const formatRichText = string => JSON.parse(string)

const shouldScrollIntoView = elem => {
  if (typeof window === 'undefined' || !elem) return
  // use this to adjust scroll triggering. basically the px offset from center screen spot that should not yet trigger scroll
  const treshold = 150
  const center = window.innerHeight / 2
  const { top: elemTop, height: elemHeight } = elem.getBoundingClientRect()
  return treshold < Math.abs(center - elemTop - elemHeight / 2)
}

const StyledContentfulRichText = styled(ContentfulRichText)`
  ul,
  ol {
    padding-top: 0;
  }
`

const TeaserContent = styled.div`
  margin-bottom: 1em;
  outline: none;
`

const HeadingTrigger = styled(MediumText.withComponent('button')).attrs(
  props => ({
    children: (
      <>
        <SvgArrow aria-hidden="true" />
        <span {...resolvePropsForHeading(props)} />
      </>
    ),
  })
)`
  display: flex;
  margin-bottom: 1em;
  align-items: center;
  text-align: left;
  font-size: 1.167em;

  svg {
    margin-right: 0.5em;
    color: inherit;
    stroke: currentColor;
    stroke-width: 2px;
    height: 32px;
    width: 32px;
    flex-shrink: 0;

    circle {
      stroke: none;
    }
  }

  &:focus:not(:active) {
    position: relative;

    &:before {
      content: '';
      border-bottom: 2px solid currentColor;
      position: absolute;
      bottom: -0.1em;
      left: calc(32px + 0.5em);
      right: -0.125em;
    }
  }

  &[aria-expanded='false'] {
    font-weight: 400;

    &:not(:hover):not(:focus):not(:active) {
      color: ${p => p.theme.colors.grey};
    }

    svg {
      visibility: hidden;
    }
  }
`

const Grid = styled.div(
  ({ theme: { breakpoints }, rowCount = 1 }) => `
  font-size: 1.5rem;
  display: flex;
  flex-flow: column nowrap;

  // see mobile exceptions as max-break after min-break

  @media screen and (min-width: ${breakpoints.md.min}) {
    display: -ms-grid;
    display: grid;

    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;

    -ms-grid-rows: ${
      rowCount > 1
        ? [...Array(rowCount - 1).fill('50px'), 'auto auto'].join(' ')
        : 'auto auto'
    };
    grid-template-rows: ${
      rowCount > 1
        ? [...Array(rowCount - 1).fill('50px'), 'auto auto'].join(' ')
        : 'auto auto'
    };

    column-gap: 1.5rem;
    row-gap: 1em;
    justify-items: flex-start;
    align-items: flex-start;
    max-width: 85%;
    margin: 0 auto 1em;

    ${HeadingTrigger} {
      -ms-grid-column: 1;
      grid-column: 1;

      &:first-of-type {
        -ms-grid-row: 1;
      }
      ${Array(rowCount)
        .fill(null)
        .map(
          (_, i) => `
          &:nth-of-type(${i + 1}) {
            -ms-grid-row: ${i + 1};
          }
      `
        )
        .join(' ')}
    }
    ${TeaserContent} {
      -ms-grid-column: 2;
      grid-column: 2;

      -ms-grid-row: 1;
      -ms-grid-row-span: ${rowCount + 1};
      grid-row: 1 / ${rowCount + 1};
    }

    .st-cta {
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      grid-column: 1 / 3;

      -ms-grid-row: ${rowCount + 1};
      grid-row: ${rowCount + 1};

      justify-self: center;
      font-size: 1.125rem;
      width: 100%;
    }
  }

  @media screen and (max-width: ${breakpoints.md.max}) {
    ${HeadingTrigger} {
      order: 0;

      &[aria-expanded="true"] {
        order: 1;

        ~ ${HeadingTrigger},
        ~ ${TeaserContent} {
          order: 2;
        }

        + ${TeaserContent} {
          order: 1;
        }
      }
    }
    .st-cta {
      order: 1;

      a {
        margin-bottom: 2em;
      }
    }
  }
`
)

const Wrapper = styled.div`
  padding-top: 1.5rem;
`

const SectionTeaser = ({ teasers, renderCta }) => {
  const [active, setActive] = useState({ index: 0, cta: teasers[0].cta })

  return (
    <Wrapper>
      <Grid rowCount={teasers.length}>
        {teasers.map(({ heading, richTextJson, cta, id: originalId }, i) => {
          const id = originalId.replace(/\d/g, '')
          return (
            <React.Fragment key={`${id}`}>
              <HeadingTrigger
                onClick={({ target }) => {
                  setActive({ index: i, cta })
                  // when browsing with keyboard, target is button, when using mouse, it's the child of button (span).
                  const { nextSibling: contentElem } =
                    target.tagName === 'BUTTON' ? target : target.parentNode

                  if (contentElem) {
                    setTimeout(() => {
                      contentElem.focus()
                      if (shouldScrollIntoView(contentElem)) {
                        contentElem.scrollIntoView({
                          block: 'center',
                          behavior: 'smooth',
                        })
                      }
                    }, 0)
                  }
                }}
                aria-controls={`${id}`}
                aria-expanded={active.index === i}
              >
                {heading}
              </HeadingTrigger>
              <TeaserContent
                tabIndex={-1}
                id={id}
                aria-labelledby={id}
                hidden={active.index !== i}
              >
                <StyledContentfulRichText richText={{ raw: richTextJson }} />
                {/* <StyledContentfulRichText richText={formatRichText(richTextJson)} /> */}
              </TeaserContent>
              {renderCta({
                to: active.cta.to,
                children: active.cta.text || 'Lue lisää',
                className: 'st-cta',
                hidden: active.index !== i,
              })}
            </React.Fragment>
          )
        })}
      </Grid>
    </Wrapper>
  )
}

SectionTeaser.propTypes = propTypes

export default SectionTeaser
