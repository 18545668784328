import styled from 'styled-components'

export default styled.div`
  padding: 10px;
  width: 100%;
  max-width: 640px;

  > * {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    padding: 0 0 15px 0;
  }

  ${({ itemsPerRow, theme }) =>
    !itemsPerRow || itemsPerRow > 1
      ? `
      @media (min-width: ${theme.breakpoints.lg.min}) {
        margin: 0;

        &:not(:only-child) {
          width: ${
            itemsPerRow === 1
              ? '100%'
              : itemsPerRow === 2
              ? '50%'
              : itemsPerRow === 3
              ? '33.33%'
              : itemsPerRow === 4
              ? '25%'
              : 'auto'
          };
        }
      }

      @media (min-width: ${theme.breakpoints.xl.min}) {
        &:not(:only-child) {
          max-width: 640px;
        }
        width: ${(100 / parseInt(itemsPerRow, 10)).toFixed(2)}%; // TODO: FIXME
      }

      @media (max-width: ${theme.breakpoints.xl.min}) {
        &:not(:only-child) {
          max-width: ${itemsPerRow > 1 ? '640px' : '100%'};
          ${itemsPerRow === 1 ? 'width: 100%;' : ''}
        }
        width: ${(100 / parseInt(itemsPerRow, 10)).toFixed(2)}%; // TODO: FIXME
      }

      @media (max-width: ${theme.breakpoints.md.max}) {
        &:not(:only-child) {
          max-width: 100%;
          width: 100%;
        }
      }
    `
      : ''}
`
