import React from 'react'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/contentfulPage'
import styled from 'styled-components'
import NotFound from '../pages/404'
import MailingListForm from '../components/mailingListForm'
import { Subheading } from '../components/typography/headings'
import Lead from '../components/typography/lead'
import LayoutContent from '../components/layoutContent'

const StyledSubheading = styled(Subheading)`
  text-align: flex-start !important;
`

const MailingList = ({ data }) => {
  const page = data.page

  if (!page) {
    return <NotFound />
  }
  return (
    <ContentfulPage page={{ ...page }}>
      <LayoutContent>
        <StyledSubheading as="h3" headingSize="3">
          Tilaa uutiskirje
        </StyledSubheading>
        <Lead>
          Ole hyvä ja täytä sähköpostiosoitteesi alla olevaan lomakkeeseen
          liittyäksesi Alexandrian postituslistalle.
        </Lead>
        <MailingListForm />
      </LayoutContent>
    </ContentfulPage>
  )
}

export default MailingList

export const query = graphql`
  query {
    page: contentfulPage(slug: { eq: "tilaa-uutiskirje" }) {
      ...contentfulPageInformation
    }
  }
`
