import React from 'react'
import styled from 'styled-components'

import Input from './input'

const TextInput = styled(
  ({
    className,
    field,
    form,
    helpText,
    label,
    suffix,
    required,
    hidden,
    whiteTheme,
    isContainer,
    ...props
  }) => (
    <Input
      {...{
        className,
        field,
        form,
        helpText,
        label,
        required,
        hidden,
        whiteTheme,
        isContainer,
      }}
    >
      <div>
        <input id={field.name} {...field} {...props} />
        {suffix && <span>{suffix}</span>}
      </div>
    </Input>
  )
)``

export default TextInput
