import React from 'react'
import styled from 'styled-components'

import Button from '../buttons/button'

const LoadMore = styled.div`
  display: flex;

  button {
    margin: 0 auto;
    width: 170px;
    height: 45px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
      font-size: 14px;
      line-height: normal;
    }
  }
`

const LoadMoreButton = ({
  updateItemCount,
  currentCount,
  totalItemCount,
  delta = 8,
}) => {
  return (
    <LoadMore>
      <Button
        onClick={() => updateItemCount(currentCount + delta)}
        disabled={currentCount >= totalItemCount}
        large
      >
        Näytä lisää
      </Button>
    </LoadMore>
  )
}

export default LoadMoreButton
