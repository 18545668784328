import React from 'react'
import { Helmet } from 'react-helmet'

import maskIcon from '../images/safari-pinned-tab.svg'

const Favicon = () => (
  <Helmet
    link={[{ rel: 'mask-icon', href: `${maskIcon}`, color: '#000450' }]}
    meta={[{ name: 'msapplication-TileColor', content: '#000450' }]}
  />
)

export default Favicon
