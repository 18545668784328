import React from 'react'
import { bool, node, oneOfType, string } from 'prop-types'
import styled from 'styled-components'
import Link from '../links/link'
import { getHeadingStyle } from '../typography/headings'
import { linkTo } from '../../utils'

const propTypes = {
  noLink: bool,
  className: string,
  children: oneOfType([node, string]),
}

const ContentStyle = styled.span``
// Use non-default focus style due to the use of <span> in order to optimize click area with proper styling.
const Wrapper = styled.button.attrs({ noDefaultLinkFocus: true })(
  ({ theme: { spacing, breakpoints, colors } }) => `
  padding: 0 ${spacing.medium};
  cursor: pointer;
  width: 100%;
  display: block;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  ${ContentStyle} {
    border-style: solid;
    border-color: transparent;
    border-width: 4px 0;
    color: inherit;
    padding: ${spacing.xsmall} 0;
    display: inline-flex;
    transition: none;
    border-radius: 0;
    text-align: center;
    align-items: center;
    height: 55px;
  }

  &:active:not([href="/"]) {
    ${ContentStyle} {
      border-bottom-color: transparent;
    }
  }

  & > span &:hover:not(:focus):not([aria-expanded="true"]):not([aria-current="page"]):not([href="/"]) ${ContentStyle},
  &[aria-current="page"]:not([href="/"]) ${ContentStyle} {
    &:not(:hover) {
      &:before {
        content: "";
        position: absolute;
        height: 4px;
        width: 49.5px;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: ${colors.white};
        border-radius: 0;
      }
    }

    ul ul & {
      border-bottom-color: transparent;
      border-bottom-color: currentColor;
    }
  }

  &[aria-current="page"] {
    cursor: default;
    pointer-events: none;
  }

  @media screen and (min-width: ${breakpoints.lg.min}) {
    padding: .25rem 0.5em;

    ${ContentStyle} {
      position: relative;
      padding: calc(${spacing.xsmall} - 1px) 0;
      line-height: 1.25;

      &:before {
        content: '';
        border: 2px solid transparent;
        position: absolute;
        left: -.5em; right: -.5em;
        top: -.5em; bottom: -.5em;
        border-radius: 16px;
      }
    }

    &:focus:not(:active):not([href="/"]):not([aria-expanded="true"]) {
      ${ContentStyle}:before {
        border-color: ${colors.highlightColor};
      }
    }

    &:hover:not(:focus):not([aria-current="page"]):not([href="/"]) ${ContentStyle} {
      border-bottom-color: ${colors.greyLighter};
    }

    [role="menuitem"] & {
      padding: .125rem 0.5em .375rem;

      ${ContentStyle} {
        padding: .5rem 0 .25rem;

        :before {
          left: -.75em; right: -.75em;
          top: -.25em; bottom: -.5em;
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoints.md.max}) {
    ${ContentStyle} {
      ${getHeadingStyle(2)};
      font-size: 20px;
      &:after {
        display: none;
      }
    }
  }
`
)

const StyledLink = Wrapper.withComponent(Link)

const NavLink = ({ children, noLink, data = {}, ...props }) => {
  const content = <ContentStyle>{children}</ContentStyle>
  const Elem = noLink ? Wrapper : StyledLink
  const slug = linkTo(data)

  return (
    <Elem data={{ ...data, slug }} {...props}>
      {content}
    </Elem>
  )
}

NavLink.propTypes = propTypes

export default NavLink
