import React from 'react'
import styled from 'styled-components'
import { Heading } from '../typography/headings'
import ContentfulText from '../contentfulText'
import Container from '../container'
import BackgroundImage from '../backgroundImage'

const StyledContainer = styled(Container)`
  display: flex;
  align-items: normal;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg.min}) {
    flex-direction: row;
  }
`

const AlexandriaInBriefSection = styled.section`
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  padding-top: ${({ theme }) => theme.spacing.xxlarge};
  padding-bottom: ${({ theme }) => theme.spacing.xxlarge};
`

const AlexandriaStatsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.medium};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm.min}) {
    grid-template-columns: 1fr 1fr;
  }
`

const AlexandriaStat = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.primaryColor};
  padding-top: ${({ theme }) => theme.spacing.medium};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
  font-size: 1rem;
`

const AlexandriaInBriefImage = styled.div`
  position: relative;
  height: 500px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg.min}) {
    flex-basis: 100%;
    height: auto;
  }
`

const AlexandriaStats = ({ stats }) => {
  const splitStats = stats.map((i, idx) => {
    // find the first number, ignoring whitespace between numbers
    let firstNumberFound = false
    const words = i
      .trim()
      .split(' ')
      .reduce((acc, i, idx) => {
        if (!Number.isNaN(i)) {
          if (!acc[idx]) {
            acc[idx] = ''
          }
          acc[idx] += i
        } else {
          acc.push(i)
        }
        return acc
      }, [])
      .reduce((acc, i) => {
        const isNumber = !Number.isNaN(parseFloat(i))
        const isNumberWithSuffix = i.endsWith('M€') || i.endsWith('MRD€')
        if (!firstNumberFound && (isNumber || isNumberWithSuffix)) {
          let val = i.replace('.', ',')

          if (isNumberWithSuffix) {
            val = val.replace('M', '&nbsp;M')
          }

          acc.push(
            <Heading as="span" headingSize="2" key="number">
              {val}
            </Heading>
          )
          firstNumberFound = true
        } else {
          acc.push(`${i} `)
        }
        return acc
      }, [])

    return <AlexandriaStat key={idx}>{words}</AlexandriaStat>
  })

  return <AlexandriaStatsWrapper>{splitStats}</AlexandriaStatsWrapper>
}

export default React.forwardRef(({ heading, text, image, stats }, ref) => (
  <AlexandriaInBriefSection ref={ref}>
    <StyledContainer>
      <div>
        <Heading as="h2" headingSize="2">
          {heading}
        </Heading>
        <ContentfulText text={text} />
        <AlexandriaStats stats={stats} />
      </div>
      <AlexandriaInBriefImage>
        <BackgroundImage
          image={image.gatsbyImageData}
          alt={image.title || ''}
          objectFit="contain"
        />
      </AlexandriaInBriefImage>
    </StyledContainer>
  </AlexandriaInBriefSection>
))
