import React from 'react'
import { bool } from 'prop-types'
import styled from 'styled-components'

import SplitCard from './cards/splitCard'
import SplitCardHero from './cards/splitCardHero'
import segments from '../utils/segments'
import { cardPropTypes } from '../utils/prop-types'
import { ReactComponent as CalendarIcon } from '../images/calendar.svg'
import PatternImgUrl from '../images/pattern.svg'
import { isExternalUrl } from '../utils/link'

const propTypes = {
  card: cardPropTypes,
  isHero: bool,
}

const LongHeading = styled.span`
  white-space: pre-wrap;
`

const getCardImageProps = ({ image, slug }) => ({
  ...image,
  ...({
    tuottolaskuri: {
      coverCss: `background: transparent url('${PatternImgUrl}') 0 0 /cover repeat;`,
    },
  }[slug] || {}),
})

const ContentfulCard = ({
  card: {
    __typename,
    slug,
    longHeading,
    previewHeading,
    riskRating,
    leadParagraph,
    image = {},
    previewBody: prevBodyObj,
    previewHeroHeading,
    category,
    publicationDate,
    metaText: premadeMetaText,
  },
  isHero,
  ...rest
}) => {
  /*
   * NOTE: this is quite bad and these props should really should be mapped on graphql resolver level, but that's a long refactor.
   * Basically this is a component which handles many types of teasers/posts and they may want their data handled differently
   */
  const { leadParagraph: lead } = leadParagraph || {}
  const { previewBody } = prevBodyObj || {}
  const Component = isHero ? SplitCardHero : SplitCard
  const segment = segments[__typename]
  const cardProps = {
    /*  Passing in an external url done to accommodate for directing user to https://career.alexandria.fi, from a single JobPosting fetched to the meille-toihin -page, where it's mapped into a contentfulCard
     */
    to: isExternalUrl(slug)
      ? slug
      : segment
      ? `${segment}/${slug}`
      : `/${slug}`,
    title: longHeading ? (
      <LongHeading>{longHeading.longHeading}</LongHeading>
    ) : (
      previewHeroHeading || previewHeading
    ),
    riskRating,
    image: getCardImageProps({ image, slug }),
    category,
    date: publicationDate,
    __typename,
  }
  // NOTE: eg. ContentfulStructuredInvestment have lead and no riskRating
  const metaText = premadeMetaText || (!riskRating && lead ? previewBody : null)

  if (metaText) {
    // here __typename should always be ContentfulStructuredInvestment or ContentfulJobPosting
    cardProps.children = null
    cardProps.metaText = (
      <>
        <CalendarIcon />
        {metaText}
      </>
    )
  } else if (previewHeroHeading && isHero) {
    cardProps.children = previewBody || previewHeading
  } else if (previewBody) {
    cardProps.children = previewBody
  }

  return <Component {...rest} {...cardProps} />
}

ContentfulCard.propTypes = propTypes

export default ContentfulCard
