import React from 'react'
import styled from 'styled-components'

const ContactContainer = styled.div(
  ({ isMobile }) => `
  @media (max-width: ${p => p.theme.breakpoints.lg.min}) {
    display: none;
  }

  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: ${isMobile ? 'flex-start' : 'flex-end'};
  text-align: center;

  & > * {
    white-space: nowrap;
    &:first-child {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }
    &:nth-child(2) {
      font-family: 'Saol Display';
      font-size: 38px;
      font-weight: 600;
      line-height: 42px;
      &:hover {
        text-decoration: underline;
      }
      @media (max-width: 400px) {
        font-size: 28px;
        line-height: 33px;
      }
      @media (max-width: 350px) {
        font-size: 24px;
      }
    }
    &:nth-child(3) {
      font-size: 16px;
      line-height: 20px;
      opacity: 0.9;
      @media (max-width: 400px) {
        font-size: 14px;
      }
    }
    &:last-child {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      opacity: 0.9;
      &:hover {
        text-decoration: underline;
      }
      @media (max-width: 400px) {
        font-size: 16px;
        line-height: 20px;
      }
      @media (max-width: 350px) {
        font-size: 14px;
      }
    }
  }
`
)

const FooterContact = ({ contactInformation, isMobile }) => {
  return (
    <ContactContainer isMobile={isMobile}>
      <span>Asiakaspalvelu</span>
      <a
        target="__blank"
        href={`tel:${contactInformation[0].replace(/\s/g, '')}`}
      >
        {contactInformation[0]}
      </a>
      <span>
        {contactInformation[1]} <br /> {contactInformation[2]}
      </span>
      <a target="__blank" href={`mailto:${contactInformation[3]}`}>
        {contactInformation[3]}
      </a>
    </ContactContainer>
  )
}

export default FooterContact
