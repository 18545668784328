import styled from 'styled-components'

import CtaButton from '../buttons/ctaButton'

export default styled.div`
  padding: 0;
  color: ${props => props.theme.colors.blue};

  p {
    margin: 0 0 1em;
  }

  a {
    display: inline-block;

    &:not(${CtaButton}) {
      color: inherit;

      &:hover,
      &:focus {
        outline: none;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
`
