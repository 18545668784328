import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import smallCaps from '../../styles/mixins/smallCaps'
import { redBlueTheme } from '../../styles/theme'
import Container from '../container'
import ContentfulRichText from '../contentfulRichText'
import TextContent from '../textContent'
import { getHeadingFontBySize, Subheading } from '../typography/headings'

const AllStats = styled.div`
  display: flex;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    flex-direction: column;
    padding: 0;
  }
`

const ListStats = styled.div`
  flex-direction: row;
  align-items: flex-end;
`

const HeroContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.red};
  padding: 3em 0;
`

const StyledContentfulRichText = styled(ContentfulRichText)`
  ul,
  ol {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    div p {
      display: flex;
      align-items: center;
    }

    b,
    h3 {
      border: 3px solid currentColor;
      border-radius: 50%;
      text-align: center;
      padding: 7px;
      min-width: 63px;
      font: ${getHeadingFontBySize(1, 'subheading')};
      margin: 0 10px 0 0;
    }
  }
`

const InfoText = styled.p`
  margin-top: ${({ theme }) => theme.spacing.large};
  ${smallCaps}
  @media (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    padding: 0;
  }
`

const StyledHeading = styled(Subheading)`
  text-align: ${({ alignment }) => alignment};
`

const StatsLead = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    padding: 0;
  }
`

const CustomerFeedbackHero = ({
  shortTitle,
  statsLead,
  statsRawText,
  statsInfo,
  centerTitle = true,
}) => {
  return (
    <ThemeProvider theme={redBlueTheme}>
      <HeroContainer>
        <Container containerWidth="content">
          <TextContent>
            <StyledHeading
              headingSize="1"
              as="h2"
              alignment={centerTitle ? 'center' : 'left'}
            >
              {shortTitle}
            </StyledHeading>
            <StatsLead>{statsLead}</StatsLead>
            <AllStats>
              <ListStats>
                <StyledContentfulRichText
                  richText={{
                    raw: statsRawText,
                  }}
                />
              </ListStats>
            </AllStats>
            <InfoText>{statsInfo}</InfoText>
          </TextContent>
        </Container>
      </HeroContainer>
    </ThemeProvider>
  )
}

// CustomerFeedbackHero.propTypes = propTypes

export default CustomerFeedbackHero
