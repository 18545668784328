import React from 'react'

const formatNumber = (number, suffix) => {
  if (isNaN(number)) {
    return '-'
  }

  return `${number.toLocaleString('fi-FI', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}${suffix || ''}`
}

const Decimal = ({ value, suffix }) => {
  const number = parseFloat(value)

  return <span>{formatNumber(number, suffix)}</span>
}

export default Decimal
