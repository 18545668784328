import React, { useState } from 'react'
import Flow from './flow'
import { ReactComponent as CalendarIcon } from '../../../images/calendar-check.svg'
import { ReactComponent as MessageIcon } from '../../../images/message.svg'
import { ReactComponent as ChatIcon } from '../../../images/chat.svg'

const FlowStep3 = ({
  step,
  alternativeView,
  selectedFlowOptions,
  onSelectedFlowOptionsChange,
}) => {
  const [buttonStates, setButtonStates] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const isChatClosed = () => {
    const currentDay = new Date().getDay()
    const currentHour = new Date().getHours()

    const isWeekend = currentDay === 0 || currentDay === 6
    const isOutsideWorkingHours = currentHour < 8 || currentHour >= 16

    return isWeekend || isOutsideWorkingHours
  }

  const chatClosed = isChatClosed()

  const flowButtons = [
    {
      icon: CalendarIcon,
      title: 'Varaa aika',
      text: step.buttonTexts[0],
    },
    {
      icon: MessageIcon,
      title: 'Jätä yhteystietosi',
      text: step.buttonTexts[1],
    },
    {
      icon: ChatIcon,
      title: chatClosed ? 'Chat suljettu' : 'Chat',
      text: step.buttonTexts[2],
    },
  ]

  return (
    <Flow
      heading={alternativeView ? step.altHeading : step.heading}
      description={
        alternativeView
          ? step.altDescription.altDescription
          : step.description.description
      }
      flowButtons={flowButtons}
      buttonStates={buttonStates}
      setButtonStates={setButtonStates}
      onSelectedFlowOptionsChange={onSelectedFlowOptionsChange}
      selectedFlowOptions={selectedFlowOptions}
      step3={true}
      disabled={chatClosed}
      currentStep={3}
    />
  )
}

export default FlowStep3
