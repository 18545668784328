import React from 'react'
import { shape } from 'prop-types'
import { INLINES, MARKS, BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import TextContent from './textContent'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const propTypes = {
  richText: shape({}),
  renderOptions: shape(),
}

const renderAssetLink = (node, children) => {
  const {
    data: { target },
  } = node
  return (
    <a href={target?.file?.url} title={target?.title}>
      {children}
    </a>
  )
}

const renderBold = text => {
  return <h3>{text}</h3>
}

const options = {
  renderNode: {
    [INLINES.ASSET_HYPERLINK]: (node, children) =>
      renderAssetLink(node, children),
    [BLOCKS.LIST_ITEM]: (node, children) => {
      const transformedChildren = documentToReactComponents(node, {
        renderMark: {
          [MARKS.BOLD]: text => renderBold(text),
        },
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, paragraph) => paragraph,
          [BLOCKS.LIST_ITEM]: (node, listItem) => listItem,
        },
      })
      return (
        <li>
          <div>{transformedChildren}</div>
        </li>
      )
    },
  },
}

const ContentfulRichText = ({ richText, renderOptions = {}, ...rest }) => {
  if (!richText) {
    return null
  }

  return (
    <TextContent {...rest}>{renderRichText(richText, options)}</TextContent>
  )
}

ContentfulRichText.propTypes = propTypes

export default ContentfulRichText
