import React from 'react'
import {
  highlightTheme,
  pinkTheme,
  lightTheme,
  orangeTheme,
  subtleTheme,
  blueRedTheme,
} from '../styles/theme'
import { Heading } from '../components/typography/headings'
import { ReactComponent as ArticleIcon } from '../images/article.svg'
import { ReactComponent as VideoIcon } from '../images/video.svg'
import { ReactComponent as InfoIcon } from '../images/information.svg'
import { ReactComponent as PodcastIcon } from '../images/podcast.svg'
import { ReactComponent as FilledCategoryIcon } from '../images/categories_filled.svg'
import { ReactComponent as FundIcon } from '../images/fund.svg'
import { ReactComponent as StructuresIcon } from '../images/structures.svg'
import { ReactComponent as InsurancesIcon } from '../images/insurances.svg'

const segments = {
  ContentfulArticle: '/sisallot',
  ContentfulAssetManagementPage: '/varainhoito',
  ContentfulFund: '/rahastot',
  ContentfulInsurance: '/sijoitusvakuutukset',
  ContentfulNewsItem: '/tiedotteet',
  ContentfulPage: '',
  ContentfulStructuredInvestment: '',
  ContentfulContent: '/sisallot',
  ContentfulPodcastEpisode: '/sisallot/rikasta-elamaa',
}

export const articleCategories = {
  podcast: 'Rikasta elämää -podcast',
  newsletter: 'Tiedotteet',
  investing: 'Sijoittaminen',
  startInvesting: 'Sijoittamisen aloittaminen',
  pension: 'Eläkesäästäminen',
  entrepreneurs: 'Yrittäjälle',
  markets: 'Markkinakommentit ja trendit',
}

/* eslint-disable quote-props */
export const getHeroThemeBySlug = slug =>
  ({
    index: highlightTheme,
    'strukturoidut-sijoitukset': pinkTheme,
    vakuutukset: pinkTheme,
    rahastot: pinkTheme,
    'premium-advisors': pinkTheme,
    yrityslainat: pinkTheme,
    'rahastot-tanaan': pinkTheme,
    'yrityksen-sijoitukset': orangeTheme,
    yritysrahoitus: orangeTheme,
    'alexandria-yrityksena': lightTheme,
    alexandrialaiset: lightTheme,
    tiedotteet: lightTheme,
    meista: lightTheme,
    ajankohtaista: lightTheme,
    'toimipaikat-ja-yhteystiedot': lightTheme,
    varainhoito: blueRedTheme,
  }[slug.split('/')[0]] || null)

export const getLabelByText = text => {
  switch (text) {
    case 'article':
      return 'Artikkeli'
    case 'newsletter':
      return 'Tiedote'
    case 'podcast':
      return 'Podcast'
    case 'video':
      return 'Video'
    case 'newest':
      return 'Uusin ensin'
    case 'oldestFirst':
      return 'Vanhin ensin'
    case 'mostpopular':
      return 'Suosituimmat'
    case 'showAll':
      return 'Näytä kaikki'
    case 'Markkinakommentit ja trendit':
      return 'Trendit'
    case 'Rikasta elämää -podcast':
      return 'Rikasta elämää'
    default:
      return text
  }
}

export const getIconByContentType = contentType => {
  switch (contentType) {
    case 'article':
      return <ArticleIcon />
    case 'newsletter':
      return <InfoIcon />
    case 'video':
      return <VideoIcon />
    case 'podcast':
      return <PodcastIcon />
    default:
      return <ArticleIcon />
  }
}

export const getRelatedPostHeadingByTypename = typename => {
  switch (typename) {
    case 'ContentfulFund':
      return (
        <>
          <FundIcon />
          <Heading as="h2" headingSize="3">
            Tutustu rahastotuotteisiin
          </Heading>
        </>
      )
    case 'ContentfulStructuredInvestment':
      return (
        <>
          <StructuresIcon />
          <Heading as="h2" headingSize="3">
            Tutustu strukturoituihin
          </Heading>
        </>
      )
    case 'ContentfulInsurance':
      return (
        <>
          <InsurancesIcon />
          <Heading as="h2" headingSize="3">
            Tutustu vakuutustuotteisiin
          </Heading>
        </>
      )
    default:
      return (
        <>
          <FilledCategoryIcon />
          <Heading as="h2" headingSize="3">
            Tutustu myös
          </Heading>
        </>
      )
  }
}

export const getColorByContentType = {
  article: '#FF6063',
  video: '#7AB8FF',
  newsletter: '#FF992A',
  podcast: '#FFB6B6',
}

export const getTagContainerColorByContentType = {
  podcast: '#ffe9e8',
  newsletter: '#ffca5d',
  video: '#afeaff',
  article: '#ff938d',
}

export const getTagCircleColorByContentType = {
  podcast: '#CB8686',
  newsletter: '#C66A00',
  video: '#4288cc',
  article: '#c62936',
}

export const getHeroTheme = type =>
  ({
    orange: orangeTheme,
    pink: pinkTheme,
    red: highlightTheme,
    grey: subtleTheme,
    blue: lightTheme,
    blueDark: blueRedTheme,
  }[type])

export const getHeroThemeByContentType = type => {
  switch (type) {
    case 'article':
      return getHeroTheme('red')
    case 'newsletter':
      return getHeroTheme('orange')
    case 'podcast':
      return getHeroTheme('pink')
    case 'video':
      return getHeroTheme('blue')
    default:
      return getHeroTheme('pink')
  }
}

export const isCustomerExperiencePage = pathname => {
  return [
    'kuukausisaastamalla-oma-alppimaja',
    'turvattua-elamaa-myos-lastenlapsille',
    'tavoitteena-taloudellinen-vapaus',
    'sijoittamaan-jo-nuorena',
  ].some(slug => (pathname || '').includes(slug))
}

export const getCityWordForm = city => {
  switch (city) {
    case 'Lahti':
      return 'Lahdessa'
    case 'Tampere':
      return 'Tampereella'
    case 'Hyvinkää':
      return 'Hyvinkäällä'
    case 'Lappeenranta':
      return 'Lappeenrannassa'
    case 'Rauma':
      return 'Raumalla'
    case 'Vantaa':
      return 'Vantaalla'
    case 'Helsinki':
      return 'Helsingissä'
    case 'Jyväskylä':
      return 'Jyväskylässä'
    case 'Turku':
      return 'Turussa'
    case 'Seinäjoki':
      return 'Seinäjoella'
    case 'Rovaniemi':
      return 'Rovaniemellä'
    case 'Mikkeli':
      return 'Mikkelissä'
    case 'Lempäälä':
      return 'Lempäälässä'
    default:
      return `${city}ssa`
  }
}
export default segments
